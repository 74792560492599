.settings {
    &__common-duration-form-group, &__common-end-poll-form-group {
        min-width: 260px;
    }

    &__common-select {
        &.is-invalid {
            .select2 .select2-selection.form-control {
                border-color: #F96261;
                box-shadow: 0 0 5px rgba(249, 98, 97, 0.5) !important;
            }
        }

        &.is-valid {
            .select2 .select2-selection.form-control {
                border-color: #37A74A;
            }
        }
    }

    &__common-end-poll-form-group {
        .settings__common-select {
            width: 100%;
        }
    }

    &__common-target-form-group, &__common-offset {
        min-width: 200px;
    }

    &__common-trigger-form-group {
        display: flex;
        flex-wrap: wrap;
    }

    &__common-trigger-form-group-input-group {
        min-width: 200px;
        flex-grow: 1;
    }
}
