@import "./delete-modal-dialog/delete-modal-dialog";
@import "./edit-modal-dialog/edit-modal-dialog";
@import "./change-modal-dialog/change-modal-dialog";
@import "./variants-control/variants-control";

@keyframes fadeInFromNone {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes fadeOutToNone {
	0% {
		display: block;
		opacity: 1;
	}

	1% {
		display: block;
		opacity: 1;
	}

	100% {
		display: none;
		opacity: 0;
	}
}

.title-quest {
	margin-bottom: 22px;

	.title-h-super {
		font-weight: 900;
		font-size: 22px;
		color: #73808D;
	}

	.title-h {
		display: inline-flex;
		.flex-align-items(center);

		h1 {
			padding-bottom: 0;
		}
	}

	.title-form {
		display: none;
		padding-top: 1px;
	}

	.title-input {
		font-size: 22px;
		font-weight: bold;
		line-height: 1.2;
		color: #2e2f31;
		padding: 0 0 0 0;
		border: none;
		background: none;
		/*transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);*/
		min-width: 20px;
		border-bottom: solid 1px #a6b1bc;
	}

	.title-text-hide {
		font-size: 22px;
		font-weight: bold;
		line-height: 1.2;
		min-width: 20px;
		display: none;
	}

	.title-edit {
		&__edit {

			&:before {
				content: '';

				.icon-pencil(#73808d);
			}
		}

		&__link {
			margin-left: 10px;

			&:before {
				content: '';

				.icon-link3(#73808d);
			}
		}

		&__cancel {
			width: 24px;
			height: 24px;
			border: 0;
			background-color: transparent;
			background-repeat: no-repeat;
			background-position: center;
			.icon-close-circle(#a6b1bc);
			margin: 0 0 0 7px;
			transition: all 0.3s ease;
			&:hover {
				.icon-close-circle(#2e2f31);
			}
		}

		&__ok {
			width: 24px;
			height: 24px;
			border: 0;
			background-color: transparent;
			background-repeat: no-repeat;
			background-position: center;
			.icon-ok-colors(#ABEFB7, #37A74A);
			margin: 0 0 0 7px;
			transition: all 0.3s ease;
			&:hover {
				.icon-ok-colors(#37A74A, #fff);
			}
		}
	}

	&.editing {
		.title-h {
			display: none;
		}

		.title-form {
			display: inline-flex;
		}
	}
}

.survey-question__content {
	.page-header {
		margin-bottom: 21px;
	}
}

.quest {

	.survey-question__variants-type-selector {
		margin-bottom: 25px;
		width: 100%;
	}

	.form-group_small {
		margin-bottom: 15px;
	}

	.survey-question__card-footer {
		padding: 20px;
		position: relative;
		border-radius: 0 0 9px 9px;

		background-color: #FBFBFB;
		margin: 63px -20px -20px;
	}



	&__number {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		margin-left: 15px;
		margin-right: 15px;
	}

	&__number-menu {
		display: flex;
		align-items: center;
		margin-bottom: 0;

		&:not(.quest__number-menu--over) {
			.quest__number-menu-item:not(.quest__number-menu-item--active):hover {
				background: #C6CFD3;
				color: #3F65F1 !important;
			}
		}
	}

	&__number-menu-item {
		display: flex;
		width: 28px;
		height: 28px;
		align-items: center;
		justify-content: center;

		border-radius: 50%;
		background: #ECEFF1;
		text-align: center;
		font-weight: bold;
		font-size: 15px;
		color: #3F65F1 !important;
		text-decoration: none !important;
		cursor: pointer;

		transition: background-color 0.3s ease;

		&:not(:first-child) {
			margin-left: 17px;
		}

		&.quest__number-menu-item--original:first-child + .quest__number-menu-item {
			margin-left: 0 !important;
		}

		&--active {
			background: #3F65F1;
			color: #FFF !important;
		}

		&--mirror {
			box-shadow: 0 8px 10px rgba(63, 101, 241, 0.3);
			opacity: 1 !important;
			cursor: grabbing !important;
			z-index: 100;
		}

		&--dragging {
			text-indent: -9999px;
			background: transparent;
			border: 2px dotted #9BB0FB;
		}
	}

	&__number-add-button {
		margin-left: 17px;

		background-color: #3f65f1;
		color: #fff;

		&:hover {
			color: #fff;
		}
	}

	&__number-add-button-icon {
		.icon-plus2(#FFF);

		display: inline-block;
	}

	&__number-menu-body--dragging {
		cursor: grabbing !important;

		* {
			cursor: grabbing !important;
		}
	}

	&__contact-point-menu {
		display: flex;
		margin-right: 15px;
	}

	&__contact-point-menu-list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		&:not(.quest__contact-point-menu--over) {
			.quest__contact-point-menu-item:not(.quest__contact-point-menu-item--active):hover {
				background-color: #C6CFD3;
				color: #3F65F1 !important;
			}
		}
	}

	&__contact-point-menu-item {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px 13px;

		border-radius: 60px;
		background-color: #D9E0FC;
		text-align: center;
		font-weight: bold;
		font-size: 14px;
		color: #3F65F1 !important;
		text-decoration: none !important;
		cursor: pointer;
		white-space: nowrap;

		transition: background-color 0.3s ease;
		margin-bottom: 5px;
		margin-right: 7px;

		&.quest__contact-point-menu-item--original:last-child {
			margin-right: 0;
		}

		&--active {
			background-color: #3F65F1;
			color: #FFF !important;
		}

		&--system {
			padding-left: 6px;

			&:before {
				.icon-star(#9BB0FB);

				content: '';

				margin-right: 4px;
			}
		}

		&--mirror {
			box-shadow: 0 8px 10px rgba(63, 101, 241, 0.3);
			opacity: 1 !important;
			cursor: grabbing !important;
			z-index: 100;
		}

		&--dragging {
			padding: 3px 10px;

			color: transparent !important;
			background-color: transparent;
			border: 2px dotted #9BB0FB;

			&:before {
				display: none;
			}
		}
	}

	&__contact-point-menu-body--dragging {
		cursor: grabbing !important;

		* {
			cursor: grabbing !important;
		}
	}

	&__contact-point-menu-add-button {
		margin-left: 17px;

		background-color: #3f65f1;
		color: #fff;

		&:hover {
			color: #fff;
		}
	}

	&__contact-point-menu-add-button-icon {
		.icon-plus2(#FFF);

		display: inline-block;
	}

	&__save-message {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 10;

		background: #ABEFB7;
		color: #37A74A;
	}

	&__menu-type {
		.flex();
		.flex-align-items(center);
		width: 100%;
		border-bottom: 1px solid #cfd8dc;
		height: 65px;
		justify-content: center;
		font-size: 16px;
		font-weight: bold;

		li {
			flex: 1;
			height: 100%;
			border-right: 1px solid #cfd8dc;

			&:last-child {
				border-right: 0;
			}
		}

		a {
			.flex();
			.flex-align-items(center);
			justify-content: center;
			height: 100%;
			color: #a6b1bc;



			&:hover,
			&:focus {
				text-decoration: none;
			}

			&:hover {
				color: #2e2f31;
			}

			&:before {
				content: "";
				display: block;
				position: relative;
				width: 24px;
				height: 24px;
				background-color: transparent;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				top: 0;
				left: 0;
				margin-right: 12px;
				transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
			}
			&.quest__menu-type--text:before {
				.icon-menu-qw-txt(#A6B1BC);
			}
			&.quest__menu-type--text:hover:before {
				.icon-menu-qw-txt(#2E2F31);
			}
			&.quest__menu-type--img:before {
				.icon-menu-qw-img(#A6B1BC);
			}
			&.quest__menu-type--img:hover:before {
				.icon-menu-qw-img(#2E2F31);
			}
			&.quest__menu-type--video:before {
				.icon-menu-qw-video(#A6B1BC);
			}
			&.quest__menu-type--video:hover:before {
				.icon-menu-qw-video(#2E2F31);
			}
		}

		.active {
			a {
				color: #2e2f31;
				&.quest__menu-type--text:before {
					.icon-menu-qw-txt(#3F65F1);
				}
				&.quest__menu-type--img:before {
					.icon-menu-qw-img(#3F65F1);
				}
				&.quest__menu-type--video:before {
					.icon-menu-qw-video(#3F65F1);
				}
			}
		}

		&--readonly {
			padding-left: 20px;
			padding-right: 20px;
			justify-content: start;

			li {
				flex: 0;
			}
		}
	}

	&__content {
		padding-top: 28px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	&__form {
		.form-btns {
			display: flex;
			justify-content: flex-end;
			background-color: rgba(242, 242, 242, 0.28);
			.border-bottom-radius(4px);
			margin-left: -20px;
			margin-right: -20px;
			margin-bottom: -20px;
			padding: 20px;
			margin-top: 62px;
			position: relative;
			overflow: hidden;
			.btn {
				.flex();
				&:before {
					content: "";
					display: block;
					position: relative;
					width: 15px;
					height: 15px;
					background-color: transparent;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
					top: 2px;
					left: 0;
					margin-right: 11px;
					transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
				}
				&.btn-save:before {
					.icon-save-question(#73808D);
				}
				&.btn-delete:before {
					.icon-delete(#F96261);
					width: 14.4px;
					height: 17px;
					top: 0;
				}
			}
		}

		&-options {
			display: flex;
			flex-wrap: wrap;
			margin-top: -18px;
			margin-left: -21px;
			margin-bottom: 30px;

			& > * {
				margin-top: 18px;
				margin-left: 21px;
			}

			.input-group {
				width: 236px;
			}

			.input-group-text {
				background-color: #f1f5f6;
				border-color: #cfd8dc;
				font-size: 18px;
				font-weight: bold;
				min-width: 48px;
				justify-content: center;
				color: #a6b1bc;
			}

			.btn-add-option {
				display: flex;
				align-items: center;
				height: 48px;
				border: none;
				width: 236px;
				padding: 0 59px 0 0;

				border-radius: 4px;
				font-size: 16px;
				font-weight: 500;
				color: #3f65f1;
				background-color: #e9f0ff;

				.btn-add-option-icon {
					margin-right: 16px;
				}

				&[disabled] {
					opacity: 0.7;
				}
			}

			.btn-add-option-icon {
				display: flex;
				align-items: center;
				justify-content: center;

				width: 48px;
				height: 48px;
				background-color: #3f65f1;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;

				&:before {
					.icon-plus4(#FFF);

					content: '';

					display: block;
				}
			}
		}

		&-stars {
			.flex();
			margin: 26px 0 0 0;
			width: 100%;
			justify-content: center;
			height: 48px;
			.flex-align-items(center);

			li {
				width: 18px;
				height: 18px;
				background-color: transparent;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				.icon-rate-star(#F8CD1C);
				margin-right: 10px;

				&:last-child {
					margin-right: 0;
				}

				&.active {
					.icon-rate-star-fill(#F8CD1C);
				}
			}

		}
	}

	&__checkbox-list-form-control {
		display: flex;
		padding-top: 10px;

		.form-check {
			&:not(:first-child) {
				margin-left: 23px;
			}
		}
	}

	&__rating-type-form-control {
		display: flex;

		.btn-group {
			max-width: 290px;
		}

		.quest__form-stars {
			width: unset;
			margin-top: 0;
			margin-left: 27px;
		}
	}

	&__rating-type-form-control {
		display: flex;

		.btn-group {
			max-width: 290px;
		}

		.quest__form-stars {
			width: unset;
			margin-top: 0;
			margin-left: 27px;
		}
	}

	&__min-sum-form-control {
		max-width: 290px;
	}

	&__comment-checkbox {
		margin-top: 32px;
	}

	&__like-all-checkbox {
		margin-top: 30px;
	}

	.video-upload-actions {
		margin-top: 8px;
		display: flex;

		.btn {
			.flex();

			&:not(:first-child) {
				margin-left: 8px;
			}

			&:before {
				content: "";
				display: block;
				position: relative;
				background-color: transparent;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				left: 0;
				margin-right: 11px;
				transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
			}

			&.btn-upload-link:before {
				.icon-link(#73808D);

				width: 21px;
				height: 19px;
			}

			&.btn-upload-disk {
				input {
					display: none;
				}

				&:before {
					.icon-upload(#73808D);

					width: 17px;
					height: 19px;
				}
			}
		}
	}

	.video-list {
		.flex();
		flex-wrap: wrap;

		margin-top: 16px;

		> * {
			margin-bottom: 9px;
		}

		&:not(.video-list--multiple) {
			.video__letter-input {
				display: none;
			}
		}

		&__item {
			width: 94px;
			height: 94px;
			position: relative;
			margin-right: 9px;
			.border-radius(8px);
			.flex();
			background-size: cover;
			background-position: center center;

			&:after {
				content: " ";
				background-color: rgba(46, 47, 49, 0.43);
				position: absolute;
				top: 0;
				left: 0;
				width: 94px;
				height: 94px;
				.border-radius(8px);
			}

			&:hover .video-list__delete-button {
				animation: fadeInFromNone 0.1s ease-in;
				animation-iteration-count: 1;
				opacity: 1;
			}

			.video-list__letter {
				visibility: visible;
			}
		}

		&__item-load {
			background-color: #ECEFF1;

			&:after {
				content: "\f110";
				font-family: 'Font Awesome 5 Pro';
				font-weight: 900;
				background-color: transparent;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				width: auto;
				height: auto;
				color: #3F65F1;
				font-size: 20px;
				top: 36px;
				left: 36px;

				-webkit-animation: fa-spin 1s infinite steps(8);
				animation: fa-spin 1s infinite steps(8);
			}
		}

		&__delete-button {
			cursor: pointer;
			background-color: rgba(0, 0, 0, 0.66);
			border: none;
			z-index: 3;
			.border-radius(50%);
			margin: auto;

			position: absolute;
			width: 20px;
			height: 20px;
			top: -6px;
			right: -6px;
			font-size: 11px;
			opacity: 0;
			background-color: #000;
			animation: fadeOutToNone 0.1s ease-out;
			animation-iteration-count: 1;

			> i {
				cursor: pointer;
				color: #fff;
			}
		}

		.video__letter-input {
			font-family: Roboto, sans-serif;
			font-size: 34px;
			font-weight: bold;
			color: #fff;
			border: none;
			background: none;
			width: 94px;
			text-align: center;
			z-index: 2;
			text-transform: uppercase;
		}
	}

	.file {
		.flex();
		flex-wrap: wrap;

		margin-top: 16px;

		> * {
			margin-bottom: 9px;
		}

		&__img {
			width: 94px;
			height: 94px;
			position: relative;
			margin-right: 9px;
			.border-radius(8px);
			.flex();
			background-size: cover;
			background-position: center center;

			&:after {
				content: " ";
				background-color: rgba(46, 47, 49, 0.43);
				position: absolute;
				top: 0;
				left: 0;
				width: 94px;
				height: 94px;
				.border-radius(8px);
			}

			.file__input-text {
				font-size: 34px;
				font-weight: bold;
				color: #fff;
				border: none;
				background: none;
				width: 94px;
				text-align: center;
				z-index: 2;
				text-transform: uppercase;
			}

			.file__delete {
				width: 32px;
				height: 32px;
				background-color: rgba(0, 0, 0, 0.66);
				border: none;
				z-index: 2;
				.border-radius(50%);
				margin: auto;

				>i {
					color: #fff;
				}
			}
		}

		&__upload {
			width: 94px;
			height: 94px;
			border: dashed 1px #cfd8dc;
			font-size: 11px;
			color: #73808d;
			.border-radius(8px);
			.flex();
			flex-direction: column;
			text-align: center;
			cursor: pointer;

			>input[type="file"] {
				display: none;
			}

			.btn-add-file {
				width: 32px;
				height: 32px;
				background-color: #cfd8dc;
				margin: 14px auto 9px;
			}

			.file__bigger-text {
				font-size: 12px;
				font-weight: 500;
				margin-bottom: 2px;
			}
		}

		&__youtube {
			width: 94px;
			height: 94px;
			border: dashed 1px #cfd8dc;
			font-size: 12px;
			font-weight: 500;
			color: #73808d;
			.border-radius(8px);
			.flex();
			flex-direction: column;
			align-items: center;
			margin-left: 9px;

			.fa-youtube {
				font-size: 30px;
				color: #cfd8dc;
				margin-top: 8px;
				margin-bottom: 6px;
			}
		}
	}
}

.preview {
	background: url(../../assets/img/fqz_quest_bg_opt.jpg) center -27px no-repeat;
	background-size: 1524px 1016px;
	.border-radius(4px);
	.box-shadow(0 2px 4px 0 #cfd8dc);
	overflow: hidden;

	&__tabs {
		height: 54px;
		.flex();
		position: relative;
		border-bottom: none;
		overflow: hidden;

		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 108px;
			-webkit-filter: blur(10px);
			filter: blur(10px);
			z-index: 1;
			background: url(../../assets/img/fqz_quest_bg_opt.jpg) center top no-repeat;
			background-size: 1524px 1016px;
			top: -27px;
		}

		&-tab {
			color: rgba(255, 255, 255, 0.4);
			background: none !important;
			border: none !important;
			.border-radius(0);
			border-right: 1px solid rgba(207, 216, 220, 0.1) !important;
			width: 33.33%;
			z-index: 2;
			font-size: 16px;
			font-weight: 500;
			text-align: center;
			padding: 19px 0;

			&.active,
			&:hover {
				color: rgba(255, 255, 255, 1) !important;

				.btn-question {
					opacity: 1;
				}
			}

			.btn-question {
				opacity: 0.4;
				.transition(all, 0.3s, ease);
			}
		}
	}

	&__tab-pane {
		height: 100%;
		overflow: auto;

		&--mobile {
			height: 935px;

			.preview__content-device {
				background: url(../../assets/img/iphone.png) 0 40px no-repeat;
				width: 370px;
				height: 790px;
				position: absolute;
				top: 48px;
				background-size: 370px 750px;
				margin: 0 auto;
			}

			.preview__content-page {
				position: absolute;
				width: 323px;
				height: 552px;
				background: #fff;
				overflow-y: auto;
			}
		}

		&--tablet {
			.preview__content {
				width: 768px;
				height: 1024px;
			}

			.preview__content-page {
				width: 768px;
				height: 1024px;
			}
		}

		&--desktop {
			.preview__content {
				width: 1366px;
			}

			.preview__content-page {
				width: 1366px;
				height: 768px;
			}
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		position: relative;
	}
}

.animate-opacity {
	transition: opacity 600ms cubic-bezier(0.23, 1, 0.32, 1);
}
