.folder-option {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  &__icon {
    display: inline-block;
    margin-right: 11px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  &__parent {
    color: @f-color-service;
  }

  .select2-selection & {
    padding: 0;
  }
}

.user-option {
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;

  &__icon {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 11px;
    position: relative;
    align-items: center;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.reviews-page {
  &-filters {
    padding-bottom: 0;

    &__container {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
    }

    .reviews-filter {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 20px;
      margin-right: 0;
    }

    &__actions {
      margin-left: auto;
      display: flex;
    }

    &__toggler {
      .f-btn {
        width: 100%;
        margin-left: 0 !important;
        height: auto;
        padding-bottom: 16px;
      }
      .f-btn[data-open] {
        padding-top: 16px;
      }
    }

    .only-mobile({
      border-bottom: none;
      padding-top: 0px;

      &__container {
        flex-direction: column;
      }

      .reviews-filter {
        margin-bottom: 15px;
        width: 100%;

        .input-checkbox {
          margin-top: 5px;
        }
      }

      &__actions {
        margin-top: 10px;
        padding-left: 0!important;
        padding-right: 0!important;

        .f-btn {
          margin-left: 10px;
          margin-right: 10px;
          flex-grow: 1;
          flex-basis: 50%;
        }
      }
    });
  }

  .tablet-and-desktop({
    [data-filters] {
      display: block!important;
    }
  });

  [data-filters] + [data-filters-toggler] {
    [data-open] {
      display: none;
    }
  }

  [data-filters][data-closed] + [data-filters-toggler] {
    [data-open] {
      display: block;
    }
    [data-close] {
      display: none;
    }
  }
}
