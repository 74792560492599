.question-statistics {
    &__rating {
        display: flex;
        align-items: center;
    }

    &__rating-star {
        width: 28px;
        height: 27px;
    }
}
