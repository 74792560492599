.select-group {
	&__label {
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		color: #73808D;
	}

	&__control {
		.select2-selection {
			height: unset;
		}

		.select2-selection__rendered {
			font-weight: 500 !important;
			font-size: 12px !important;
			color: #000000 !important;

			padding-right: 12px !important;
		}

		.select2-selection__placeholder {
			font-size: 12px;
		}
	}

	&__dropdown {
		&:before {
			left: 21.7px !important;
			right: unset;
		}

		&--right {
			&:before {
				left: unset !important;
				right: 21.7px !important;
			}
		}
	}
}
