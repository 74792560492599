@import "_mixins";

.image-picker {
	display: flex;
	width: 105px;
	height: 105px;
	min-width: 105px;
	min-height: 105px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-right: 9px;

	background: #ECEFF1;
	border-radius: 8px;
	cursor: pointer;

	transition: background-color 0.2s ease;

	&:hover {
		background: #C6CFD3;
	}

	&--zoomable {
		.image-picker__preview {
			cursor: zoom-in	;
		}
	}

	&--empty {
		.image-picker__icon {
			display: block;
		}

		.image-picker__label {
			display: block;
		}

		.image-picker__preview {
			display: none;
		}

		.image-picker__clear-button {
			display: none;
		}
	}

	&__icon {
		display: none;

		.icon-upload(#73808D);
	}

	&__label {
		display: none;
		margin-top: 10px;

		font-size: 10px;
		line-height: 12px;
		text-align: center;
		color: #73808D;
	}

	&__clear-button {
		position: absolute;
		width: 20px;
		height: 20px;
		top: -6px;
		right: -6px;
		margin: auto;

		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.66);
		border: none;
		z-index: 3;
		border-radius: 50%;
		font-size: 11px;
		background-color: #000;

		> i {
			cursor: pointer;
			color: #fff;
		}
	}

	&__preview {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		border-radius: 8px;
		background-size: cover;
		background-position: center center;
		cursor: default;
	}
}
