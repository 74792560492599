.report-list {
    &__card {
        margin-top: 20px;
        padding: 14px 20px;
        min-height: 600px;

        background: #FFFFFF;
        box-shadow: 0 2px 4px #CFD8DC;
        border-radius: 9px;
    }

    &__empty {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 131px;
        padding-bottom: 180px;

        .text {
            font-size: 15px;
            line-height: 1.3;

            width: 500px;
            max-width: 500px;
            min-width: 500px;
            padding-right: 55px;
            margin-left: 50px;

            ul {

                color: #2e2f31;
                margin-bottom: 25px;

                li {
                    font-size: 15px;
                    line-height: 1.3;
                    margin-bottom: 7px;
                    padding-left: 24px;
                    position: relative;

                    &:before {
                        content: '';
                        width: 6px;
                        height: 6px;
                        position: absolute;
                        left: 8px;
                        top: 7px;
                        background-color: #cfd8dc;
                        border-radius: 50%;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

            }

            p, .item {
                margin-bottom: 14px;

                &.first-item {
                    margin-bottom: 28px;
                }
            }
        }
    }

    &__card-header {
        margin-top: -14px;
        margin-left: -20px;
        margin-right: -20px;

        padding: 14px 20px;

        justify-content: flex-end;
        display: flex;

        border-bottom: 1px solid #ECEFF1;

        &_empty {
            border-bottom: none;
        }

    }

    &__table {
        margin-top: 30px;
        margin-left: -7px;
        margin-right: -7px;

        td {
            height: 60px;
        }
    }


    &__table-owner-head-cell {
        max-width: 250px;
    }

    &__table-name-cell {
        font-weight: bold;
        padding: 0!important;
    }

    &__table-name {
        display: block;
        padding: 12px;
        color: #212529 !important;
        text-decoration: none !important;
    }

    &__table-last-edited-at-head-cell {
        max-width: 250px;
    }

    &__table-actions-head-cell {
        width: 147px;
    }

    &__table-actions-cell {
        width: 147px;
    }

    &__table-button {
        border-radius: 0;

        &:not(:first-child) {
            margin-left: 30px;
        }
    }

    &__table-copy-link-button {
        .icon-link6(#73808D) !important;
    }

    &__table-share-button {
        .icon-share(#73808D) !important;

        width: 17px !important;
        height: 17px !important;
    }

    &__table-delete-button {
        .icon-delete(#73808D) !important;

        width: 17px !important;
        height: 17px !important;
    }
}
