@import "./new-folder-modal-dialog/new-folder-modal-dialog";
@import "./move-modal-dialog/move-modal-dialog";
@import "./delete-folder-modal-dialog/delete-folder-modal-dialog";
@import "./delete-survey-modal-dialog/delete-survey-modal-dialog";
@import "./duplicate-survey-modal-dialog/duplicate-survey-modal-dialog";
@import "./archive-survey-modal-dialog/archive-survey-modal-dialog";
@import "./restore-survey-modal-dialog/restore-survey-modal-dialog";
@import "./element-card/element-card";
@import "./element-row/element-row";

.survey-list {
  &__empty {
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    line-height: 1.3;

    &--watcher {
      min-height: 396px !important;
    }

    .text {
      max-width: 500px;
      margin-left: 50px;

      @media screen and (max-width: 825px) {
        margin-left: 0;
        margin-top: 20px;
        text-align: center;
      }

      ul {
        color: #2e2f31;
        margin-bottom: 25px;

        li {
          font-size: 15px;
          line-height: 1.3;
          margin-bottom: 7px;
          padding-left: 24px;
          position: relative;

          &:before {
            content: "";
            width: 6px;
            height: 6px;
            position: absolute;
            left: 8px;
            top: 7px;
            background-color: #cfd8dc;
            border-radius: 50%;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      p,
      .item {
        margin-bottom: 14px;
        &.first-item {
          margin-bottom: 28px;
        }
      }
    }
  }

  &__view-toggle {
    margin-left: -25px;
    margin-right: -25px;
    padding-left: 22px;
    padding-right: 22px;

    .survey-list__view-toggle-button {
      &:not(:first-child) {
        margin-left: 22px;
      }
    }
  }

  &__view-toggle-button {
    padding: 0;

    border-radius: 0;
    box-shadow: none !important;
  }

  &__view-toggle-cards-button {
    .icon-squares(#cfd8dc);

    &.survey-list__view-toggle-button--active,
    &:hover {
      .icon-squares(#3f65f1);
    }
  }

  &__view-toggle-table-button {
    .icon-list(#cfd8dc);

    &.survey-list__view-toggle-button--active,
    &:hover {
      .icon-list(#3f65f1);
    }
  }

  &__card {
    margin-top: 20px;

    min-height: 600px;
  }

  &__card-first-row {
    display: flex;
    height: 61px;
  }

  &__summary {
    display: inline-flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    border-right: 1px solid #f2f5f6;
  }

  &__summary-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    padding-left: 25px;
    padding-right: 25px;

    &:not(:first-child) {
      border-left: 1px solid #f2f5f6;
    }
  }

  &__summary-send-item {
    .survey-list__summary-item-value {
      color: #3a5cdc;
    }
  }

  &__summary-open-item {
    .survey-list__summary-item-value {
      color: #8400ff;
    }
  }

  &__summary-process-item {
    .survey-list__summary-item-value {
      color: #2d99ff;
    }
  }

  &__summary-filled-item {
    .survey-list__summary-item-value {
      color: #16ceb9;
    }
  }

  &__summary-target-item {
    .survey-list__summary-item-value {
      color: #caad46;
    }
  }

  &__summary-item-value {
    font-weight: bold;
    font-size: 19px;
  }

  &__summary-item-label {
    margin-top: 5px;

    font-weight: 500;
    font-size: 12px;
    color: #73808d;
  }

  &__search {
    display: flex;
    height: 100%;
    align-items: center;
    flex-grow: 1;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;

    &--active {
      padding-left: 19px;
      padding-right: 19px;

      border: 1px solid #3f65f1;
      border-bottom: none;
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
      box-shadow: 0 5px 15px rgba(46, 47, 49, 0.3);
      overflow: hidden;

      .survey-list__search-clear-button {
        right: 12px;
      }
    }

    &--empty {
      .survey-list__search-clear-button {
        display: none;
      }
      .survey-list__search-input {
        padding-right: 0;
      }
    }
  }

  &__search-icon {
    .icon-search(#73808d);

    display: inline-block;
    margin-right: 17px;
  }

  &__search-input {
    display: block;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 24px;
    height: 100%;

    background-color: #fff;
    background-clip: padding-box;

    font-size: 16px;
    color: #2e2f31;
    font-weight: 400;
    border: none;

    transition: border-color 0.15s ease-in-out;
  }

  &__search-clear-button {
    .icon-plus3(#2e2f31) !important;

    position: absolute;
    right: 13px;
    top: calc(50% - (17px / 2));
    padding: 0;
    transform: rotate(45deg);

    border-radius: 0;
    box-shadow: none !important;

    transition: none;
  }

  &__search-menu {
    padding-bottom: 15px;

    border-top: none !important;
    border-color: @f-color-primary!important;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    box-shadow: 2px 6px 6px rgba(46, 47, 49, 0.15);

    .survey-list__search-menu-item:not(:first-child) {
      margin-top: 2px;
    }

    .ui-menu-item-wrapper {
      border: none;
      background: none;
      margin: 0;
      padding: 0;
    }
  }

  &__search-menu-item {
    display: flex;
    min-height: 34px;
    align-items: center;
    padding-left: 8px;

    &--focus {
      background: #f1f5f6;
    }
  }

  &__search-menu-item-icon {
    display: inline-block;
    flex-shrink: 0;
    margin-right: 11px !important;
  }

  &__search-menu-item-folder-icon {
    .icon-folder(#A6B1BC) !important;

    margin-left: 2px !important;
  }

  &__search-menu-item-survey-icon {
    .icon-survey(#A6B1BC) !important;
  }

  &__search-menu-item-name {
    font-size: 14px;
    color: #3f65f1;
  }

  &__search-menu-item-sub-name {
    font-size: 12px;
    color: #73808d;
  }

  &__search-menu-no-data-placeholder {
    padding: 5px 54.3px 5px 54.3px;

    font-size: 12px;
    color: #2e2f31;
    cursor: text !important;

    .only-mobile({
      padding: 5px 15px;
    });
  }

  &__search-menu-loading-placeholder {
    display: flex;
    align-self: center;
    justify-content: center;
    margin: 0 !important;
    height: unset !important;
    padding-bottom: 10px;

    font-size: 14px !important;
    color: #3f65f1;
    border: none !important;
  }

  &__card-second-row {
    display: flex;
    height: 75px;
    align-items: center;

    padding-left: 20px;
    padding-right: 20px;

    &_no-border {
      border-bottom-color: transparent;
    }
  }

  &__filters {
    display: flex;
    align-items: center;

    .survey-list__filter:not(:first-child) {
      margin-left: 36px;
    }
  }

  &__filters-checkbox {
    .form-check-input:checked + .form-check-label {
      font-weight: 500;
    }
  }

  &__author-filter-control-avatar {
    width: 28px;
    height: 28px;
    margin-right: 12px;

    border-radius: 50%;
    object-fit: cover;
  }

  &__add-folder-button {
    display: flex;
    height: 38px;
    padding: 0 18px;
    align-items: center;

    border: 0;
    border-radius: 4px;
    background-color: #3f65f1;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    line-height: 1;

    transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);

    &:hover {
      background-color: #3553c1;
      color: #fff;

      &:after {
        background-color: #3553c1;
      }
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  &__survey-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  &__survey-play-status {
    background-color: #4cd964;
  }

  &__survey-stop-status {
    background-color: #f96261;
  }

  &__favour {
    .icon-rate-star(#FFCC00);

    width: 16px;
    height: 16px;

    border: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;

    &--active {
      .icon-rate-star-fill(#FFCC00);
    }
  }

  &__menu-button {
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    padding: 0;

    border-radius: 50%;
    box-shadow: none !important;

    transition: none;

    &:before {
      .icon-menu2(#FFF);

      content: "";
    }
  }

  &__survey-cards {
    margin-top: 15px;

    .survey-list__survey-cards-category {
      margin-bottom: 30px;
      &:not(:first-child) {
        margin-top: 30px;
      }
      &:last-child {
        margin-bottom: 30px;
      }
    }
  }

  &__survey-cards-category {
    //max-width: 943px;

    &--open {
      .survey-list__survey-cards-category-arrow {
        transform: rotate(180deg);
      }
    }
  }

  &__survey-cards-category-header {
    display: flex;
    align-items: center;
  }

  &__survey-cards-category-title {
    display: flex;
    align-items: center;

    cursor: pointer;
  }

  &__survey-cards-category-counter {
    font-size: 19px;
    color: #2e2f31;
  }

  &__survey-cards-category-name {
    margin-left: 9px;

    font-weight: bold;
    font-size: 19px;
    color: #2e2f31;
    word-break: break-word;
  }

  &__survey-cards-category-arrow {
    .icon-select-arrow(#2e2f31);

    display: inline-block;
    margin-left: 14px;

    transition: transform 0.3s ease;
  }

  &__survey-cards-category-content {
    margin-top: 26px;
  }

  &__survey-cards-category-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -20px;
    margin-left: -21px;

    & > * {
      padding-left: 21px;
      margin-top: 20px;
    }
  }

  &__survey-cards-category-show-more-button {
    width: 100%;
    margin-top: 18px;

    background: #eceff1;
    color: #73808d;
    border-radius: 6px;
  }

  &__survey-table {
    margin-top: 5px;

    font-size: 12px;
    color: #2e2f31;

    width: calc(100% - 15px);
    margin-left: 7px;
    min-width: 1290px;
  }

  &__survey-single-category-table {
    margin-top: 36px;
  }

  &__survey-table-head {
    display: flex;
    border-bottom: 2px solid #e7ebed;
  }

  // &__survey-table-body {
  //   display: inline;
  // }

  &__survey-table-head-cell {
    display: flex;

    padding: 0.75rem;

    font-weight: bold;
    text-align: center;
    flex-shrink: 0;
  }

  &__survey-table-category {
    margin-top: 22px;

    &--open {
      .survey-list__survey-table-category-arrow {
        transform: rotate(180deg);
      }
    }
  }

  &__survey-table-category-header {
    display: flex;
    align-items: center;
    padding-bottom: 13px;

    border-bottom: 1px solid #e7ebed;

    .survey-list__survey-table-category-header-wrapper {
      display: flex;
      align-items: center;
      width: 100%;

      .show-more {
        position: sticky;
        right: 10px;
        text-align: right;
        pointer-events: none;
        z-index: 10;

        a {
          pointer-events: all;
        }
      }
    }
  }

  &__survey-table-category-title {
    display: flex;
    align-items: center;

    cursor: pointer;
    position: sticky;
    left: 8px;
    z-index: 10;
  }

  &__survey-table-category-counter {
    font-size: 19px;
    color: #2e2f31;
  }

  &__survey-table-category-name {
    margin-left: 9px;

    font-weight: bold;
    font-size: 19px;
    color: #2e2f31;
  }

  &__survey-table-category-arrow {
    .icon-select-arrow(#2e2f31);

    display: inline-block;
    margin-left: 14px;

    transition: transform 0.3s ease;
  }

  &__survey-table-row {
    display: inline-flex;
    min-height: 60px;
    min-width: 100%;

    color: #2e2f31;
  }

  &__survey-table-head-cell {
    padding-top: 0;
  }

  &__survey-table-cell {
    display: flex;
    padding: 0.75rem;
    align-items: center;

    border-bottom: 1px solid #e7ebed;
    flex-shrink: 0;
  }

  &__survey-table-checked-head-cell,
  &__survey-table-checked-cell {
    width: 35px;
    padding: 0px;
    padding-left: 6px;
  }

  &__survey-table-icon-head-cell,
  &__survey-table-icon-cell {
    width: 35px;
    padding-right: 5px;
  }

  &__survey-table-icon-cell {
    justify-content: flex-end;
  }

  &__survey-table-name-head-cell,
  &__survey-table-name-cell {
    min-width: 250px;
    flex-grow: 2;
    flex-shrink: 1;
    flex-basis: 100%;
  }

  &__survey-table-owner-name-head-cell,
  &__survey-table-owner-name-cell {
    max-width: 180px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 50%;
  }

  &__survey-table-created-at-head-cell,
  &__survey-table-created-at-cell {
    width: 110px;
    justify-content: center;
  }

  &__survey-table-send-head-cell,
  &__survey-table-send-cell {
    width: 95px;
    justify-content: center;
  }

  &__survey-table-open-head-cell,
  &__survey-table-open-cell {
    width: 80px;
    justify-content: center;
  }

  &__survey-table-process-head-cell,
  &__survey-table-process-cell {
    width: 95px;
    justify-content: center;
  }

  &__survey-table-filled-head-cell,
  &__survey-table-filled-cell {
    width: 95px;
    justify-content: center;
  }

  &__survey-table-target-head-cell,
  &__survey-table-target-cell {
    width: 140px;
    justify-content: center;
  }

  &__survey-table-status-head-cell,
  &__survey-table-status-cell {
    width: 65px;
    justify-content: center;
  }

  &__survey-table-favour-head-cell,
  &__survey-table-favour-cell {
    width: 40px;
    justify-content: center;
  }

  &__survey-table-actions-head-cell,
  &__survey-table-actions-cell {
    width: 50px;
    justify-content: center;

    .user--filial-employee & {
      display: none;
    }
  }

  &__breadcrumbs {
    margin-bottom: 20px;
  }

  &__card-footer {
    display: flex;
    padding: 21px 22px;
    margin: 35px -20px -35px;

    background: #fbfbfb;
    mix-blend-mode: normal;
    border-radius: 0 0 7px 7px;
  }

  &__back-button {
    display: flex;
    align-items: center;

    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #2e2f31;

    &:before {
      .icon-arrow-back(#73808d);

      content: "";

      margin-right: 10px;

      transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    &:hover {
      &:before {
        .icon-arrow-back(#2e2f31);
      }
    }
  }
}

.user--filial-employee {
  .survey-list {
    &__element-card-dropdown,
    &__survey-card-checked,
    &__element-card-favour,
    &__survey-table-actions-head-cell,
    &__survey-table-actions-cell,
    &__survey-table-checked-cell,
    &__survey-table-checked-head-cell,
    &__survey-table-favour-head-cell,
    &__survey-table-favour-cell {
      display: none !important;
    }

    &__element-card-created-at {
      left: 10px;
    }
    &__survey-card-status {
      left: 80px;
    }
    &__survey-card-type {
      left: 105px;
    }
  }
}
