.review-question-view {
  &__meta {
    margin-top: 12px;
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    color: @f-color-text;
  }
  // question types
  &--type {
    &_text {
    }
    &_image {
    }
    &_vars {
    }
    &_text-input {
    }
    &_date {
    }
    &_address {
    }
    &_upload {
    }
    &_quiz {
    }
    &_priority {
    }
    &_gallery {
    }
  }

  &--media-type {
    &_text {
    }
    &_image {
    }
    &_video {
    }
  }

  &__name {
    display: block;

    font-weight: 500;
    font-size: 19px;
    color: #2e2f31;

    .question-name__required-mark {
      display: none;
    }
  }

  &__text {
    margin-top: 10px;
    display: block;

    font-weight: normal;
    font-size: 16px;
    color: #73808d;
  }

  &__body {
    margin-top: 18px;
    display: flex;
    overflow: hidden;
  }

  &__media-section {
    margin-right: 32px;
  }

  &__answer-section {
    flex-grow: 1;
    overflow: hidden;
  }

  &-block {
    border-radius: 8px;
    background-color: #eceff1;
    padding: 15px 20px;
    font-size: 16px;
    line-height: 1.2;
    color: #2e2f31;
    margin-bottom: 1px;
    &.skip-block {
      background-color: transparent;
    }

    &--comment {
      background-color: #D9E0FC;
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 9px;
      line-height: 1;
    }

    &__text {
      font-size: 16px;
      font-weight: normal;
      overflow: hidden;
      color: #2E2F31;
    }

    &__text--skipped {
      color: #73808d;
    }

    &__small-text {
      font-size: 14px;
      overflow: hidden;
    }

    &__label {
      font-size: 12px;
      color: #73808d;
      line-height: 1.08;
      margin-bottom: 4px;
    }
  }

  // media-section
  &__media-section {
    width: 295px;
  }

  &-media {
    height: 220px;

    border-radius: 8px;
    overflow: hidden;
  }

  .review-question-view-image {
    width: 100%;
    height: 100%;
    object-fit: contain;

    cursor: pointer;
  }

  .review-question-view-video {
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block !important;

    cursor: pointer;

    &__content {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__preview-svg{
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);

     svg{
       width: 48px;
       height: 48px;
     }
    }

    &__mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background: black;
      opacity: 0.5;
    }

    &__play-button {
      .icon-video-play-button(#FFF);

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      opacity: 0.7;
    }
  }

  .review-question-view-slider {
    width: 100%;
    height: 100%;
  }

  .fs-file-audio-icon, .print-audio {
    display: none !important;
  }



  &__files-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: calc(100% + 10px);
    padding-bottom: 9px;
    padding-top: 3px;
  }

  &-file {
    width: 105px;
    height: 105px;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    img,
    video,
    a {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
    .pseudo {
      position: absolute;
      z-index: 999;
      opacity: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    &-wrapper {
      span {
        display: none;
      }
      .img-print-link {
        display: none;

        .review-question-view-video__preview-svg{
          display: none !important;
        }
      }
      .img-prewiew {
        height: 105px;
      }
    }
  }

  // rating
  &-rating {
    display: flex;
    align-items: center;

    &__label {
      margin-left: 18px;
      font-weight: 900;
      font-size: 16px;
      color: #2e2f31;
    }
  }

  &-variants {
    padding: 0 15px;
  }

  &-variant {
    border-top: 1px solid #e7ebed;
    padding: 10px 1px;
    font-size: 16px;
    color: #2e2f31;

    &:last-child {
      border-bottom: 1px solid #e7ebed;
    }


    &--selected {
      border: none;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: calc(1px + 15px);
      padding-right: calc(1px + 15px);
      background: #d9e0fc;
      border-radius: 8px;

      & + .review-question-view-variant {
        border-top: none;
      }
    }

    &--selected + .review-question-view-variant--selected {
      margin-top: 1px;
    }

    &__custom-variant {
      margin-top: 12px;
      font-size: 14px;
    }
  }

  // clarifying question
  &-clarifying-question {
    margin-top: 27px;

    &__text {
      font-weight: bold;
      font-size: 16px;
      color: #2e2f31;
      margin-bottom: 15px;
    }

    &__service-text {
      font-size: 12px;
      color: #73808d;
      font-weight: normal;
    }
  }

  &-priority {
    &-variant {
      display: flex;
      align-items: center;
      min-height: 40px;
      margin: 1px 0;
      font-size: 16px;
      color: #2e2f31;
      background: #eceff1;
      border-radius: 8px;
      padding: 15px;
      &__index {
        font-weight: bold;
        margin-right: 10px;
      }
      &__text {
      }
    }
  }

  &-media-variants {
    &__media {
      width: 115px;
      border-radius: 8px;
      padding: 10px;

      img {
        width: 95px;
        height: 95px;
        object-fit: cover;
        border-radius: 8px;
      }

      &-description {
        font-size: 12px;
        margin-top: 5px;
        line-height: 1.4;
        max-height: calc(12px * 2.8);
        overflow: hidden;
        color: #2e2f31;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &.selected {
        margin-right: 10px;
        background: #d9e0fc;
      }
    }
  }

  &-gallery {
    .table {
      font-size: 14px;
      max-width: 722px;
      th {
        font-weight: 500;
        font-size: 12px;
      }
      td {
        padding-top: 9px;
        padding-bottom: 9px;
      }
    }

    &__item {
      &-media {
        width: 35px;
        height: 35px;
        border-radius: 8px;
        position: relative;
        cursor: pointer;
        margin-right: 10px;
        overflow: hidden;
        flex-shrink: 0;

        img {
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .review-question-view-rating {
        &__label {
          font-size: 14px;
        }
      }
      &-description {
      }
      &--inactive {
        .review-question-view-gallery__item {
          &-media,
          &-description {
            opacity: 0.4;
          }
        }
      }
    }
  }

  .only-mobile({
    .review-question-view__answer-section {
      overflow: hidden;
    }
  });

  .star-rating {
    justify-content: flex-end;
  }

  .scale-variant__no-value {
    width: 188px;
  }

  .star-variants {
    display: flex;
    flex-direction: column;
  }

  .star-variant {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    .only-mobile({
      flex-direction: column;
      align-items: flex-start;
      padding: 12px 15px;
    });
  }
  
  .star-variant__text {
    flex-grow: 1;
  }
}

.print-answer {
  .review-question-view-variant:last-child {
    border-bottom: none !important;
  }
}
