.survey-questions {
  /** <Paginator> **/

  &__modern-paginator {
    box-sizing: content-box;
    max-width: 100%;
    margin-top: 17px;
    opacity: 0;
    width: 100%;
    padding: 0 6px;

    font-size: 14px!important;

    &.showed {
      opacity: 1;
    }

    &--compact {
      .survey-questions__modern-paginator-anchor {
        display: flex;
        width: 32px;
        margin-right: 0;

        &.active-anchor {
          margin-right: 5px;
        }
      }

      .survey-questions__modern-paginator-anchor-text {
        display: none;
      }

      .swiper-slide {
        width: 32px;

        &.active-tab {
          width: auto;
        }

        &:not(:first-child) {
          margin-left: 3px;
        }
      }
    }

    .nav-tabs {
      border: none;
    }

    .swiper-wrapper {
      flex-wrap: nowrap;
    }

    .swiper-slide {
      width: auto;
    }
  }

  &__modern-paginator-anchor {
    display: flex;
    width: auto;
    border-radius: 50%;
    align-items: center;
    transition: none;
    margin-right: 14px;

    &.active-anchor {
      width: auto;
      background: none;

      .survey-questions__modern-paginator-anchor-num-wrapper {
        padding: 5px;
        border-width: 3px;
        border-style: solid;
      }

      .survey-questions__modern-paginator-anchor-text {
        display: inline;
        opacity: 1;
      }
    }

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  &__modern-paginator-anchor-num-wrapper {
    padding: 8px;
    border-radius: 50%;
    margin-left: -3px;
    margin-right: -3px;
  }

  &__modern-paginator-anchor-num {
    display: block;
    text-align: center;
    line-height: 22px;
    width: 22px;
    height: 22px;

    background-color: #cfd8dc;
    color: #000;
    border-radius: 50%;
    font-size: 14px;
    font-weight: bold;
  }

  &__modern-paginator-anchor-text {
    mix-blend-mode: normal;
    opacity: 0.6;
    font-weight: 500;
    margin-left: 3px;
    font-size: 15px;

    .active-anchor & {
      margin-left: 11px;
    }
  }

  /** </Paginator> **/
}


@media (min-width: 768px) {
  .survey-questions {
    &__modern-paginator {
      margin-top: 32px;
    }

    &__modern-paginator-anchor-text {
      font-size: 16px;
    }
  }
}

@media (max-width: 767px) {
  .survey-questions__modern-paginator-anchor-text {
    display: none;
  }

  .survey-questions__modern-paginator {
    padding-bottom: 10px;
  }
}

.no-animate {
  .survey-questions__modern-paginator .swiper-wrapper {
    transform: translate3d(0, 0px, 0px);
  }
}
