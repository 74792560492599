.processing-status {
  &--0 {
    color: @answer-new-color;
  }
  &--1 {
    color: @answer-in-process-color;
  }
  &--2 {
    color: @answer-delayed-color;
  }
  &--3 {
    color: @answer-completed-color;
  }
  &--4 {
    color: @answer-handle-color;
  }
}

.reviews-page {
  &-meta {
    display: flex;
    margin-left: calc(-1 * var(--foquz-side-offset));

    .foquz-stats-item {
      .label {
        max-width: 150px;
      }
    }

    .mobile-and-tablet({
      border-bottom: 1px solid @f-color-border;
      margin-right: calc(-1 * var(--foquz-side-offset));

      .foquz-stats-item {
        .label {
          max-width: none;
        }
      }
    });

    .only-mobile({
      flex-direction: column;
      border-bottom: 0;
    });

    .tablet-and-desktop({
      display: flex!important;
    });
  }

  .reviews-page-meta + .reviews-page-settings {
    [data-open] {
      display: none;
    }
  }

  .reviews-page-meta[data-closed] + .reviews-page-settings {
    [data-open] {
      display: block;
    }
    [data-close] {
      display: none;
    }
  }

  .tablet-and-desktop({
    .reviews-page-settings__columns.processing-disabled {
      margin-right: calc(-1 * var(--foquz-side-offset));
    }
  })
}
