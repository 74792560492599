.survey-question {
    &__delete-modal-dialog {
        width: 460px !important;

        &--initializing {
            visibility: hidden;
        }
    }
}

.modal-dialog-edit {
    .modal-header {
        padding-top: 24px !important;
    }
    .modal-body {
        font-size: 14px;
        line-height: 1.3;
        color: #2e2f31;
    }
    b {
        font-weight: bold;
    }
}
