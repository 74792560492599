.strong {
  font-weight: bold;
}

.hidden-input {
  max-height: 46px;
}

.no-rgba-colorpicker {
  width: 279px;
  .colorpicker-default__a-input-container,
  .colorpicker-default__opacity-container,
  .colorpicker-default__history {
    display: none;
  }
}

.poll-design {
  &__delete-modal-dialog.modal-dialog {
    width: 460px;

    .form-group {
      margin-bottom: 0 !important;
    }
  }

  .beta-icon {
    width: 7px;
    margin-bottom: 8px;
    height: 14px;
    margin-left: 5px;
  }
  &__preview {
    border-radius: 9px;
    background-image: url("../../assets/img/poll-design__preview-bg.jpg");

    .preview__tabs:after {
      background: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(27.1828px);
      background-image: none;
    }

    .preview__tabs-tab {
      padding: 18px 0;
    }
  }

  &__content {
    padding: 25px 20px;
    .users__details-modal-dialog-media-form-control-list-item-remove-button {
      line-height: 1;
    }
  }

  &__pseudo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__themes {
    .ps {
      &__rail-x {
        bottom: 20px;
      }
    }

    &-theme {
      width: 180px;
      min-width: 180px;
      max-width: 180px;
      margin-right: 10px;
      background-position: 50% 50%;
      background-size: contain;
      cursor: pointer;
      color: #2e2f31;
      padding-bottom: 25px;

      &:hover {
        color: #3f65f1;
      }
    }

    &-preview {
      width: 180px;
      height: 110px;
      position: relative;
      border-radius: 9px;
      background-color: #ccc;
      margin-bottom: 10px;
      transition: all 0.2s ease-in-out;
      background-size: cover;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        border-radius: inherit;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 0;
      }
    }

    &-name {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.1;
      height: calc((14px * 1.1) * 2);
      max-height: calc((14px * 1.1) * 2);
      margin-bottom: calc(14px * 1.1);
      overflow: hidden;
      text-overflow: ellipsis;
      width: 180px;
    }

    &-font {
      position: absolute;
      top: 6px;
      left: 15px;
      display: inline-flex;
      align-items: baseline;
      justify-content: flex-start;
      color: #ffffff;
      font-size: 20px;

      strong {
        font-size: 45px;
        margin-right: 7px;
        font-weight: bold;
      }
    }

    &-control {
      position: absolute;
      bottom: 4px;
      right: 6px;
      z-index: 11;
    }
  }

  &__menu-button {
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    padding: 0;

    border-radius: 50%;
    box-shadow: none !important;

    transition: none;

    &:before {
      .icon-menu2(#FFF);

      content: "";
    }
  }

  &--initializing {
    visibility: hidden;
  }

  &__upload {
    margin-right: 30px;

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #eceff1;
      border-radius: 8px;
      flex-wrap: wrap;
      flex-direction: column;
      width: 105px;
      height: 105px;
      padding: 20px 16px 20px;
      font-size: 10px;
      line-height: 1;
      font-weight: 500;

      &:before {
        position: static;
        margin-bottom: 10px;
      }

      &_small {
        width: 140px;
        height: 48px;
        align-items: center;
        justify-content: center;
        padding: 15px 10px;
        flex-direction: row;
        border-radius: 9px;

        &:before {
          margin-bottom: 0;
          margin-right: 10px;
        }
      }
    }
  }

  &__color-preview {
    width: 46px;
    min-width: 46px;
    max-width: 46px;
    height: 46px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid #cfd8dc;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
  }

  .checkerboard {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 11px;
    height: 11px;
    background: red;
  }

  .f-1 {
  }

  .f-2 {
    left: 22px;
  }

  .f-3 {
    left: 11px;
    top: 11px;
  }

  .f-4 {
    left: 33px;
    top: 11px;
  }

  .f-5 {
    top: 22px;
  }

  .f-6 {
    left: 22px;
    top: 22px;
  }

  .f-7 {
    left: 11px;
    top: 34px;
  }

  .f-8 {
    left: 33px;
    top: 34px;
  }
  &__uploaded {
    .img {
      height: 100%;
      width: 100%;
    }

    .actions {
      position: absolute;
      transform: translate(calc(-100% + -12px), 11px);
      z-index: 2;
    }
  }

  &__note {
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    color: #73808d;
    padding-top: 10px;
  }

  &__section-name {
    font-weight: bold;
    font-size: 16px;
    color: #2e2f31;
    margin-bottom: 25px;
    margin-top: -10px;

    &_to_text {
      margin-bottom: 15px;
    }
  }

  .form-group.form-group_bordered {
    margin-bottom: 30px;
    border-bottom: 1px solid #e7ebed;
    padding: 0 0px 5px;
    width: 100%;
  }

  .dropdown .survey-list__element-card-dropdown-menu {
    top: -45px !important;
    margin-left: -23px;
    box-shadow: 0 5px 15px rgba(115, 128, 141, 0.4);
  }

  .modal .modal-dialog {
    width: 460px;
  }

  .tab-group__tab-pane-footer {
    margin-top: 0;
    margin-bottom: -25px;
  }

  .users__details-modal-dialog-media-form-control-list {
    margin-right: 30px;
  }

  .users__details-modal-img-preview_logo {
    width: 141px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/img/poll-design__logo-bg.png");
    padding: 12px 8px;
    border-radius: 8px;
    position: relative;

    img {
      max-width: calc(100% - 16px);
      max-height: calc(100% - 24px);
      width: auto;
      height: auto;
      display: block;
      top: 12px;
      bottom: 12px;
      left: 8px;
      right: 8px;
      border-radius: 0;
      object-fit: contain;
      object-position: center;
      margin: auto;
      position: absolute;
    }
  }
}

.quest {
  &__save-message {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: auto;
    height: auto;
    left: -22px;
    right: -22px;
    top: -21px;
    bottom: -21px;

    background: #abefb7;
    color: #37a74a;
  }
}

.preview__content-page {
  transition: opacity 0.25s ease-in-out;
}

.tooltip {
  max-width: 250px;
}

.fa-times:before {
  line-height: 1;
}
