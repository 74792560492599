.review-question-preview {
  flex-shrink: 0;
  width: 110px;
  height: 110px;
  position: relative;

  border: 1px solid #cfd8dc;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;

  &--disabled {
    opacity: 0.4;
    cursor: default;
  }

  &--active {
    z-index: 2;

    &:after {
      .frame(#FFF);

      content: "" !important;

      position: absolute;
      left: -6px;
      top: -6px;

      z-index: -1;
    }
  }

  &:not(:first-child) {
    margin-left: 8px;
  }

  &__index {
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: 500;
    font-size: 12px;
    color: #2e2f31;
    cursor: help;
  }

  &__name {
    width: 90px;
    position: absolute;
    top: 71px;
    left: 10px;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    color: #2e2f31;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: help;
  }

  &__employee-name {
    width: 90px;
    position: absolute;
    top: 86px;
    left: 10px;
    font-weight: 500;
    font-size: 11px;
    text-align: center;
    color: #73808d;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: help;
  }

  &__comment-indicator {
    position: absolute;
    top: 11px;
    right: 10px;
    .icon-comment(#73808d);

    display: inline-block;
  }

  &__type {
    width: 100%;
    height: 32px;
    position: absolute;
    top: 31px;
    display: flex;
    align-items: center;
    justify-content: center;

    *:not(:first-child) {
      margin-left: 5px;
    }
  }

  &__media-type-indicator {
    &--value_text {
      .icon-text(#A6B1BC);
    }

    &--value_vars {
      .icon-type-vars(#A6B1BC);
    }

    &--value_quiz {
      .icon-type-quiz(#A6B1BC);
    }

    &--value_priority {
      .icon-type-priority(#A6B1BC);
    }

    &--value_text-input {
      .icon-type-text(#A6B1BC);
    }

    &--value_address {
      .icon-type-address(#A6B1BC);
    }

    &--value_upload {
      .icon-type-upload(#A6B1BC);
    }

    &--value_date {
      .icon-type-date(#A6B1BC);
    }

    &--value_image {
      .icon-image2(#A6B1BC);
    }

    &--value_video {
      .icon-video(#A6B1BC);
    }

    &--value_media-variants {
      .icon-photovideo(#A6B1BC);
    }

    &--value_gallery {
      .icon-rating-star(#A6B1BC);
    }

    &--value_smile {
      .icon-smile(#A6B1BC);
    }

    &--value_nps {
      .icon-nps(#A6B1BC);
    }

    &--value_matrix {
      .icon-matrix(#A6B1BC);
    }

    &--value_diff {
      .icon-diff(#A6B1BC);
    }

    &--value_stars {
      .icon-stars(#A6B1BC);
    }

    &--value_rating {
      .icon-rating-question(#A6B1BC);
    }

    &--value_filials {
      .icon-filials(#A6B1BC);
    }

    &--value_classifier {
      .icon-classifier(#A6B1BC);
    }

    &--value_scale {
      .icon-scale(#A6B1BC);
    }

    &--value_matrix-3d {
      .icon-matrix-3d(#A6B1BC);
    }
  }

  &__rating {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    position: relative;

    border-radius: 50%;
    font-weight: 900;
    font-size: 13px;
    color: #ffffff;

    &--value_0 {
      background: #ECEFF1 !important;
    }

    &--value_1 {
      background: #f96261 !important;
    }

    &--value_2 {
      background: #f97f46 !important;
    }

    &--value_3 {
      background: #f4a224 !important;
    }

    &--value_4 {
      background: #aab540 !important;
    }

    &--value_5 {
      background: #3ece67 !important;
    }
  }
}
