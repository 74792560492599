.clients {
    &__counter {
        display: flex;
        align-items: center;
    }

    &__counter-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        padding: 0;

        background-color: #ECEFF1;
        border-radius: 50%;

        &:before {
            content: '';
        }
    }

    &__counter-decrement-button {
        &:before {
            .icon-minus(#2E2F31);
        }
    }

    &__counter-increment-button {
        &:before {
            .icon-plus6(#2E2F31);
        }
    }

    &__counter-input {
        min-width: 24px;
        box-sizing: border-box;
        padding: 0;
        border: none;
        margin-right: 9px;
        margin-left: 9px;

        font-weight: 500;
        font-size: 14px;
        color: #2E2F31;
        box-shadow: none;
        text-align: center;
    }
}
