.settings {
    &__conn-channels-body--dragging {
        cursor: grabbing !important;

        * {
            cursor: grabbing !important;
        }
    }

    &__conn-channels-message {
        margin-left: 12px;

        font-size: 12px;
        color: #73808D;
    }

    &__conn-channels-table {
        margin-top: 19px;

        .flex-table__cell {
            align-items: center;
        }
    }

    &__conn-channels-table-body {
        &:not(.settings__conn-channels-table-body--over) {
            .settings__conn-channels-table-row {
                border-radius: 4px;

                &:hover {
                    &:before {
                        background: #F1F4FF;
                    }

                    border-top-color: #FFF;

                    & + .settings__conn-channels-table-row {
                        border-top-color: #FFF;
                    }
                }
            }
        }
    }

    &__conn-channels-table-row {
        height: 62px !important;
        position: relative;

        cursor: pointer;

        &:not(:first-child) {
            border-top: 1px solid #E7EBED;
        }

        transition: border-top-color 0.2s ease;

        .flex-table__cell {
            position: relative;
            z-index: 1;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -8px;
            right: -8px;
            transition: background 0.2s ease;
            z-index: 0;
            pointer-events: none;;
            border-radius: 4px;
        }

        &--inactive {
            color: #CFD8DC;

            .svg-icon {
                opacity: 0.5;
            }
        }

        &--no-template {
            &:before {
                background-color: #F1F5F6;
            }


            .svg-icon {
                opacity: 0.5;
            }
        }

        &--mirror {
            background: #F1F4FF;
            box-shadow: 0 15px 20px rgba(63, 101, 241, 0.3);
            border-radius: 4px;
            z-index: 100;

            .settings__conn-channels-table-cell {
                opacity: 0.4;
            }
        }

        &--dragging {
            position: relative;
            height: 5px !important;
            margin-top: -2.5px;
            margin-bottom: -2.5px;

            background: #9BB0FB;
            border-radius: 5px;
            border: none;
            z-index: 10;

            * {
                display: none !important;
            }
        }
    }


    &__conn-channels-table-name-head-cell,
    &__conn-channels-table-name-cell {
        width: 200px;
        flex-shrink: 0;
    }

    &__conn-channels-table-name-cell {
        font-weight: bold;
    }

    &__conn-channels-table-name {
        display: inline-flex;
        align-items: center;
    }

    &__conn-channels-table-name-icon {
        margin-right: 16px;
    }

    &__conn-channels-table-delay-head-cell,
    &__conn-channels-table-delay-cell {
        width: 110px;
        white-space: nowrap;
        flex-shrink: 0;

        justify-content: center;
    }

    &__conn-channels-table-delay-cell {
        font-weight: bold;
    }

    &__conn-channels-table-repeat-head-cell,
    &__conn-channels-table-repeat-cell {
        width: 140px;
        flex-shrink: 0;

        justify-content: center;
    }

    &__conn-channels-table-repeat-cell {
        font-weight: bold;
    }

    &__conn-channels-table-repeat {
        display: inline-flex;
        align-items: center;

        &--zero {
            color: #A6B1BC;

            .settings__repeat-icon {
                .icon-repeat(#cfd8dc);
            }
        }
    }

    &__conn-channels-table-text-head-cell,
    &__conn-channels-table-text-cell {
        width: 100%;
    }

    &__conn-channels-table-text-cell {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }

    &__conn-channels-table-active-head-cell,
    &__conn-channels-table-active-cell {
        width: 160px;
        flex-shrink: 0;
        justify-content: flex-end;
    }

    &__conn-channels-table-checkbox {
        display: inline-block;
        width: 24px;
        height: 24px;

        label {
            padding: 0 !important;

            &:before {
                top: 0 !important;
            }

            &:after {
                top: 8px !important;
            }
        }
    }

    &__conn-channels-channel-title {
        display: flex;
        margin-bottom: 30px;

        font-weight: 900;
        font-size: 22px;
        color: #2E2F31;
    }

    &__conn-channels-channel-row {
        display: flex;

        .settings__conn-channels-channel-basic-message-form-group {
            flex-grow: 1;
        }
    }

    &__conn-channels-channel-basic-message-form-group {
        .form-control {
            height: 150px;
            min-height: 150px;
            font-size: 14px;
            line-height: 19px;
            color: #2E2F31;
        }
    }

    &__conn-channels-channel-image-picker {
        margin-top: 29px;
        margin-left: 18px;
    }

    &__conn-channels-channel-repeats-header {
        display: flex;
        align-items: center;
        padding-bottom: 12px;

        border-bottom: 1px solid #e7ebed;


    }

    &__conn-channels-channel-repeats-title {
        font-weight: bold;
        font-size: 19px;
        line-height: 13px;
        color: #2E2F31;
    }

    &__conn-channels-channel-repeats-count {
        font-weight: 400;
        color: #73808d;
    }

    &__conn-channels-channel-repeats-sub-title {
        margin-top: 12px;

        font-size: 12px;
        line-height: 13px;
        color: #73808D;
    }

    &__conn-channels-channel-repeat {
        padding-top: 23px;
        position: relative;

        &:not(:last-child) {
            border-bottom: 1px solid #e7ebed;
        }


    }

    &__conn-channels-channel-repeat-remove-button {
        display: flex;
        width: 36px;
        height: 36px;
        align-items: center;
        justify-content: center;
        padding: 0;
        position: absolute;
        top: 20px;
        right: 0;

        z-index: 2;

        &:before {
            content: '';
            .icon-add(#F96261);
            transform: rotate(45deg);
        }
    }

    &__conn-channels-channel-back-button {
        display: flex;
        align-items: center;

        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #2E2F31;

        &:before {
            .icon-arrow-back(#73808D);

            content: '';
            margin-right: 10px;
        }
    }
}

.delay-group {
    .form-control {
        padding: 0 15px;
        text-align: center;
    }

    &__days {
        .form-control {
            width: 90px;
        }
    }

    &__time {
        .form-control {
            width: 90px;
        }
    }
}
