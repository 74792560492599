.design {

	&__menu-type {
		.flex();
		.flex-align-items(center);
		width: 100%;
		border-bottom: 1px solid #cfd8dc;
		height: 65px;
		justify-content: center;
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 20px;

		li {
			flex: 1;
			height: 100%;
			border-right: 1px solid #cfd8dc;
			opacity: 0.4;

			&:last-child {
				border-right: 0;
			}
		}

		a {
			.flex();
			.flex-align-items(center);
			justify-content: center;
			height: 100%;
			//color: #a6b1bc;
			color: #2e2f31;


			&:hover,
			&:focus {
				text-decoration: none;
			}

			&:hover {
				color: #2e2f31;
			}
		}

		.active {
			opacity: 1;
			a {
				color: #2e2f31;
			}
		}
	}

	&__content {
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	&__form {
		.form-group {
			margin-bottom: 30px;
		}
		.form-btns {
			background-color: rgba(242, 242, 242, 0.28);
			.border-bottom-radius(4px);
			margin-left: -20px;
			margin-right: -20px;
			margin-bottom: -20px;
			padding: 20px;
			margin-top: 15px;
			.btn {
				.flex();
				&:before {
					content: "";
					display: block;
					position: relative;
					width: 15px;
					height: 15px;
					background-color: transparent;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
					top: 2px;
					left: 0;
					margin-right: 13px;
					transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
				}
				&.btn-save:before {
					.icon-save-question(#73808D);
				}
				&.btn-delete:before {
					.icon-delete(#F96261);
					width: 14.4px;
					height: 17px;
					top: 0;
				}
			}
		}
	}

	&__file {
		.flex();

		&-img {
			width: 100px;
			height: 48px;
			position: relative;
			margin-right: 10px;
			.border-radius(3.4px);
			background-size: contain;
			border: solid 1px #cfd8dc;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
		}

		&-upload {
			flex: 1;
			height: 48px;
			border: 1px solid #cfd8dc;
			font-size: 14px;
			color: #2e2f31;
			.border-radius(4px);
			cursor: pointer;
			.flex();

			>input[type="file"] {
				display: none;
			}

			.btn-del-file {
				width: 26px;
				height: 26px;
				background-color: rgba(0, 0, 0, 0.66);
				margin: auto 14px auto auto;
				color: #fff;
				.flex();
				align-items: center;
				justify-content: center;
			}
		}

		&-name {
			font-size: 14px;
			line-height: 1;
			color: #2e2f31;
			margin: auto auto auto 19px;
		}
	}

	&__color {
		.flex();
		flex-wrap: wrap;

		.form-control {
			flex: 1;
		}

		.invalid-feedback {
			margin-left: 110px;
		}

		&-view {
			width: 100px;
			height: 48px;
			position: relative;
			margin-right: 10px;
			.border-radius(3.4px);
			border: solid 1px #cfd8dc;
			background-color: #fff;
		}
	}
}

.theme {
	border-radius: 8px;
	height: 130px;
	width: calc(~'50% - 12px');
	position: relative;
	box-shadow: 0 2px 4px 0 #cfd8dc;
	margin-bottom: 22px;

	&:nth-child(2n + 1) {
		margin-right: 24px;
	}
	&.active {
		box-shadow: none;
		&:before {
			content: "";
			position: absolute;
			left: -5px;
			top: -5px;
			width: calc(~'100% + 10px');
			height: calc(~'100% + 10px');
			background-color: #3f65f1;
			border-radius: 13px;
			box-shadow: 0 2px 4px 0 #cfd8dc;
		}
	}
	&.disabled {
		box-shadow: none;
		&:after {
			content: "";
			display: block;
			background: rgba(255, 255, 255, 0.8);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
	}
	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 8px;
		&:after {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.18);
			border-radius: 8px;
		}
	}
	&__content {
		position: absolute;
		bottom: 12px;
		left: 17px;
		color: #ffffff;
	}
	&__title {
		font-size: 18px;
		font-weight: bold;
		line-height: 1.17;
	}
	&__descr {
		opacity: 0.6;
		font-size: 13px;
		line-height: 1.62;
	}
}

// Modal save-design
.save-design {
	.modal-title {
		margin-bottom: 30px;
	}
	.form-group {
		margin-bottom: 0;
	}
	.maxchars {
		position: relative;
		.maxchars__cur {
			color: #cfd8dc;
			font-size: 11px;
			position: absolute;
		}
		.maxchars__input + .maxchars__cur {
			bottom: 18px;
			right: 18px;
		}
		.maxchars__input.is-invalid + .maxchars__cur {
			bottom: 33px;
			right: 38px;
		}
		.maxchars__textarea + .maxchars__cur {
			bottom: 10px;
			right: 10px;
		}
		.maxchars__textarea.is-invalid + .maxchars__cur {
			bottom: 25px;
		}
	}
}

// Modal youtube
.youtube {
	.modal-dialog {
		width: 468px;
	}

	.modal-body {
		padding: 15px 20px 0 20px !important;
	}

	.modal-footer {
		padding: 22px 20px !important;
	}

	.modal-btns {
		justify-content: flex-end;

		.btn {
			width: unset !important;
			min-width: 128px;
		}
	}

	.modal-title {
		margin-bottom: 68px;
	}

	.form-group {
		margin-bottom: 0;
	}

	.message {
		margin-top: 16px;
	}

	.form-label {
		font-weight: normal;
	}

	.message {
		display: flex;
		align-items: center;

		padding: 23px 27px 18px 27px;
		background: rgba(233, 235, 241, 0.45);
		border-radius: 4px;

		&__image {
			align-self: center;
			flex: 0 0 auto;

			opacity: 0.27;
			filter: grayscale(1);
		}

		&__text {
			margin-left: 24px;

			font-family: Roboto, sans-serif;
			font-weight: normal;
			font-size: 14px;
			color: #73808D;
		}
	}

	.maxchars {
		position: relative;
		.maxchars__cur {
			color: #cfd8dc;
			font-size: 11px;
			position: absolute;
		}
		.maxchars__input + .maxchars__cur {
			bottom: 18px;
			right: 18px;
		}
		.maxchars__input.is-invalid + .maxchars__cur {
			bottom: 33px;
			right: 38px;
		}
		.maxchars__textarea + .maxchars__cur {
			bottom: 10px;
			right: 10px;
		}
		.maxchars__textarea.is-invalid + .maxchars__cur {
			bottom: 25px;
		}
	}
}

.launched-survey-alert-modal {
	.modal-dialog {
		width: 460px;
	}

	b {
		font-weight: bold;
	}
}

.save-contact-point-modal {
	.modal-dialog {
		width: 510px;
		max-width: unset;
	}

	.modal-actions {
		& > * {
			&:not(:first-child) {
				margin-left: 15px !important;
			}
		}
	}
}
