.review-card {
  font-weight: 500;
  font-size: 12px;

  &__header {
    padding: 8px 15px;
    border-bottom: 1px solid @f-color-border;
    display: flex;
    align-items: center;

    .icon {
      flex-shrink: 0;
    }
  }
  &__poll-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-left: 10px;
    word-break: break-word;
  }
  &__content {
    padding: 8px 15px 0;
  }
  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  &__executor {
    flex-grow: 1;
  }
  &__complaint {
    flex-shrink: 0;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 15px;
  }
  &__time {
    margin-right: 5px;
  }
  &__answers {
    margin-right: 5px;
  }
}

.reviews-page--grouped {
  .review-card {
    &__header {
      display: none;
    }
    &__content {
      padding-top: 15px;
    }
  }
}

.kanban-header {
  position: sticky;
  top: -1px;
  z-index: 100;
  pointer-events: all;
  height: auto;

  .ps__rail-x {
    opacity: 0;
  }
}

.kanban-header.isSticky + .kanban-sticky {
  z-index: 60;
  .kanban-sticky__bg,
  .kanban-sticky__shadow {
    display: block;
  }
}

.kanban {
  &-column {
    min-width: 250px;
    width: 20%;
  }
}


@media screen and (max-width: 767px) {
  .reviews-kanban {
    .scroll-container__scroll-gutter--left {
      left: 0;
    }
    .scroll-container__scroll-gutter--right {
      right: 0;
    }
    .kanban-wrapper .kanban-sticky {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.reviews-page {
  .reviews-kanban {
    padding-left: 20px;
    padding-right: 20px;

    .mobile-and-tablet({
      padding-left: 0;
      padding-right: 0;

      .kanban-offset {
        width: var(--foquz-side-offset);
        flex-shrink: 0;
      }

      .ps__rail-x {
        opacity: 0;
      }

      .kanban-sticky {
        margin-left: 0;
        margin-right: 0;
      }

      .scroll-container__scroll-gutter--left {
        left: 5px;
      }

      .scroll-container__scroll-gutter--right {
        right: 5px;
      }

      // .scroll-container__wrapper .ps {
      //   padding-left: var(--foquz-side-offset);
      // }
    });
  }
}

.s-app {
  .reviews-page {
    .kanban {
      &-column {
        width: 25%;

        &--new {
          display: none;
        }
      }
    }
  }
}
