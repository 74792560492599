.question-statistics {
    &__rating-statistics-table {
        margin-bottom: 0;

        & > tbody > tr > td {
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }

    &__rating-statistics-table-rating {
        .question-statistics__rating-statistics-table-rating-star {
            width: 17.94px;
            height: 17.77px;

            &:not(:first-child) {
                margin-left: 10px;
            }
        }
    }

    &__rating-statistics-table-percentage-cell {
        padding-right: 3px !important;

        text-align: end;
    }
}
