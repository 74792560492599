.review-details-modal {
  &__footer {
    display: flex;
    position: relative;

    border-top: none;
    background: #fbfbfb;
    padding: 21.5px 30px;

    *:not(:first-child) {
      margin-left: 13px;
    }

    .save-message {
      margin: 0;
    }
  }
}
