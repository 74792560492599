.survey-questions__question-card {

      .ps-wrapper {
        width: 100%!important;
      }

      .ps {
        overflow: visible!important;
      }


}

.survey__images-form-control-add-button_big,
.survey__images-form-control-content
  .survey__images-form-control-add-button.survey__images-form-control-add-button_big {
  width: 105px;
  height: 105px;
  padding: 0 10px;
  margin-bottom: 10px;
}
.survey__images-form-control-add-button-label {
  max-width: 100%;
}

.form-group_to_check.form-group {
  margin-bottom: 9px;

  .form-check {
    min-height: 24px;
    display: inline-flex;
    align-items: center;

    input[type='checkbox'] + label:before {
      border-radius: 3px;
      width: 22px;
      height: 22px;
    }

    input[type='checkbox'] + label:after {
      left: 6px;
    }

    .form-radio + label:before,
    .form-radio + label:after {
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0;
    }

    input[type='checkbox'] + label:before,
    input[type='checkbox'] + label:after,
    input[type='checkbox'] + label:after {
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0;
    }
  }
}

.i-check-icon {
  .icon-check-arrow-2(white);
}

.survey-question__media-form-control-list-content_flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -10px;

  .i-icon-photovideo {
    .icon-photovideo(#A6B1BC);
  }

  .i-icon-photo {
    .icon-image(#A6B1BC);
    width: 24px !important;
    height: 24px !important;
  }

  .i-icon-video {
    .icon-video(#A6B1BC);
  }

  .survey-question__media-form-control-list-loading-placeholder {
    width: 105px;
    height: 105px;
    margin: 0 10px 0 0 !important;
    max-height: 105px;
    min-height: 105px;
  }

  .survey-questions__image.survey-question__media-form-control-list-item {
    width: 105px;
    height: 105px;
    margin: 0 10px 10px 0 !important;
    max-height: 105px;
    min-height: 105px;
  }

  .survey-question__media-form-control-list-item:after {
    display: none !important;
  }
}

.survey-questions__tab-pane.tab-pane {
  animation: slide-to-out 0.35s linear;
}

.survey-questions__tab-pane.tab-pane.active {
  animation: slide-to-in 0.35s linear;
}

@keyframes slide-to-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-to-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.survey__container {
  .survey-question__media-form-control-list-content {
    margin-top: 0;
    width: calc(100% + 20px);
    padding-left: 0;
    padding-bottom: 0;
  }

  .survey-question__media-form-control-list {
    margin-top: 0 !important;
    margin-bottom: -11px;
    padding: 0 10px;
  }

  .survey-question__media-form-control-actions.disabled {
    opacity: 0.5;
    cursor: default !important;

    .survey__images-form-control-add-button {
      background-color: #eceff1 !important;
      cursor: default;
    }
  }

  input[type='checkbox']:not(.f-check-input) {
    & + label:after {
      .icon-checkbox(#000000);

      content: '';

      font-weight: 900;
      font-size: 8px;
      display: inline-block;
      opacity: 0;
      position: absolute;
      top: 4px;
      left: 8px;
      transform: scale(0.8);
      transition: all 0.157s ease-in-out;
    }

    &:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.survey-questions__wrapper {
  .form-control:not(.is-invalid):focus {
    box-shadow: none !important;
    border-color: #3f65f1;
  }
}

.survey-questions {
  &--initializing {
    visibility: hidden;
  }

  &__variants {
    padding-top: 40px;
    font-size: 15px;

    input[type='checkbox'] + label:before {
      border-radius: 3px;
    }

    p {
      margin-bottom: 18px;
    }

    .form-group_to_check.form-group {
      margin-bottom: 9px;
    }
  }

  &__question {
    .survey-new-types-media-variants-slider {
      .swiper-slider-control.swiper-button-disabled {
        display: none !important;
      }
    }
  }

  &__preview {
    width: 347px;
    height: auto;
    margin-top: -13px;
    margin-bottom: -13px;
    min-height: 242px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #cfd8dc;
    font-size: 15px;
    line-height: 1.3;
    color: #000;
    position: relative;
    overflow: hidden;
    //margin-left: -28px;

    &_almost_transparent.survey-questions__preview {
      background-color: transparent !important;
      width: 100%;
    }

    &.survey-question-media-variants__preview {
      overflow: visible;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      min-height: 0;

      .swiper-slide {
        background: transparent;
      }

      .survey-questions__slider {
        padding: 0;
      }
    }

    .survey-new-types-media-variants-slider {
      width: 100%;
      position: relative;

      .swiper-wrapper {
        align-items: flex-start;
      }

      label {
        &:before,
        &:after {
          display: none !important;
        }
        i {
          display: none;
        }
      }

      .survey-questions__slider {
        height: auto !important;
        min-height: 0;
      }

      .swiper-slide {
        flex-direction: column;
        background: transparent;
        height: auto !important;
        border-radius: 0;

        &:only-child {
          margin-right: 0 !important;
        }
      }

      .swiper-slide__media {
        width: 100%;
        height: 235px;
        overflow: hidden;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        flex-shrink: 0;
        position: relative;
        margin-bottom: 15px;

        img {
          border-radius: 0;
          position: absolute!important;
          top: 0;
          left: 0;
          width: 100%!important;
          height: 100%!important;
          object-fit: contain;
          object-position: center;
        }
      }

      .swiper-slide--empty {
        background: transparent !important;
        max-width: 100%;
        .swiper-slide__media {
          background: #eceff1;
          border-radius: 16px;
        }
      }

      input:checked ~ label,
      .checked label {
        i {
          display: block;
        }
        span {
          display: none;
        }
      }

      input:checked ~ .swiper-slide__check,
      .checked .swiper-slide__check {
        background: transparent;
        padding: 0;
        width: 32px;
      }

      input:checked ~ .swiper-slide__checked-curtain,
      .checked .swiper-slide__checked-curtain {
        background: var(--poll-main-color);
        opacity: 0.7;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 4;
        border-radius: 12px;
        cursor: pointer;
      }

      .swiper-slider-control {
        position: absolute;
        top: 0;
        bottom: calc(100% - 235px);
        margin: auto;
        width: 32px;
        height: 32px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        z-index: 4;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        font-size: 16px;

        &--prev {
          left: -16px;
        }

        &--next {
          right: -16px;
        }
      }

      .icon-play-button {
        border: none;
        padding: 0;
        pointer-events: none;

        width: 73px;
        height: 51px;
        position: absolute;
        .icon-video-play(#fff, rgba(0, 0, 0, 0.6));
        border-radius: 0;
        background-color: transparent;
        opacity: 1;
        transition: all 0.25s ease-in-out;
      }
    }

    .survey-questions__slider {
      min-height: 245px;
      height: 245px;

      .swiper-slide {
        height: 245px;

        &--empty {
          max-width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #eceff1;

          i {
            width: 90px !important;
            height: 90px !important;
          }

          .i-icon-photovideo {
            .icon-photovideo-light(#A6B1BC);
          }

          .i-icon-photo {
            .icon-image-light(#A6B1BC);
          }

          .i-icon-video {
            .icon-video-light(#A6B1BC);
          }
        }

        .swiper-slide__input {
          display: none;
        }

        input:checked + &__checked-curtain {
          &:after {
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 2;
            pointer-events: none;
            background: rgba(0, 255, 0, 0.5);
          }
        }

        &__check {
          position: absolute;
          right: 12px;
          top: 12px;
          z-index: 5;

          display: flex;
          align-items: center;
          justify-content: center;

          height: 32px;
          min-width: 32px;
          border-radius: 60px;
          border: 2px solid white;
          color: white;
          font-size: 13px;
          cursor: pointer;

          padding: 0 20px;

          background: rgba(0, 0, 0, 0.8);
        }
      }
    }

    .photo,
    .survey-question__media-form-control-list-item {
      width: 100%;
      height: 100%;
      min-height: 245px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      cursor: pointer;
      position: relative;

      &:after {
        display: none !important;
      }

      img {
        width: 100%;
        height: 100%;
        min-height: 245px;
      }
    }

    .video,
    .survey-question__media-form-control-list-item {
      width: 100%;
      height: 100%;
      min-height: 245px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      cursor: pointer;

      .video-wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          min-width: 100%;
          min-height: 245px;
          position: absolute;
          object-fit: contain;
        }

        .icon {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 73px;
          height: 51px;
          position: absolute;
          .icon-video-play(#fff, rgba(0, 0, 0, 0.6));
          border-radius: 0;
          background-color: transparent;
          opacity: 1;
          transition: all 0.25s ease-in-out;
        }

        &:hover {
          .icon {
            .icon-video-play(#fff, rgba(0, 0, 0, 1));
          }
        }
      }
    }

    .text {
      padding: 36px 44px;
    }

    .survey-question__media-form-control-list-item {
      width: 100%;
      height: 100%;
      min-height: 245px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      cursor: pointer;

      &:after {
        //display: none !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 73px;
        height: 51px;
        position: absolute;
        .icon-video-play(#fff, rgba(0, 0, 0, 0.6));
        content: '';
        border-radius: 0;
        background-color: transparent;
        opacity: 1;
        transition: all 0.25s ease-in-out;
      }

      &:hover {
        &:after {
          .icon-video-play(#fff, rgba(0, 0, 0, 1));
        }
      }

      img {
        width: 100%;
        height: 100%;
        min-height: 245px;
      }
    }

    .text {
      padding: 36px 44px;
    }
    .swiper-slide__description {
      font-size: var(--poll-font-size);
      width: 100%;
      text-align: left;
      padding: 0 15px;
      margin-bottom: 10px;
    }
  }

  &__preview--empty {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eceff1;

    i {
      width: 90px !important;
      height: 90px !important;
    }

    .i-icon-photo {
      .icon-image-light(#A6B1BC);
    }

    .i-icon-video {
      .icon-video-light(#A6B1BC);
    }
  }

  &__collapse-textarea {
    padding-top: 20px;
    //padding-right: 26px;
    padding-right: 0;

    &.wh-top {
      padding-top: 0 !important;
    }

    textarea.form-control {
      min-height: 100px;
      border-radius: 4px;
      box-shadow: none !important;
    }
  }

  &__wrapper_pre {
    margin-left: -28px;
    width: calc(100% + 56px);

    .col-12.col-lg-7 ~ .col-12.col-lg-5 {
      padding-left: 17px;

      .survey-questions__collapse-textarea {
        padding-top: 23px;
      }
    }

    &.no-margin {
      width: 100%;
      margin-left: 0;

      & ~ .survey-questions__variants {
        padding-top: 30px;
      }
    }

    .rating__star {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }

    .rating .rating__star:not(:first-child) {
      margin-left: 15px;
    }
  }

  &__search-menu {
    padding: 5px 0 15px 0;
    border: none !important;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(46, 47, 49, 0.3);
    border-radius: 9px;
    max-height: 240px;
    overflow: hidden;

    &:before {
      position: absolute;
      z-index: 99;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 21px;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        #fff 100%
      );
      content: '';
      display: block;
    }

    &:after {
      position: absolute;
      z-index: 99;
      top: 0;
      left: 0;
      width: 100%;
      height: 21px;
      background-image: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 0%,
        #fff 100%
      );
      content: '';
      display: block;
    }

    .js-body-scroller {
      max-height: 230px;
      overflow: auto;

      .js-body {
        padding-bottom: 10px;
        padding-top: 10px;
      }

      .scroll-element.scroll-y {
        right: 10px !important;
        z-index: 101 !important;
      }
    }

    .survey-list__search-menu-item:not(:first-child) {
      margin-top: 2px;
    }

    .ui-menu-item-wrapper {
      border: none;
      background: none;
      margin: 0;
      padding: 0;
    }
  }

  &__search-menu-item {
    display: flex;
    height: auto;
    align-items: center;
    padding: 5px 24px;
    min-height: 34px;
    font-size: 14px;
    line-height: 1.34;
    transition: all 0.2s ease-in-out;
    color: #3f65f1;

    &--focus {
      background-color: transparent;
    }

    &:hover {
      background-color: #f1f5f6;
    }
  }

  &__search-menu-item-icon {
    display: inline-block;
    margin-right: 11px !important;
  }

  &__search-menu-item-folder-icon {
    .icon-folder(#A6B1BC) !important;

    margin-left: 2px !important;
  }

  &__search-menu-item-survey-icon {
    .icon-survey(#A6B1BC) !important;
  }

  &__search-menu-item-name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
  }

  &__search-menu-no-data-placeholder {
    padding: 5px 54.3px 20px 54.3px;

    font-size: 12px;
    color: #2e2f31;
    cursor: text !important;
  }

  &__search-menu-loading-placeholder {
    display: flex;
    align-self: center;
    justify-content: center;
    margin: 0 !important;
    height: unset !important;
    padding: 15px 0 10px;

    font-size: 14px !important;
    color: #3f65f1;
    border: none !important;
  }

  &__content {
    display: flex;
    max-width: 100%;
    width: 100%;
    // overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  &__image {
    position: relative;
    width: 105px;
    height: 105px;
    min-height: 105px;
    max-height: 105px;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .survey-question__media-form-control-list-item-remove-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      .survey-question__media-form-control-list-item-remove-button {
        opacity: 1;
      }
    }
  }

  &__wrapper {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 0 35px;

    .form-group {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__body {
    max-width: 100%;
    width: 100%;
    flex-grow: 1;
  }

  &__footer {
    margin-bottom: 18px;

    .only-mobile({
      margin-bottom: 25px;
    });
  }



  &__tab-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  &__tab-pane {
    flex-grow: 1;
    display: flex !important;
    flex-direction: column;

    &:not(.active) {
      display: none !important;
    }
  }

  /** <Question> **/

  &__question {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding-bottom: 26px;

    &--type_dishes {
      .survey-questions__question-object {
        &--type_single-multimedia {
          .survey-questions__question-object-content {
            background: none;
          }
        }
      }

      .survey-questions__question-card {
        padding: 20px 20px 40px;

        .ps-wrapper {
        width: 100%!important;
      }

        & > .survey-questions__question-card-column {
          &:last-child {
            position: relative;
            padding-top: 0;

            &:before {
              content: '';

              position: absolute;
              top: -20px;
              left: 0;
              width: 100%;
              height: 37px;
              z-index: 1;

              background: linear-gradient(
                180deg,
                #ffffff 50%,
                rgba(255, 255, 255, 0) 100%
              );

              display: none;
            }

            .survey-questions__question-card-column-content {
              width: 100%;
              height: unset !important;
              overflow: visible !important;

              & > .scroll-content {
                box-sizing: border-box !important;
                width: 100% !important;
                align-items: stretch;
              }

              .scroll-element {
                display: none;
              }
            }
          }
        }
      }

      &.survey-questions__question--multimedia-type_less {
        .survey-questions__question-card
          > .survey-questions__question-card-column {
          &:last-child {
            &:before {
              display: none;
            }
          }
        }

        .survey-questions__question-like-all-button {
          margin-top: 0;
        }
      }
    }

    &:not(.survey-questions__question--type_dishes).survey-questions__question--multimedia-type_less {
      .survey-questions__question-card {
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 40px;

        & > .survey-questions__question-card-column {
          width: 100%;
        }
      }

      .survey-questions__form-text {
        margin-bottom: 24px;
      }

      .survey-questions__form {
        width: calc(100% - 70px);
        margin-left: 35px;
        margin-right: 35px;
      }

      .survey-questions__choice {
        margin-left: 35px;
        margin-right: 35px;

        text-align: start;

        &--option {
          align-self: flex-start;
          font-size: 15px;
        }
      }

      .survey-questions__option {
        justify-content: flex-start;
        margin-left: 25px;
        margin-right: 35px;
      }
    }

    .copy-var {
      .copied {
        position: relative;
        z-index: 1;
          animation-name: copied;
          animation-duration: 2s;
        /* &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: var(--poll-main-color) !important;
          animation-name: copied;
          animation-duration: 2s;
          z-index: -1;
        } */
      }
      svg {
        cursor: pointer;
        margin-left: 10px;
        height: unset;
        width: calc(1em + 1px);
      }
    }
    
  }

  &__question-object {
    display: block;
    height: 100%;

    width: 100%;
    max-width: 100%;

    overflow: hidden;

    &--type_text {
      .form-label:empty {
        display: none;
      }

      .survey-questions__question-object-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 11px;
        padding: 36px 44px;
        font-size: 15px;
        line-height: 1.3;
        text-align: center;
        color: #000;
      }
    }

    &--type_single-multimedia {
      .survey-questions__question-object-content {
        border-radius: 12px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background: black;
        position: relative;
      }
    }

    &--type_multiple-multimedia {
      border-radius: 0;

      .survey-questions__question-object-content {
        max-height: 238px;
      }
    }
  }

  &__question-object-content {
    width: 100%;
    height: 100%;
  }

  &__question-object-content-img {
    width: auto !important;
    max-width: 100%;
    height: 100% !important;
    max-height: 100%;

    border-radius: 19px;
  }

  &__question-message {
    max-width: 660px;
    text-align: center;
    margin: 12px 30px 0 30px;
    &.auth-card {
      font-weight: 900;
    }
  }

  &__custom-question-text * {
    text-align: center !important;
  }

  &__question-required {
    font-size: 12px;
    text-align: center;
    margin-top: 4px;
    margin-bottom: -13px;
  }

  &__question-card {
    display: flex;
    flex-wrap: wrap;
    margin-top: 22px;
    width: 100%;

    border-radius: 8px;
    padding: 14px 14px 36px;
  }

  &__question-card-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__question-like-all-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 165px;
    width: auto;
    padding: 0 20px;
    white-space: nowrap;
    height: 40px;
    margin-top: 18px;
    font-weight: bold;
    font-size: 15px;
    color: #fff !important;
    border-radius: 100px;

    &.focus,
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  &__question-dish-rating {
    width: calc(100% + 28px);
    margin: 21px -20px 16px;

    .survey-questions__question-dish-rating-position {
      border-top: 1px solid #cfd8dc;

      &:last-child {
        border-bottom: 1px solid #cfd8dc;
      }
    }
  }

  &__question-dish-rating-position {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px 20px 18px;
  }

  &__question-dish-rating-position-value {
    justify-content: start;
    width: 100%;
    margin-top: 21px !important;

    .survey-questions__rating-star {
      &:not(:first-child) {
        margin-left: 18px;
      }
    }
  }

  &__question-dish-rating-position-name {
    display: block;
    margin-bottom: 4px;

    font-weight: bold;
    font-size: 16px;
    color: #2e2f31;
  }

  &__question-dish-rating-position-quantity {
    font-size: 14px;
    color: #2e2f31;
  }

  &__question-dish-rating-position-weight {
    font-size: 14px;
    color: #73808d;
  }

  &__question-dish-rating-position-category {
    font-size: 14px;
    color: #73808d;
  }

  &__question-dish-comment-form-group {
    margin-bottom: 0;

    .form-control {
      min-height: 75px;
    }
  }

  /** </Question> **/

  /** <Rating> **/

  &__rating {
    display: flex;
    margin-top: 3px;

    .survey-questions__rating-star.active,
    &:hover .survey-questions__rating-star,
    &:hover .survey-questions__rating-star:hover {
      .icon-rate-star-fill(#F8CD1C);
    }

    .survey-questions__rating-star:hover ~ .survey-questions__rating-star {
      .icon-rate-star(#F8CD1C);
    }
  }

  &__rating-star {
    width: 30px;
    height: 28.8px;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icon-rate-star(#F8CD1C);
    cursor: pointer;
    padding: 0;
    box-sizing: content-box;
    border: none;

    &:not(:first-child) {
      margin-left: 18px;
    }
  }

  /** </Rating> **/

  &__choice {

    line-height: 1.3;
    text-align: center;
    margin-top: 15px;

    &--widescreen {
      display: none;
    }
  }

  &__choice-score {
    font-weight: bold;
  }

  /** <Form> **/

  &__form {
    margin-top: 18px;
    margin-left: 25px;
    margin-right: 25px;
    align-self: start;

    .form-check {
      margin-top: 10px;

      input:checked + .form-check-label {
        color: #000;
        font-weight: 500;
      }
    }

    .form-check-label {
      font-size: 15px;
      line-height: 22px;
      color: #9ea4aa;
      font-weight: 300;

      &:before {
        width: 22px;
        height: 22px;
        top: 0;
      }

      &:after {
        color: #231f20;
        top: 7px;
        left: 7px;
      }
    }
  }

  &__form-text {
    color: #000;
    font-weight: normal;
    line-height: 1.38;
    margin-bottom: 24px;
  }

  &__form-check-list {
    padding-right: 28px;

    .form-check-label {
      font-weight: normal !important;

      &:before {
        border-radius: 3px;
      }

      &:after {
        .icon-checkbox(#000);
      }
    }
  }

  &__form-textarea {
    min-height: 77.2px;
    margin: 10px 0 3px;
  }

  /** </Form> **/

  /** <Slider> **/

  &__slider {
    width: 100%;
    height: 100%;
    padding: 0 0 31px;
    font-size: 0;

    .swiper-slide {
      overflow: hidden;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
    }
  }

  &__slider-img {
    width: 100%;
    height: 100%;
    .border-radius(18px);
    object-fit: cover;
  }

  &__slider-video {
    height: 100%;
    width: 100%;
    .border-radius(18px);
    object-fit: cover;
  }

  &__slider-mask {
    background-color: rgba(0, 0, 0, 0.32);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 12px;
    z-index: 2;
  }

  &__slider-controls {
    position: absolute;
    left: ~'calc(50% - 38px)';
    top: ~'calc(50% - 50px)';
    z-index: 3;
  }

  &__slider-play-button {
    background-color: rgba(0, 0, 0, 0.34);
    background-position: 56% center;
    background-repeat: no-repeat;
    background-size: 26.5px 27.1px;
    .icon-play(#fff);
    width: 76px;
    height: 76px;
    .border-radius(50%);
    border: none;
    padding: 0;
  }

  &__slider-time {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    margin: 5px 0 0;
  }

  &__slider-letter {
    background-color: rgba(0, 0, 0, 0.34);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    min-width: 30px;
    width: auto;
    padding: 0 10px;
    height: 30px;
    position: absolute;
    top: 13px;
    left: 14px;
    .border-radius(15px);
    text-align: center;
    line-height: 30px;
    z-index: 5;
  }

  &__slider-paginator {
    position: absolute;
    top: 13px;
    right: 12px;
    display: flex;
    z-index: 5;

    *:not(:first-child) {
      margin-left: 7px;
    }
  }

  &__slider-paginator-button {
    background-color: rgba(0, 0, 0, 0.34);
    width: 30px;
    height: 30px;
    .border-radius(50%);
    border: none;
    padding: 0;
    display: none;

    &--prev {
      .icon-prev(#fff);
    }

    &--next {
      .icon-next(#fff);
    }
  }

  &__slider-counter {
    background-color: rgba(0, 0, 0, 0.34);
    color: #fff;
    font-size: 13px;
    padding-left: 12.28px;
    padding-right: 12.28px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    z-index: 5;
  }

  /** </Slider> **/

  &__option {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 6px;
    margin-left: -10px;

    &-check {
      margin-top: 10px;
      margin-left: 10px;
    }

    input[type='checkbox'] + &-label {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      border: 2px solid #e0e0e0;
      .border-radius(24px);
      height: 48px;
      min-width: 48px;
      font-size: 16px;
      font-weight: bold;
      padding-right: 23px;
      padding-left: 23px;
      color: #2e2f31;
      background-color: #fff;

      &:before,
      &:after {
        content: none;
      }
    }

    input:checked + &-label {
      padding-right: 25px;
      padding-left: 25px;
      border: none;
      color: #fff !important;
    }
  }

  &__copyright {
    font-size: 12px!important;
    text-decoration-line: underline;
  }
}

.survey-questions__priority {
  &-item {
    border-radius: 8px;
    margin: 2px 0;
    min-height: 48px;
    display: flex;
    padding: 15px;
    align-items: center;

    &-drag {
      margin-right: 20px;
    }

    &.sortable-item--dragging {
      background-color: transparent !important;
      border: 2px dotted white;
    }

    &.sortable-item--mirror {
      box-shadow: 0px 6px 15px var(--poll-main-color);
    }
  }
}

.col-lg-5 {
  .survey-questions__collapse-textarea {
    padding-right: 26px;
  }
}

.survey-questions__collapse-textarea_serv {
  padding-top: 15px;

  textarea.form-control {
    min-height: 75px;
    max-height: 75px;
  }
}

.js-surv {
  max-height: 370px;
  margin: 0 -15px;

  &__inner {
    padding: 0 15px;
  }
}

.survey-questions__serv {
  border-bottom: 1px solid #cfd8dc;
  border-top: 1px solid #cfd8dc;
  padding: 12px 0 15px;
  margin-bottom: -1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .form-error_star {
    position: absolute;
    bottom: 5px;
  }

  .name {
    font-style: normal;
    font-weight: bold;

    line-height: 1.62;
    padding-right: 15px;
    margin-bottom: 4px;
  }

  .serving {
    font-size: 14px;
    line-height: 1.2;
    color: #73808d;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .count {
      color: #000000;
    }

    span {
      margin-right: 10px;
    }
  }

  .rating {
    justify-content: flex-start;
  }
}

.central .survey-questions__preview {
  margin: -10px auto 20px;
}

.central {
  .survey-questions__question-like-all-button {
    margin: 0 0 20px;
  }
}

@media (min-width: 768px) {
  .survey-questions__choice--mobilescreen {
    display: none !important;
  }

  .survey-questions__wrapper_pre .rate-link {
    //margin-top: -10px;
    padding-top: 7px;
    padding-bottom: 20px;

    .survey-questions__question-like-all-button {
      margin: 0 !important;
    }
  }

  .survey-questions__all-width-column {
    padding-left: 42px;
    padding-right: 42px;

    .form-error_star {
      margin-top: 19px;
    }
  }

  .survey-questions__serv {
    .name {
      line-height: 1.2;
    }

    .rating__star {
      width: 18px;
      height: 18px;

      &:not(:first-child) {
        margin-left: 11px !important;
      }
    }
  }

  .survey-questions {
    &__body {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__wrapper {
      padding: 19px 25px;

      &.ps {
        padding-left: 40px;
        padding-right: 40px;
      }

      .survey-questions__question--type_8 & {
        padding: 0;
      }

      .form-control {
        //height: 45px;
      }

      textarea.form-control {
        height: 75px;
      }

      .form-error {
        font-size: 12px;
        padding-top: 2px;

        &_inner {
          padding-top: 8px;
          margin-top: 0;
        }

        &_star {
          padding-top: 17px;
          margin-top: 0;

          &_all {
            text-align: center;
            display: block;
            width: 100%;
          }
        }

        &_no_space {
          white-space: nowrap;
        }
      }
    }



    &__question-card {
      margin-top: 27px;
      padding: 21px 25px 21px;
      width: 680px;
      &.auth-card {
        width: 680px;
        padding: 21px 75px 21px;
      }

      &.question-card--type_21 {
        padding: 50px;
        padding-top: 40px;

        .survey-questions__wrapper {
          padding-top: 0;
          padding-bottom: 0;

          .swiper-slide__description {
            margin-bottom: 30px;
          }
        }
      }
    }

    /** <Question> **/

    &__question {
      .survey-questions__question-card
        > .survey-questions__question-card-column {
        &:first-child {
          width: 338px;
        }

        &:last-child {
          width: 246px;
        }
      }

      &--type_dishes {
        .survey-questions__question-card {
          width: 680px;
          padding-bottom: 20px;
        }

        .survey-questions__question-object {
          max-height: 292px;
        }

        .survey-questions__question-card
          > .survey-questions__question-card-column {
          &:last-child {
            .survey-questions__question-card-column-content {
              overflow: visible !important;

              .scroll-element {
                display: none;
              }
            }
          }
        }

        .survey-questions__question-card
          > .survey-questions__question-card-column {
          &:first-child {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: calc(42.3% - 10px);
          }

          &:last-child {
            width: calc(57.7% - 10px);
            margin-left: 20px;
            max-height: 371px;
            margin-top: 0;

            &:before {
              display: block;
            }

            .survey-questions__question-card-column-content {
              overflow: hidden !important;

              & > .scroll-content {
                padding-right: 20px;

                overflow: auto !important;
              }

              .scroll-element.scroll-y {
                display: block;
              }
            }
          }
        }

        &.survey-questions__question--multimedia-type_less {
          .survey-questions__question-card-column {
            width: 100% !important;
          }

          .survey-questions__question-like-all-button {
            margin-top: 0;
            margin-bottom: 21px;
          }
        }
      }

      &:not(.survey-questions__question--type_dishes).survey-questions__question--multimedia-type_less {
        .survey-questions__question-card {
          padding-right: 50px;
          padding-left: 50px;
        }

        .survey-questions__question-card-column {
          width: 100% !important;
        }

        .survey-questions__form-text {
          margin-bottom: 16px;
        }

        .survey-questions__form-check-list {
          margin-top: 16px;
        }

        .survey-questions__form {
          width: calc(100%);
          margin-left: 0;
          margin-right: 0;
        }

        .survey-questions__choice {
          margin-left: 0;
          margin-right: 0;
        }

        .survey-questions__option {
          margin-left: -10px;
          margin-right: 0;
        }
      }
    }

    &__question-rating-label {
      display: block;
    }

    &__question-card-column {
      width: calc(50% - 25px);
      align-items: flex-start;

      &:not(:first-child) {
        margin-left: 50px;
      }

      &:nth-child(2) {
        padding-top: 10px;
      }
    }

    &__question-actions {
      margin-top: 30px;
    }

    &__question-object {
      position: relative;
      width: 100%;
      height: 338px;
      overflow: hidden;

      &--type_multiple-multimedia {
        .survey-questions__question-object-content {
          max-height: unset;
        }
      }
    }

    &__question-dish-rating {
      width: 100%;
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
    }

    &__question-dish-rating-position {
      padding-right: 0;
      padding-left: 0;
      justify-content: space-between;
      flex-wrap: nowrap;
    }

    &__question-dish-rating-position-value {
      width: 134px;
      margin-left: 15px;
      margin-top: 0 !important;

      .survey-questions__rating-star {
        &:not(:first-child) {
          margin-left: 11px;
        }
      }
    }

    &__question-like-all-button {
      margin-bottom: 35px;
    }

    &__rating-star {
      width: 24px;
      height: 24px;

      &:not(:first-child) {
        margin-left: 14px;
      }
    }

    /** </Rating> **/

    &__choice {
      display: none;
      font-size: 15px;
      margin-top: 0;
      text-align: start;
      margin-bottom: 10px;

      &--widescreen {
        display: block;
      }

      &--option {
        display: block;
      }
    }

    /** <Form> **/

    &__form {
      margin-top: 24px;
      margin-left: 0;
      margin-right: 0;
    }

    &__form-text {
      font-size: 15px;
    }

    &__form-check-list {
      margin-top: 28px;
      padding-left: 0;
      padding-right: 0;
    }

    /** </Form> **/

    /** <Slider> **/

    &__slider {
      padding: 0;

      .swiper-pagination {
        display: none;
      }
    }

    &__slider-paginator-button {
      display: block;
    }

    &__slider-img {
      min-height: unset;
    }

    &__slider-video {
      min-height: unset;
    }

    /** </Slider> **/

    &__option {
      justify-content: flex-start;
    }
  }

  .survey-questions__choice--mobilescreen {
    display: none;
  }

  .form-control_full {
    width: 100%;
    min-height: 100px;

    &.sm {
      min-height: 75px;
    }
  }

  .form-control.text-center {
    padding: 0 !important;
  }

  .survey-question__media-form-control + .form-error_inner {
    padding-top: 18px;
  }

  .survey-questions__question {
    // .survey-new-types-media-variants-slider[data-slides='2'] {
    //     .swiper-slider-control {
    //         display: none;
    //     }
    // }
    .survey-new-types-media-variants-slider {
      .swiper-button-disabled {
        display: none;
      }
    }
  }

  .survey-questions__question--type_9,
  .survey-questions__question--type_10 {
    .survey-questions__wrapper {
      padding: 11px 35px 9px;
    }
    .survey-questions__preview {
      &.survey-question-media-variants__preview {
        .survey-new-types-media-variants-slider .swiper-slider-control--prev {
          left: -15px;
        }
        .survey-new-types-media-variants-slider .swiper-slider-control--next {
          right: -15px;
        }
      }
    }

    .survey-questions__media-variants-comment {
      padding: 0 10px 3px;
    }
  }
  .swiper-slide__description {
    padding: 0 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .survey .survey-questions__question-message,
  .survey-start__title,
  .survey-end__title {
    font-size: 21px;
  }

  .survey-questions__all-width-column {
    .rate-link {
      padding-bottom: 26px;
      margin-top: -10px;
    }

    .form-error_star {
      margin-top: 19px;
    }
  }

  .col-lg-6 {
    .survey-questions__question-like-all-button {
      margin: 40px 0 20px !important;
    }
  }

  .form-group_file_comment {
    padding-left: 15px;
    padding-bottom: 17px;

    textarea.form-control {
      height: 100px;
    }
  }

  .survey-questions__wrapper_pre .col-12.col-lg-7 ~ .col-12.col-lg-5 {
    padding-left: 41px;
    padding-right: 41px;
  }

  .survey-questions__wrapper_pre
    .col-12.col-lg-7
    ~ .col-lg-5
    .survey-questions__collapse-textarea {
    padding-right: 0;
  }

  .survey-questions__wrapper_pre.no-margin ~ .survey-questions__variants,
  .survey-questions__variants {
    padding-top: 15px;
  }

  .survey__images-form-control-content .survey__images-form-control-item,
  .survey__images-form-control-content .survey__images-form-control-add-button {
    margin-top: 20px;
    margin-left: 17px;
  }

  .form-group_file_comment {
    margin-bottom: -10px;
  }

  .survey-questions__preview {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 5px);
  }

  .survey-questions__wrapper_pre .col-12.col-lg-7 ~ .col-12.col-lg-5 {
    padding-top: 33px;
  }

  .survey-questions__wrapper_pre .rating__star {
    width: 28px;
    height: 28px;
  }

  .survey-questions__choice--widescreen {
    display: none;
  }

  .survey-questions__choice--mobilescreen {
    display: block;
  }

  .rating {
    justify-content: center;
  }

  textarea.form-control {
    height: 90px;
  }

  .survey__container .survey-question__media-form-control-list {
    margin-bottom: -30px;
  }

  .form-error_star {
    display: block;
    text-align: center;
  }

  .col-4.offset-4,
  .col-8 + .col-4 {
    .form-group {
      width: 90px;
      margin-left: -4px;

      .form-control.is-invalid {
        padding: 16px 18px;
      }
    }

    .form-error {
      position: absolute;
      min-width: 110px;
      max-width: 120px;
    }
  }

  .survey-questions__search-menu-item {
    display: flex;
    height: auto;
    align-items: center;
    padding: 5px 24px;
    min-height: 34px;
    font-size: 14px;
    line-height: 1.34;

    &--focus {
      background: #f1f5f6;
    }
  }

  .survey-questions__question:not(.survey-questions__question--type_dishes).survey-questions__question--multimedia-type_less
    .survey-questions__choice {
    text-align: center;
  }

  .survey-questions__rating {
    justify-content: center;
  }

  .survey-questions__question {
    width: 100%;
  }

  .survey-questions__wrapper {
    padding: 30px 36px 37px;
    .survey-questions__question--type_8 & {
      padding: 0;
    }
    .survey-questions__question--type_21 & {
      padding-top: 15px;
    }
  }

  .survey-questions__question-actions {
    margin-top: 20px;
  }

  .survey-questions__question-card {
    padding: 0;
  }

  .survey-question__media-form-control {
    margin-left: -36px;
    width: calc(100% + 36px * 2);
    padding: 0 0 0 20px;
    margin-top: -30px;
    margin-bottom: -20px;
  }

  .survey-question__media-form-control-list-content_flex
    .survey-questions__image.survey-question__media-form-control-list-item {
    margin: 10px 10px 0 0 !important;
  }

  .survey__container .survey-question__media-form-control-list-content {
    padding-top: 7px;
  }

  .survey__images-form-control-content {
    margin-top: -10px;
  }

  .form-group_file_comment {
    padding-top: 10px;
    margin-bottom: -10px !important;
    margin-left: -15px;
    padding-left: 0;
  }

  .survey-question__media-form-control + .form-error_inner {
    padding-top: 25px;
    margin-left: -15px;
    margin-bottom: -25px;
  }

  .survey-question__media-form-control-list-content_flex
    .survey-question__media-form-control-list-loading-placeholder {
    margin: 10px 10px 0 0 !important;
  }

  .survey-questions__serv {
    margin-left: -15px;
    width: calc(100% + 30px);
    padding: 14px 42px 18px;
    display: block;

    &:first-child {
      border-top: 1px solid #cfd8dc;
    }

    .rating {
      padding-top: 17px;
    }
  }

  .survey-questions__collapse-textarea_serv {
    padding-left: 27px;
    padding-right: 27px;
  }

  .central {
    .survey-questions__question-like-all-button {
      margin: -8px 0 25px;
    }
  }

  .survey-questions__question--type_2,
  .survey-questions__question--type_6 {
    .survey-questions__wrapper {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .survey-questions__question--type_9,
  .survey-questions__question--type_10 {
    .survey-questions__wrapper {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .survey-questions__preview {
      &.survey-question-media-variants__preview {
        .survey-new-types-media-variants-slider .swiper-slider-control--prev {
          left: 15px;
        }
        .survey-new-types-media-variants-slider .swiper-slider-control--next {
          right: 15px;
        }
      }
    }

    .survey-questions__media-variants-comment {
      padding: 0 20px 25px;
    }
  }
  .swiper-slide__description {
    padding: 0 20px;
    margin-bottom: 10px;
  }
}

@media (max-width: 340px) {
  .col-12.col-lg-8.offset-lg-2 {
    .col-8:not(.offset-2) {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;

      .date-input-group .form-control {
        padding-right: 29px;
      }

      .date-input-group__icon {
        right: 6px;
      }
    }

    .col-4:not(.offset-4) {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
  }

  .survey-questions__preview {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  }
}

.col-lg-6 {
  .survey-questions__preview {
    width: 100%;
    height: 245px;
  }
}

.survey {
  .survey-question__media-form-control-list-item {
    width: 100%;
    height: 245px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    cursor: pointer;
  }
}

.swiper-slide-active.active-tab ~ .swiper-slide-active.active-tab {
  display: none !important;
}

.survey-questions__tab-pane.tab-pane.fade.show.in.active
  ~ .survey-questions__tab-pane.tab-pane.fade.show.in.active {
  display: none !important;
}

.no-animate {
  .survey-questions__tab-pane.tab-pane {
    animation: none;
  }

  .js-clone {
    position: absolute;
  }

  .survey-questions__tab-pane.tab-pane.active {
    animation: none;
  }

  .swiper-slide,
  .swiper-wrapper {
    transition: none !important;
  }

  .smart-hide {
    opacity: 0;
  }


}

.survey-questions__question.survey-questions__question--type_10,
.survey-questions__question.survey-questions__question--type_9 {
  .survey-questions__question-card {
    .survey-questions__wrapper {
      .survey-question__assessment-type-selector-rating {
        padding-bottom: 10px;
      }
      .survey-questions__media-variants-comment {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
      }
    }
  }
}

.survey-questions__question.survey-questions__question--type_10 {
  .survey-questions__question-card {
    .survey-questions__wrapper {
      .form-error {
        padding-top: 10px;
        margin: 0;
      }
    }
  }
}

.survey-questions__question.survey-questions__question--type_9 {
  .survey-questions__question-card {
    .survey-questions__wrapper {
      .form-error {
        margin: 0;
      }
    }
  }
}

.poll-question-gallery {
  width: 100%;
  margin-bottom: 15px;

  .swiper {
    &-container {

    }

    &-slide {
      max-width: 100%;
      &__media {
        height: 220px;
        border-radius: 8px;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 0;

        position: relative;

        img {
          position: absolute!important;
          top: 50%!important;
          left: 50%!important;
          max-width: 100%!important;
          max-height: 100%!important;
          transform: translate(-50%, -50%);
          object-fit: contain!important;
          object-position: center;
        }

        .i-icon-photovideo {
          .icon-photovideo-light(#A6B1BC);
        }

        .i-icon-photo {
          .icon-image-light(#A6B1BC);
        }

        .i-icon-video {
          .icon-video-light(#A6B1BC);
        }
      }

      &--empty {
        background: transparent !important;

        .swiper-slide__media {
          background: #eceff1;
          border-radius: 16px;
        }

        i {
          width: 90px !important;
          height: 90px !important;
        }
      }

      &__description {
        font-size: var(--poll-font-size);
        width: 100%;
        text-align: left;
        padding: 0 15px;
        margin-top: 15px;
      }

      .icon-play-button {
        border: none;
        padding: 0;
        pointer-events: none;
        width: 73px;
        height: 51px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .icon-video-play(#fff, rgba(0, 0, 0, 0.6));
        border-radius: 0;
        background-color: transparent;
        opacity: 1;
        transition: all 0.25s ease-in-out;
      }
    }

    &-slider-control {
      position: absolute;
      top: 0;
      bottom: calc(100% - 235px);
      margin: auto;
      width: 32px;
      height: 32px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      z-index: 4;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 16px;

      &--prev {
        left: -16px;
      }

      &--next {
        right: -16px;
      }

      &.swiper-button-disabled {
        display: none;
      }
    }


  }

  @media (max-width: 768px) {
    .swiper {
      &-slide {
        &__media {
          height: 240px;
          border-radius: 18px;
        }

        &--empty {
        }
      }

      &-slider-control {
        &--prev {
          left: 10px;
        }

        &--next {
          right: 10px;
        }
      }
    }
  }

  @media (max-width: 375px) {
    .swiper-slide {
      &__media {
        height: 63.5vw;
      }
    }
  }
}

.poll-question-comment {
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    margin-top: 40px;
  }

  .question-card--type_14 & {
    margin-top: 30px;
  }
}

.survey-questions__body .foquz-success-message {
  position: fixed;
  top: 50%;
  left: 50%;
  bottom: unset;
  right: unset;
  transform: translate(-50%, -50%);
  border-radius: 9px;
  padding: 35px 30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  min-width: 260px;
  color: #2E2F31;
  z-index: 1000;
  box-shadow: 0px 5px 20px 0px rgba(115, 128, 141, 0.4);
  justify-content: flex-start;
}

@keyframes copied {
  0%   {background-color: rgba(from var(--poll-main-color) r g b / 0);}
  20%   {background-color: rgb(from var(--poll-main-color) r g b / 0.7);}
  40%   {background-color: rgb(from var(--poll-main-color) r g b / 0.3);}
  60%   {background-color: rgb(from var(--poll-main-color) r g b / 0.7);}
  100%   {background-color: rgb(from var(--poll-main-color) r g b / 0);}
}

@import './matrix-controller.less';
