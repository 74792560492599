.survey-list {
    &__survey-table-folder-icon {
        .icon-folder(#A6B1BC);

        display: inline-block;
        flex-shrink: 0;
        margin-right: 16px;
    }

    &__survey-table-survey-icon {
        .icon-survey(#A6B1BC);

        display: inline-block;
        flex-shrink: 0;
        margin-right: 16px;
    }

    &__survey-table-element-row {
        margin-left: -7px;
        margin-right: -7px;
        padding-left: 7px;
        padding-right: 7px;

        width: calc(100% + 14px);

        border-radius: 4px;

        &:hover {
            background: #F1F4FF;

            &__survey-list__survey-table-cell {
                background: #F1F4FF;
            }
        }
    }

    &__survey-table-survey-row {
        &--archived {
            color: rgba(0, 0, 0, 0.5) !important;

            .survey-list__survey-table-element-row-label {
                color: rgba(0, 0, 0, 0.5) !important;
            }

            &:hover {
                background: none;
            }

            .not-archived-card-checked & {
                .survey-list__survey-table-checked-cell {
                    visibility: hidden;
                }
            }
        }
        &:not(.survey-list__survey-table-survey-row--archived) {
            .archived-card-checked & {
                .survey-list__survey-table-checked-cell {
                    visibility: hidden;
                }
            }
        }
    }

    &__survey-table-element-row.checked {
        background: #F1F4FF;;
    }

    &__survey-table-element-row-label {
        text-decoration: none !important;
        color: #2E2F31 !important;
        font-size: 14px;
    }

    &__survey-table-survey-row-folder-name {
        margin-top: 2px;

        font-size: 12px;
        color: #73808D;
    }

    &__survey-table-menu-button {
        &:before {
            .icon-menu2(#73808D) !important;
        }
    }
}
