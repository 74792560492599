.point {
  width: 29px;
  height: 29px;
  border: 2px solid white;
  border-radius: 50%;
  color: white;
  font-size: 13px;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;

  &--1 {
    background: @rating-1-color;
  }

  &--2 {
    background: @rating-2-color;
  }

  &--3 {
    background: @rating-3-color;
  }

  &--4 {
    background: @rating-4-color;
  }

  &--5 {
    background: @rating-5-color;
  }

  &--comment {
    &:before {
      content: '';
      .icon-comment(@f-color-service);
      position: absolute;
      right: 0;
      top: -6px;
    }
  }
}

.points {
  display: flex;
  position: relative;
  overflow: hidden;
  padding-top: 10px;

  .point + .point {
    margin-left: -5px;
  }

  &-wrapper {
    display: flex;
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
  }

  &--row {
    padding-top: 0px;
    &:after {
      content: '';
      position: absolute;
      top: -10px;
      right: 0;
      width: 23px;
      height: calc(100% + 10px);

      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
    }
  }

  &:not(.points--row) {
    .point {
      &:last-child {
        &:nth-of-type(n + 5) {
          &:after {
            content: '';
            position: absolute;
            top: -10px;
            right: 0;
            width: 13px;
            height: calc(100% + 10px);

            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              #ffffff 100%
            );
          }
        }
      }
    }
  }
}
