.review-details-modal {
  &-nav {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;

    border: none;

    .nav-item {
      display: flex;
      width: 50%;
      align-items: center;
      padding: 13px 16px 13px 28px;

      .mobile-and-tablet({
        padding-left: var(--foquz-side-offset);
        padding-right: var(--foquz-side-offset);
      });

      font-weight: bold;
      font-size: 15px;
      color: #2e2f31;
      border: none;
      border-radius: 0;

      &:not(.active) {
        background: #dfe3e5;
        color: #73808d;
      }
    }

    &:after {
      content: '';

      position: absolute;
      width: 100%;
      height: 30px;
      bottom: -30px;
      z-index: 1;

      background: linear-gradient(
        180deg,
        #ffffff 18.23%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &__icon {
      flex-shrink: 0;
    }

    &__poll-name {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__complaint-indicator {
      flex-shrink: 0;
      .icon-complaint(#F96261);
      margin-left: 13px;
    }

    &__rating-indicator {
      flex-shrink: 0;
      margin-left: 9px;

      &--value_1 {
        .icon-rating1(#F96261);
      }

      &--value_2 {
        .icon-rating2(#F97F46);
      }

      &--value_3 {
        .icon-rating3(#F4A224);
      }

      &--value_4 {
        .icon-rating4(#AAB540);
      }

      &--value_5 {
        .icon-rating5(#3ece67);
      }
    }
  }
}
