.settings {
	&__content {
		&--initializing {
			visibility: hidden;
		}

		.f-card {
			min-height: 600px;
		}

		.tab-group {
			.nav {
				flex-wrap: nowrap;
			}
			.nav-item {
				text-align: center;
			}
		}
	}

	&__actions {
		display: flex;
		position: relative;

		&__save-message {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: -22px;
			right: -22px;
			top: -21px;
			bottom: -21px;
			background: #ABEFB7;
			color: #37A74A;
			border-radius: 0 0 7px 7px;
		}
	}

	&__save-button {
		margin-left: 13px;
	}

	&__email-icon {
		.icon-email(#73808D);

		display: inline-block;
	}

	&__sms-icon {
		.icon-sms(#73808D);

		display: inline-block;
	}

	&__telegram-icon {
		.icon-telegram(#1588C9);

		display: inline-block;
	}

	&__viber-icon {
		.icon-viber(#9151C1);

		display: inline-block;
	}

	&__repeat-icon {
		.icon-repeat(#73808D);

		display: inline-block;
	}

	&__variables {
		display: flex;
		flex-wrap: wrap;
		margin-top: calc(10px + (-5px));
		margin-left: -5px;
	}

	&__variable {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 32px;
		padding-right: 15px;
		padding-left: 15px;
		margin-top: 5px;
		margin-left: 5px;

		background: #ECEFF1;
		border-radius: 60px;
		font-size: 13px;
		color: #2E2F31;
		cursor: pointer;

		transition: background-color 0.2s ease;

		&:hover {
			background: #C6CFD3;
		}
	}

	&__next-button {
		padding-left: 22px;
		padding-right: 45px;

        &:before {
            .icon-arrow-back(#73808D);
			transform: rotate(180deg);
			top: 10px;
			right: 15px;
			left: auto;

            content: '';
        }

	}
}

@import '_tabs/common/common';
@import '_tabs/conn-channels/conn-channels';
@import '_tabs/contact-points/contact-points';
@import '_tabs/inter-screens/inter-screens';
