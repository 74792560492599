@import './question-type-select';

.question-form {
  .hat-radio-group {
    width: 100%;
    margin-bottom: 25px;
  }

  .hide-question {
    font-size: @f-fs-2;

    .form-label {
      line-height: 1.2;
    }

    &__reset-answers {
      font-size: 13px;
      white-space: nowrap;
      margin-right: 10px;
    }

    &__reset-note {
      font-size: @f-fs-1;
      color: @f-color-service;
      margin-top: 10px;
    }
  }

  .ckeditor{
    &:not(.ckeditor--inter) {
      .ck-content {
        min-height: 75px;
      }
    }
  }
}

@import './types/media-variants';
@import './types/nps';
@import './types/smile';
@import './types/matrix';
@import './types/diff';
@import './types/stars';

@import './variants-controller';
@import './media-variants-controller';
