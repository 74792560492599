@import './answer-with-rating-comments-modal-dialog/answer-with-rating-comments-modal-dialog';
@import './edit-columns-modal-dialog/edit-columns-modal-dialog';
@import './change-status-modal-dialog/change-status-modal-dialog';
@import './details-modal-dialog/details-modal-dialog';
@import './answer-list/answer-list';
@import './diff.less';

.statistics {
  &__content {
    &--initializing {
      visibility: hidden;
    }

    .statistics__menu {
      margin-top: 20px;
    }

    .statistics__card {
      margin-top: 21px;
    }
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 485px;

    .text {
      font-size: 16px;
      line-height: 1.5;
      padding-right: 0;
      text-align: center;
      transform: translate(2px, -17px);
      display: flex;
      align-items: center;
      justify-content: center;

      ul {
        color: #2e2f31;
        margin-bottom: 25px;

        li {
          font-size: 15px;
          line-height: 1.3;
          margin-bottom: 7px;
          padding-left: 24px;
          position: relative;

          &:before {
            content: '';
            width: 6px;
            height: 6px;
            position: absolute;
            left: 8px;
            top: 7px;
            background-color: #cfd8dc;
            border-radius: 50%;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      p,
      .item {
        margin-bottom: 14px;

        &.first-item {
          margin-bottom: 28px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      .title {
        font-weight: 900;
        font-size: 22px;
        line-height: 1;
        text-align: center;
        color: #2e2f31;
        margin-bottom: 13px;
      }
    }

    &_large {
      .text {
        font-size: 16px;
        transform: translate(2px, -32px);
      }
      .title {
        transform: translateX(2px);
      }
    }
  }

  &__header-edit-button {
    margin-left: 13px;
    flex-shrink: 0;

    &:before {
      .icon-pencil(#73808d);

      content: '';
    }
  }

  &__header-copy-button {
    margin-left: 10px;
    flex-shrink: 0;

    &:before {
      .icon-link3(#73808d);

      content: '';
    }
  }

  &__card-filter-checkbox {
    margin-top: 14px;

    .form-check-input:checked + .form-check-label {
      font-weight: 500;
    }
  }

  &__card-filters-apply-button {
    width: 130px;
    height: 38px;
  }

  &__card-filters-reset-button {
    height: 38px;
  }

  &__contact-point-rating-filters {
    display: flex;
    flex-wrap: wrap;

    margin-top: -20px;
    margin-left: -39px;

    .statistics__contact-point-rating-filter {
      margin-top: 20px;
      margin-left: 39px;
    }
  }

  &__contact-point-rating-filter {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    transition: width 0.5s ease-in;

    .statistics__contact-point-rating-filter-wrapper {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      transition: width 0.5s ease-in;
      width: 161px;
    }

    &--closed {
      .statistics__contact-point-rating-filter-wrapper {
        width: 65px;
      }

      .statistics__rating-slider {
        width: calc(100% - 35px) !important;
        margin-top: 16px;
        margin-right: 19px;
        margin-bottom: 16px;

        .ui-slider-handle:first-child {
          left: 0 !important;

          transition: left 0.5s ease-in;
        }

        .ui-slider-handle:nth-last-child(2) {
          width: 38px !important;
          height: 38px !important;
          top: -16px !important;
          margin-left: -18px !important;
          left: 100% !important;

          border: 3px solid #fff !important;

          transition: left 0.5s ease-in;
        }
      }

      .statistics__contact-point-rating-filter-curtain {
        height: 38px !important;
      }

      .statistics__contact-point-rating-filter-name {
        margin-top: 17px !important;
      }
    }

    .statistics__rating-slider {
      width: calc(100% - 32px);
      margin-top: calc(13px + 3px);

      transition: width 0.5s ease-in;
    }

    .statistics__contact-point-rating-filter-curtain {
      position: absolute;
      height: 32px;
      top: 0;
      right: 0;
      left: 0;
      z-index: 2;

      cursor: pointer;
    }

    .statistics__contact-point-rating-filter-name {
      margin-top: 20px;
    }
  }

  &__score-rating-filter {
    &:not(:first-child) {
      margin-left: 24px;
    }
  }

  &__contact-point-rating-filter-name {
    max-width: 100%;

    font-weight: bold;
    font-size: 14px;
    color: #2e2f31;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__rating-slider {
    margin-top: 13px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 13px;

    background: linear-gradient(
      90deg,
      #f96261 1.49%,
      #f5a123 50.37%,
      #33d16b 100.93%
    );

    .ui-slider-range {
      background: none;
    }
  }

  &__rating-slider-handle {
    display: flex;
    width: 32px !important;
    height: 32px !important;
    top: -13px !important;
    margin-left: -16px !important;
    align-items: center;
    justify-content: center;

    color: #fff !important;
    font-weight: 900 !important;
    font-size: 13px !important;

    &--value_1 {
      background: #f96261 !important;
    }

    &--value_2 {
      background: #f97f46 !important;
    }

    &--value_3 {
      background: #f4a224 !important;
    }

    &--value_4 {
      background: #aab540 !important;
    }

    &--value_5 {
      background: #3ece67 !important;
    }
  }

  &__card-control-row {
    display: flex;
    height: 61px;
    margin-left: -20px;
    margin-right: -20px;
  }

  &__card-control-row-summary {
    display: inline-flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

  &__card-control-row-summary-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    padding-left: 25px;
    padding-right: 25px;
    border-top: 1px solid #eceff1;
    border-bottom: 1px solid #eceff1;

    &:not(:first-child) {
      border-left: 1px solid #f2f5f6;
    }
  }

  &__card-control-row-summary-new-item {
    .statistics__card-control-row-summary-item-value {
      color: #16ceb9;
    }
  }

  &__card-control-row-summary-process-item {
    .statistics__card-control-row-summary-item-value {
      color: #2d99ff;
    }
  }

  &__card-control-row-summary-delayed-item {
    .statistics__card-control-row-summary-item-value {
      color: #f96261;
    }
  }

  &__card-control-row-summary-completed-item {
    .statistics__card-control-row-summary-item-value {
      color: #8400ff;
    }
  }

  &__card-control-row-summary-item-value {
    font-weight: bold;
    font-size: 19px;
  }

  &__card-control-row-summary-item-label {
    margin-top: 5px;

    font-weight: 500;
    font-size: 12px;
    color: #73808d;
  }

  &__card-control-row-search {
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
    flex-grow: 1;

    border: 1px solid #eceff1;

    transition: border-color, box-shadow 0.15s ease-in-out;

    &--active {
      border: 1px solid #3f65f1;
      box-shadow: 0 0 5px rgba(63, 101, 241, 0.5);
    }
  }

  &__card-control-row-search-icon {
    .icon-search(#73808d);

    display: inline-block;
    margin-right: 17px;
  }

  &__card-control-row-search-input {
    display: block;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 24px;
    height: 100%;

    background-color: #fff;
    font-size: 16px;
    color: #2e2f31;
    font-weight: 400;
    border: none;

    &::placeholder {
      color: #73808d;
    }
  }

  &__card-control-row-search-clear-button {
    .icon-plus3(#2e2f31) !important;

    position: absolute;
    right: 13px;
    top: calc(50% - (17px / 2));
    padding: 0;
    transform: rotate(45deg);

    border-radius: 0;
    box-shadow: none !important;

    transition: none;
  }

  &__card-control-row-edit-columns-section {
    display: flex;
    height: 100%;
    width: 58px;
    align-items: center;
    justify-content: center;

    border-top: 1px solid #eceff1;
    border-bottom: 1px solid #eceff1;
  }

  &__card-control-row-button {
    padding: 0;

    border-radius: 0;
    box-shadow: none !important;
  }

  &__card-control-row-edit-columns-button {
    .icon-settings2(#73808d);

    &:hover {
      .icon-settings2(#3f65f1);
    }
  }

  &__card-control-row-view-toggle-section {
    display: flex;
    height: 100%;
    padding-left: 22px;
    padding-right: 22px;
    align-items: center;
    justify-content: center;

    border-left: 1px solid #eceff1;
    border-top: 1px solid #eceff1;
    border-bottom: 1px solid #eceff1;

    .statistics__card-control-row-button {
      &:not(:first-child) {
        margin-left: 22px;
      }
    }
  }

  &__card-control-row-column-kanban-button {
    .icon-columns(#A6B1BC);

    &.statistics__card-control-row-button--active,
    &:hover {
      .icon-columns(#3f65f1);
    }
  }

  &__card-control-row-column-list-button {
    .icon-list(#A6B1BC);

    &.statistics__card-control-row-button--active,
    &:hover {
      .icon-list(#3f65f1);
    }
  }

  &__table-section {
    position: relative;
  }

  &__table-container {
    position: relative;

    .statistics__table-container-scroll-button-gutter {
      position: absolute;
      top: 80.8px;
      bottom: 16px;
    }

    .statistics__table-container-left-scroll-button-gutter {
      left: calc(-20px - 34px / 2);
    }

    .statistics__table-container-right-scroll-button-gutter {
      right: calc(-20px - 34px / 2);
    }
  }

  &__table-container-scroll-button-gutter {
    width: 34px;

    .statistics__table-container-scroll-button {
      position: sticky;
      top: 50%;
      z-index: 20;
    }
  }

  &__table-container-scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    padding: 0;

    background: #ffffff;
    box-shadow: 0 5px 15px rgba(46, 47, 49, 0.3);
    border: none;
    border-radius: 50%;

    transition: transform 200ms ease-in;

    &:before {
      .icon-arrow-bottom2(#73808d);

      content: '';
      display: inline-block;
    }
  }

  &__table-container-left-scroll-button {
    &:before {
      transform: rotate(90deg);
    }
  }

  &__table-container-right-scroll-button {
    &:before {
      transform: rotate(-90deg);
    }
  }

  &__table-wrapper {
    &.scroll-content {
      overflow-y: hidden !important;
      overflow-x: auto !important;
    }

    .scroll-y {
      display: none !important;
    }
  }

  &__table {
    margin-top: 24px;

    font-weight: 500;
    font-size: 12px;
    color: #2e2f31;

    th,
    td {
      vertical-align: top !important;
    }

    td {
      position: relative;
    }

    th {
      padding-bottom: 5px;
    }
  }

  &__table-head-cell-filter {
    width: 100%;
    margin-top: 4px;

    border: none;
    font-weight: 500;
    color: #2e2f31;
  }

  &__table-head-cell-name {
    white-space: nowrap;
  }

  &__table-row {
    cursor: pointer;
    position: relative;

    &--selected {
      background: #f1f4ff;
    }
  }

  &__table-status--value_new {
    color: #16ceb9;
  }

  &__table-status--value_process {
    color: #2d99ff;
  }

  &__table-status--value_delayed {
    color: #f96261;
  }

  &__table-status--value_completed {
    color: #8400ff;
  }

  &__table-answers-with-rating-rating-row {
    display: flex;
  }

  &__table-answer-with-rating-rating {
    display: flex;
    align-items: center;
    position: relative;

    &:not(:first-child) {
      margin-left: -5px;
    }

    &--with-comment {
      &:before {
        content: '';
        position: absolute;
        top: -4px;
        right: 0;
        .icon-comment(#73808d);
      }
    }

    &--value_1 {
      .statistics__table-answer-with-rating-rating-indicator {
        background: #f96261 !important;
      }
    }

    &--value_2 {
      .statistics__table-answer-with-rating-rating-indicator {
        background: #f97f46 !important;
      }
    }

    &--value_3 {
      .statistics__table-answer-with-rating-rating-indicator {
        background: #f4a224 !important;
      }
    }

    &--value_4 {
      .statistics__table-answer-with-rating-rating-indicator {
        background: #aab540 !important;
      }
    }

    &--value_5 {
      .statistics__table-answer-with-rating-rating-indicator {
        background: #3ece67 !important;
      }
    }
  }

  &__table-answer-with-rating-rating-indicator {
    display: flex;
    width: 29px;
    height: 29px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    border-radius: 50%;
    font-weight: 900;
    font-size: 13px;
    border: 2px solid #fff;
    color: #ffffff;
  }

  &__table-answer-with-rating-rating-name {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__table-answer-with-rating-comment {
    display: flex;
    height: 29px;
    align-items: center;

    max-width: 80px;

    &:not(:first-child) {
      margin-top: -5px;
    }
  }

  &__table-answers-with-rating-comment-icon {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
    .icon-comment2(#73808d);
  }

  &__table-answer-with-rating-comment-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__table-complaint-text {
    display: block;
    max-width: 150px;
    word-wrap: break-word;
  }

  &__table-complaint-photos {
    display: flex;
    align-items: center;

    font-size: 12px;
    color: #3f65f1;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 7px;
    }
  }

  &__table-complaint-photos-icon {
    .icon-image(#A6B1BC);

    margin-right: 8px;
  }

  &__table-checkbox {
    margin-top: 5px;
  }

  &__table-selection-head-cell {
    .statistics__table-checkbox {
      margin-bottom: 20px;
    }
  }

  &__table-selection-head-cell,
  &__table-selection-cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &__table-price-cell {
    white-space: nowrap;
  }

  &__table-phone-cell {
    white-space: nowrap;
  }

  &__table-answers-with-rating-rating-cell,
  &__table-answers-with-rating-comment-cell {
    &:after {
      content: '';
      display: inline-block;
      width: 25px;
      height: 100%;
      right: 0;
      top: 0;
      position: absolute;
      box-shadow: -20px -6px 6px 0 white inset;
      transition: box-shadow 200ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    .statistics__table-row--selected & {
      &:after {
        box-shadow: -20px -6px 6px #f1f4ff inset;
      }
    }
  }

  &__table-sorting-icon {
    .icon-sorting(#3f65f1);

    display: inline-block;
    flex-shrink: 0;
    margin-left: 4px;

    &--order_asc {
      transform: rotate(180deg);
    }
  }

  &__table-filtering-icon {
    .icon-filtering(#3f65f1);

    display: inline-block;
    flex-shrink: 0;
    margin-left: 4px;
  }

  &__table-head-cell-title {
    display: flex;
    align-items: center;
  }

  &__table-complaint-fancybox-description-text {
    margin-top: 7px;
  }

  &__table-group-actions-wrapper {
    position: absolute;
    height: 100%;
  }

  &__table-group-actions {
    display: flex;
    align-items: center;
    padding: 18px 20px;
    position: sticky;
    top: 0;

    z-index: 1000;
    background: #ffffff;
    box-shadow: 0 5px 15px rgba(46, 47, 49, 0.3);
    border-radius: 9px;
  }

  &__table-group-actions-counter {
    font-size: 16px;
  }

  &__table-group-actions-counter-value {
    font-weight: bold;
  }

  &__table-group-actions-select-all-button {
    margin-left: 28px;
    padding: 12px 25px;

    background: #dadfe3;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #2e2f31;
  }

  &__table-group-actions-selector {
    &.select2 {
      margin-left: 28px;
    }
  }

  &__table-group-actions-dismiss-button {
    display: flex;
    width: 61px;
    height: 48px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    padding: 0;

    background: #ffdddd;
    border-radius: 4px;

    &:before {
      .icon-plus3(#F96261);

      content: '';

      transform: rotate(45deg);
      width: 21px;
      height: 21px;
    }
  }

  &__table-group-actions-apply-button {
    display: flex;
    width: 61px;
    height: 48px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    padding: 0;

    background: #abefb7;
    border-radius: 4px;

    &:before {
      .icon-done(#37a74a);

      content: '';

      width: 19.6px;
      height: 14px;
    }
  }

  &__card-executor-filter-control-avatar {
    width: 28px;
    height: 28px;
    margin-right: 12px;

    border-radius: 50%;
    object-fit: cover;
  }

  &__kanban-board {
    display: flex;
    margin-top: 19px;

    .statistics__kanban-board-column {
      width: 25%;

      &:not(:first-child) {
        margin-left: 4px;
      }
    }
  }

  &__kanban-board-column {
    background: #f5f6fa;
    border-radius: 9px;

    &--disabled {
      opacity: 0.35;
    }

    .statistics__kanban-board-card {
      &:not(:first-child) {
        margin-top: 5px;
      }
    }
  }

  &__kanban-board-column-header {
    padding: 9px 9px 8px 9px;

    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;

    .statistics__kanban-board-column--status_new & {
      background: #16ceb9;
    }

    .statistics__kanban-board-column--status_process & {
      background: #2d99ff;
    }

    .statistics__kanban-board-column--status_delayed & {
      background: #f96261;
    }

    .statistics__kanban-board-column--status_completed & {
      background: #8400ff;
    }
  }

  &__kanban-board-column-content {
    height: 100%;
    padding: 11px 8px;
  }

  &__kanban-board-card {
    padding: 12px 15px 14px 15px;

    background: #ffffff;
    box-shadow: 0 2px 4px rgba(46, 47, 49, 0.15);
    border-radius: 9px;
    cursor: pointer;

    &.gu-hide {
      display: none !important;
    }

    &.gu-mirror {
      position: fixed !important;
      margin: 0 !important;
      height: unset !important;

      z-index: 9999 !important;
      box-shadow: 0 15px 20px rgba(63, 101, 241, 0.3);
      cursor: grabbing;
    }

    &.gu-transit {
      height: 10px;
      padding: 0;

      background: #9bb0fb;
      border-radius: 9px;

      * {
        display: none;
      }
    }

    .statistics__answer-list {
      margin-top: 19px;
    }
  }

  &__kanban-board-card-header {
    display: flex;
    min-height: 28px;
    align-items: center;
  }

  &__kanban-board-card-executor {
    display: flex;
    align-items: center;

    .statistics__kanban-board-card-executor-name {
      margin-left: 10.5px;
    }
  }

  &__kanban-board-card-executor-avatar {
    width: 28px;
    height: 28px;

    border-radius: 50%;
    object-fit: cover;
  }

  &__kanban-board-card-executor-name {
    font-weight: 500;
    font-size: 12px;
    color: #2e2f31;
  }

  &__kanban-board-card-executor-placeholder {
    font-weight: 500;
    font-size: 12px;
    color: #73808d;
  }

  &__kanban-board-card-complaint-icon {
    .icon-complaint(#F96261);
  }

  &__kanban-board-card-footer {
    display: flex;
    margin-top: 28px;
    justify-content: space-between;
  }

  &__kanban-board-card-passed-at {
    font-weight: bold;
    font-size: 12px;
    color: #3f65f1 !important;
    text-decoration: none !important;
  }

  &__kanban-board-card-questions {
    font-weight: bold;
    font-size: 12px;
    color: #73808d;
  }

  &__kanban-board-card-id {
    font-weight: bold;
    font-size: 12px;
    color: #2e2f31;
  }

  &__details-modal {
    padding-right: 0 !important;

    background: #ffffff99;

    .statistics__details-modal-dialog-wrapper {
      position: absolute;
      height: 100%;
      right: 0;
      width: 100%;
      max-width: 1000px;

      &.statistics__details-modal-dialog-wrapper--last {
        margin-left: 175.31px;
        width: calc(100% - 175.31px);
      }

      &:not(.statistics__details-modal-dialog-wrapper--last) {
        right: 28px;
      }

      &.statistics__details-modal-dialog-wrapper--single {
        margin-left: 45px;
        width: calc(100% - 45px);
      }
    }
  }

  &__details-modal-dialog-wrapper {
    .statistics__details-modal-dialog {
      height: 100%;
      width: 100%;
    }

    .statistics__details-modal-dialog-wrapper-actions {
      position: absolute;
      transform: translate(calc(-100% + -12px), 11px);
      z-index: 2;
    }
  }

  &__details-modal-dialog-wrapper-actions {
    display: flex;

    .statistics__details-modal-dialog-wrapper-close-all-button {
      margin-right: 10.32px;
    }
  }

  &__details-modal-dialog-wrapper-close-button {
    display: flex;
    width: 33px;
    height: 33px;
    align-items: center;
    justify-content: center;
    padding: 0;

    border-radius: 50%;
    box-shadow: none !important;
    border: 2px solid #73808d !important;

    &:before {
      .icon-cross3(#73808d);

      content: '';
    }
  }

  &__details-modal-dialog-wrapper-close-all-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 14.54px;

    border-radius: 50px;
    box-shadow: none !important;
    border: 2px solid #73808d !important;
    font-weight: 500;
    font-size: 14px;
    color: #73808d !important;
  }
}

.statistics__score-rating-filter
  + .statistics__contact-point-rating-filter:not(.statistics__score-rating-filter) {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: -20px;
    top: 0;
    bottom: 0;
    border-left: 1px solid #eceff1;
  }
}

.statistics-print-mode() {
  .app__content {
    padding-bottom: 0 !important;
  }

  .question-statistics__card {
    border-radius: 0;
    margin-top: 0 !important;
    padding-bottom: 0 !important;

    .question-statistics__variant-statistics-mode-toggle {
      display: none;
    }

    .question-statistics__question-additional-button,
    .highcharts-exporting-group {
      display: none;
    }

    .question-statistics__question {
      padding: 32px 0px 32px 30px;
      page-break-inside: avoid;
    }

    .question-statistics__variant-statistics {
      margin-top: 0;
    }

    .question-statistics__question-chart,
    .question-statistics__question-assessment-variants-variant-statistics {
      margin-top: 16px;
    }

    .dish-info {
      .worst-icon {
        display: inline-block;
        margin-right: 12px;
        .icon-worst(#F96261);
      }
      .best-icon {
        display: inline-block;
        margin-right: 12px;
        .icon-best(#37a74a);
      }
    }

    .question-statistics__question-dish-ratings-statistics-table-row:nth-child(n
        + 10) {
      display: none;
    }

    .ps {
      overflow: visible !important;
      max-height: none;
    }
    .ps__rail-x,
    .ps__rail-y {
      display: none !important;
    }

    .question-statistics__question-dish-ratings-statistics-table-container:after {
      background: transparent;
    }
  }
}

.print-mode {
  .statistics-print-mode();
}

@media print {
  .statistics-print-mode();
}
