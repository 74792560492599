.login-page {
  min-height: 100vh;
  width: 100%;
  min-width: 0 !important;
  overflow: hidden;

  .app__content {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 5px 15px;
  }

  &__title {
    font-weight: 900;
    font-size: 15px;
    line-height: 1;
    color: #2e2f31;
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .tab-group {
      flex-grow: 1;
    }
  }

  .tablet-and-desktop({
    &__header {
        height: 75px;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__title {
        font-size: 22px;
    }
  });

  .only-desktop({
    &__header {
      justify-content: flex-start;
      padding-left: 25px;
      padding-right: 25px;
    }
    &__logo {
      width: 235px;
    }

    &__content {
      padding-left: 260px;
      padding-right: 65px;

      .tab-group {
        flex-grow: 0;
      }
    }
  })
}

.login {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__body {
    margin-left: auto;
    margin-right: auto;
    width: 522px;
    max-width: 100%;

    &_long {
      width: 100%;
    }
  }

  &__some-form {
    max-width: 522px;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.3;
    color: #2e2f31;

    .button-add {
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 4px;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -4px;

    .button-add {
      padding-left: 35px;
      padding-right: 35px;
    }
  }

  .tab-group .tab-pane {
    padding-top: 35px;
    padding-bottom: 50px;
  }

  .form-group {
    margin-bottom: 24px;

    &_login {
      width: 292px;
      max-width: 292px;
    }
  }

  &__header {
    padding: 0 20px 21px;
    width: calc(100% + 40px);
    margin-left: -20px;
    border-bottom: 1px solid #eceff1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: -12px;

    .title {
      padding-left: 10px;
      font-weight: bold;
      font-size: 15px;
      color: #2e2f31;
      display: none;
    }

    .item {
      margin-right: 17px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &_active {
        .round {
          background-color: #3f65f1;
          color: #ffffff;
        }

        .title {
          display: block;
        }
      }
    }

    .round {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: #d9e0fc;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #3f65f1;
    }
  }

  .p {
    margin-bottom: 21px;
  }

  .form-check-label {
    font-size: 14px;
  }

  .button-add {
    text-decoration: none !important;

    &:not([href]):not([tabindex]) {
      color: #ffffff;
      text-decoration: none;
    }

    &[disabled] {
      cursor: default;
    }
  }

  .form-control {
    &_code {
      width: 120px;
      max-width: 120px;
      margin-right: 25px;
    }
  }

  .i-ajax-btn_code {
    margin-right: 47px;
  }

  &__timer {
    font-size: 19px;
    transform: translateY(3px);
  }

  &__subtitle {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1;
  }

  .form-note {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    color: #73808d;
    padding-top: 7px;
  }

  label {
    .form-note {
      padding-top: 0;
    }
  }

  .d-flex {
    .chars-counter {
      width: 425px;
      max-width: 425px;
      min-width: 425px;
      margin-right: 11px;
    }

    .description {
      font-size: 16px;
      line-height: 1.2;
      color: #2e2f31;
    }
  }

  &__success,
  .p.login__success {
    margin-bottom: 28px;
  }

  .only-mobile({
      .tab-group {
          .tab-pane {
              padding-left: 15px;
              padding-right: 15px;
          }
      }
      .login__controls {
          display: block;
          .form-check, .recovery-link {
              margin-bottom: 12px;
          }
          .recovery-link {
              display: block;
              margin-right: 0!important;
          }
      }
      .login-button {
          width: 100%;
          margin-top: 10px;
      }
      .tab-content {
        min-height: 0;
        padding-bottom: 24px;
      }
  });

  .only-desktop({
    &__body {
      margin-left: 0;
      margin-right: 0;
    }
  })
}

.password-recovery {
  &:not(.password-recovery--submitted) {
    .captcha {
      .form-control {
        border-color: #cfd8dc !important;
        box-shadow: none !important;
        background: none !important;
      }
      .form-error {
        display: none;
      }
    }
  }
}
