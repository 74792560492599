@import './counter.less';
.conditions-list {
  &__title {
    font-size: 19px;
    font-weight: bold;
    color: @f-color-text;
  }
  &__subtitle {
    margin-top: 10px;
  }
  &__count {
    font-weight: normal;
  }
  &__item {
    padding-right: 50px;
  }
  .condition {
    position: relative;
    padding-top: 25px;
    border-bottom: 1px solid rgba(207, 216, 220, 0.5);
    position: relative;

    &:first-child {
      border-top: 1px solid rgba(207, 216, 220, 0.5);
    }

    .btn-remove {
      position: absolute;
      top: 20px;
      right: 0;
    }

    &__type {
      width: 522px;
      margin-bottom: 5px;
      margin-right: 25px;
      max-width: 100%;
      flex-shrink: 0;
    }

    &__value {
      flex-grow: 1;
      flex-shrink: 1;
    }

    .form-error {
      margin-top: -20px;
      display: block;

      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  .condition__row {
    display: flex;
    flex-wrap: wrap;
  }

  .condition--filial {
    .condition__type {
      margin-bottom: 0;
    }
    .condition-value--order-type {
      .condition-value__select {
        width: 100%;
        max-width: 450px;
      }
    }
    .condition-value--filial {
      .condition-value__select {
        width: 100%;
        max-width: 996px;
      }
    }
  }

  .condition--promocode {
    .condition-value--promocode {
      max-width: 394px;
    }
    .condition-value--pools {
      width: 522px;
      max-width: 100%;
    }
  }

  .condition-value {
    width: 100%;

    &--filial {
      width: 100%;
    }

    &__select {
      width: 190px;
      max-width: 100%;

      &--lg {

        width: 260px;
      }

      &--xl {
        width: 480px;
      }

      &--full {
        width: 100%;
      }
    }

    &__date {
      max-width: 160px;
      width: 100%;
    }

    &__period {
      max-width: 270px;
      width: 100%;
    }

    &__slider {
      max-width: 480px;
      width: 100%;
    }

    &__length-control {
      display: flex;
      align-items: center;
      padding-top: 22px;

      &-slider {
        flex-grow: 1;

        &-handle {
          top: calc(6px / 2 - 24px / 2);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: -12px;

          width: 24px !important;
          height: 24px !important;

          &-indicator {
            margin-top: -50px;
            font-weight: bold;
            font-size: 14px;
            color: #3f65f1;
          }
        }
      }

      &-min-boundary {
        margin-right: 15px;
      }

      &-max-boundary {
        margin-left: 15px;
      }
    }
  }

  &__separator {
    display: flex;
    align-items: center;
    margin-right: 9px;
    margin-left: 9px;
    font-size: 16px;
    color: #2e2f31;
  }

  &__days {
    width: 100%;
    padding-top: 40px;
    margin-left: 4px;
    position: relative;

    &-columns {
      column-width: 140px;
      margin-bottom: 24px;
    }
    .form-group {
      margin-bottom: 4px;
    }
  }

  &__time {
    width: 100%;
    padding-top: 40px;
    margin-left: 4px;
    position: relative;
    &-columns {
      column-width: 80px;
      margin-bottom: 24px;
    }
    .form-group {
      margin-bottom: 4px;
    }
  }

  &__footer {
    padding: 20px 0;
  }

  &__dish-select {
    .select2-dropdown {
      max-width: 475px;
      min-width: 100px!important;

      &--below {
        &:before {
          left: 15px;
        }
      }
    }

  }
}

.condition-filters {
  .condition-filter {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    border-bottom: 1px solid @f-color-border;

    &:first-child {
      border-top: 1px solid @f-color-border;
    }

    &__content {
      flex-grow: 1;
    }

    &__filters {
      display: flex;
    }

    &__filter {
      margin-right: 20px;

      &--dishes {
        flex-grow: 1;
      }
    }

    &__checkbox-list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -18px;
      padding-bottom: 10px;
    }
    &__checkbox {
      margin-left: 18px;
      margin-top: 14px;
    }
    &__range {
      display: flex;
      align-items: center;
      margin-top: 12px;
    }
    &__actions {
      padding-top: 10px;
      flex-shrink: 0;
    }
  }

  .condition-filter--filial {
    .form-check {
      margin-left: 0;
      .form-check-label {
        margin-top: -10px;
        &:before {
          top: -5px;
        }
      }
    }
  }
}
