.tab-group {
	box-shadow: 0 2px 4px #CFD8DC;
	border-radius: 4px 4px 7px 7px;
	display: flex;
	flex-direction: column;

	.nav {
		display: flex;
	}

	.nav-tabs {
		height: 48px;
		border-bottom: none;

		.nav-link.disabled {
			color: #A6B1BC;
		}
	}



	.nav-item {
		display: flex;
		flex-grow: 1;
		align-items: center;
		justify-content: center;

		background: rgba(190, 198, 202, 0.488168);
		border: none;
		border-radius: 4px 4px 0 0;
		font-size: 14.6667px;
		font-weight: 500;
		color: #73808D;
		cursor: pointer;

		&:not(:first-child) {
			margin-left: 1px;
		}

		&.active {
			background: #FFF;
			color: #333;
			font-weight: 700;
		}
	}

	.tab-content {
		background: #FFF;
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		&:last-child {
			border-radius: 0 0 7px 7px;
		}
	}

	.tab-pane {
		flex-grow: 1;
		padding: 20px;
		flex-direction: column;


		&.active {
			display: flex;
		}

		&__content {
			flex-grow: 1;
		}
	}

	&__tab-pane-footer {
		position: relative;

		padding: 20px;
		margin: 20px -20px -20px;

		background: #FBFBFB;
		mix-blend-mode: normal;
		border-radius: 0 0 7px 7px;
	}
}
