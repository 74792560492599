.reviews-page {
  &-info {
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: var(--foquz-side-offset);
    padding-right: var(--foquz-side-offset);

    .mobile-and-tablet({
      flex-direction: column;
    });
  }

  &-settings {
    flex-grow: 1;
    display: flex;
    align-items: stretch;

    .svg-icon {
      flex-shrink: 0;
    }

    &__columns {
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      align-items: center;
    }

    &__view {
      display: flex;
      padding-left: 20px;
      border-left: 1px solid @f-color-border;

      button:first-child {
        margin-right: 18px;
      }
    }

    .only-mobile({
      margin-right: calc(-1 * var(--foquz-side-offset));
      &__view {
        display: none;
      }
      &__columns {
        padding-right: 15px;
      }
    });
  }

  &-meta__toggler {
    display: flex;
    align-items: center;
    padding-left: 16px;
  }
}

.s-app {
  .reviews-page {
    .mobile-and-tablet({
      margin-left: 0;
      margin-right: 0;
    });
  }
}
