.folders-list {
  max-height: 240px;
  padding-right: 5px;

  .scroll-content {
    padding-right: 10px;
  }
  &__item {
    display: flex;
    min-height: 30px;
    padding-top: 5px;
    padding-bottom:5px;
    align-items: center;
    transition: background-color 300ms;
    cursor: pointer;
    color: @f-color-primary;
    border-radius: 4px;
    margin-bottom: 4px;

    &:hover {
      background-color: #F1F4FF;
    }

    &--active {
      color: @f-color-text;
    }

    &--blocked {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}
