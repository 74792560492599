.nps-scale {
  display: inline-block;
  width: 100%;

  .nps-scale__list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -6px;
    margin-right: -6px;
  }

  &__item {
    position: relative;
    flex-grow: 1;
    height: 36px;
    width: 42px;
    max-width: 42px;
    margin: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f5f6;
    color: black;
    font-size: 15px;
    font-weight: 500;
    padding: 0 4px;
    border-radius: 4px;

    &:before,
    &-bg {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 4px;
      background: inherit;
      border: 1px solid #cfd8dc;
      transition: all 250ms;

      z-index: 1;
    }

    span {
      z-index: 2;
    }

    &:only-child {
      width: 42px;
    }
  }

  &--colored {
    .nps-scale__item {
      color: white;

      &:before,
      &-bg {
        border: none;
      }
    }
  }

  &--inited {
    .nps-scale__item:not(.active) {
      opacity: 0.2;
    }
    .nps-scale__item.active {
      &:before,
      .nps-scale__item-bg {
        transform: scaleX(1.24) scaleY(1.33);
      }
    }
  }

  &--sm {
    .nps-scale__item {
      height: 24px;
      margin: 0 1px;
      font-size: 13px;
      &:only-child {
        width: 28px;
      }
    }
  }

  &--from-one {
    .nps-scale__item:first-child {
      display: none;
    }
  }

  &__labels {
    margin-top: 16px;
    opacity: 0.4;
    display: flex;
    justify-content: space-between;
    font-size: 15px;

    div:first-child {
      text-align: left;
    }
    div:last-child {
      text-align: right;
    }
  }
}

@media screen and (max-width: 767.98px) {
  .nps-scale {
    margin-left: -1px;
    margin-right: -1px;
    &__item {
      height: 24px;
      width: 28px;
      max-width: 28px;
      margin: 0 1px;
      font-size: 13px;
    }

    &--inited {
      .nps-scale__item.active {
        &:before,
        .nps-scale__item-bg {
          transform: scaleY(1.5);
        }
      }
    }
  }
}
