.clients {
  &-tags {


    .btn-tag-apply {
      box-shadow: none;
      padding: 0;
      .icon {
        display: block;
      }
    }

    .button-add {
      padding: 0 25px;
    }

    &__content {
      .mobile-and-tablet({
        margin-left: -7px;
        margin-right: -7px;
      })
    }
  }

  &-tags-table {
    margin-top: 20px;
    &__actions {
      white-space: nowrap;
    }
    .icon-sorting {
      position: relative;
      top: -2px;
    }

    thead > tr > th {
      border-bottom-width: 2px;

      body.sticky-tags-title & {
        border: none;
      }
    }

    th {
      background: white;
      position: sticky;
      top: 0;
      z-index: 100;

      body.sticky-tags-title & {
        th {
          border: none !important;
        }

        &:before {
          content: '';
          position: absolute;
          right: 0;
          left: 0;
          bottom: -22px;
          height: 22px;
          background: linear-gradient(
            180deg,
            rgba(115, 128, 141, 0.15) 0%,
            rgba(115, 128, 141, 0) 100%
          );
        }

        &.foq-table__outer-element-head-cell {
          &:first-child {
            &:before {
              left: -20px;
            }
          }
          &:last-child {
            &:before {
              right: -20px;
            }
          }
        }
      }
    }

    &__cell {
      &--name {
        .foq-table__head-cell-title {
          min-width: 220px;
        }
      }
    }

    &__conditions {
      font-size: 12px;
      font-weight: 500;
      color: #2e2f31;
    }

    .mobile-and-tablet({
      width: 100%;
      table-layout: fixed;

      .foq-table__head-cell-name {
        white-space: normal;
      }
    });
  }
}

@media screen and (max-width: 1200px) {
  body.sidebar-open .clients-tags-table {
    &__cell {
      &--name {
        .foq-table__head-cell-title {
          min-width: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .clients-tags-table {
    &__cell {
      &--name {
        .foq-table__head-cell-title {
          min-width: 120px;
        }
      }
    }
  }
}
