.sur {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header {
        background: #000;
        height: 44px;
    }

    &__content {
        flex-grow: 1;
    }
}
