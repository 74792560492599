.question-statistics {
    &__files-modal-dialog {
        &--initializing {
            visibility: hidden;
        }
    }

    &__files-modal-dialog-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        background: #FFFFFF;
        box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);

        .question-statistics__files-modal-dialog-header {
            height: 72px;
            flex-shrink: 0;
        }

        .question-statistics__files-modal-dialog-body {
            flex-grow: 1;
            margin-top: 24px;
        }

        .question-statistics__files-modal-dialog-loader {
            margin-top: 0;
            margin-bottom: 20px;
        }
    }

    &__files-modal-dialog-header {
        display: flex;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;

        .question-statistics__files-modal-dialog-search-form {
            width: 100%;
            max-width: 400px;
        }
    }

    &__files-modal-dialog-title {
        font-weight: bold;
        font-size: 19px;
        color: #2E2F31;
    }


    &__files-modal-dialog-search-form {
        position: relative;
    }

    &__files-modal-dialog-search-form-icon {
        .icon-search(#73808D);

        display: inline-block;
        position: absolute;
        left: 15px;
        top: 16px;
        margin-right: 11px;
    }

    &__files-modal-dialog-search-form-control {
        padding-left: 46px;
    }

    &__files-modal-dialog-search-form-clear-button {
        .icon-plus3(#2E2F31) !important;

        position: absolute;
        right: 13px;
        top: calc(50% - (17px / 2));
        padding: 0;
        transform: rotate(45deg);

        border-radius: 0;
        box-shadow: none !important;

        transition: none;
    }

    &__files-modal-dialog-body {
        padding-left: 30px;
        padding-right: 30px;

        overflow-y: hidden;

        .question-statistics__files-modal-dialog-list-container {
            max-height: 100%;
            margin-right: -19px;
        }
    }

    &__files-modal-dialog-list-container {
        display: flex;
        position: relative;

        overflow-y: hidden;

        .question-statistics__files-modal-dialog-list-wrapper {
            flex-grow: 1;
            width: 100% !important;
        }
    }

    &__files-modal-dialog-list-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .scroll-element.scroll-y {
            right: 0;
        }
    }

    &__files-modal-dialog-list {
        display: flex;
        flex-wrap: wrap;

        width: 100%;
        margin: -30px 19px 30px -18px;

        .question-statistics__files-modal-dialog-list-item {
            margin-left: 18px;
            margin-top: 30px;
            width: 105px;
        }
    }

    &__files-modal-dialog-list-item {
        .question-statistics__files-modal-dialog-list-item-first-info {
            margin-top: 8px;
        }

        .question-statistics__files-modal-dialog-list-item-second-info {
            margin-top: 2px;
        }
    }

    &__files-modal-dialog-list-item-preview {
        width: 105px;
        height: 105px;
        position: relative;

        border-radius: 8px;
        background-color: #000;
        overflow: hidden;
        cursor: pointer;

        .question-statistics__files-modal-dialog-list-item-preview-count {
            position: absolute;

            top: 7px;
            left: 8px;
        }

        .question-statistics__files-modal-dialog-list-item-preview-comment-indicator {
            position: absolute;

            top: 9px;
            right: 8px;
        }
    }

    &__files-modal-dialog-list-item-preview-image {
        width: 100%;
        height: 100%;

        object-fit: contain;
        opacity: 0.6;
        &.no-opacity {
            opacity: 1;
        }
        &_audio {
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 8px;
            border: 2px solid #DADFE3;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__files-modal-dialog-list-item-preview-count {
        font-weight: bold;
        font-size: 14px;
        color: #FFF;
        &.audio {
            color: #73808D;
        }
    }

    &__files-modal-dialog-list-item-preview-comment-indicator {
        .icon-comment2(#FFF);
        &.audio {
            .icon-comment2(#73808D);
        }
    }

    &__files-modal-dialog-list-item-info {
        font-weight: 500;
        font-size: 12px;
        color: #3F65F1;
    }

    &__files-modal-dialog-list-item-fancybox {
        display: inline-block;
        text-align: start;

        .question-statistics__files-modal-dialog-list-item-fancybox-comment {
            margin-top: 14px;
        }
    }

    &__files-modal-dialog-list-item-fancybox-info-block {
        font-size: 12px;

        .question-statistics__files-modal-dialog-list-item-fancybox-info {
            &:not(:first-child) {
                margin-left: 8px;
            }
        }
    }

    &__files-modal-dialog-highlight {
        background: #3F65F133;
    }
}
