.foq-table {
  & > thead {
    & > tr {
      & > th {
        border-top: none;
        font-weight: bold;
        font-size: 12px;
        color: #2e2f31;
      }
    }
  }

  & > tbody {
    & > tr {
      & > td {
        vertical-align: middle;
      }

      transition: background-color 200ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    & > tr:last-child {
      & > td {
        border-bottom: 1px solid #e7ebed;
      }
    }
  }

  &__section {
    position: relative;
    padding-top: 12px;
  }

  &__outer-element-head-cell {
    width: 7px;
    min-width: 7px;
    padding: 0 !important;

    border: none !important;
  }

  &__outer-element-cell {
    padding: 0 !important;

    border: none !important;

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  &__selection-head-cell {
    .foq-table__checkbox {
      margin-bottom: 20px;
    }
  }

  &__selection-head-cell,
  &__selection-cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &__row {
    &--selected {
      background: #f1f4ff;
    }
  }

  &__sorting-icon {
    display: inline-block;
    flex-shrink: 0;
    margin-left: 4px;

    &--default {
      .icon-sorting(#A6B1BC);
    }

    &--order_asc {
      .icon-sorting(#3f65f1) !important;
      transform: rotate(180deg);
    }

    &--order_desc {
      .icon-sorting(#3f65f1) !important;
    }

    &--fake {
      display: none;
    }
  }

  &__filtering-icon {
    .icon-filtering(#3f65f1);

    display: inline-block;
    flex-shrink: 0;
    margin-left: 4px;
  }

  &__head-cell-title {
    display: flex;
    align-items: center;

    &--active {
      color: #3f65f1;
    }
  }

  &__head-cell-name {
    white-space: nowrap;
  }

  &__head-cell-filter {
    width: 100%;
    margin-top: 4px;

    font-size: 12px;
    border: none;
    color: #2e2f31;

    &::placeholder {
      font-size: 12px;
      color: #a6b1bc;
      font-weight: 400;
    }
  }

  &__group-actions-wrapper {
    position: absolute;
    height: 100%;
    top: 0;
  }

  &__group-actions {
    display: flex;
    align-items: center;
    padding: 18px 20px;
    position: sticky;
    top: 0;

    z-index: 1000;
    background: #ffffff;
    box-shadow: 0 5px 15px rgba(46, 47, 49, 0.3);
    border-radius: 9px;

    .foq-table__group-actions-counter {
      flex-shrink: 0;
    }

    .foq-table__group-actions-select-all-button {
      flex-shrink: 0;
      margin-left: 28px;
    }

    .foq-table__group-actions-selector {
      flex-shrink: 1;

      &.select2 {
        margin-left: 28px;
      }
    }

    .foq-table__group-actions-dismiss-button {
      flex-shrink: 0;
      margin-left: 10px;
    }

    .foq-table__group-actions-apply-button {
      flex-shrink: 0;
      margin-left: 10px;
    }
  }

  &__group-actions-counter {
    font-size: 16px;
  }

  &__group-actions-counter-value {
    font-weight: bold;
  }

  &__group-actions-select-all-button {
    padding: 5px 25px;

    background: #dadfe3;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #2e2f31;
  }

  &__group-actions-dismiss-button {
    display: flex;
    width: 61px;
    height: 48px;
    align-items: center;
    justify-content: center;
    padding: 0;

    background: #ffdddd;
    border-radius: 4px;

    &:before {
      .icon-plus3(#F96261);

      content: "";

      transform: rotate(45deg);
      width: 21px;
      height: 21px;
    }
  }

  &__group-actions-apply-button {
    display: flex;
    width: 61px;
    height: 48px;
    align-items: center;
    justify-content: center;
    padding: 0;

    background: #abefb7;
    border-radius: 4px;

    &:before {
      .icon-done(#37a74a);

      content: "";

      width: 19.6px;
      height: 14px;
    }
  }

  &__edit-columns-button {
    &:before {
      .icon-settings2(#73808d);
    }

    &:hover {
      &:before {
        .icon-settings2(#3f65f1);
      }
    }
  }

  &--hoverable {
    & > tbody {
      & > tr {
        &:hover {
          background: #f1f4ff;
        }
      }
    }
  }

  &--filterable {
    & > thead {
      & > tr {
        & > th {
          vertical-align: top !important;
          padding-bottom: 5px;
        }
      }
    }
  }

  &--clickable {
    & > tbody {
      & > tr {
        cursor: pointer;
      }
    }
  }

  .foq-table__head-cell-name--active {
    color: #3f65f1;
  }
}
