.statistics {
    &__edit-columns-modal-dialog {
        width: 460px !important;

        &--initializing {
            visibility: hidden;
        }
    }

    &__edit-columns-modal-dialog-search-form {
        position: relative;
    }

    &__edit-columns-modal-dialog-search-form-icon {
        .icon-search(#73808D);

        display: inline-block;
        position: absolute;
        left: 15px;
        top: 16px;
        margin-right: 11px;
    }

    &__edit-columns-modal-dialog-search-form-control {
        padding-left: 46px;
    }

    &__edit-columns-modal-dialog-search-form-clear-button {
        .icon-plus3(#2E2F31) !important;

        position: absolute;
        right: 13px;
        top: calc(50% - (17px / 2));
        padding: 0;
        transform: rotate(45deg);

        border-radius: 0;
        box-shadow: none !important;

        transition: none;
    }

    &__edit-columns-modal-dialog-column-picker {
        margin-top: 24px;
    }

    &__edit-columns-modal-dialog-column-picker-list-wrapper {
        max-height: 252px;

        .scroll-element.scroll-y {
            right: 0;
        }
    }

    &__edit-columns-modal-dialog-column-picker-list {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: -18px;
    }

    &__edit-columns-modal-dialog-column-picker-column {
        width: 50%;
        min-height: 24px;
        padding-top: 5px;

        margin-top: 18px;
    }
}
