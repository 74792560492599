@import "./delete-modal-dialog/delete-modal-dialog";

.companies {
    &__content {
        &--initializing {
            visibility: hidden;
        }
    }

    &__card {
        padding: 32px 20px;

        background: #FFFFFF;
        box-shadow: 0 2px 4px #CFD8DC;
        border-radius: 9px;

        .companies__card-header {
            margin-top: -32px;
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    &__card-header {
        display: flex;
        padding: 18px 20px;
        align-items: center;
        justify-content: flex-end;

        border-bottom: 1px solid #ECEFF1;
    }

    &__table {
        width: 100%;

        color: #2E2F31;

        & > tbody > tr > td {
            height: 47px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    &__table-created-at-head-cell {
        width: 121px;
    }

    &__table-actions-head-cell {
        padding-top: 5px !important;
        width: 98px;

        text-align: end;
    }

    &__table-actions-cell {
        text-align: end;

        .companies__table-action-button {
            transition: none !important;

            &:not(:first-child) {
                margin-left: 13px;
            }
        }
    }

    &__table-row {
        position: relative;

        &--has-details {
            cursor: pointer;
        }
    }

    &__table-domain {
        color: #3F65F1 !important;
        text-decoration: none !important;
    }

    &__table-edit-button {
        &:before {
            .icon-pencil(#73808D);
        }
    }

    &__table-delete-button {
        &:before {
            .icon-delete(#73808D);
        }
    }

    &__table-auto-pool-icon {
        &:before {
            position: absolute;
            left: -33px;

            .icon-company-auto-poll(#73808D);
        }
    }
}
