.survey-question {
    &__change-modal-dialog {
        width: 520px !important;
        max-width: 520px !important;

        &--initializing {
            visibility: hidden;
        }
    }
}

.modal-dialog-change {
    .modal-body {
        font-size: 14px;
        line-height: 1.3;
        color: #2e2f31;
    }

    b {
        font-weight: bold;
    }
}

.modal .modal-dialog-change .modal-content .modal-actions > *:not(:first-child) {
    margin-left: 20px;
}
