.reviews-page {
  &-points {
    padding-top: 12px;
    padding-bottom: 0;

    &__container {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
    }

    &__separator {
      width: 0;
      height: 65px;
      border-left: 1px solid #eceff1;
      align-self: flex-start;
    }

    .reviews-rating-filter {
      margin-bottom: 12px;
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 10px;
      padding-right: 10px;
    }

    &__add {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: auto;
      margin-bottom: 12px;
      text-align: right;

      .f-btn {
        text-align: right;
      }
    }

    .only-tablet({
      &__add {
        max-width: 200px;
      }
    });

    .only-mobile({
      .reviews-rating-filter {
        width: 50%;
      }
      &__add {
        width: 100%;
        margin-left: 0px;
        text-align: center;
        margin-bottom: 20px;
        max-width: none;
      }

      &__separator {
        height: 0;
        width: 100%;
        border-left: 0;
        border-bottom: 1px solid #eceff1;
        margin-bottom: 20px;
      }

      .reviews-rating-filter {
        margin-bottom: 20px;
      }

      .rating-slider {
        max-width: 100%;
        &__wrapper {
          max-width: 100%;
        }
      }
    });
  }
}
