.review-details-modal {
  &__info {
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
  }

  &__info-block {
    flex-grow: 1;
    background: hsl(204, 15%, 94%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 20px;
    margin: 0 5px 10px;

    &--call {
      align-items: center;
    }
  }

  .client-info {
    font-size: 16px;
    color: #2e2f31;

    &__name {
      font-weight: bold;
    }
    &__phone {
      font-weight: 400;
    }
  }

  .call-block {
    width: 44px;
    height: 44px;
    box-shadow: none !important;
    background: #37a74a;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    &:before {
      flex-shrink: 0;
      display: block;
      .icon-phone(#FFF);
      content: '';
    }
  }

  .review-notifications {
    &__header {
      font-weight: bold;
      font-size: 12px;
      color: #73808d;
    }

    &__list {
      display: flex;
      margin-top: 21px;
    }
  }

  .review-notification {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    position: relative;

    background: #ffffff;
    border-radius: 50%;

    &:not(:first-child) {
      margin-left: 29px;
    }

    &--disabled {
      background: #f6f7f8;
      opacity: 0.4;
    }

    &__count {
      position: absolute;
      top: -3px;
      right: -13px;
    }
  }

  .review-date {
    display: flex;
    align-items: center;

    font-weight: bold;
    font-size: 12px;
    color: #73808d;
    cursor: help;

    &:not(:first-child) {
      margin-top: 6px;
    }
  }

}
