.time-picker {
	padding: 17px 0 !important;
	width: 100px;
	height: 265px;
	min-width: unset;

	&__wrapper {
		position: relative;
	}

	.dropdown-menu-content {
		height: 100%;
		position: relative;

		overflow-y: auto;

		&.scroll-wrapper:after {
			content: '';

			position: absolute;
			bottom: -8px;
			width: 100%;
			height: 21px;

			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
		}

		&.scroll-content {
			padding-right: 20px;
		}
	}

	.dropdown-item {
		cursor: pointer;
	}

	.scroll-y {
		right: 8px !important;
	}
}
