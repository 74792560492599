.review-details-modal {
  .review-history {
    &__not-found {
      text-align: center;
      padding: 20px;
      color: @f-color-service;
    }
  }
  &__history-nav {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;

    border: 1px solid #cfd8dc;
    border-radius: 4px;
    overflow: hidden;

    .nav-item {
      display: flex;
      flex-grow: 1;
      width: 100%;
      align-items: center;
      padding: 14.4px 15px;

      font-weight: bold;
      font-size: 16px;
      color: #2e2f31;
      border: none;
      border-radius: 0;

      &:not(:first-child) {
        border-left: 1px solid #cfd8dc;
      }

      &:not(.active) {
        background: #f1f5f6;
        color: #73808d;
      }
    }
  }

  &__history-nav-item {
    .review-details-modal__history-nav-item-count {
      margin-left: 11px;
    }
  }

  &__history-nav-item-count {
    font-size: 16px;
    font-weight: normal;
    color: #73808d;
  }

  table th {
    .only-desktop({
      white-space: nowrap;
    });
  }

  &__history-orders-table {
    margin-top: 24px;
    margin-left: -7px;
    margin-right: -7px;
    width: calc(100% + 14px);
  }

  &__history-orders-table-row {
    &--open {
      background: #eceff1;

      &:not(:first-child) {
        & > td {
          padding-top: calc(0.75rem + 1px);

          border-top: none;
        }
      }

      &
        + .review-details-modal__history-orders-table-content-row
        + .review-details-modal__history-orders-table-row {
        & > td {
          padding-top: calc(0.75rem + 1px);

          border-top: none;
        }
      }

      & + .review-details-modal__history-orders-table-content-row {
        border-bottom: 2px solid #e7ebed;
      }
    }
  }

  &__history-orders-table-id-cell {
    cursor: pointer;
  }

  &__history-orders-table-id {
    color: #3f65f1 !important;
    font-weight: 500;
    text-decoration: none !important;
  }

  &__history-orders-table-price-cell {
    white-space: nowrap;
  }

  &__history-orders-table-price-ruble-sign {
    font-size: 12px;
  }

  &__history-orders-table-arrow-cell {
    width: 34px;
    padding-left: 14px !important;
    padding-right: 6px !important;
  }

  &__history-orders-table-arrow {
    .icon-arrow-bottom2(#73808d);

    display: inline-block;

    cursor: pointer;

    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);

    .review-details-modal__history-orders-table-row--open & {
      transform: rotate(180deg);
    }
  }

  &__history-orders-table-sending-indicator-cell {
    width: 41px;

    padding-right: 9px !important;
  }

  &__history-orders-table-feedback-indicator-cell {
    width: 39px;

    padding-left: 9px !important;
    padding-right: 16px !important;
  }

  &__history-orders-table-sending-indicator {
    .icon-sending(#73808d);

    display: inline-block;
  }

  &__history-orders-table-feedback-indicator {
    .icon-feedback(#37a74a);

    display: inline-block;
  }

  &__history-orders-table-content-row {
    & > td {
      padding: 0;
      border-top: none;
    }
  }

  &__history-orders-table-content {
    padding-top: 13px;
    padding-bottom: 16.5px;
    overflow: hidden;
  }

  &__history-orders-table-content-table {
    & > thead {
      & > tr {
        & > th {
          padding: 8px 16px;

          font-size: 16px;
          color: #2e2f31;
        }
      }
    }

    & > tbody {
      & > tr {
        & > td {
          padding: 8px 16px;
        }

        &:first-child {
          & > td {
            border-top-width: 2px;
          }
        }
      }
    }
  }

  &__history-orders-table-order-list-table-quantity-cell {
    text-align: right;
    white-space: nowrap;
  }

  &__history-orders-table-order-list-table-price-head-cell {
    white-space: nowrap;
    text-align: right;

    .review-details-modal__history-orders-table-order-list-table-price-ruble-sign {
      font-size: 14px;
    }
  }

  &__history-orders-table-order-list-table-price-cell {
    white-space: nowrap;
    text-align: right;

    .review-details-modal__history-orders-table-order-list-table-price-ruble-sign {
      font-size: 12px;
    }
  }

  &__history-orders-table-history-table-time-cell {
    text-align: right;
  }

  &__history-orders-table-info-block {
    display: flex;
    padding-left: 12px;

    .review-details-modal__history-orders-table-info-block-item {
      &:not(:first-child) {
        margin-left: 64px;
      }
    }
  }

  &__history-orders-table-info-block-item {
    .review-details-modal__history-orders-table-info-block-item-value-row {
      margin-top: 4px;
    }
  }

  &__history-orders-table-info-block-item-label {
    font-weight: bold;
    font-size: 14px;
    color: #2e2f31;
  }

  &__history-orders-table-info-block-item-value-row {
    display: block;

    font-size: 14px;
    color: #2e2f31;
  }

  &__history-orders-table-date {
    & > *:not(:first-child) {
      margin-left: 7px;
    }

    .only-tablet({
      display: flex;
      flex-direction: column;

      span {
        margin-left: 0!important;
      }
    });
  }

  &__history-reviews-table {
    margin-top: 24px;
  }

  &__history-reviews-table-name {
    color: #3f65f1 !important;
    font-weight: 500;
    text-decoration: none !important;
    cursor: pointer;
  }

  &__history-reviews-table-id {
    color: #3f65f1 !important;
    font-weight: 500;
    text-decoration: none !important;
    cursor: pointer;
  }

  &__history-reviews-table-price-cell {
    white-space: nowrap;
  }

  &__history-reviews-table-price-ruble-sign {
    font-size: 12px;
  }

  &__history-reviews-table-complaint-indicator-cell {
    width: 43px;

    padding-right: 9px !important;
  }

  &__history-reviews-table-compensation-indicator-cell {
    width: 44px;

    padding-left: 9px !important;
    padding-right: 16px !important;
  }

  &__history-reviews-table-complaint-indicator {
    .icon-complaint(#F96261);

    display: inline-block;
  }

  &__history-reviews-table-compensation-indicator {
    .icon-compensation(#73808d);

    display: inline-block;
  }

  &__history-reviews-table-rating-cell {
    .statistics__answer-list {
      width: 200px;
    }
  }

  &__history-reviews-table-row {
    cursor: pointer;
  }

  &__history-compensations-table {
    margin-top: 24px;
  }

  &__history-compensations-table-name {
    color: #3f65f1 !important;
    font-weight: 500;
    text-decoration: none !important;
    cursor: pointer;
  }

  &__history-compensations-table-compensation-usage-indicator-cell {
    width: 48px;

    padding-left: 9px !important;
    padding-right: 16px !important;
  }

  &__history-compensations-table-compensation-usage-indicator {
    .icon-compensation-usage(#37a74a);

    display: inline-block;
  }

  &__history-compensations-table-compensation-name-cell,
  &__history-compensations-table-compensation-reason-cell,
  &__history-compensations-table-notification-script-cell {
    font-size: 12px;
  }

  &__history-compensations-table-date {
    & > *:not(:first-child) {
      margin-left: 7px;
    }
  }
}
