.review-details-modal {
  .review-complaint {
    margin-top: 30px;
    margin-left: -30px;
    margin-right: -30px;
    padding: 22px 30px 24px;

    background: #ffdddd;

    &__text {
      margin-top: 10px;
    }

    &__photos {
      margin-top: calc(10px + (-5px));
    }

    &__title {
      font-weight: bold;
      font-size: 19px;
      color: #2e2f31;
    }

    &__text {
      display: block;

      font-size: 14px;
      color: #2e2f31;
    }

    &__photos {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      margin-left: -5px;
    }

    &__photo {
      width: 35px;
      height: 35px;
      margin-top: 5px;
      margin-left: 5px;

      border-radius: 8px;
      object-fit: cover;
      cursor: pointer;
    }
  }
}
