.question-statistics__gallery-modal {
  .question-statistics__gallery-table {
    &-media {
      &-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;
        &--inactive {
          img {
            opacity: 0.4;
          }
        }
      }
      &-wrapper {
        width: 35px;
        height: 35px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        margin-right: 10px;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      &-rating {
        font-size: 14px;
        font-weight: bold;
        width: 30px;
      }
    }
  }
}
