@import './_nav/_nav.less';
@import './_poll-tab/_poll-tab.less';
@import './_history-tab/_history-tab.less';
@import './_review-question-preview/_review-question-preview.less';
@import './_review-question-view/_review-question-view.less';


.review-details-modal-wrapper {
  position: absolute;
  height: 100%;
  right: 0;
  width: 100%;
  max-width: 1000px;

  &--last {
    margin-left: 175.31px;
    width: calc(100% - 175.31px);
  }

  &:not(.review-details-modal-wrapper--last) {
    right: 28px;
  }

  &--single {
    margin-left: 45px;
    width: calc(100% - 45px);
  }

  &__actions {
    position: absolute;
    transform: translate(calc(-100% + -12px), 11px);
    z-index: 2;
    display: flex;
  }

  &__close,
  &__close-all {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    box-shadow: none !important;
    border: 2px solid #73808d !important;
  }

  &__close-all {
    margin-right: 10.32px;
    padding: 4px 14.54px;
    font-weight: 500;
    font-size: 14px;
    color: #73808d !important;
  }

  &__close {
    width: 33px;
    height: 33px;
    padding: 0;

    &:before {
      .icon-cross3(#73808d);
      content: '';
    }
  }
}

.review-details-modal {
  padding-right: 0 !important;
  background: #ffffff99;
  height: 100%;
  width: 100%;

  &__loader {
    text-align: center;
    padding: 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;

    background: #ffffff;
    box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);
    border-top: 4px solid transparent;

    &.processing-status--0 {
      border-top-color: @answer-new-color;
    }

    &.processing-status--1 {
      border-top-color: @answer-in-process-color;
    }

    &.processing-status--2 {
      border-top-color: @answer-delayed-color;
    }

    &.processing-status--3 {
      border-top-color: @answer-completed-color;
    }

    &.processing-status--4 {
      border-top-color: @answer-handle-color;
    }
  }


  &__tab-content {
    flex-grow: 1;
  }

  &__tab-pane {
    padding: 20px 0px;

    &--poll {
      display: flex !important;
      flex-direction: column;
      min-height: 100%;

      &:not(.active) {
        display: none !important;
      }
    }
  }
}

.statistics {
  &__details-modal-dialog-id {
    font-weight: normal;
    font-size: 15px;
    color: #2e2f31;
  }



  &__details-modal-dialog-status-form-control-value {
    &--new {
      color: #16ceb9 !important;
    }

    &--process {
      color: #2d99ff !important;
    }

    &--delayed {
      color: #f96261 !important;
    }

    &--completed {
      color: #8400ff !important;
    }
  }



  &__details-modal-dialog-view-form-group {
    margin-bottom: 26px;
  }

  &__details-modal-dialog-view-form-label {
    font-size: 16px;
    color: #73808d;
  }

  &__details-modal-dialog-view-form-control {
    display: block;
    margin-top: 8px;

    font-weight: bold;
    font-size: 16px;
    color: #2e2f31;
  }

  &__details-modal-dialog-status-view-form-control {
    color: #9151c1;
  }

  &__details-modal-dialog-history-nav {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;

    border: 1px solid #cfd8dc;
    border-radius: 4px;
    overflow: hidden;

    .nav-item {
      display: flex;
      flex-grow: 1;
      width: 100%;
      align-items: center;
      padding: 14.4px 15px;

      font-weight: bold;
      font-size: 16px;
      color: #2e2f31;
      border: none;
      border-radius: 0;

      &:not(:first-child) {
        border-left: 1px solid #cfd8dc;
      }

      &:not(.active) {
        background: #f1f5f6;
        color: #73808d;
      }
    }
  }

  &__details-modal-dialog-history-nav-item {
    .statistics__details-modal-dialog-history-nav-item-count {
      margin-left: 11px;
    }
  }

  &__details-modal-dialog-history-nav-item-count {
    font-size: 16px;
    font-weight: normal;
    color: #73808d;
  }

  &__details-modal-dialog-history-orders-table {
    margin-top: 24px;
    margin-left: -7px;
    margin-right: -7px;
    width: calc(100% + 14px);
  }

  &__details-modal-dialog-history-orders-table-row {
    &--open {
      background: #eceff1;

      &:not(:first-child) {
        & > td {
          padding-top: calc(0.75rem + 1px);

          border-top: none;
        }
      }

      &
        + .statistics__details-modal-dialog-history-orders-table-content-row
        + .statistics__details-modal-dialog-history-orders-table-row {
        & > td {
          padding-top: calc(0.75rem + 1px);

          border-top: none;
        }
      }

      & + .statistics__details-modal-dialog-history-orders-table-content-row {
        border-bottom: 2px solid #e7ebed;
      }
    }
  }

  &__details-modal-dialog-history-orders-table-id-cell {
    cursor: pointer;
  }

  &__details-modal-dialog-history-orders-table-id {
    color: #3f65f1 !important;
    font-weight: 500;
    text-decoration: none !important;
  }

  &__details-modal-dialog-history-orders-table-price-cell {
    white-space: nowrap;
  }

  &__details-modal-dialog-history-orders-table-price-ruble-sign {
    font-size: 12px;
  }

  &__details-modal-dialog-history-orders-table-arrow-cell {
    width: 34px;
    padding-left: 14px !important;
    padding-right: 6px !important;
  }

  &__details-modal-dialog-history-orders-table-arrow {
    .icon-arrow-bottom2(#73808d);

    display: inline-block;

    cursor: pointer;

    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);

    .statistics__details-modal-dialog-history-orders-table-row--open & {
      transform: rotate(180deg);
    }
  }

  &__details-modal-dialog-history-orders-table-sending-indicator-cell {
    width: 41px;

    padding-right: 9px !important;
  }

  &__details-modal-dialog-history-orders-table-feedback-indicator-cell {
    width: 39px;

    padding-left: 9px !important;
    padding-right: 16px !important;
  }

  &__details-modal-dialog-history-orders-table-sending-indicator {
    .icon-sending(#73808d);

    display: inline-block;
  }

  &__details-modal-dialog-history-orders-table-feedback-indicator {
    .icon-feedback(#37a74a);

    display: inline-block;
  }

  &__details-modal-dialog-history-orders-table-content-row {
    & > td {
      padding: 0;
      border-top: none;
    }
  }

  &__details-modal-dialog-history-orders-table-content {
    padding-top: 13px;
    padding-bottom: 16.5px;
  }

  &__details-modal-dialog-history-orders-table-content-table {
    & > thead {
      & > tr {
        & > th {
          padding: 8px 16px;

          font-size: 16px;
          color: #2e2f31;
        }
      }
    }

    & > tbody {
      & > tr {
        & > td {
          padding: 8px 16px;
        }

        &:first-child {
          & > td {
            border-top-width: 2px;
          }
        }
      }
    }
  }

  &__details-modal-dialog-history-orders-table-order-list-table-quantity-cell {
    text-align: right;
  }

  &__details-modal-dialog-history-orders-table-order-list-table-price-head-cell {
    white-space: nowrap;
    text-align: right;

    .statistics__details-modal-dialog-history-orders-table-order-list-table-price-ruble-sign {
      font-size: 14px;
    }
  }

  &__details-modal-dialog-history-orders-table-order-list-table-price-cell {
    white-space: nowrap;
    text-align: right;

    .statistics__details-modal-dialog-history-orders-table-order-list-table-price-ruble-sign {
      font-size: 12px;
    }
  }

  &__details-modal-dialog-history-orders-table-history-table-time-cell {
    text-align: right;
  }

  &__details-modal-dialog-history-orders-table-info-block {
    display: flex;
    padding-left: 12px;

    .statistics__details-modal-dialog-history-orders-table-info-block-item {
      &:not(:first-child) {
        margin-left: 64px;
      }
    }
  }

  &__details-modal-dialog-history-orders-table-info-block-item {
    .statistics__details-modal-dialog-history-orders-table-info-block-item-value-row {
      margin-top: 4px;
    }
  }

  &__details-modal-dialog-history-orders-table-info-block-item-label {
    font-weight: bold;
    font-size: 14px;
    color: #2e2f31;
  }

  &__details-modal-dialog-history-orders-table-info-block-item-value-row {
    display: block;

    font-size: 14px;
    color: #2e2f31;
  }

  &__details-modal-dialog-history-orders-table-date {
    & > *:not(:first-child) {
      margin-left: 7px;
    }
  }

  &__details-modal-dialog-history-reviews-table {
    margin-top: 24px;
  }

  &__details-modal-dialog-history-reviews-table-name {
    color: #3f65f1 !important;
    font-weight: 500;
    text-decoration: none !important;
    cursor: pointer;
  }

  &__details-modal-dialog-history-reviews-table-id {
    color: #3f65f1 !important;
    font-weight: 500;
    text-decoration: none !important;
    cursor: pointer;
  }

  &__details-modal-dialog-history-reviews-table-price-cell {
    white-space: nowrap;
  }

  &__details-modal-dialog-history-reviews-table-price-ruble-sign {
    font-size: 12px;
  }

  &__details-modal-dialog-history-reviews-table-complaint-indicator-cell {
    width: 43px;

    padding-right: 9px !important;
  }

  &__details-modal-dialog-history-reviews-table-compensation-indicator-cell {
    width: 44px;

    padding-left: 9px !important;
    padding-right: 16px !important;
  }

  &__details-modal-dialog-history-reviews-table-complaint-indicator {
    .icon-complaint(#F96261);

    display: inline-block;
  }

  &__details-modal-dialog-history-reviews-table-compensation-indicator {
    .icon-compensation(#73808d);

    display: inline-block;
  }

  &__details-modal-dialog-history-reviews-table-rating-cell {
    .statistics__answer-list {
      width: 200px;
    }
  }

  &__details-modal-dialog-history-reviews-table-row {
    cursor: pointer;
  }

  &__details-modal-dialog-history-compensations-table {
    margin-top: 24px;
  }

  &__details-modal-dialog-history-compensations-table-name {
    color: #3f65f1 !important;
    font-weight: 500;
    text-decoration: none !important;
    cursor: pointer;
  }

  &__details-modal-dialog-history-compensations-table-compensation-usage-indicator-cell {
    width: 48px;

    padding-left: 9px !important;
    padding-right: 16px !important;
  }

  &__details-modal-dialog-history-compensations-table-compensation-usage-indicator {
    .icon-compensation-usage(#37a74a);

    display: inline-block;
  }

  &__details-modal-dialog-history-compensations-table-compensation-name-cell,
  &__details-modal-dialog-history-compensations-table-compensation-reason-cell,
  &__details-modal-dialog-history-compensations-table-notification-script-cell {
    font-size: 12px;
  }

  &__details-modal-dialog-history-compensations-table-date {
    & > *:not(:first-child) {
      margin-left: 7px;
    }
  }
}



.statistics__details-modal-dialog-priority-variant {
  display: flex;
  align-items: center;
  min-height: 40px;
  margin: 1px 0;
  font-size: 16px;
  color: #2e2f31;
  background: #eceff1;
  border-radius: 8px;
  padding: 15px;

  &-index {
    font-weight: bold;
    margin-right: 10px;
  }
}

@import './_adaptive.less';
