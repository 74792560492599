.media-preview {
  width: 94px;
  height: 94px;
  position: relative;
  border-radius: 8px;
  flex-shrink: 0;



  &:after {
    content: '';

    position: absolute;

    top: 0;
    left: 0;
    width: 94px;
    height: 94px;

    border-radius: 8px;
    background-color: #000;
    opacity: 0.3;
    pointer-events: none;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: inherit;
    object-fit: cover;
    cursor: pointer;
  }

  &__remove {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -6px;
    right: -6px;
    border: none;
    z-index: 3;

    font-size: 11px;
    opacity: 0;
    cursor: pointer;
    background-color: #000;
    border-radius: 50%;

    & > i {
      cursor: pointer;
      color: #fff;
    }
  }

  &__label {
    position: absolute;
    top: 25.68px;
    left: 2px;
    width: 90px;
    border: none;
    width: 94px;
    z-index: 2;

    font-family: Roboto, sans-serif;
    font-size: 34px;
    font-weight: bold;
    line-height: 120%;
    color: #fff;
    background: none;
    text-align: center;
    text-transform: uppercase;
  }

  &:hover {
    .media-preview__remove {
      opacity: 1;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 94px;
    height: 94px;
    position: relative;

    border-radius: 8px;
    background-color: #eceff1;

    &:after {
      content: '\f110';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      background-color: transparent;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      width: auto;
      height: auto;
      color: #3f65f1;
      font-size: 20px;

      -webkit-animation: fa-spin 1s infinite steps(8);
      animation: fa-spin 1s infinite steps(8);
    }
  }

  &__load {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    background: #eceff1;
    border-radius: inherit;

    i {
      width: 40px !important;
      height: 40px !important;
    }

    .i-icon-photo {
      .icon-image-light(#A6B1BC);
    }
    .i-icon-video {
      .icon-video-light(#A6B1BC);
    }
    .i-icon-photovideo {
      .icon-photovideo-light(#A6B1BC);
    }
  }
}

@media (max-width: 1219px) {
  .media-preview {
    &__remove {
      opacity: 1;
    }
  }
}
