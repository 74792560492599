.company-settings {
  .scripts-tab {

    .settings__conn-channels-channel-title {
      margin-top: 10px;
    }

    .settings__conn-channels-table {
      margin-top: 0;
    }

    .tab-group__tab-pane-footer {
      margin-bottom: -20px;
    }


    &__list {
      margin-left: -7px;
      margin-right: -7px;
    }

    &__actions {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid @f-color-border;
    }

    &__message {
      margin-right: 40px;
      flex-grow: 1;
    }

    &--inactive, &--empty {
      .scripts-tab__message {
        opacity: 0;
        visibility: hidden;
      }

      .scripts-tab__actions {
        border-bottom: 0;
      }
    }

    &__warning {
      min-height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px 40px 80px;
    }

    &__filters {
      display: flex;
      flex-wrap: wrap;

      .form-group {
        margin-right: 30px;
      }
    }

    &__filter {
      max-width: 100%;

      .form-control {
        max-width: 100%;
      }
    }

    &__filters-actions {
      margin-left: auto;
    }

    &__channel-filters {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    &__channel-filter {
      outline: none;
      box-shadow: none;
      margin: 0 3px;
      height: 30px;
      border-radius: 60px;
      background-color: #D9E0FC;
      color: #3F65F1;
      font-size: 14px;
      padding: 0px 15px;
      line-height: 1;
      display: flex;
      align-items: center;

      &--active {
        color: white;
        background-color: #3F65F1;
      }
    }

    &__reset-filters {
      margin-right: 24px;
    }

    .scripts-table {
      width: 100%;

      td {
      }

      th {
      }

      &__channel {
        margin-right: 15px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;

        &:last-child {
          margin-right: 0;
        }

        &-icon {
          margin-right: 10px;
        }
      }
    }

    .script-form {
      min-height: 460px;
      padding-top: 10px;
      display: flex;
      flex-direction: column;

      &__content {
        flex-grow: 1;
      }
    }
  }
}
