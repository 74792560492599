.footer {
  width: 100%;

  background-color: rgba(63, 101, 241, 0.03);
  color: #73808d;

  a {
    color: inherit;
    transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: #3f65f1;
    }
  }

  &__logo-footer {
    margin-bottom: 33px;
  }

  &__logoFooter-link {
    text-decoration: none;
    width: 93px;
    height: 20px;
    display: block;
    transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
    cursor: pointer;
    opacity: 0.2;

    &:hover {
      opacity: 0.4;
    }

    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
  }

  &__copyright {
    font-size: 13px;
    font-weight: normal;
    line-height: 1.38;
    margin-bottom: 0px;
  }

  &__social {
    display: flex;
  }

  &__social-link {
    text-decoration: none;
    margin-right: 15px;
    transition: color 600ms;

    &:hover {
      svg {
        path[stroke] {
          stroke: #3f65f1 !important;
        }
        path[fill] {
          fill: #3f65f1 !important;
        }
      }
    }

    svg {
      transition: all 390ms;
    }
  }

  &__quick-open {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 34px;
    font-size: 13px;
    &:before {
      content: '';
      .icon-quick-open(#73808d);
      margin-right: 8px;
    }
    &:hover {
      &:before {
        .icon-quick-open(#3f65f1);
      }
    }
  }
}

.simple-footer {
  .footer {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;

    transition: none;
  }

  &.simple-footer--default {
    .footer {
      transition: padding-left 500ms ease-in-out;
      padding-left: 108px;
    }

    body.sidebar-open & {
      .only-desktop({
        .footer {
          padding-left: var(--foquz-sidebar-width);
        }

      });
    }
  }

  .footer__logo-footer {
    margin-bottom: 0;
  }

  .only-mobile({
    .footer {
      padding-top: 30px;
    }
  });

  .tablet-and-desktop({
    .simple-footer__links {
      padding-right: 50px;
    }

    &.simple-footer--login {
      .footer {
        padding-left: 20px;
      }
    }
  });

  .mobile-and-tablet({
    &.simple-footer--default {
      .footer {
        padding-top: 24px;
        padding-bottom: 0;
        padding-left: var(--foquz-side-offset);
        padding-right: var(--foquz-side-offset);
      }
    }
  });

  .only-desktop({
    &.simple-footer--login {
      .footer {
        padding-left: 260px;
      }
    }
  });

  @media screen and (min-width: 1620px) {
    // &.simple-footer--executor,
    // &.simple-footer--external {
    //   .footer {
    //     padding-left: calc(50vw - 790px);
    //     padding-right: calc(50vw - 790px);
    //   }
    // }
  }

  &__columns {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;

    & > div {
      flex-basis: 50%;
      flex-grow: 1;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .show-big-mobile {
    display: none;

    @media screen and (min-width: 450px) and (max-width: 767px) {
      display: block;
    }
  }

  .hide-big-mobile {
    @media screen and (min-width: 450px) and (max-width: 767px) {
      display: none !important;
    }
  }
}
