.consolidated-report {
	&__period-filter {
		width: 261px;
		flex-shrink: 0;

		.form-group {
			margin-bottom: 0;
		}
	}

	&__period-dropdown {
		&:after {
            left: 218px !important;
        }
	}

	&__surveys-filter {
		width: 0;
		flex-grow: 1;
		flex-shrink: 1;

		.form-group {
			margin-bottom: 0;
		}
	}

	&__branch-filter {
		width: 0;
		flex-grow: 1;
		flex-shrink: 1;

		.form-group {
			margin-bottom: 0;
		}
	}

	&__financing {
		margin-top: 46px;
	}

	&__financing-title {
		margin-bottom: 22px;

		font-weight: bold;
		font-size: 19px;
		color: #2E2F31;
	}

	&__financing-form {
		display: flex;
		align-items: center;

		.form-group {
			margin-bottom: 0;
			flex-grow: 0;
			margin-right: 15px;
		}

		.report__apply-button {
			flex-shrink: 0;
			margin-top: 29px;
		}
	}

	&__financing-form-control {
		text-align: center;
		max-width: 100px;
	}

	&__financing-results {
		display: flex;
		margin-top: 30px;
		margin-left: -20px;
		margin-right: -20px;
		padding: 0 5px;

		border-top: 1px solid @f-color-border;
		border-bottom: 1px solid @f-color-border;

		&-item {
			border-right: 1px solid @f-color-border;
			padding: 15px;
		}

		&-value {
			font-size: 19px;
			font-weight: bold;
			margin-bottom: 5px;
		}

		&-label {
			color: @f-color-service;
			font-size: 12px;
			font-weight: bold;
		}
	}
}
