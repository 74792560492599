.contact-points {
    &__details-modal-dialog {
        .select2 .select2-selection--multiple.select2-selection .select2-search {
            min-width: 130px;

            input {
                min-width: 100%;
            }
        }

        .contact-points__details-modal-dialog-footer {
            margin-top: 20px;
        }

        .contact-points__details-modal-dialog-min-sum-form-group {
            width: 290px;
        }

        &--initializing {
            visibility: hidden;
        }
    }

    &__details-modal-dialog-content {
        display: flex;
        flex-direction: column;
        height: 100% !important;
        overflow-y: auto;
        border-radius: 0;
        box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);
    }

&__details-modal-dialog-content {
  min-width: 900px;
}

    &__details-modal-dialog-body {
        .contact-points__details-modal-dialog-media-type-selector {
            margin-bottom: 25px;
            width: 100%;
        }

        .contact-points__details-modal-dialog-clarifying-type-selector {
            margin-bottom: 26px;

            &-col {
                &:not(:first-child) {
                    margin-left: 30px;
                }
            }


        }

        .contact-points__details-modal-dialog-variants-type-selector {
            margin-bottom: 25px;
            width: 100%;
        }

        .contact-points__details-modal-dialog-custom-variant-checkbox {
            margin-top: 27.6px;
        }

        .contact-points__details-modal-dialog-custom-clarifying-question-answer-checkbox {
            margin-top: 27.6px;
        }

        .contact-points__details-modal-dialog-comment-length-form-group {
            margin-top: 24px;
        }

        .contact-points__details-modal-dialog-custom-variant-length-form-group {
            margin-top: 24px;
        }

        .contact-points__details-modal-dialog-custom-clarifying-question-answer-length-form-group {
            margin-top: 24px;
        }

        .contact-points__details-modal-dialog-card-footer {
            margin: 63px -20px -20px;
        }

        .contact-points__details-modal-dialog-question-loader {
            margin-top: 12px;
        }

        .contact-points__details-modal-dialog-conditions {
            margin-top: 16px;
        }
    }

    &__details-modal-dialog-scroll-content {
        padding: 20px;
    }


    &__details-modal-dialog-type-form-control-value {
        display: inline-flex;
        align-items: center;

        &:before {
            content: '';

            display: inline-block;
            margin-right: 6px;
        }

        &--rating {
            &:before {
                margin-left: -1px;
                .icon-rating(#A6B1BC);
                margin-right: 8px;
            }
        }

        &--variants {
            &:before {
                margin-left: -3px;
                .icon-variants(#A6B1BC);
                margin-right: 11px;
            }
        }

        &--text {
            &:before {
                margin-left: -3px;
                .icon-type-text(#A6B1BC);
                margin-right: 13px;
            }
        }

        &--date {
            &:before {
                margin-left: -3px;
                .icon-type-date(#A6B1BC);
                margin-right: 11px;
            }
        }

        &--address {
            &:before {
                margin-left: -1px;
                .icon-type-address(#A6B1BC);
                margin-right: 13px;
            }
        }

        &--upload {
            &:before {
                margin-left: -1px;
                .icon-type-upload(#A6B1BC);
                margin-right: 11px;
            }
        }

        &--quiz {
            &:before {
                margin-left: -1px;
                .icon-type-quiz(#A6B1BC);
                margin-right: 11px;
            }
        }

        &--priority {
            &:before {
                margin-left: -1px;
                .icon-type-priority(#A6B1BC);
                margin-right: 11px;
            }
        }

        &--media-variants {
            &:before {
                margin-left: -1px;
                .icon-photovideo(#A6B1BC);
                margin-right: 11px;
            }
        }
    }

    &__details-modal-dialog-first-row {
        display: flex;

        .contact-points__details-modal-dialog-type-form-group {
            width: 290px;
        }

        .contact-points__details-modal-dialog-required-form-group {
            flex-shrink: 0;
            margin-top: 27px;
            margin-left: 40px;
        }
    }

    &__details-modal-dialog-media-type-selector {
        .hat-radio-group {
            height: 48px;
        }

        .hat-radio-group__radio-label {
            justify-content: center;
        }

        .contact-points__details-modal-dialog-media-type-selector-value-icon {
            margin-right: 10px;
        }
    }

    &__details-modal-dialog-media-type-selector-value-text-icon {
        .icon-text(#A6B1BC);

        .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
            .icon-text(#3F65F1);
        }
    }

    &__details-modal-dialog-media-type-selector-value-image-icon {
        .icon-image(#A6B1BC);

        width: 24px !important;
        height: 24px !important;

        .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
            .icon-image(#3F65F1);
        }
    }

    &__details-modal-dialog-media-type-selector-value-video-icon {
        .icon-video(#A6B1BC);

        .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
            .icon-video(#3F65F1);
        }
    }

    &__details-modal-dialog-media-form-control {
        .contact-points__details-modal-dialog-media-form-control-list {
            margin-top: 13px;
        }
    }

    &__details-modal-dialog-media-form-control-actions {
        .contact-points__details-modal-dialog-media-form-control-actions-item {
            &:not(:first-child) {
                margin-left: 8px;
            }
        }
    }

    &__details-modal-dialog-media-form-control-list {
        .contact-points__details-modal-dialog-media-form-control-list-item,
        .contact-points__details-modal-dialog-media-form-control-list-loading-placeholder {
            margin-top: 10px;
            margin-left: 10px;
        }
    }

    &__details-modal-dialog-media-form-control-list-content {
        display: flex;
        flex-wrap: wrap;

        margin-top: -10px;
        margin-left: -10px;
    }

    &__details-modal-dialog-media-form-control-list-item {
        display: flex;
        width: 94px;
        height: 94px;
        position: relative;

        border-radius: 8px;

        &:after {
            content: '';

            position: absolute;

            top: 0;
            left: 0;
            width: 94px;
            height: 94px;

            border-radius: 8px;
            background-color: #000;
            opacity: 0.3;
            cursor: pointer;
        }

        .contact-points__details-modal-dialog-media-form-control-list-item-preview-image {
            width: 100%;
            height: 100%;
        }

        .contact-points__details-modal-dialog-media-form-control-list-item-label {
            position: absolute;
            top: 25.68px;
            left: 2px;
            width: 90px;
        }
    }

    &__details-modal-dialog-media-form-control-list-item-preview-image {
        border-radius: 8px;
        object-fit: cover;
    }

    &__details-modal-dialog-media-form-control-list-item-remove-button {
        position: absolute;
        width: 20px;
        height: 20px;
        top: -6px;
        right: -6px;
        border: none;
        z-index: 3;

        font-size: 11px;
        opacity: 0;
        cursor: pointer;
        background-color: #000;
        border-radius: 50%;

        animation: fadeOutToNone 0.1s ease-out;

        & > i {
            cursor: pointer;
            color: #fff;
        }

        .contact-points__details-modal-dialog-media-form-control-list-item:hover & {
            opacity: 1;
        }
    }

    &__details-modal-dialog-media-form-control-list-item-label {
        border: none;
        width: 94px;
        z-index: 2;

        font-family: Roboto, sans-serif;
        font-size: 34px;
        font-weight: bold;
        line-height: 120%;
        color: #fff;
        background: none;
        text-align: center;
        text-transform: uppercase;
    }

    &__details-modal-dialog-media-form-control-list-loading-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 94px;
        height: 94px;
        position: relative;

        border-radius: 8px;
        background-color: #ECEFF1;

        &:after {
            content: "\f110";
            font-family: 'Font Awesome 5 Pro';
            font-weight: 900;
            background-color: transparent;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            width: auto;
            height: auto;
            color: #3F65F1;
            font-size: 20px;

            -webkit-animation: fa-spin 1s infinite steps(8);
            animation: fa-spin 1s infinite steps(8);
        }
    }

    &__details-modal-dialog-assessment-type-selector {
        display: flex;
        flex-wrap: wrap;

        .contact-points__details-modal-dialog-assessment-type-selector-form-group {
            width: 290px;
            margin-right: 15px;
        }

        .contact-points__details-modal-dialog-assessment-type-selector-rating {
            margin-left: 10px;
        }
    }

    &__details-modal-dialog-clarifying-type-selector {
        margin-top: 3.6px;
        display: flex;

        .contact-points__details-modal-dialog-clarifying-type-selector-checkbox {
            &:not(:first-child) {
                margin-left: 30px;
            }
        }
    }

    &__details-modal-dialog-variants-type-selector {
        .hat-radio-group {
            height: 48px;
        }

        .hat-radio-group__radio-label {
            justify-content: center;
        }

        .contact-points__details-modal-dialog-variants-type-selector-value-icon {
            margin-right: 10px;
        }
    }

    &__details-modal-dialog-variants-type-selector-value-single-icon {
        .icon-radio(#A6B1BC);

        .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
            .icon-radio(#3F65F1);
        }
    }

    &__details-modal-dialog-variants-type-selector-value-multiple-icon {
        .icon-checkbox2(#A6B1BC);

        .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
            .icon-checkbox2(#3F65F1);
        }
    }

    &__details-modal-dialog-variants-type-selector-value-date-icon {
        .icon-date(#A6B1BC);

        .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
            .icon-date(#3F65F1);
        }
    }

    &__details-modal-dialog-variants-type-selector-value-time-icon {
        .icon-time(#A6B1BC);

        .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
            .icon-time(#3F65F1);
        }
    }

    &__details-modal-dialog-variants-type-selector-value-datetime-icon {
        .icon-datetime(#A6B1BC);

        .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
            .icon-datetime(#3F65F1);
        }
    }

    &__details-modal-dialog-variants-type-selector-value-photo-icon {
        .icon-image2(#A6B1BC);

        .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
            .icon-image2(#3F65F1);
        }
    }

    &__details-modal-dialog-variants-type-selector-value-video-icon {
        .icon-video(#A6B1BC);

        .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
            .icon-video(#3F65F1);
        }
    }

    &__details-modal-dialog-variants-type-selector-value-photovideo-icon {
        .icon-photovideo(#A6B1BC);

        .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
            .icon-photovideo(#3F65F1);
        }
    }

    &__details-modal-dialog-variants-type-selector-value-oneline-icon {
        .icon-oneline(#A6B1BC);

        .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
            .icon-oneline(#3F65F1);
        }
    }

    &__details-modal-dialog-variants-type-selector-value-multiline-icon {
        .icon-multiline(#A6B1BC);

        .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
            .icon-multiline(#3F65F1);
        }
    }

    &__details-modal-dialog-length-control {
        display: flex;
        align-items: center;
        padding-top: 22px;

        .contact-points__details-modal-dialog-length-control-slider {
            flex-grow: 1;
        }

        .contact-points__details-modal-dialog-length-control-min-boundary {
            margin-right: 12px;
        }

        .contact-points__details-modal-dialog-length-control-max-boundary {
            margin-left: 12px;
        }
    }

    &__details-modal-dialog-length-control-slider {
        .contact-points__details-modal-dialog-length-control-slider-handle {
            top: calc(6px / 2 - 24px / 2);
        }
    }

    &__details-modal-dialog-length-control-slider-handle {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 24px !important;
        height: 24px !important;

        .contact-points__details-modal-dialog-length-control-slider-handle-indicator {
            margin-top: -50px;
        }
    }

    &__details-modal-dialog-length-control-slider-handle-indicator {
        font-weight: bold;
        font-size: 14px;
        color: #3F65F1;
    }

    &__details-modal-dialog-select2 {
        .select2-dropdown--below {
            padding-top: 4px;
        }

        .select2-results__options {
            max-height: 400px !important;
        }

        .select2-dropdown .select2-results__option {
            font-size: 16px;
            padding-left: 17px;
            height: 40px;

            .contact-points__details-modal-dialog-type-form-control-value--rating {
                &:before {
                    .icon-rating(#3F65F1);
                    width: 22px !important;
                    height: 22px !important;
                    margin-right: 19px !important;
                    margin-left: 0;
                }
            }

            .contact-points__details-modal-dialog-type-form-control-value--variants {
                &:before {
                    .icon-variants(#3F65F1);
                    margin-left: 0;
                    margin-right: 20px;
                }
            }

            .contact-points__details-modal-dialog-type-form-control-value--text {
                &:before {
                    .icon-type-text(#3F65F1);
                    margin-left: 0;
                    margin-right: 21px;
                }
            }

            .contact-points__details-modal-dialog-type-form-control-value--quiz {
                &:before {
                    .icon-type-quiz(#3F65F1);
                    margin-left: 0;
                    margin-right: 21px;
                }
            }

            .contact-points__details-modal-dialog-type-form-control-value--priority {
                &:before {
                    .icon-type-priority(#3F65F1);
                    margin-left: 0;
                    margin-right: 21px;
                }
            }

            .contact-points__details-modal-dialog-type-form-control-value--media-variants {
                &:before {
                    .icon-photovideo(#3F65F1);
                    margin-left: 0;
                    margin-right: 21px;
                }
            }

            .contact-points__details-modal-dialog-type-form-control-value--date {
                &:before {
                    .icon-type-date(#3F65F1);
                    margin-left: 1px;
                    margin-right: 19px;
                }
            }

            .contact-points__details-modal-dialog-type-form-control-value--address {
                &:before {
                    margin-left: 3px;
                    margin-right: 21px;
                    .icon-type-address(#3F65F1);
                }
            }

            .contact-points__details-modal-dialog-type-form-control-value--upload {
                &:before {
                    margin-left: 1px;
                    margin-right: 20px;
                    .icon-type-upload(#3F65F1);
                }
            }
            &[aria-selected="true"] {
                .contact-points__details-modal-dialog-type-form-control-value--rating {
                    &:before {
                        .icon-rating(#A6B1BC);
                    }
                }
                .contact-points__details-modal-dialog-type-form-control-value--variants {
                    &:before {
                        .icon-variants(#A6B1BC);
                    }
                }
                .contact-points__details-modal-dialog-type-form-control-value--text {
                    &:before {
                        .icon-type-text(#A6B1BC);
                    }
                }
                .contact-points__details-modal-dialog-type-form-control-value--quiz {
                    &:before {
                        .icon-type-quiz(#A6B1BC);
                    }
                }
                .contact-points__details-modal-dialog-type-form-control-value--priority {
                    &:before {
                        .icon-type-priority(#A6B1BC);
                    }
                }
                .contact-points__details-modal-dialog-type-form-control-value--media-variants {
                    &:before {
                        .icon-photovideo(#A6B1BC);
                    }
                }
                .contact-points__details-modal-dialog-type-form-control-value--date {
                    &:before {
                        .icon-type-date(#A6B1BC);
                    }
                }
                .contact-points__details-modal-dialog-type-form-control-value--address {
                    &:before {
                        .icon-type-address(#A6B1BC);
                    }
                }
                .contact-points__details-modal-dialog-type-form-control-value--upload {
                    &:before {
                        .icon-type-upload(#A6B1BC);
                    }
                }
            }
            &:not(:first-child) {
                margin-top: 1px;
            }
        }
    }

    &__details-modal-dialog-mask {
        display: flex;
        align-items: flex-end;
        padding-bottom: 40px;
        font-size: 16px;
        color: #73808d;
        position: relative;
        div {
            position: absolute;
            min-width: 100%;
            white-space: nowrap;
        }
    }

    &__details-modal-dialog-strong {
        font-weight: bold;
        margin-right: .2em;
    }

    &__details-modal-dialog-conditions {
        margin-top: 16px;
    }

    &__details-modal-dialog-conditions-header {
        display: flex;
        align-items: center;
    }

    &__details-modal-dialog-conditions-title {
        font-weight: bold;
        font-size: 19px;
        line-height: 13px;
        color: #2E2F31;
    }

    &__details-modal-dialog-conditions-count {
        font-weight: 400;
        color: #73808d;
    }

    &__details-modal-dialog-conditions-sub-title {
        margin-top: 12px;

        font-size: 12px;
        line-height: 13px;
        color: #73808D;
    }

    &__details-modal-dialog-conditions-item {
        display: flex;
        padding-top: 23px;
        position: relative;

        border-top: 1px solid #e7ebed;

        .contact-points__details-modal-dialog-conditions-item-form-group {
            width: 255px;

            &:not(:first-child) {
                margin-left: 13px;
            }
        }

        &:nth-child(2) {
            margin-top: 19px;
        }
    }

    &__details-modal-dialog-conditions-item-remove-button {
        display: flex;
        width: 36px;
        height: 36px;
        align-items: center;
        justify-content: center;
        padding: 0;
        position: absolute;
        top: 20px;
        right: 0;

        z-index: 2;

        &:before {
            content: '';
            .icon-add(#F96261);
            transform: rotate(45deg);
        }
    }


    &__details-modal-dialog-dish-information-form-group {
        .contact-points__details-modal-dialog-dish-information-form-control {
            margin-top: 10px;
        }
    }

    &__details-modal-dialog-dish-information-form-control {
        display: flex;

        .contact-points__details-modal-dialog-dish-information-form-control-checkbox {
            &:not(:first-child) {
                margin-left: 23px;
            }
        }
    }

    &__details-modal-dialog-disclaimer {
        font-size: 12px;
        color: #F96261;
    }

    &__details-modal-dialog-actions {
        .contact-points__details-modal-dialog-action {
            &:not(:first-child) {
                margin-left: 10px;
            }
        }
    }
}

@media (max-width: 1219px) {
    .contact-points {
        &__details-modal-dialog-media-form-control-list-item-remove-button {
            opacity: 1;
        }
    }
}

@import "variants-control/variants-control";
@import "quizzes-control/quizzes-control";
