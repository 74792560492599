@import "./delete-modal-dialog/delete-modal-dialog";
@import "./launch-modal-dialog/launch-modal-dialog";
@import "./stop-modal-dialog/stop-modal-dialog";
@import "./resume-modal-dialog/resume-modal-dialog";
@import "./details-modal-dialog/details-modal-dialog";
@import "./select-clients-modal-dialog/select-clients-modal-dialog";

.mailings {
    &__content {
        &--initializing {
            visibility: hidden;
        }

        .mailings__menu {
            margin-top: 20px;
        }
    }

    &__empty {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 445px;

        .text {
            font-size: 15px;
            line-height: 1.3;
            padding-right: 0;
            text-align: center;
            transform: translate(-12px, -36px);
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 60px;

            ul {

                color: #2e2f31;
                margin-bottom: 25px;

                li {
                    font-size: 15px;
                    line-height: 1.3;
                    margin-bottom: 7px;
                    padding-left: 24px;
                    position: relative;

                    &:before {
                        content: '';
                        width: 6px;
                        height: 6px;
                        position: absolute;
                        left: 8px;
                        top: 7px;
                        background-color: #cfd8dc;
                        border-radius: 50%;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

            }

            p, .item {
                margin-bottom: 14px;

                &.first-item {
                    margin-bottom: 28px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }

            .title {
                font-weight: 900;
                font-size: 22px;
                line-height: 1;
                text-align: center;
                color: #2E2F31;
                margin-bottom: 13px;
            }

        }

        &_large {
            .text {
                font-size: 16px;
                transform: translate(2px, -32px);
            }
            .title {
                transform: translateX(2px);
            }
        }
    }

    &__header-edit-button {
        margin-left: 13px;
        flex-shrink: 0;

        &:before {
            .icon-pencil(#73808d);

            content: '';
        }
    }

    &__header-copy-button {
        margin-left: 10px;
        flex-shrink: 0;

        &:before {
            .icon-link3(#73808d);

            content: '';
        }
    }

    &__card {
        padding: 0px 20px;

        .mailings__card-actions {
            margin-top: -32px;
            margin-left: -20px;
            margin-right: -20px;
        }

        .mailings__card-filters {
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    &__card-actions {
        display: flex;
        padding: 18px 20px;
        align-items: center;
        justify-content: flex-end;

        border-bottom: 1px solid #ECEFF1;

        &_empty {
            border-bottom: none;
        }

        .mailings__new-contact-button {
            margin-left: 15px;
        }

        .mailings__load-from-csv-progress-bar {
            width: 186.78px;
            height: 38px;
            margin-left: 15px;
        }
    }

    &__card-filters {
        padding: 18px 20px;

        border-bottom: 1px solid #ECEFF1;
    }

    &__card-filters-content {
        display: flex;
        margin-top: -19px;
        margin-left: -47px;
        flex-wrap: wrap;

        .mailings__card-filter,
        .mailings__card-filters-actions {
            margin-top: 19px;
            margin-left: 47px;
        }

        .mailings__card-filters-actions {
            margin-left: auto;
        }

        .mailings__card-filters-actions-spacer {
            min-width: 47px;
        }
    }

    &__card-filters-actions {
        .mailings__card-filters-apply-button {
            margin-left: 20px;
        }
    }

    &__name-filter {
        min-width: 80px;
    }

    &__card-author-filter-control-avatar {
        width: 28px;
        height: 28px;
        margin-right: 12px;

        border-radius: 50%;
        object-fit: cover;
    }

    &__table {
        width: 100%;

        color: #2E2F31;

        & > tbody > tr > td {
            height: 47px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    &__table-actions-head-cell {
        padding-top: 5px !important;
        width: 144px;
        padding-right: 18px !important;
    }

    &__table-actions-cell {
        .mailings__table-action-button {
            transition: none !important;

            &:not(:first-child) {
                margin-left: 13px;
            }
        }
    }

    &__table-statistics-cell {
        width: 1px;

        .mailings__table-statistics-table {
            margin-left: auto;
        }
    }

    &__table-row {
        position: relative;

        &--has-details {
            cursor: pointer;
        }
    }

    &__table-status {
        font-weight: bold;
        font-size: 12px;

        &--value_new {
            color: #16CEB9;
        }

        &--value_launched {
            color: #2D99FF;
        }

        &--value_stopped {
            color: #73808D;
        }

    }

    &__table-statistics-table {
        td {
            padding: 0;

            border-top: none;
        }

        .mailings__table-statistics-table-value-cell {
            width: 1px;
        }
    }

    &__table-statistics-table-value-cell {
        padding-right: 6px !important;

        font-weight: bold;
        font-size: 11px;
        text-align: right;
    }

    &__table-statistics-table-indicator-cell {
        padding-left: 6px !important;
        width: 94px;

        vertical-align: middle !important;
    }

    &__table-statistics-table-indicator {
        height: 4px;

        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    &__table-launch-button {
        &:before {
            .icon-launch(#16CEB9);
        }
    }

    &__table-stop-button {
        &:before {
            .icon-stop(#F96261);
        }
    }

    &__table-resume-button {
        &:before {
            .icon-resume(#FCC072);
        }
    }

    &__table-edit-button {
        &:before {
            .icon-pencil(#73808D);
        }
    }

    &__table-delete-button {
        &:before {
            .icon-delete(#73808D);
        }
    }

    &__details-modal {
        padding-right: 0 !important;

        background: #FFFFFF99;

        .mailings__details-modal-dialog-wrapper {
            position: absolute;
            height: 100%;
            right: 0;
            width: calc(100% - 45px);

            margin-left: 45px;
            max-width: calc(100vw - 200px);

      @media screen and (min-width: 1500px) {
        max-width: 1300px;
      }
        }
    }

    &__details-modal-dialog-wrapper {
        .mailings__details-modal-dialog {
            height: 100%;
            width: 100%;
        }

        .mailings__details-modal-dialog-wrapper-actions {
            position: absolute;
            transform: translate(calc(-100% + -16px), 11px);
            z-index: 2;
        }
    }

    &__details-modal-dialog-wrapper-actions {
        display: flex;
    }

    &__details-modal-dialog-wrapper-close-button {
        display: flex;
        width: 33px;
        height: 33px;
        align-items: center;
        justify-content: center;
        padding: 0;

        border-radius: 50%;
        box-shadow: none !important;
        border: 2px solid #73808D !important;

        &:before {
            .icon-cross3(#73808D);

            content: '';
        }
    }
}

.mailings__content,.mailing-mailings {
    .foquz-success-message {
        position: fixed;
        top: 50%;
        left: 50%;
        bottom: unset;
        right: unset;
        transform: translate(-50%, -50%);
        border-radius: 9px;
        padding: 35px 30px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        min-width: 260px;
        color: #2E2F31;
        z-index: 1000;
        box-shadow: 0px 5px 20px 0px rgba(115, 128, 141, 0.4);
    }
  
    .foquz-success-message--error {
        .foquz-success-message {
            background: #FFDDDD;
            max-width: 460px;
            .only-mobile({
                max-width: 100%;
            });
        }
    }     
}
