.only-print {
  display: none !important;
}

.print-mode() {
  * {
    box-shadow: none;
  }

  body {
    background-color: white;
  }

  .no-print {
    display: none !important;
  }

  .only-print {
    display: block !important;
  }

  .app__content {
    width: 100%;
    padding: 40px;

    display: block;
  }

  .app__footer {
    padding-left: 40px;
    padding-right: 40px;
  }

  .page-header {
    margin-bottom: 24px;

    .breadcrumbs__item {
      .btn {
        display: none;
      }
    }
  }

  .os-scrollbar {
    opacity: 0;
  }

  .upward-button {
    display: none;
  }
}

.print-mode {
  .print-mode();
}

@media print {
  .print-mode();
}
