.breadcrumbs {
	max-width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	&__item {
		font-weight: 500;
		font-size: 12px;
		max-width: 100%;
		color: #3F65F1 !important;
		text-decoration: none!important;

		&:not(:last-child) {
			&:after {
				content: '\00a0>\00a0';

				color: #3F65F1;
			}

			&:nth-last-child(2) {
				&:after {
					color: #2E2F31;
				}
			}
		}

		&:last-child {
			color: #2E2F31 !important;
			display: flex;
			align-items: center;

			.btn-icon {
				align-self: flex-start;
			}
		}
	}
}
