.edit-columns-modal-dialog {
  width: 460px !important;

  &--initializing {
    visibility: hidden;
  }

  .edit-columns-modal {
    &__search-form {
      position: relative;

      .icon {
        position: absolute;
        left: 15px;
        top: 16px;
        margin-right: 11px;
      }

      .form-control {
        padding-left: 46px;
      }
    }

    &__clear-search {
      position: absolute;
      right: 13px;
      top: calc(50% - (17px / 2));
      padding: 0;
      transform: rotate(45deg);

      border-radius: 0;
      box-shadow: none !important;

      transition: none;
    }

    &__columns {
      margin-top: 24px;
    }

    &__columns-wrapper {
      max-height: 252px;

      .scroll-element.scroll-y {
        right: 0;
      }
    }

    &__columns-list {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      margin-top: -18px;
    }

    &__column {
      width: 50%;
      min-height: 24px;
      padding-top: 5px;

      margin-top: 18px;
    }
  }
}
