.scroll-container {
  position: relative;

  &__scroll-gutter {
    position: absolute;
    top: 80.8px;
    bottom: 16px;
    width: 34px;

    &--left {
      left: -27px;
    }

    &--right {
      right: -27px;
    }

    .mobile-and-tablet({
      &--left {
        left: -10px;
      }

      &--right {
        right: -10px;
      }
    })
  }



  &__scroll-button {
    position: sticky;
    top: 50%;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    padding: 0;
    z-index: 1000;

    background: #ffffff;
    box-shadow: 0 5px 15px rgba(46, 47, 49, 0.3);
    border: none;
    border-radius: 50%;

    transition: transform 200ms ease-in;

    &:before {
      .icon-arrow-bottom2(@f-color-service);

      content: '';
      display: inline-block;
    }

    &:hover:before {
      .icon-arrow-bottom2(@f-color-blue-2);
    }

    &--left {
      &:before {
        transform: rotate(90deg);
      }
    }

    &--right {
      &:before {
        transform: rotate(-90deg);
      }
    }
  }

  &__wrapper {
    &.scroll-content {
      overflow-y: hidden !important;
      overflow-x: auto !important;
    }

    .scroll-y {
      display: none !important;
    }
  }


}
