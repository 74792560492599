.dense-form-group {
	margin-bottom: 0;
	max-width: 100%;

	.form-label {
		margin-bottom: 2px;

		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		color: #73808D;
	}

	.form-control {
		height: unset;
		box-sizing: border-box;
		padding: 0;

		font-weight: 500 !important;
		font-size: 14px !important;
		color: #000000 !important;
		border: none;
		box-shadow: none !important;

		&::placeholder {
			font-size: 14px;
		}
	}



	.select2-container--form-control {
		border: none !important;

		.select2-selection {
			height: unset;

			&--multiple {
				border: none !important;
				min-height: unset !important;

				&.select2-selection .select2-selection__rendered {
					display: flex;
					flex-wrap: wrap;
					padding: 0 !important;
					margin-top: -5px;
				}

				.select2-selection__choice {
					padding: 0 !important;
					margin-right: 15px !important;

					background: none !important;
				}

				.select2-selection__choice__remove {
					margin-left: 8px !important;

					background: #ECEFF1 !important;
				}

				.select2-search {
					margin-top: 2px !important;
					margin-left: -13px !important;

					font-size: 14px;
					line-height: 16px;
				}

				.select2-search__field {
					max-width: 256px;
				}
			}
		}

		.select2-selection__rendered {
			padding-right: 12px !important;

			font-weight: 500 !important;
			font-size: 14px !important;
			color: #000000 !important;
		}

		.select2-selection__arrow {
			b {
				.icon-select-arrow(#73808D) !important;
			}
		}

		&.select2-container--empty {
			.select2-selection {
				&--multiple {
					.select2-search {
						margin-left: 0 !important;
					}
				}
			}
		}
	}

	&__dropdown {
		&:before {
			left: 21.7px !important;
			right: unset;
		}
	}
}
