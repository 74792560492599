@import "./delete-modal-dialog/delete-modal-dialog";
@import "./details-modal-dialog/details-modal-dialog";
@import "./scripts/scripts";

.company-settings {

    &__header {
        .company-settings__header-edit-button {
            margin-left: 13px;
            flex-shrink: 0;
        }
    }

    &__nav {
        margin-top: 20px;
    }

    &__card {
        margin-top: 30px;
        min-height: 490px;
    }


    &__some-collapse {
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: -36px;
    }

    &__empty {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 445px;

        .text {
            font-size: 16px;
            line-height: 1.5;
            padding-right: 0;
            text-align: center;
            transform: translate(0, -29px);
            display: flex;
            align-items: center;
            justify-content: center;

            ul {

                color: #2e2f31;
                margin-bottom: 25px;

                li {
                    font-size: 15px;
                    line-height: 1.3;
                    margin-bottom: 7px;
                    padding-left: 24px;
                    position: relative;

                    &:before {
                        content: '';
                        width: 6px;
                        height: 6px;
                        position: absolute;
                        left: 8px;
                        top: 7px;
                        background-color: #cfd8dc;
                        border-radius: 50%;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

            }

            p, .item {
                margin-bottom: 14px;

                &.first-item {
                    margin-bottom: 28px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }

            .title {
                font-weight: 900;
                font-size: 22px;
                line-height: 1;
                text-align: center;
                color: #2E2F31;
                margin-bottom: 13px;
            }

        }

        &_large {
            .text {
                font-size: 16px;
                transform: translate(2px, -32px);
            }
            .title {
                transform: translateX(2px);
            }
        }
    }


    &__share-link-input-group-button {
        .icon-copy-text(#73808D) !important;

        position: absolute;
        top: 14px;
        right: 16px;

        z-index: 3;
        box-shadow: none !important;
        background-color: transparent !important;
        border-radius: 0 !important;
    }

    &__share-link-input-group {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__in-collapse {

        background-color: #eceff1;

        .company-settings__point-body {
            padding-top: 0;
        }

        > * {
            padding: 25px 20px 5px;
        }

        .disabled {
            display: none !important;
        }
    }

    &__tab-pane {
        &--empty {
            .f-card__header {
                border-bottom-color: transparent;
            }
        }
    }

    &__service-accesses-tab-pane-header {
        display: flex;
        padding: 18px 20px;
        align-items: center;
        justify-content: flex-end;

        border-bottom: 1px solid #ECEFF1;
        &_empty {
            border-bottom: none;
        }
    }

    &__service-accesses-tab-pane-table {
        width: 100%;

        color: #2E2F31;

        & > tbody > tr > td {
            height: 47px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .company-settings__service-accesses-tab-pane-table-connected-at-head-cell {
            width: 121px;
        }

        .company-settings__service-accesses-tab-pane-table-active-cell {
            width: 91px;
        }

        .company-settings__service-accesses-tab-pane-table-actions-head-cell {
            width: 98px;
        }
    }

    &__service-accesses-tab-pane-table-row {
        &--inactive {
            color: #CFD8DC;
        }
    }

    &__service-accesses-tab-pane-table-service-cell {
        font-weight: bold;
    }

    &__service-accesses-tab-pane-table-active-head-cell {
        text-align: center;
    }

    &__service-accesses-tab-pane-table-active-cell {
        text-align: center;
    }

    &__service-accesses-tab-pane-table-active-checkbox {
        display: inline;
        padding-left: 10px;
    }

    &__service-accesses-tab-pane-table-actions-head-cell {
        padding-top: 5px !important;

        text-align: end;
    }

    &__service-accesses-tab-pane-table-actions-cell {
        text-align: end;

        .company-settings__service-accesses-tab-pane-table-action-button {
            transition: none !important;

            &:not(:first-child) {
                margin-left: 13px;
            }
        }
    }

    &__service-accesses-tab-pane-table-row {
        position: relative;

        &--has-details {
            cursor: pointer;
        }
    }

    &__service-accesses-tab-pane-table-edit-button {
        &:before {
            .icon-pencil(#73808D);
        }
    }

    &__service-accesses-tab-pane-table-delete-button {
        &:before {
            .icon-delete(#73808D);
        }
    }

    &__service-access-details-modal {
        padding-right: 0 !important;

        background: #FFFFFF99;

        .company-settings__service-access-details-modal-dialog-wrapper {
            position: absolute;
            height: 100%;
            right: 0;
            width: calc(100% - 284px);
            margin-left: 45px;
            max-width: 1156px;
        }
    }

    &__service-access-details-modal-dialog-wrapper {
        .company-settings__service-access-details-modal-dialog {
            height: 100%;
            width: 100%;
        }

        .company-settings__service-access-details-modal-dialog-wrapper-actions {
            position: absolute;
            transform: translate(calc(-100% + -12px), 11px);
            z-index: 2;
        }
    }

    &__service-access-details-modal-dialog-wrapper-actions {
        display: flex;
    }

    &__service-access-details-modal-dialog-wrapper-close-button {
        display: flex;
        width: 33px;
        height: 33px;
        align-items: center;
        justify-content: center;
        padding: 0;

        border-radius: 50%;
        box-shadow: none !important;
        border: 2px solid #73808D !important;

        &:before {
            .icon-cross3(#73808D);

            content: '';
        }
    }

    &__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid #E7EBED;

        .text {
            font-size: 14px;
            color: #73808d;
            line-height: 1.2;
            flex-grow: 1;

            + .btn {
                margin-left: 30px;
                min-width: 145px;
                flex-shrink: 0;
            }
        }
    }

    &__point {
        padding-bottom: 17px;
        border-bottom: 1px solid #E7EBED;
        margin-bottom: 17px;

        .form-group {

            &_custom {
                margin-top: -5px;
            }

            .p {
                margin-bottom: 10px;
                font-size: 16px;
            }

            &_in-collapse {
                margin-bottom: 20px;

                textarea.form-control {
                    min-height: 100px;
                    height: auto;
                    overflow: hidden;
                }

                .form-control {
                    padding-right: 40px;
                }
            }
        }

        &-remove-button {
            display: flex;
            width: 36px;
            height: 36px;
            align-items: center;
            justify-content: center;
            padding: 0;

            &:before {
                content: '';
                .icon-add(#F96261);
                transform: rotate(45deg);
            }
        }

        &-name {
            font-weight: bold;
            font-size: 19px;
            line-height: 1;
            color: #2E2F31;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .toggle {
                display: inline-flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;

                &:after {
                    margin-left: 13px;
                    .icon-collapse(#73808D);
                    content: '';
                    display: block;
                    transition: all .15s ease-in-out;
                }

                &[aria-expanded=false] {
                    &:after {
                        transform: rotate(-180deg);
                        margin-top: -2px;
                    }
                }
            }
        }

        &-body {
            padding-top: 12px;
            max-width: 522px;
            &.collapse {
                margin-bottom: -6px;
            }

            &_long {
                max-width: 800px;
            }
        }

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }
    }

    p.info {

    }

}

.mailing-frequency__switch {
    margin-right: 15px;

    .form-label {
        font-size: 19px;
        font-weight: 700;
        line-height: 13px;
    }
}

.mailing-frequency__explainer {
    max-width: 640px;
}

.mailing-frequency__form {
    display: flex;
}

.mailing-frequency__form-control {
    margin-right: 15px;

    .number-control {
        width: 200px;
    }
}

.mailing-frequency__switch-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .mailing-frequency__switch, .fc-expander {
        margin-bottom: 0;
    }
    
    .fc-expander {
        height: 14px;
    }
}
