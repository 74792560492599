@import '_mixins';

body {
  color: #000000;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.2;
  background-color: #f5f6fa;
  color: #000000;
}

body,
html {
  height: 100%;
}

.body {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

* {
  -webkit-font-smoothing: antialiased;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  outline: none !important;
}

.clearfix() {
  &:before,
  &:after {
    display: table;
    content: ' ';
  }
  &:after {
    clear: both;
  }
}

.content {
  min-height: 700px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #cfd8dc;
  background-color: #ffffff;
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;

  &--wo-bg {
    background-color: transparent;
    box-shadow: none;
  }

  &__inner {
    padding: 0 20px;
  }
}

.bg-white {
  background-color: #ffffff;
  .box-shadow(0 2px 4px 0 #cfd8dc);
  .border-radius(4px);
}

a {
  .transition(all, 0.3s, ease);
}

h1,
h2 {
  font-weight: bold;
  line-height: 1.2;
  padding-bottom: 22px;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

.notransition {
  transition: none !important;

  * {
    transition: none !important;
  }
}

.initializing {
  visibility: hidden;
}

.save-message {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  background: #abefb7;
  color: #37a74a;

  border-radius: inherit;
}

.cursor-pointer {
  cursor: pointer;
}

.saved-message {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  background: #ABEFB7;
	color: #37A74A;
  border-radius: inherit;

  z-index: 10;
}

.highcharts-container, .highcharts-container svg {
  overflow: visible !important;
}


body.jivo-hidden {
  jdiv {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
}
