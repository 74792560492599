.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  margin-bottom: 24px;

  &__title {
    padding-bottom: 0;
    flex-shrink: 1;
    word-break: break-word;

    font-weight: 900;
    color: #2e2f31;
  }

  .svg-icon {
    flex-shrink: 0;
  }

  .breadcrumbs {
    overflow: hidden;

    .breadcrumbs__item {
      font-weight: 900;
      font-size: 22px;
      color: #73808d !important;

      &:not(:last-child) {
        &:after {
          content: '\00a0\00a0/\00a0\00a0';

          color: #73808d !important;
        }
      }

      &:last-child {
        color: #2e2f31 !important;
      }
    }
  }

  .poll-actions {
    margin-left: 15px;
  }

  .mobile-and-tablet({
    padding-left: var(--foquz-side-offset);
    padding-right: var(--foquz-side-offset);
  });

  .only-tablet({
    margin-bottom: 16px;
  });

  .only-mobile({
    flex-wrap: wrap;
    margin-bottom: 12px;
    padding-top: 6px;
    .breadcrumbs {
        .breadcrumbs__item {
            font-size: 16px;
        }
    }

    .poll-actions {
      margin-left: 0;
      margin-top: 15px;

      .poll-info-button {
        .f-btn {
          margin-left: 0px!important;
        }
      }
    }
  });
}
