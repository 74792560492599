.clients {
  &-pools {
    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin-left: -20px;
      margin-right: -20px;
    }
    &__actions {
      border-bottom: 1px solid @f-color-border;
      flex-grow: 1;
      align-self: stretch;
      padding: 20px;
      display: flex;
      align-items: center;

      .button-add {
        margin-left: auto;
      }
    }

    &__stats {
      border-bottom: 1px solid @f-color-border;
      display: flex;
      flex-grow: 1;
      &-item {
        border-right: 1px solid @f-color-border;
        padding: 20px;
      }

      &-value {
        font-size: 19px;
        font-weight: bold;
        &--total {
          color: @coupon-total-color;
        }
        &--sended {
          color: @coupon-sended-color;
        }
        &--used {
          color: @coupon-used-color;
        }
        &--answers {
          color: @coupon-answers-color;
        }
        &--available {
          color: @coupon-available-color;
        }
      }

      &-label {
        font-size: 12px;
        font-weight: 500;
        color: @f-color-service;
      }
    }

    &__problems {
      display: flex;
      align-items: center;
      padding-left: 20px;
    }

    &__problem {
      position: relative;
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }

      i {
        font-size: 24px;
        color: @f-color-danger;
      }

      .badge {
        position: absolute;
        top: -5px;
        left: 100%;
        margin-left: -7px;
      }
    }

    &__content {
      margin-left: -7px;
      margin-right: -7px;
    }
    .btn-tag-apply {
      box-shadow: none;
      padding: 0;
      .icon {
        display: block;
      }
    }

    .button-add {
      padding: 0 25px;
    }
  }

  &-pools-table {
    margin-top: 20px;
    tr.with-problem {
      td {
        background: #FFECEC;
      }
    }
    &__actions {
      white-space: nowrap;
    }
    &__polls {
      font-size: 12px;
    }
    .icon-sorting {
      position: relative;
      top: -2px;
    }

    .pool-problem {
      i {
        font-size: 24px;
        color: @f-color-danger;
      }
    }

    thead > tr > th {
      border-bottom-width: 2px;

      body.sticky-pools-title & {
        border: none;
      }
    }

    th {
      background: white;
      position: sticky;
      top: 0;
      z-index: 100;

      body.sticky-pools-title & {
        th {
          border: none !important;
        }

        &:before {
          content: '';
          position: absolute;
          right: 0;
          left: 0;
          bottom: -22px;
          height: 22px;
          background: linear-gradient(
            180deg,
            rgba(115, 128, 141, 0.15) 0%,
            rgba(115, 128, 141, 0) 100%
          );
        }

        &.foq-table__outer-element-head-cell {
          &:first-child {
            &:before {
              left: -14px;
            }
          }
          &:last-child {
            &:before {
              right: -14px;
            }
          }
        }
      }
    }

    .pool-codes {
      font-size: 11px;
      text-align: right;
      margin-left: auto;

      tr, td {
        border: 0;
      }

      &__line {
        padding: 0;
        vertical-align: middle;
        &:before {
          content: '';
          display: block;
          width: 100px;
        }

        span {
          display: block;
          height: 4px;
          border-radius: 0px 100px 100px 0px;
        }
      }

      &__row {
        &--total {
          .pool-codes__line {
            span {
              background-color: @coupon-total-color;
            }
          }
        }

        &--sended {
          .pool-codes__line {
            span {
              background-color: @coupon-sended-color;
            }
          }
        }

        &--used {
          .pool-codes__line {
            span {
              background-color: @coupon-used-color;
            }
          }
        }

        &--answers {
          .pool-codes__line {
            span {
              background-color: @coupon-answers-color;
            }
          }
        }

        &--available {
          .pool-codes__line {
            span {
              background-color: @coupon-available-color;
            }
          }
        }
      }

      &__count {
        font-weight: bold;
        padding: 0;
      }
      &__percent {
        font-weight: normal;
        padding: 0 5px;
      }
    }
  }
}

.pool-modal {
  &__count-group {
    margin-top: 10px;
    padding-left: 34px;

    .form-control {
      margin-right: 10px;
      width: 100px;
      text-align: right;
    }
  }

  .load-file-progress-bar {
    width: 186.78px;
    height: 38px;
    margin-left: 15px;
  }

  .pool-charts {
    &__total {
      font-size: 19px;
    }
  }

  &__codes {
    &-header {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid @f-color-border;
    }
  }

  .dropdown--add-codes {
    .dropdown-menu {

      &[x-placement="top-start"] {
        top: -10px!important;
        &:before {
          top: auto;
          bottom: -5px;
          transform: rotate(180deg);
        }
      }
    }
  }
}

.new-pool {
  &__checkboxes {
    padding-top: 45px;
  }
}

.modal .add-codes-modal-dialog {
  width: 465px;
}
