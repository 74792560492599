.question-statistics {
    &__addresses-modal-dialog {
        &--initializing {
            visibility: hidden;
        }
    }

    &__addresses-modal-dialog-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        background: #FFFFFF;
        box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);

        .question-statistics__addresses-modal-dialog-header {
            height: 72px;
            flex-shrink: 0;
        }

        .question-statistics__addresses-modal-dialog-body {
            flex-grow: 1;
            margin-top: 24px;
        }

        .question-statistics__addresses-modal-dialog-loader {
            margin-top: 0;
            margin-bottom: 20px;
        }
    }

    &__addresses-modal-dialog-header {
        display: flex;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;

        .question-statistics__addresses-modal-dialog-search-form {
            width: 100%;
            max-width: 400px;
        }
    }

    &__addresses-modal-dialog-title {
        font-weight: bold;
        font-size: 19px;
        color: #2E2F31;
    }


    &__addresses-modal-dialog-search-form {
        position: relative;
    }

    &__addresses-modal-dialog-search-form-icon {
        .icon-search(#73808D);

        display: inline-block;
        position: absolute;
        left: 15px;
        top: 16px;
        margin-right: 11px;
    }

    &__addresses-modal-dialog-search-form-control {
        padding-left: 46px;
    }

    &__addresses-modal-dialog-search-form-clear-button {
        .icon-plus3(#2E2F31) !important;

        position: absolute;
        right: 13px;
        top: calc(50% - (17px / 2));
        padding: 0;
        transform: rotate(45deg);

        border-radius: 0;
        box-shadow: none !important;

        transition: none;
    }

    &__addresses-modal-dialog-body {
        padding-left: 30px;
        padding-right: 30px;

        overflow-y: hidden;

        .question-statistics__addresses-modal-dialog-table-container {
            max-height: 100%;
            margin-right: -19px;
        }
    }

    &__addresses-modal-dialog-table-container {
        display: flex;
        position: relative;

        overflow-y: hidden;

        .question-statistics__addresses-modal-dialog-table-wrapper {
            flex-grow: 1;
            width: 100% !important;
        }
    }

    &__addresses-modal-dialog-table-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .scroll-element.scroll-y {
            right: 0;
        }
    }

    &__addresses-modal-dialog-table {
        width: 100%;
        margin-right: 19px;
        margin-bottom: 30px;

        th {
            padding-bottom: 25px;
        }

        td {
            padding-top: 15.5px;
            padding-bottom: 15.5px;
            border-bottom: none;

            font-size: 12px;
            vertical-align: middle;
        }
    }

    &__addresses-modal-dialog-table-order-id-head-cell {
        white-space: nowrap;
    }

    &__addresses-modal-dialog-table-order-id-cell {
        width: 1px;
    }

    &__addresses-modal-dialog-table-phone-cell {
        white-space: nowrap;
    }

    &__addresses-modal-dialog-table-email-cell {
        white-space: nowrap;
    }

    &__addresses-modal-dialog-table-link {
        font-size: 12px;
        text-align: center;
        color: #3F65F1 !important;
        cursor: pointer;
        text-decoration: none !important;
    }

    &__addresses-modal-dialog-table-map-cell {
        border: none !important;
        padding: 0 !important;
    }

    &__addresses-modal-dialog-table-map-cell-content {
        height: 400px;
    }

    &__addresses-modal-dialog-table-highlight {
        background: #3F65F133;
    }
}
