.mailings-list {
  .mailing-row {
    td {
      font-size: 12px;
    }
    a {
      color: @f-color-text;
      text-decoration: none;

      &:hover {
        color: @f-color-primary;
        text-decoration: none;
      }
    }

    &--archived {
      color: rgba(0, 0, 0, 0.5);

      .not-archived-card-checked & {
        .mailing-row__checked {
          visibility: hidden;
        }
      }
    }

    &:not(.mailing-row--archived) {
      cursor: pointer;
      &:hover {
        background-color: #F1F4FF;
      }

      .archived-card-checked & {
        .mailing-row__checked {
          visibility: hidden;
        }
      }
    }

    &.mailing-row--checked {
      background-color: #F1F4FF;
    }
  }

}

.mailing-exclusions {
  &__scroll {
    margin-right: -10px;
  }
}

.mailing-exclusion {
  position: relative;

  select {
    width: 100%;
  }

  &__message {
    position: absolute;
    margin-top: -16px;
    color: @f-color-service;
    font-size: @f-fs-1;
  }


  border-top: 1px solid rgba(207, 216, 220, 0.5);
  padding: 24px 80px 24px 0;

  &__wrapper {
    display: flex;
  }

  .btn-remove {
    position: absolute;
    top: 24px;
    right: 10px;
    width: 36px;
    height: 36px;
  }

  &__mailing {
    width: 100%;
  }

  &.mailing-prohibition {
    .mailing-exclusion__mailing {
      width: calc(100% - 145px);
      margin-right: 15px;
    }
  }

  &__days {
    width: 130px;
    flex-grow: 0;
    flex-shrink: 0;
  }
}
