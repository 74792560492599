.spinner-block {
  padding: 20px;
  width: 100%;
  text-align: center;

  &__full {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
