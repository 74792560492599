.survey-list {
	&__move-modal-dialog {
		width: 460px !important;
	}

	&__move-modal-dialog-search-string {
		position: relative;
	}

	&__move-modal-dialog-search-string-icon {
		.icon-search(#73808D);

		display: inline-block;
		position: absolute;
		left: 15px;
		top: 16px;
		margin-right: 11px;
	}

	&__move-modal-dialog-search-string-control {
		padding-left: 46px;
	}

	&__move-modal-dialog-search-string-clear-button {
		.icon-plus3(#2E2F31) !important;

		position: absolute;
		right: 13px;
		top: calc(50% - (17px / 2));
		padding: 0;
		transform: rotate(45deg);

		border-radius: 0;
		box-shadow: none !important;

		transition: none;
	}

	&__move-modal-dialog-folder-picker {
		height: 238px;
		margin-top: 24px;
	}

	&__move-modal-dialog-folder-picker-list {
		height: 100%;
		margin-left: -13px;
	}

	&__move-modal-dialog-folder-picker-folder {
		display: flex;
		align-items: center;
		height: 30px;
		margin-right: 12px;

		font-size: 14px;
		color: #3f65f1;
		padding: 0 27px;
		border-radius: 4px;
		cursor: pointer;

		transition: background-color 0.3s ease;

		&:not(:first-child) {
			margin-top: 4px;
		}

		&:hover {
			&:not(.survey-list__move-modal-dialog-folder-picker-folder--current) {
				background: #F1F4FF;
			}
		}

		&--selected {
			color: #2E2F31;
			background: #F1F4FF!important;
		}

		&--current {
			color: #73808D;
			cursor: text;
		}

		&:before {
			.icon-folder(#A6B1BC);

			content: '';

			display: inline-block;
			margin-right: 11px;
			flex-shrink: 0;
		}
	}
}
