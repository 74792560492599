.survey-list {
  &__element-card-section {
    width: 100%;
    height: 180px;
    position: relative;

    border-radius: 9px;
    background: #2e2f31;

    transition: background 150ms ease-in;

    .folder-icon {
      position: absolute;
      color: #73808d;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      transition: color 250ms ease-in-out;
      pointer-events: none;
    }
  }

  &__element-card-section-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.75);
      z-index: 0;
      border-radius: inherit;
      transition: background-color 250ms ease-in-out;
    }
  }

  &__element-card-section-content {
    transition: opacity 150ms ease-in;
  }

  &__element-card-statistics {
    position: absolute;
    width: 165px;
    left: 25px;
    right: 25px;
    top: 46px;
    margin-left: auto;
    margin-right: auto;
  }

  &__element-card-statistics-item {
    cursor: help;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  &__element-card-statistics-item-value {
    height: 17px;

    font-size: 11px;
    font-weight: bold;
    text-align: right;
    color: #fff;
    vertical-align: middle;
  }

  &__element-card-statistics-item-graph {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;

    vertical-align: middle;
  }

  &__element-card-statistics-item-graph-line {
    height: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &__element-card-statistics-item-send-graph {
    .survey-list__element-card-statistics-item-graph-line {
      background-color: #2d99ff;
    }
  }

  &__element-card-statistics-item-open-graph {
    .survey-list__element-card-statistics-item-graph-line {
      background-color: #8400ff;
    }
  }

  &__element-card-statistics-item-process-graph {
    .survey-list__element-card-statistics-item-graph-line {
      background-color: #4346d3;
    }
  }

  &__element-card-statistics-item-filled-graph {
    .survey-list__element-card-statistics-item-graph-line {
      background-color: #16ceb9;
    }
  }

  &__element-card-statistics-item-target-graph {
    .survey-list__element-card-statistics-item-graph-line {
      background-color: #caad46;
    }
  }

  &__element-card-created-at {
    font-weight: 500;
    font-size: 11px;
    color: #ffffff;
  }

  &__element-card-dropdown {
    position: absolute;
    right: 13px;
    bottom: 10px;
  }

  &__element-card-dropdown-menu {
    .dropdown & {
      top: -15px !important;
    }

    left: 50% !important;
    transform: translate(-50%, 37px) !important;

    &:before {
      left: calc(50% - (22px / 2));
      right: unset;
    }

    .dropdown-item {
      cursor: pointer;
    }
  }

  &__element-card-label {
    display: block;
    margin-top: 10px;

    text-decoration: none !important;
  }

  &__element-card-name {
    font-weight: bold;
    font-size: 14px;
    color: #2e2f31;

    transition: color 150ms ease-in;
    word-wrap: break-word;
  }

  &__survey-card {
    width: 25%;
    position: relative;

    .survey-list__element-card-section--checked--checked {
      &:before {
        content: '';
        position: absolute;
        border-radius: inherit;
        background: #3f65f1;
        opacity: 0.5;
        pointer-events: none;
        z-index: 3;
      }
    }

    &.survey-list__survey-card--archived {
      .survey-list__survey-card-checked {
        .f-check {
          &-label {
            &:before {
              border-color: white;
            }
          }
        }
      }

      .not-archived-card-checked & {
        .survey-list__survey-card-checked {
          display: none;
        }
      }

      .survey-list__survey-card-section-archived-content-actions {
        opacity: 0;

        transition: opacity 150ms ease-in;
      }

      &:hover {
        .survey-list__survey-card-section-archived-content-actions {
          opacity: 1;
        }
      }
    }

    &:not(.survey-list__survey-card--archived) {
      .archived-card-checked & {
        .survey-list__survey-card-checked {
          display: none;
        }
      }

      .survey-list__survey-card-section-archived-content {
        display: none;
      }

      &:hover {
        .survey-list__element-card-section-background {
          &:before {
            background-color: rgba(73, 110, 190, 0.75);
          }
        }

        .survey-list__element-card-name,
        .survey-list__survey-card-folder-name {
          color: #3f65f1;
        }
      }
    }

    &.checked {
      .survey-list__element-card-section {
        background: rgb(73, 110, 190);
      }

      .survey-list__element-card-section-content {
        opacity: 0.9;
      }
    }
  }

  &__survey-card-section-background {
    border-radius: 9px;
    background-position: center center;
  }

  &__survey-card-checked {
    position: absolute;
    top: 9px;
    right: 9px;
    padding: 0;
    z-index: 4;

    .f-check-input:not(:checked) + .f-check-label {
      &:before {
        background: transparent !important;
      }
    }
  }

  &__survey-card-owner {
    display: flex;
    align-items: center;

    position: absolute;
    left: 12px;
    bottom: 9px;
  }

  &__survey-card-owner-avatar {
    width: 28px;
    height: 28px;

    border-radius: 50%;
    background: #ffffff no-repeat center;
    object-fit: cover;
  }

  &__survey-card-owner-name {
    max-width: 130px;
    margin-left: 10px;

    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__survey-card-folder-name {
    font-size: 12px;
    color: #73808d;

    transition: color 150ms ease-in;
  }

  &__survey-card-section-archived-content {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    border-radius: 5px;
  }

  &__survey-card-section-archived-content-actions {
    display: flex;
    flex-direction: column;

    & > *:not(:first-child) {
      margin-top: 15px;
    }
  }

  &__folder-card {
    width: 25%;
    position: relative;

    &:hover {
      .survey-list__element-card-section-background {
        &:before {
          background-color: rgba(73, 110, 190, 0.75);
        }
      }

      .folder-icon {
        color: white;
      }

      .survey-list__element-card-name {
        color: #3f65f1;
      }
    }
  }

  &__folder-card-counter {
    display: flex;
    align-items: center;

    position: absolute;
    left: 12px;
    bottom: 14px;
  }

  &__folder-card-counter-icon {
    .icon-survey(#FFF);

    display: inline-block;
  }

  &__folder-card-counter-value {
    margin-left: 12px;

    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
  }
}

@media (max-width: 1200px) {
  .survey-list__survey-card,
  .survey-list__folder-card {
    width: 33.3333%;
  }
}
