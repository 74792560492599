.reviews-page {
  .initializing {
    visibility: hidden;
  }

  .container-fluid {
    .mobile-and-tablet({
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
    });
  }

  .reviews-loader {
    width: 100%;
    text-align: center;
    padding: 20px;
  }


  .reviews-page__view {
    .only-mobile({
      .reviews-page__view {
        position: relative;

        .reviews-loader {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.4);

          padding-left: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    });
  }

  .page-header {
    .mobile-and-tablet({
      .breadcrumbs {
        .breadcrumbs__item {
          font-size: 16px;
        }
      }
    });
  }

  .only-mobile({
    .mobile-hide {
      display: none;
    }
  });
}

.upward-button {
  z-index: 100;
}
