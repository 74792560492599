.sortable {
  &-item {
    .sortable-handle {
      cursor: move;
    }

    &.sortable-item--mirror {
      background-color: white;
      box-shadow: 0px 6px 15px rgba(63, 101, 241, 0.3);
      z-index: 100;

      .hide-in-mirror {
        visibility: hidden;
        opacity: 0;
      }

      .remove-in-mirror {
        display: none;
      }
    }

    &.sortable-item--dragging {
        background-color: #D9E0FC;

        .survey-question__quiz-title {
            visibility: hidden;
            opacity: 0;
        }

        .remove-on-dragging {
          display: none;
        }
        .hide-on-dragging {
          visibility: hidden;
          opacity: 0;
        }
    }
  }
}


.body--dragging {
	* {
		cursor: move!important;
	}
	textarea, input {
		cursor: move!important;
	}
}
