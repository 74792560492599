@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .poll-question-gallery {
    .swiper-slide__media {
      img {

      }
    }
  }
  .survey-questions__preview--text {
    display: table-cell;
    vertical-align: middle;
  }

  .matrix-controller__item {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
}
