.b-quick-help {

    display: none;
    opacity: 0;
    transition: all .45s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(0, 0, 0, .1);

    .btn-primary {
        background-color: #3f65f1;
        font-size: 13px;
        font-weight: 500;
        padding: 0 22px;
        display: inline-flex;
        height: 36px;
        align-items: center;
        border-radius: 4px;
    }

    &_visible {
        opacity: 1;
        background-color: rgba(0, 0, 0, .5);
    }

    &__close {
        z-index: 9;
        position: absolute;
        top: 15px;
        right: 15px;
        .icon-quick-close(#73808D);
        transition: all .2s ease-in-out;
        cursor: pointer;

        &:hover {
            .icon-quick-close(#3F65F1);
        }
    }

    &__icon {
        position: absolute;
        top: 41px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__text {
        min-height: 270px;
        padding-top: 177px;
        position: relative;
    }

    &__body {
        background: #FFFFFF;
        border-radius: 9px;
        width: 400px;
        left: 400px;
        padding: 0 32px 32px;
        position: absolute;
        font-size: 15px;
        line-height: 1.3;
        transition: all .25s ease-in-out;
        .btn {
            outline: none !important;
            box-shadow: none !important;
        }

        color: #2E2F31;

        p, .p {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {

            color: #2e2f31;

            li {
                font-size: 15px;
                line-height: 1.3;
                margin-bottom: 7px;
                padding-left: 24px;
                position: relative;

                &:before {
                    content: '';
                    width: 6px;
                    height: 6px;
                    position: absolute;
                    left: 8px;
                    top: 7px;
                    background-color: #cfd8dc;
                    border-radius: 50%;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .b-quick-help__arrow {
            .popover-arrow(#FFF) !important;
            position: absolute;
            top: 25px;
            content: '';
            left: -13px;
            transform: rotate(-90deg);
        }
    }

    &__nav {
        
        padding-top: 19px;

        .pagination {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .item {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin: 0 6px;
                background-color: #CFD8DC;
                transition: all .25s ease-in-out;
                cursor: pointer;

                &:hover {
                    background-color: #3f65f1;
                    opacity: .4;
                }

                &.passed, &.passed:hover {
                    background-color: #3f65f1;
                    opacity: 1;
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

}

.js-i-preview {
    position: fixed !important;
    z-index: 99999;
    opacity: .99;

    &:after {
        content: '';
        z-index: -1;
        position: absolute;
        right: -16px;
        top: 50%;
        left: -18px;
        height: 36px;
        transform: translate(0, -50%);
        background-color: #fff;
        border-radius: 5px;
    }
    &.sidebar__create-survays {
        &:after {
            display: none;
        }
    }
    &.small {
        &:after {
            right: 0;
            left: 0;
        }
        &:before {
            margin: 0;
            
        }
        .button-add {
            font-size: 0;
            height: 36px;
            padding: 0;
        }
        .button-add--with-dropdown:before {
            width: 10px;
            height: 10px;
            .icon-sidebar-plus(#fff);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
