.popover {
	border: none;
	box-shadow: 0 2px 4px 0 #cfd8dc;
	min-width: 84px;
	&-body {
		padding: 10px;
	}
	&__menu {
		font-size: 10px;
		font-weight: bold;
		line-height: 1.6;
		&-item {
			display: block;
			background: none;
			color: #2e2f31;
			&:hover {
				text-decoration: none;
				color: #000;
			}
		}
	}
}
