.rating-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__wrapper {
    width: 161px;
    position: relative;
    transition: width 0.5s ease-in;
  }

  &__slider {
    width: calc(100% - 32px);
    transition: width 0.5s ease-in;

    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;

    background: linear-gradient(
      90deg,
      #f96261 1.49%,
      #f5a123 50.37%,
      #33d16b 100.93%
    );

    .ui-slider-range {
      background: none;
    }
  }

  .rating-slider__value {
    display: flex;
    width: 32px;
    height: 32px;
    top: -13px;
    margin-left: -16px;
    align-items: center;
    justify-content: center;

    color: #fff;
    font-weight: 900;
    font-size: 13px;

    border: none;

    transition: border-width 0.5s ease-in;

    &--1 {
      background: @rating-1-color !important;
    }

    &--2 {
      background: @rating-2-color !important;
    }

    &--3 {
      background: @rating-3-color !important;
    }

    &--4 {
      background: @rating-4-color !important;
    }

    &--5 {
      background: @rating-5-color !important;
    }
  }

  &__curtain {
    position: absolute;
    height: 38px;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;

    cursor: pointer;
  }

  &__text {
    display: flex;
    justify-content: center;
    max-width: 161px;
    text-align: center;
    margin-top: 7px;
    max-width: 100%;
    font-weight: bold;
    font-size: 14px;
    color: #2e2f31;
    transition: width 0.5s ease-in;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__remove {
    display: inline-block;
    margin-left: 4px;
    .icon-add(#F96261);
    width: 10px;
    height: 10px;
    margin-top: 2px;
    transform: rotate(45deg);
  }

  &--dense {
    .rating-slider__text {
      width: 161px;
    }
  }

  &--closed {
    .rating-slider__wrapper {
      width: 65px;
      margin-left: -3px;
    }

    &.rating-slider--dense {
      .rating-slider__text {
        width: 65px;
      }
    }

    .rating-slider__slider {
      width: calc(100% - 35px) !important;
      margin-right: 19px;

      .ui-slider-handle:first-child {
        left: 0 !important;
        transition: left 0.5s ease-in;
      }

      .ui-slider-handle:nth-child(2) {
        left: 100% !important;
        transition: left 0.5s ease-in;
      }
    }

    .rating-slider__value {
      top: -16px;
      margin-left: -16px;
      border: 3px solid white;
      width: 38px;
      height: 38px;
    }
  }
}
