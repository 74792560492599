@import "_mixins";

.menu-quests {
	background-color: #fff;
	.border-radius(4px);
	.box-shadow(0 2px 4px 0 #cfd8dc;);
	margin-bottom: 30px;

	.swiper-slide {
		height: 48px;
		margin-left: -1px;
		border-left: 1px solid #eceff1;
		border-right: 1px solid#eceff1;
		border-bottom: 1px solid #eceff1;

		flex-shrink: 1;
		flex-grow: 1;
		flex-basis: 100px;
	}

	a {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding-left: 10px;
		padding-right: 10px;

		white-space: nowrap;

		color: #333;
		font-weight: 800;
		font-size: 16px;
		line-height: 1;

		&:hover,
		&:focus {
			text-decoration: none;
		}

		&:hover {
			color: #3f65f1;
		}

		.badge {
			width: 22px;
			height: 22px;
			padding: 0;
			margin-left: 10px;
			margin-right: -10px;

			background-color: #3f65f1;
			color: #fff;
			border-radius: 50%;
			font-size: 13px;
			font-weight: bold;
			line-height: 22px;
		}
	}

	.active {
		a {
			color: #3f65f1;
		}
	}

	.disabled, [disabled] {
		a {
			color: #73808d;
			cursor: not-allowed;
		}
	}

	.mobile-and-tablet({
		border-radius: 0;
	});

	.only-tablet({
		margin-bottom: 18px;
	});

	.only-mobile({
		margin-bottom: 12px;

		[data-section="launch"],
		[data-section="questions"],
		[data-section="settings"],
		[data-section="design"] {
			display: none;
		}
	});
}
