.alert {
	padding: 0;
	margin: 0 0 20px 0;
	border: 0;
	border-radius: 0;
	font-size: 13px;
	
	&-danger {
		padding-left: 25px;
		color: #EA1D27;
		background-color: transparent;
		background-position: center left;
		background-repeat: no-repeat;

		.icon-mistake('#F96261');
	}
}