.mailings {
    &__details-modal-dialog {
        &--initializing {
            visibility: hidden;
        }

        &--status_new {
            .mailings__details-modal-dialog-header {
                border-top-color: #16CEB9;
            }
        }

        &--status_launched {
            .mailings__details-modal-dialog-header {
                border-top-color: #2D99FF;
            }
        }

        &--status_stopped {
            .mailings__details-modal-dialog-header {
                border-top-color: #73808D;
            }
        }

        table {
            font-size: 12px;
        }
    }

    &__details-modal-dialog-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;

        background: #FFFFFF;
        box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);

        &:after {
            content: '';

            position: fixed;
            width: 100%;
            height: 30px;
            bottom: 0;
            z-index: 1;

            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        }

        .mailings__details-modal-dialog-header {
            flex-shrink: 0;
        }

        .mailings__details-modal-dialog-info-row {
            flex-shrink: 0;
            border-bottom: 1px solid #ECEFF1;
            flex-wrap: wrap;
        }
    }

    &__details-modal-dialog-header {
        display: flex;
        align-items: center;
        padding: 14.5px 30px;

        border-top: 4px solid;
    }

    &__details-modal-dialog-header-breadcrumbs {
        overflow: hidden;

        .breadcrumbs__item {
            overflow: hidden;
        }
    }

    &__details-modal-dialog-header-edit-button {
        margin-left: 13px;
        flex-shrink: 0;

        &:before {
            .icon-pencil(#73808d);

            content: '';
        }
    }

    &__details-modal-dialog-launch-button {
        flex-shrink: 0;
        margin-left: 16px;

        &:before {
            .icon-launch(#16CEB9);

            content: '';
        }
    }

    &__details-modal-dialog-delete-button {
        flex-shrink: 0;
        margin-left: 28px;
    }

    &__details-modal-dialog-stop-button {
        flex-shrink: 0;

        &:before {
            .icon-stop(#F96261);

            content: '';
        }
    }

    &__details-modal-dialog-resume-button {
        flex-shrink: 0;

        &:before {
            .icon-resume(#FCC072);

            content: '';
        }
    }

    &__details-modal-dialog-add-clients-button {
        flex-shrink: 0;
        margin-left: 38px;
    }

    &__details-modal-dialog-info-row {
        display: flex;
        padding-left: 30px;
        padding-right: 30px;

        & > * {
            &:not(:first-child) {
                margin-left: 20px;
            }

            &:not(:last-child) {
                border-right: 1px solid #ECEFF1;
            }
        }

        .mailings__details-modal-dialog-main-info {
            flex-grow: 1;
        }
    }

    &__details-modal-dialog-main-info {
        display: flex;
        align-items: center;

        .mailings__details-modal-dialog-main-info-element {
            &:not(:first-child) {
                margin-left: 25px;
            }
        }
    }

    &__details-modal-dialog-main-info-element {
        .mailings__details-modal-dialog-main-info-element-label {
            margin-top: 4px;
        }
    }

    &__details-modal-dialog-main-info-element-value {
        display: block;

        color: #2E2F31;
    }

    &__details-modal-dialog-main-info-element-label {
        display: block;

        font-weight: 500;
        font-size: 12px;
        color: #73808D;
    }

    &__details-modal-dialog-main-info-status-element-value {
        font-weight: bold;

        &--new {
            color: #16CEB9;
        }

        &--launched {
            color: #2D99FF;
        }

        &--stopped {
            color: #73808D;
        }
    }

    &__details-modal-dialog-notifications-block {
        display: flex;
        align-items: center;
        padding-right: 13px;

        .mailings__details-modal-dialog-notifications-block-item {
            &:not(:first-child) {
                margin-left: 20px;
            }
        }
    }

    &__details-modal-dialog-notifications-block-item {
        display: flex;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        position: relative;

        &--disabled {
            .icon {
                opacity: 0.4;
            }
        }

        .mailings__details-modal-dialog-notifications-block-item-repeat-count {
            position: absolute;
            top: -3px;
            right: -13px;
        }
    }

    &__details-modal-dialog-notifications-block-item-repeat-count {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 6.32px;

        background: #3F65F1;
        border-radius: 10px;
        font-weight: 900;
        font-size: 11px;
        color: #FFFFFF;

        .mailings__details-modal-dialog-notifications-block-item--disabled & {
            background: #A6B1BC;
        }
    }

    &__details-modal-dialog-body {
        display: flex;
        flex-direction: column;

        .mailings__details-modal-dialog-clients-placeholder {
            margin-top: 22px;
            align-self: center;
        }

        .mailings__details-modal-dialog-filters {
            width: 100%;
        }

        .mailings__details-modal-dialog-table-section {
            width: 100%;
        }
    }

    &__details-modal-dialog-clients-placeholder {
        font-size: 14px;
        color: #73808D;
    }

    &__details-modal-dialog-filters {
        padding: 18px 30px;
        border-top: 1px solid #ECEFF1;
    }

    &__details-modal-dialog-filters-content {
        display: flex;
        margin-top: -19px;
        margin-left: -24px;
        flex-wrap: wrap;

        .mailings__details-modal-dialog-filter,
        .mailings__details-modal-dialog-filters-actions {
            margin-top: 19px;
            margin-left: 24px;
        }

        .mailings__details-modal-dialog-filters-actions {
            margin-left: auto;
        }

        .mailings__details-modal-dialog-filters-actions-spacer {
            min-width: 24px;
        }
    }

    &__details-modal-dialog-filter {
        overflow: hidden;
    }

    &__details-modal-dialog-filters-actions {
        .mailings__details-modal-dialog-filters-apply-button {
            margin-left: 20px;
        }
    }

    &__details-modal-dialog-answered-only-filter {
        .form-group {
            margin-top: 12px;
            margin-bottom: 0;
        }
    }

    &__details-modal-dialog-table-section {
        padding-left: 23px;
        padding-right: 23px;
    }

    &__details-modal-dialog-table {
        color: #2E2F31;

        td {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }

    &__details-modal-dialog-table-actions-head-cell {
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 24px;

        text-align: center;
    }

    &__details-modal-dialog-table-actions-cell {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    &__details-modal-dialog-table-row {
        position: relative;
    }

    &__details-modal-dialog-table-phone-cell {
        white-space: nowrap;
    }

    &__details-modal-dialog-table-group-actions {
        .clients__table-group-actions-tags {
            margin-left: 15px;
            margin-right: 10px;
        }
    }

    &__details-modal-dialog-table-connection-channel {
        cursor: pointer;
        white-space: nowrap;
    }

    &__details-modal-dialog-tag-list-content {
        display: flex;
        flex-wrap: wrap;
        margin-left: -9px;
        margin-top: -6px;

        .mailings__details-modal-dialog-tag-list-item {
            margin-top: 5px;
            margin-left: 9px;
        }
    }


    &__details-modal-dialog-summary {
        display: inline-flex;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        margin-left: -20px;
    }


    &__details-modal-dialog-table-status {
        font-size: 12px;
        font-weight: 500;
    }

    &__details-modal-dialog-connection-channel {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;

        &:before {
            content: '';

            display: inline-block;
            margin-right: 12px;
        }

        &--email {
            &:before {
                .icon-email(#73808D);
            }
        }

        &--sms {
            &:before {
                .icon-sms(#73808D);
            }
        }

        &--viber {
            &:before {
                .icon-viber(#9151C1);
            }
        }

        &--telegram {
            &:before {
                .icon-telegram(#1588C9);
            }
        }
    }

    &__details-modal-dialog-table-sending-list-dropdown-menu {
        width: 465px;
        transform: translate3d(calc(-50% + 15px + 40px / 2), 28px, 0px) !important;
        padding: 12px 20px 22px !important;

        font-size: 14px;

        &.dropdown-menu {
            top: 0 !important;
        }



        &:before {
            right: calc(50% - 22px / 2) !important;
        }

        &.dropdown-menu-left {
            transform: translate3d(-20px, 30px, 0px) !important;

            &:before {
                left: 22px!important;
            }
        }
    }

    &__details-modal-dialog-table-sending-list-table-wrapper {
        max-height: 240px;

        &.scroll-wrapper {
            margin-right: -10px;
            padding-right: 10px !important;
        }
    }

    &__details-modal-dialog-table-sending-list-table {
        margin-bottom: 0;

        & > tbody > tr > td {
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }

    &__details-modal-dialog-table-sending-list-dropdown-menu-table-repeats-head-cell {
        text-align: center;
    }

    &__details-modal-dialog-table-sending-list-dropdown-menu-table-repeats-cell {
        text-align: center;
    }

    &__details-modal-dialog-table-sending-list-dropdown-menu-table-response-head-cell {
        text-align: center;
    }

    &__details-modal-dialog-table-sending-list-dropdown-menu-table-response-cell {
        text-align: center;
    }

    &__details-modal-dialog-repeats {
        display: inline-flex;
        align-items: center;

        &:before {
            .icon-repeat(#73808D);

            content: '';

            display: inline-block;
            margin-right: 12px;
        }
    }
}

.mailings__channel-settings-link {
    display: flex;
    flex-direction: column;
    margin-left: 24px;

    .fc-btn {
        span {
            font-size: 13px;
            font-weight: 500;
            line-height: 14px;
            max-width: 67px;
            white-space: normal;
        }
    }
}

.channel-settings-link__individual-sign {
    svg {
        display: inline;
        vertical-align: middle;
        color: #8EA6FF;
    }

    span {
        font-size: 12px;
        font-weight: 500;
        line-height: 13px;
        color: #73808D;
    }
}
