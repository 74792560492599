.folder-card {
  width: 100%;
  position: relative;

  &:before, &:after {
    content: '';
    position: absolute;
    height: 30px;
  }

  &:before {
    z-index: 1;
    left: 10px;
    right: 10px;
    top: -5px;
    border-radius: 7px;
    background: #5B5D6F;
  }

  &:after {
    z-index: 0;
    left: 20px;
    right: 20px;
    top: -9px;
    border-radius: 5px;
    background: #AEB7DA;
  }

  &__content {
    width: 100%;
    height: 140px;
    border-radius: 9px;
    padding: 10px;
    background-color: #2E2F31;
    color: #fff;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 2;

    &:hover {
      background-color: #496ebe;

      .dropdown button {
        color: #ffffff;
      }
    }

    &-link {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
    }

    button {
      position: relative;
      z-index: 4;
    }
  }

  &__header {
    font-size: 11px;
  }

  &__meta {

  }

  &__footer {
    margin-top: 15px;
  }

  &__link {
    color: @f-color-text;
    text-decoration: none;

    &:hover {
      color: @f-color-primary;
      text-decoration: none;
    }
  }

  &__name {
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
