.reviews-page {
  .reviews-search {
    flex-grow: 1;

    border-right: 1px solid @f-color-border;

    &__wrapper {
      border: 1px solid transparent;
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      padding: 0 var(--foquz-side-offset);
    }

    &--active {
      .reviews-search__wrapper {
        border-color: @f-color-blue-2;
        box-shadow: 0 0 5px rgba(63, 101, 241, 0.5);
      }
    }

    &__icon {
      margin-right: 15px;
      flex-shrink: 0;
    }

    &__input {
      display: block;
      width: 100%;
      padding-top: 16px;
      padding-bottom: 16px;
      padding-right: 24px;
      background-color: #fff;
      font: inherit;
      font-size: 16px;
      color: @f-color-text;
      border: none;
    }

    &--empty {
      .reviews-search__input {
        padding-right: 0;
      }
    }

    &__clear {
      position: absolute;
      right: 13px;
      top: 0;
      bottom: 0;
      margin: auto;
      padding: 0;
      border-radius: 0;
      box-shadow: none !important;
      transition: none;
    }

    &__wrapper {
      .mobile-and-tablet({
        padding-left: 0;
        padding-right: var(--foquz-side-offset);
      });
    }

    .only-mobile({
      flex-shrink: 1;
      flex-grow: 0;

      width: calc(100% - 85px);

      border-right: 1px solid @f-color-border;

      &__wrapper {
        padding-right: 10px;
      }

      &__input {
        font-size: 14px;
      }

      &__icon {
        margin-right: 8px;
      }
    });
  }
}
