.review-details-modal,.review-question-gallery {
  .review-questions {
    &__view {
      margin-top: 29px;
    }
  }

  .review-rating {
    display: inline-flex;
    align-items: center;

    &__item {
      .icon-rate-star(#F8CD1C);

      width: 17.94px;
      height: 17.77px;

      border: none;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: contain;

      &--active {
        .icon-rate-star-fill(#F8CD1C);
      }
      &:not(:first-child) {
        margin-left: 11px;
      }
    }
  }

  .review-dish-rating-question {
    display: flex;

    &__table {
      width: 100%;
      margin-right: 18px;
      margin-bottom: 30px;

      border-top: 1px solid #e7ebed;

      td {
        height: 33px;

        padding: 5px 17px;

        vertical-align: middle;
        border-bottom: 1px solid #e7ebed;
      }

      &-container {
        flex-grow: 1;
        position: relative;

        &:after {
          content: '';

          position: absolute;
          width: 100%;
          height: 30px;
          bottom: 0;

          background: linear-gradient(
            0,
            #ffffff 18.23%,
            rgba(255, 255, 255, 0) 100%
          );
        }
      }

      &-wrapper {
        max-height: 200px;
        width: 100% !important;
        display: flex;

        .scroll-element.scroll-y {
          right: 0;
        }
      }

      &-name-cell {
        font-weight: bold;
        font-size: 14px;
        color: #2e2f31;
      }

      &-quantity-cell {
        text-align: right;
      }

      &-rating-cell {
        width: 134px;
        padding-left: 13px;
      }
    }

    &__comment {
      margin-left: 17px;
      max-width: 310px;
      min-width:  220px;
      padding: 23px 16px;

      background: #eceff1;
      border-radius: 8px;

      &-title {
        display: block;

        font-weight: bold;
        font-size: 16px;
        color: #2e2f31;
      }

      &-text {
        display: block;
        margin-top: 10px;

        font-size: 14px;
        color: #2e2f31;
      }
    }
  }
}
