.contact-points {
    &__details-modal-dialog-quiz-control-list {
        &-content {
            margin-bottom: 25px;
        }

        &-item {
            padding: 20px;
            margin-left: -20px;
            width: calc(100% + 40px);
            border-bottom: 1px solid #E7EBED;
            position: relative;

            .contact-points__details-modal-dialog-variants-control-list-item-remove-button {
                position: absolute;
                top: 20px;
                right: 20px;
            }

            &:first-child {
                padding-top: 25px;
                border-top: 1px solid #E7EBED;
            }

            &.sortable-item--mirror, &.sortable-item--dragging {
                padding: 20px;
                .contact-points__details-modal-dialog-quiz-title {
                    margin: 0;
                }
            }
        }
    }
    &__details-modal-dialog-quiz-control-add-button {
        width: 100%;
    }
    &__details-modal-dialog-variants-control-add-button {
        width: 100%;
    }
    &__details-modal-dialog-quiz-title {
        font-weight: bold;
        font-size: 19px;
        line-height: 1;
        color: #2E2F31;
        display: flex;
        align-items: center;
        padding-right: 65px;
        margin-bottom: 18px;

        .contact-points__details-modal-dialog-quiz-drag {
            margin-right: 15px;
        }

        &-text {
            flex-grow: 1;
        }

        .form-group {
            margin-bottom: 0;
            margin-top: 2px;
        }
        .form-label {
            color: #73808d;
            &_checked {
                color: #2E2F31;
            }
        }
    }
}
