.media-variants-controller {
  .variants-controller {

    &__sortable-handle {
      height: auto;
      align-self: flex-start;
      padding-top: 20px;
    }
    &__variant {
      border-bottom: 1px solid @f-color-border;

      &:first-child {
        border-top: 1px solid @f-color-border;
      }
    }
    &__variant-content {
      padding: 17px 0 17px;

      textarea {
        min-height: 100%;
      }
    }

    &__variant-remove {
      margin-top: 20px!important;
    }
    &__add-button {
      margin-top: 24px!important;
    }
  }

}
