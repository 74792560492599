.statistics {
    &__details-modal-dialog-notifications-control {
        padding: 0;
        height: unset;

        overflow: hidden;
    }

    &__details-modal-dialog-notifications-control-templates {
        position: relative;
        border-bottom: 1px solid #CFD8DC;

        &--has-forward-scroll {
            &:after {
                content: '';

                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 33px;

                background: linear-gradient(270deg, #FFFFFF 70.23%, rgba(255, 255, 255, 0) 100%);
            }
        }

        &:not(.statistics__details-modal-dialog-notifications-control-templates--has-forward-scroll) {
            .statistics__details-modal-dialog-notifications-control-templates-forward-button {
                display: none;
            }
        }

        &--has-backward-scroll {
            &:before {
                content: '';

                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 33px;

                background: linear-gradient(90deg, #FFFFFF 70.23%, rgba(255, 255, 255, 0) 100%);
            }
        }

        &:not(.statistics__details-modal-dialog-notifications-control-templates--has-backward-scroll) {
            .statistics__details-modal-dialog-notifications-control-templates-backward-button {
                display: none;
            }
        }

        .statistics__details-modal-dialog-notifications-control-templates-button {
            z-index: 1;
        }

        .statistics__details-modal-dialog-notifications-control-templates-forward-button {
            position: absolute;
            top: calc((100% - 24px) / 2);
            right: 2px;
        }

        .statistics__details-modal-dialog-notifications-control-templates-backward-button {
            position: absolute;
            top: calc((100% - 24px) / 2);
            left: 2px;
        }
    }

    &__details-modal-dialog-notifications-control-templates-wrapper {
        overflow: hidden;
    }

    &__details-modal-dialog-notifications-control-templates-container {
        display: inline-flex;

        .statistics__details-modal-dialog-notifications-control-template {
            &:not(:first-child) {
                border-left: 1px solid #CFD8DC;
            }
        }
    }

    &__details-modal-dialog-notifications-control-template {
        padding: 8.17px 18px;

        font-weight: bold;
        font-size: 14px;
        color: #73808D;
        cursor: pointer;
        white-space: nowrap;

        &--selected {
            color: #2E2F31;
        }
    }

    &__details-modal-dialog-notifications-control-textarea {
        display: block;
        width: 100%;
        padding: 8px 18px;

        font-weight: normal;
        border: none;
        border-bottom: 1px solid #CFD8DC;
        font-size: 14px;
        color: #2E2F31;
    }

    &__details-modal-dialog-notifications-control-footer {
        display: flex;
        align-items: center;
        padding: 6.86px 18px;
    }

    &__details-modal-dialog-notifications-control-chars-counter {
        font-size: 11px;
        color: #73808D;
    }

    &__details-modal-dialog-notifications-control-templates-forward-button {
        display: flex;
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        padding: 0;

        box-shadow: none !important;

        &:after {
            .icon-arrow-bottom2(#73808D);

            content: '';

            transform: rotate(-90deg);
        }
    }

    &__details-modal-dialog-notifications-control-templates-backward-button {
        display: flex;
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        padding: 0;

        box-shadow: none !important;

        &:after {
            .icon-arrow-bottom2(#73808D);

            content: '';

            transform: rotate(90deg);
        }
    }
}
