@import "_mixins";
.btn-question {
    .icon-question(#A6B1BC);

    width: 14px;
    height: 14px;
    margin: 0 0 0 7px;

    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
}

.tooltip {
    margin-top: -14px;
    padding: 14px 0;
    max-width: 250px;

    .arrow {
        height: 15px;

        &:before {
            .icon-angle(#000);

            content: "";

            position: absolute;
            top: -5px;
            left: calc(50% - 37px);
            width: 61px;
            height: 15px;
            transform: rotate(180deg);

            background-repeat: no-repeat;
            background-size: 70%;
            background-position: 0 0;
            border: none;
        }
    }

    &.bs-tooltip-bottom {
        margin-top: 0;

        .arrow {
            &:before {
                top: 5px;
                left: calc(50% - 22px);

                transform: rotate(0deg);
            }
        }
    }

    .tooltip-inner {
        padding: 10px 15px;
        max-width: unset;

        border-radius: 9px;
        font-size: 12px;
        line-height: 1.33;
        text-align: left;
    }

    &--white {
        font: inherit;

        .tooltip-inner {
            background: white;
            color: #2E2F31;;
            padding: 20px 25px;
            box-shadow: 0px 5px 20px rgba(115, 128, 141, 0.4);
            font-size: 14px;
        }

        &.show {
            opacity: 1;
        }
    }
}

.mobile-and-tablet({
    .tooltip {
        // display: none;
    }
});
