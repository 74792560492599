.filter {
	.border-radius(4px);
	.box-shadow(0 2px 4px #cfd8dc);
	background-color: #fff;
	height: 48px;
	width: 100%;
	.flex();
	.flex-align-items(center);
	justify-content: space-between;
	margin-bottom: 25px;

	&__menu {
		font-weight: 500;
		height: 100%;
		.flex();
	}

	&__menu-item {
		height: 100%;

		a {
			display: flex;
			align-items: center;
			padding-left: 25px;
			padding-right: 25px;
			height: 100%;

			border-right: 1px solid #f2f5f6;
			color: #5a626a;
			text-decoration: none;
			line-height: 14px;
		}

		.label {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			padding: 0 3px;
			margin-left: 10px;
			min-width: 23px;
			min-height: 23px;

			font-size: 13px;
			color: #727779;
			font-weight: 700;
			border-radius: 30px;
			text-align: center;
			vertical-align: middle;
		}

		&--active {
			.label {
				color: #ffffff;
				background-color: #3f65f1;
			}
		}

		&:hover {
			a {
				color: #000000;
			}
		}
	}

	&__sort {
		.flex();
		height: 100%;
	}

	&__cell {
		padding-left: 25px;
		padding-right: 25px;
		.flex();
		.flex-align-items(center);
		height: 100%;

		&--right {
			border-left: 1px solid #f2f5f6;
		}
	}

	&__check {
		.form-check-label {
			font-size: 12px;
			font-weight: 500;
		}
	}

	&__dropdown {
		color: #000;
		.flex();

		&-label {
			font-size: 12px;
			line-height: 16px;
			font-weight: 500;
			margin-right: 10px;
			margin-bottom: 0;
			opacity: 0.4;
		}

		&-btn {
			padding: 0;
			border: none;
			background: none;
			font-size: 12px;
			line-height: 16px;
			.flex();

			&:after {
				content: "";
				.icon-arrow-bottom(#323232);
				background-repeat: no-repeat;
				background-size: contain;
				width: 15px;
				height: 16px;
				display: block;
				margin-left: 10px;
				border: none;
				line-height: 14px;
				vertical-align: unset;
				transform: rotate(180deg);
				transition: all 0.3s ease;
			}
		}

		&.show {
			.filter__dropdown-btn {
				&:after {
					transform: rotate(0);
				}
			}
		}

		&.dropdown {
			.dropdown-menu {
				margin-top: 28px;
			}
		}
	}

	&__dropdown-btn {
		font-weight: 500;
	}
}
