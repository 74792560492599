.header-name-form {
    display: flex;
    align-items: center;

    overflow: hidden;

    &__control {
        padding: 0;

        font-size: 22px;
        font-weight: 900;
        color: #2e2f31;
        border: none;
        background: none;
        border-bottom: solid 1px #a6b1bc;
    }

    &__hidden-control {
        padding-left: 10px;

        font-size: 22px;
        font-weight: 900;
        white-space: pre;
    }

    &__cancel-button {
        margin-left: 13px;
        flex-shrink: 0;

        &:before {
            .icon-cross2(#f96261);

            content: '';
        }
    }

    &__save-button {
        margin-left: 10px;
        flex-shrink: 0;

        &:before {
            .icon-done(#37A74A);

            content: '';
        }
    }
}
