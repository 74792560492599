.select-list {
  max-width: 100%;

  &__opener {
    &, &:active, &:focus, &:hover {
      outline: none;
      box-shadow: none;
    }
  }

  &__dropdown {
    font-size: 14px;
  }

  &__wrapper {
    min-width: 150px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &__search {
    margin-bottom: 15px;
    padding: 0 20px;

    .form-control {
      height: 30px;
    }
  }
  &__scroll {
    max-height: 200px;
    .scroll-element.scroll-y {
      right: 20px;
    }
  }
  &-item {
    min-height: 30px;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    color: @f-color-blue-2;

    &--hover {
      cursor: pointer;

      &:hover {
        background-color: @f-color-highlight;
      }
    }

    &--not-found {
      color: @f-color-service;
    }
  }
}
