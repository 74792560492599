.search-block {
  width: 100%;
  display: flex;
  align-items: center;

  .search-field {
    padding: 15px;
    width: 100%;
  }

  &--active {
    border: 1px solid #3F65F1;
    border-bottom: none;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    box-shadow: 0 5px 15px rgba(46, 47, 49, 0.3);
    overflow: hidden;
  }
}

.search-block-list {
  border: 1px solid #3F65F1 !important;
  border-top: none !important;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  box-shadow: 2px 6px 6px rgba(46, 47, 49, 0.15);
  overflow: hidden;

  .search-block-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
    color: @f-color-primary;

    .ui-menu-item-wrapper {
      background: none;
      border: none;
      color: inherit;
      padding: 0;
      margin: 0;
    }

    .f-icon {
      color: @f-color-light;
      margin-right: 10px;
    }

    &--focus {
      background: #F1F5F6;
    }

    &__subtitle {
      font-size: 12px;
      color: @f-color-service;
    }

    &--empty {
      padding: 5px 54.3px 20px 54.3px;

        font-size: 12px;
        color: #2E2F31;
        cursor: text !important;
    }

    &--loading {

        display: flex;
        align-self: center;
        justify-content: center;
        margin: 0 !important;
        height: unset !important;
        padding-bottom: 10px;

        font-size: 14px !important;
        color: #3f65f1;
        border: none !important;

    }
  }
}
