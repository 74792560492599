.contact-points {
    &__details-modal-dialog-variants-control {
        .contact-points__details-modal-dialog-variants-control-add-button {
            width: 100%;
        }

        .contact-points__details-modal-dialog-variants-control-add-button,
        .contact-points__details-modal-dialog-variants-control-list-item {
            &:not(:first-child) {
                margin-top: 15px;
            }
        }

    }

    &__details-modal-dialog-variants-control-list-item {
        display: flex;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 7px;
        padding-bottom: 7px;
        margin-top: 0!important;

        &-drag {
            margin-right: 15px;
            height: 48px;
            display: flex;
            align-items: center;
        }

        .form-group {
            width: 100%;
            margin-bottom: 0;
        }

        .contact-points__details-modal-dialog-variants-control-list-item-remove-button {
            margin-top: 6px;
            margin-left: 15px;
            flex-shrink: 0;
        }
    }

    &__details-modal-dialog-variants-control-list-item-remove-button {
        display: flex;
        width: 36px;
        height: 36px;
        align-items: center;
        justify-content: center;
        padding: 0;

        &:before {
            .icon-add(#F96261);

            content: '';

            margin-left: 1.5px;

            transform: rotate(45deg);
        }
    }


    &__details-modal-dialog-variants-control-add-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        border: none;
        position: relative;

        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        color: #3F65F1;
        background-color: #F5F6FA;

        .contact-points__details-modal-dialog-variants-control-add-button-icon {
            position: absolute;
            left: 0;
        }

        &[disabled] {
            opacity: 0.7;
        }
    }

    &__details-modal-dialog-variants-control-add-button-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 48px;
        height: 48px;
        background-color: #3f65f1;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        &:before {
            .icon-plus5(#FFF);

            content: '';

            display: block;
        }
    }
}
