.review-details-modal {
  .ns-templates {
    padding: 0;
    height: unset;

    overflow: hidden;

    &__content {
      border: 1px solid #cfd8dc;
      border-radius: 4px;
    }

    &__content.is-invalid {
      border-color: #f96261;
      box-shadow: 0 0 5px rgba(249, 98, 97, 0.5) !important;
    }

    &-list {
      position: relative;
      border-bottom: 1px solid #cfd8dc;

      &__button {
        z-index: 1;
        position: absolute;
        top: calc((100% - 24px) / 2);
        display: none;
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        padding: 0;

        box-shadow: none !important;
      }

      &__forward-button {
        right: 2px;
        &:after {
          .icon-arrow-bottom2(#73808d);
          content: '';
          transform: rotate(-90deg);
        }
      }

      &__backward-button {
        left: 2px;
        &:after {
          .icon-arrow-bottom2(#73808d);
          content: '';
          transform: rotate(90deg);
        }
      }

      &--has-forward-scroll {
        &:after {
          content: '';

          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 33px;

          background: linear-gradient(
            270deg,
            #ffffff 70.23%,
            rgba(255, 255, 255, 0) 100%
          );
        }

        .ns-templates-list__forward-button {
          display: flex;
        }
      }

      &--has-backward-scroll {
        &:before {
          content: '';

          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 33px;

          background: linear-gradient(
            90deg,
            #ffffff 70.23%,
            rgba(255, 255, 255, 0) 100%
          );
        }

        .ns-templates-list__backward-button {
          display: flex;
        }
      }

      &__wrapper {
        overflow: hidden;
      }

      &__container {
        display: inline-flex;
      }

      &__item {
        padding: 8.17px 18px;

        font-weight: bold;
        font-size: 14px;
        color: #73808d;
        cursor: pointer;
        white-space: nowrap;

        &--selected {
          color: #2e2f31;
        }

        &:not(:first-child) {
          border-left: 1px solid #cfd8dc;
        }
      }
    }

    &-editor {
      &__footer {
        display: flex;
        align-items: center;
        padding: 6.86px 18px;
        border-top: 1px solid #cfd8dc;
      }

      textarea {
        display: block;
        width: 100%;
        padding: 8px 18px;

        font-weight: normal;
        border: none;
        height: 180px;
        font-size: 14px;
        color: #2e2f31;

        &:focus {
          box-shadow: none;
        }
      }

      .tox-tinymce {
        border: none;
      }
    }

    &__footer {
        margin-top: 10px;
    }

    &__variables {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    &__variable {
      font-size: 13px;
      border-radius: 60px;
      padding: 8px 15px;
      background: #eceff1;
      color: #2E2F31;
      cursor: pointer;
      transition: background 250ms;
      margin-right: 5px;
      margin-bottom: 5px;

      &:hover {
        background: #c8cbcf;
      }
    }
  }
}
