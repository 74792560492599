.statistics {
    &__answer-with-rating-comments-modal-dialog {
        width: 460px !important;

        &--initializing {
            visibility: hidden;
        }
    }

    &__answer-with-rating-comments-modal-dialog-table-wrapper {
        max-height: 300px;

        .scroll-element.scroll-y {
            right: 0;
        }
    }

    &__answer-with-rating-comments-modal-dialog-table {
        width: calc(100% - 19px);
        border-top: 1px solid #E7EBED;
        border-bottom: 1px solid #E7EBED;
        margin-right: 19px;

        font-weight: 500;
        font-size: 12px;

        color: #2E2F31;

        td {
            padding: 7px 8px;

            vertical-align: middle;
            line-height: 15px;
        }
    }

    &__answer-with-rating-comments-modal-dialog-table-row {
        height: 44px;

        border-bottom: 1px solid #E7EBED;
    }

    &__answer-with-rating-comments-modal-dialog-table-indicator-cell {
        padding: 0 !important;
        width: 25px;
    }

    &__answer-with-rating-comments-modal-dialog-table-indicator {
        display: flex;
        width: 25px;
        height: 25px;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        font-weight: 900;
        font-size: 13px;
        color: #FFFFFF;

        &--value_1 {
            background: #F96261 !important;
        }

        &--value_2 {
            background: #F97F46 !important;
        }

        &--value_3 {
            background: #F4A224 !important;
        }

        &--value_4 {
            background: #AAB540 !important;
        }

        &--value_5 {
            background: #3ECE67 !important;
        }
    }
}
