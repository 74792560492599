.statistics {
    &__answer-list-container {
        position: relative;

        cursor: pointer;
    }

    &__answer-list-wrapper {
        overflow-x: hidden;

        &:before {
            content: '';

            display: inline-block;
            width: 13.12px;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;

            z-index: 1;
            background: linear-gradient(90deg, #FFFFFF 0, rgba(255, 255, 255, 0) 100%);
        }

        &:after {
            content: '';

            display: inline-block;
            width: 13.12px;
            height: 100%;
            top: 0;
            right: 0;
            position: absolute;

            background: linear-gradient(270deg, #FFFFFF 0, rgba(255, 255, 255, 0) 100%);
        }
    }

    &__answer-list-content {
        display: inline-flex;

        .statistics__answer-list-item {
            flex-shrink: 0;

            &:not(:first-child) {
                margin-left: -6px;
            }
        }
    }

    &__answer-list-item {
        display: flex;
        width: 27px;
        height: 27px;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: 4px;

        border-radius: 50%;
        border: 2px solid #FFF;

        &--rating_1 {
            background: #F96261 !important;
        }

        &--rating_2 {
            background: #F97F46 !important;
        }

        &--rating_3 {
            background: #F4A224 !important;
        }

        &--rating_4 {
            background: #AAB540 !important;
        }

        &--rating_5 {
            background: #3ECE67 !important;
        }
    }

    &__answer-list-item-rating {
        font-weight: 900;
        font-size: 13px;
        color: #FFFFFF;
    }

    &__answer-list-item-comment-icon {
        .icon-comment(#73808D);

        position: absolute;
        top: -6px;
        right: 0;
    }
}
