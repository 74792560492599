.slider {
    width: 100%;
    height: 100%;

    &__index {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 11px;
        left: 12px;

        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        background-color: rgba(0, 0, 0, 0.34);
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        z-index: 5;
    }

    &__paginator {
        display: flex;
        position: absolute;
        top: 11px;
        right: 13.3px;

        z-index: 5;

        *:not(:first-child) {
            margin-left: 7px;
        }
    }

    &__paginator-button {
        width: 30px;
        height: 30px;
        padding: 0;

        background-color: rgba(0, 0, 0, 0.34);
        border-radius: 50%;
        border: none;

        &--prev {
            .icon-prev(#fff);
        }

        &--next {
            .icon-next(#fff);
        }
    }

    &__counter {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7.25px 12.28px;

        font-size: 13px;
        text-align: center;
        color: #FFFFFF;
        background-color: rgba(0, 0, 0, 0.34);
        border-radius: 15px;
        z-index: 5;
    }
}
