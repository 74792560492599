@import "./tag-input/tag-input";
@import "./clients-loading-complete-modal-dialog/clients-loading-complete-modal-dialog";
@import "./edit-columns-modal-dialog/edit-columns-modal-dialog";
@import "./delete-modal-dialog/delete-modal-dialog";
@import "./delete-column-modal-dialog/delete-column-modal-dialog";
@import "./create-modal-dialog/create-modal-dialog";
@import "./edit-modal-dialog/edit-modal-dialog";
@import "./tag-details-modal-dialog/tag-details-modal-dialog";
@import "./counter/counter";

.clients {
  &__content {
    &--initializing {
      visibility: hidden;
    }

    .tab-group .tab-pane {
      padding-top: 0px;
      min-height: 728px;
    }

    .tab-content__watcher {
      .tab-pane {
        min-height: 396px !important;

        @media screen and (max-width: 825px) {
          min-height: 100% !important;
        }
      }
    }
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 575px;

    &--watcher {
      min-height: 396px !important;

      @media screen and (max-width: 825px) {
        min-height: 100% !important;
        flex-direction: column;
      }
    }

    svg {
      transform: translate(-4px, -1px);
    }

    .text {
      font-size: 15px;
      line-height: 1.3;
      transform: translateX(-4px);

      width: 100%;
      max-width: 500px;
      padding-right: 0;
      margin-left: 50px;

      @media screen and (max-width: 825px) {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
        text-align: center;
      }

      ul {
        color: #2e2f31;
        margin-bottom: 25px;

        li {
          font-size: 15px;
          line-height: 1.3;
          margin-bottom: 7px;
          padding-left: 24px;
          position: relative;

          &:before {
            content: "";
            width: 6px;
            height: 6px;
            position: absolute;
            left: 8px;
            top: 7px;
            background-color: #cfd8dc;
            border-radius: 50%;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      p,
      .item {
        margin-bottom: 14px;

        &.first-item {
          margin-bottom: 28px;
        }
      }
    }
  }

  &__card {
    background: #ffffff;
    box-shadow: 0 2px 4px #cfd8dc;
    border-radius: 9px;

    .clients__card-actions {
      margin-left: -20px;
      margin-right: -20px;
    }

    .clients__card-filters {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  &__card-actions {
    display: flex;
    padding: 18px 20px;
    align-items: center;
    justify-content: flex-end;

    border-bottom: 1px solid #eceff1;

    &_empty {
      border-bottom: none;
    }

    .clients__new-contact-button {
      margin-left: 15px;
    }

    .clients__load-from-csv-progress-bar {
      width: 186.78px;
      height: 38px;
      margin-left: 15px;
    }
  }

  &__card-filters {
    padding: 18px 20px;

    border-bottom: 1px solid #eceff1;
  }

  &__card-filters-content {
    display: flex;
    margin-top: -19px;
    margin-left: -24px;
    flex-wrap: wrap;

    .clients__card-filter,
    .clients__card-filters-actions {
      margin-top: 19px;
      margin-left: 24px;
    }

    .clients__card-mailing-condition-filter {
      width: 100%;
    }

    .clients__card-filters-actions {
      margin-left: auto;
    }

    .clients__card-filters-add-mailing-condition-button {
      display: block;
      margin-top: 10px;
    }

    .clients__card-filters-actions-spacer {
      min-width: 24px;
    }

    .clients__card-filter-range {
      margin-top: 15px;
    }
  }

  &__card-filter-checkbox {
    margin-top: 13px;

    .form-check-input:checked + .form-check-label {
      font-weight: 500;
    }
  }

  &__card-filter-range {
    display: flex;
    align-items: center;

    .clients__card-filter-range-divider {
      margin-right: 5px;
      margin-left: 5px;
    }
  }

  &__card-filters-add-mailing-condition-button {
    font-family: Roboto, sans-serif;
    color: #37a74a !important;
  }

  &__card-mailing-condition-filter {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-width: 1px;

    border-bottom-color: #eceff1;
    border-bottom-style: solid;

    .clients__card-filter {
      margin-top: 0;
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }

    .clients__card-mailing-condition-filter-add-button {
      margin-left: 20px;
    }

    .clients__card-mailing-condition-filter-remove-button {
      margin-left: 20px;
    }

    .clients__card-mailing-condition-filter-error {
      padding-top: 6px;
    }

    &--first {
      border-top-width: 1px;

      border-top-color: #eceff1;
      border-top-style: solid;
    }

    &:not(.clients__card-mailing-condition-filter--first) {
      margin-top: 0;
    }
  }

  &__card-mailing-condition-filter-add-button {
    font-family: Roboto, sans-serif;
    color: #37a74a !important;
  }

  &__card-mailing-condition-filter-remove-button {
    font-family: Roboto, sans-serif;
    color: #f96261 !important;
  }

  &__card-mailing-condition-filter-error {
    font-size: 13px;
    color: #ea1d27;
  }

  &__card-filter-checkbox-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -18px;

    .clients__card-filter-checkbox {
      margin-left: 18px;
    }
  }

  &__card-filters-actions {
    .clients__card-filters-apply-button {
      margin-left: 20px;
    }
  }

  &__dishes-and-categories-form-control-category-value {
    color: #37a74a;
  }

  &__dishes-and-categories-form-control-dropdown {
    min-width: 300px !important;

    .clients__dishes-and-categories-form-control-dropdown-dish-option-content {
      margin-left: 14px;
    }
  }

  &__dishes-and-categories-form-control-dropdown-category-option-content {
    color: #37a74a;

    .select2-results__option[aria-selected="true"] & {
      color: #abefb7;
    }
  }

  &__dishes-and-categories-form-control-dropdown-dish-option-content {
    color: #2e2f31;

    .select2-results__option[aria-disabled="true"] & {
      color: #73808d;
    }
  }

  &__table-section {
    position: relative;
  }

  &__table-container {
    position: relative;

    .clients__table-container-scroll-button-gutter {
      position: absolute;
      top: 80.8px;
      bottom: 16px;
    }

    .clients__table-container-left-scroll-button-gutter {
      left: calc(-20px - 34px / 2);
    }

    .clients__table-container-right-scroll-button-gutter {
      right: calc(-20px - 34px / 2);
    }

    .mobile-and-tablet({
			.clients__table-container-left-scroll-button-gutter {
				left: -10px;
			}

			.clients__table-container-right-scroll-button-gutter {
				right: -10px;
			}
		});
  }

  &__table-container-scroll-button-gutter {
    width: 34px;

    .clients__table-container-scroll-button {
      position: sticky;
      top: 50%;
      z-index: 1000;
    }
  }

  &__table-container-scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    padding: 0;

    background: #ffffff;
    box-shadow: 0 5px 15px rgba(46, 47, 49, 0.3);
    border: none;
    border-radius: 50%;

    transition: transform 200ms ease-in;

    &:before {
      .icon-arrow-bottom2(#73808d);

      content: "";
      display: inline-block;
    }
  }

  &__table-container-left-scroll-button {
    &:before {
      transform: rotate(90deg);
    }
  }

  &__table-container-right-scroll-button {
    &:before {
      transform: rotate(-90deg);
    }
  }

  &__table-wrapper {
    &.scroll-content {
      overflow-y: hidden !important;
      overflow-x: auto !important;
    }

    .scroll-y {
      display: none !important;
    }
  }

  &__table {
    font-weight: 500;
    font-size: 12px;
    color: #2e2f31;

    td {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  &__table-mailing-head-cell {
    width: 59px;
  }

  &__table-actions-head-cell {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 24px;

    text-align: center;
  }

  &__table-actions-cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &__table-row {
    position: relative;
  }

  &__table-phone-cell {
    white-space: nowrap;
  }

  &__table-group-actions {
    .clients__table-group-actions-tags {
      margin-left: 15px;
      margin-right: 10px;
    }
  }

  &__table-mailing-button {
    svg {
      * {
        transition: stroke 200ms ease-in-out;
      }
    }

    &--mode_subscribe {
      svg {
        * {
          stroke: #cfd8dc;
        }
      }
    }

    &--mode_unsubscribe {
      svg {
        * {
          stroke: #37a74a;
        }
      }
    }
  }

  .condition-filters {
    width: 100%;
    padding-left: 24px;
    margin-top: 20px;

    &--empty {
      margin-top: 0;
      width: 0;
    }
  }

  .clients__table-container.scroll-buttons-visible {
    &.can-scroll-to-left {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -20px;
        width: 70px;
        background: linear-gradient(
          to right,
          #fff 15%,
          rgba(255, 255, 255, 0.5),
          transparent
        );
        z-index: 2;
      }
    }
    &.can-scroll-to-right {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: -20px;
        width: 70px;
        background: linear-gradient(
          to left,
          #fff 15%,
          rgba(255, 255, 255, 0.5),
          transparent
        );
        z-index: 2;
      }
    }
  }
}

.clients-min-height {
  min-height: 30px;
}

@import "./tabs/_clients.less";
@import "./tabs/_tags.less";
@import "./tabs/_pools.less";
