@import "_mixins";

.cke {
	padding: 0 !important;

	border: 1px solid #CFD8DC !important;
	border-radius: 4px !important;
	background: none !important;
	overflow: hidden;
}

.cke_contents {
	border: none !important;
	border-radius: 0 !important;
}

.cke_toolgroup {
	margin: 0 !important;

	border: none !important;
}

.cke_top {
	padding: 2px 4px !important;

	background: none !important;
	border-bottom: 1px solid #CFD8DC !important;
}

.cke_bottom {
	height: 0 !important;
	padding: 0 !important;

	background: none !important;
	overflow: visible !important;
}

.cke_resizer {
	margin-bottom: 0 !important;
	position: absolute !important;
	top: -16px !important;
	right: 0 !important;
}

.cke_editable {
	font-family: Roboto, sans-serif !important;
}

.cke_button__hatlink .cke_button_icon.cke_button__hatlink_icon {
	background: url(/vendor/ckeditor-skins/bootstrapck/icons.png) no-repeat 0 -792px !important;
}
