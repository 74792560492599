.reviews-poll-group {
  &__header {
    height: 60px;
    display: flex;
    align-items: center;

    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;

    position: sticky;
    top: 0px;
    z-index: 2;
    background: white;

    font-size: 19px;
    font-weight: bold;

    cursor: pointer;
  }

  &__type {
    margin-right: 20px;
  }

  &__content {
    border-top: 1px solid @f-color-border;
  }

  &__loading {
    padding: 20px;
    text-align: center;
  }

  .reviews-table {
    tr:first-child {
      td {
        border-top: none;
      }
    }
  }

  &__arrow {
    margin-left: 10px;
    transition: transform 200ms;
  }


  &--active {
    .reviews-poll-group__arrow {
      transform: rotate(-180deg);
    }
  }
}

.reviews-list {
  .reviews-poll-group {
    &__header {
      top: 60px;
    }
  }
}

.reviews-page--active-poll-fixed {
  .reviews-poll-group--active {
    .reviews-poll-group__header {
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 22px;
        background: linear-gradient(180deg, rgba(115, 128, 141, 0.15) 0%, rgba(115, 128, 141, 0) 100%);
      }
    }
  }
}



@media screen and (max-width: 767px) {
  .reviews-poll-group__header {
    margin-left: 0;
    margin-right: 0;
    z-index: 15;
    position: static;
  }
}
