.survey {
  &__header {
    display: flex;
    align-items: center;
  }

  .survey-header {
    height: 100%;
    padding: 0 20px;
    max-width: 810px;
    overflow: hidden;
    @media screen and (max-width: 768px) {
      max-width: 546px;
    }
    @media screen and (max-width: 767px) {
      max-width: 180px;
      padding: 0;
    }
    &_center {
      position: relative;
      left: calc(50% + 7.5px);
      transform: translateX(-50%);
      @media screen and (max-width: 767px) {
        left: calc(50% + 7.5px);
      }
      &-test {
        left: calc(50% + 105px);
        @media screen and (max-width: 767px) {
          left: calc(50% + 13px);
        }
      }
    }
    &_small-mobile {
      .img {
        @media screen and (max-width: 767px) {
          margin-top: 0px !important;
          margin-bottom: 0px !important;
        }
        
        img {
          @media screen and (max-width: 767px) {
            height: 40px !important;
          }
          
        }
      }
      .logo-text {
        @media screen and (max-width: 767px) {
          font-size: 14px !important;
        }
      }
      &.survey-header_small-mobile-magrins {
        .img {
          @media screen and (max-width: 767px) {
            margin-top: 2px !important;
            margin-bottom: 2px !important;
          }
          
        }
      }
    }
  }

  &__header-logo {
    display: flex;
    align-items: center;
    padding: 0;
    text-decoration: none;
    font-size: 20px;
    line-height: 1;
    color: #fff;

    .img {
      height: 100%;
      position: relative;
      align-items: center;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        object-fit: contain;
        object-position: left;
      }
    }
  }
}
