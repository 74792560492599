.cards-group {
  margin-bottom: 30px;

  &__header {
    cursor: pointer;
  }
  &__title {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  &__list {
    margin-top: 18px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
  }
  &__item {
    padding: 6px 12px;
    width: 20%;
    min-width: 250px;

    &.hidden {
      height: 0px;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &__footer {
    margin-top: 15px;
  }
}

@media screen and (max-width: 768px) {
  .cards-group {
    &__item {
      min-width: 200px;
    }
  }
}
