:root {
  --poll-bg-image: url("/img/themes/background4.jpg");
  --poll-mobile-bg-image: url("/img/themes/background4.jpg");

  --poll-finish-bg-image: var(--poll-bg-image);

  --poll-bg-cover: block;

  --poll-start-screen-bg: var(--poll-finish-bg-image);
  --poll-end-screen-bg: var(--poll-finish-bg-image);

  // цвет фона страницы, если нет изображения
  // цвет фона блока с текстом для типа вопроса Оценка --> подтип Текст
  --poll-bg-color: #cfd8dc;

  // название вопроса, описание вопроса
  // текст кнопок вернуться и далее, рамка кнопки вернуться
  // текст и рамка кнопки отменить в форме жалобы
  // заголовок и текст начального экрана
  // заголовок и текст конечного экрана
  // цвет информационного текста после отправления жалобы
  // цвет номера отправленной жалобы
  // цвет информационного текста при отписке
  // цвет уведомления об отписке
  --poll-text-on-bg: white;

  // цвет кнопки Пройти опрос
  // цвет рамки кнопки Отправить в блоке с жалобой
  // цвет рамки кнопки Далее
  // цвет фона и границы активного номера вопроса
  // цвет кнопки Отписаться
  // цвет фона в календаре для выбранного дня (в примере 6-й вопрос).
  // цвет выбранного фона для элемента для вопроса типа Выбор изображения/видео (в примере 11-й вопрос)
  // цвет фона выбранного ответа в типе вопроса Простая матрица (в примере 15-й вопрос)
  // цвет фона выбранного ответа в типе вопроса Семантический дифференциал (в примере 16-й вопрос)
  // Цвет границы для фокуса полей ввода
  --poll-main-color: #3f65f1;

  // цвета фона карточки
  --poll-main-place-color: rgba(255, 255, 255, 1);

  --poll-button-text: white;

  // Цвет текста на карточке (кроме полей ввода)
  // Выбранные варианты
  // Фон выбранных чекбоксов и радио-кнопок
  // Цвет текста в блоке "Текст" для вопроса типа Оценка, подтип Текст
  // Цвет текста в списке адресов, для вопроса типа Адрес должен всегда быть чёрным (#000000), сейчас синий.
  // Цвет текста вариантов вопроса типа Приоритет
  // Описания элементов в галереях
  // Текст в форме жалобы
  --poll-text-on-place: black;

  // ссылки Пожаловаться, Отписаться от рассылки, Создано в Foquz
  --poll-link-color: white;

  --poll-font-family: Arial, Helvetica, sans-serif;
  --poll-font-size: 14px;
  --f-poll-font-size: 14px;
  // размер текста в полях ввода
  --poll-service-font-size: 14px;
  // Размер текста ошибок
  --poll-error-font-size: 13px;
  // Размер кнопок
  --poll-button-font-size: 16px;
  --poll-header-font-size: 30px;
  --f-poll-header-font-size: 30px;

  --poll-next-button-radius: 24px;
  --poll-next-button-background-color: rgba(63, 101, 241, 0);
  --poll-next-button-text-color: rgba(255, 255, 255, 1);
  --poll-next-button-stroke-color: rgba(63, 101, 241, 1);

  --poll-back-button-radius: 24px;
  --poll-back-button-background-color: rgba(255, 255, 255, 0);
  --poll-back-button-text-color: rgba(255, 255, 255, 1);
  --poll-back-button-stroke-color: rgba(255, 255, 255, 1);

  --poll-start-button-radius: 24px;
  --poll-start-button-background-color: rgba(63, 101, 241, 1);
  --poll-start-button-text-color: rgba(255, 255, 255, 1);
  --poll-start-button-stroke-color: rgba(63, 101, 241, 1);

  // Подложка под кнопками Назад/Далее
  --poll-buttons-bg: rgba(0, 0, 0, 0.85);

  --fc-paginator-color: var(--poll-main-color);
}

.survey {
  font-family: var(--poll-font-family);
  font-size: var(--poll-font-size);

  * {
    font-size: inherit;
  }

  button,
  .btn {
    font-family: inherit !important;
    font-size: var(--poll-button-font-size);
  }

  input,
  textarea {
    font-size: var(--poll-service-font-size);
    font-family: var(--poll-font-family);

    &::placeholder {
      font-family: inherit !important;
      font-size: inherit !important;
    }
  }

  background-color: var(--poll-bg-color);
  background-image: var(--poll-finish-bg-image);

  &__background-covering {
    display: var(--poll-bg-cover);
  }

  &[data-screen="start"] {
    background-image: var(--poll-start-screen-bg);
  }

  &[data-screen="end"] {
    background-image: var(--poll-end-screen-bg);
  }

  &.survey__initializing {
    background-image: none !important;
  }

  &__header {
    background-color: #00000080;
  }

  &__complaint-card {
    background-color: #fff;
    color: var(--poll-text-on-place);

    textarea {
      color: @f-color-text;

      &:focus {
        border-color: var(--poll-main-color);
      }
    }
  }

  &__complaint-actions-button {
    &,
    &:hover,
    &:focus {
      color: var(--poll-text-on-bg);
    }

    &.survey__complaint-actions-button--accent {
      &,
      &:hover,
      &:focus {
        border-color: var(--poll-main-color) !important;
      }
    }
  }

  &__complaint-end-title {
    font-size: var(--poll-header-font-size);
    line-height: 1.25;
    color: var(--poll-text-on-bg);
    font-weight: 700;
  }

  &__complaint-end-text {
    color: var(--poll-text-on-bg);
    font-size: var(--poll-font-size);
  }

  &__unsubscribe-text {
    color: var(--poll-text-on-bg);
  }

  &__unsubscribe-unsubscribe-button {
    background-color: var(--poll-main-color);
    color: var(--poll-button-text);
    font-size: var(--poll-service-font-size) !important;
  }

  &__unsubscribe-stay-button {
    &,
    &:hover {
      color: var(--poll-link-color);
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    & + label {
      color: var(--poll-text-on-place);
    }
    &:checked {
      & + .form-check-label {
        color: var(--poll-text-on-place) !important;
        &:before {
          background: var(--poll-main-color);
          border-color: var(--poll-main-color);
        }

        &:after {
          .icon-checkbox(white);
        }
      }
    }
  }

  .f-radio {
    &-label {
      font-size: inherit !important;
    }
    &-input:checked + .f-radio-label {
      color: var(--poll-text-on-place) !important;
      &:before {
        background: var(--poll-main-color);
        border-color: var(--poll-main-color);
      }
      &:after {
        background-color: white;
        color: white;
      }
    }
  }

  .f-check {
    &-label {
      font-size: inherit !important;
    }
    &-input:checked + .f-check-label {
      color: var(--poll-text-on-place) !important;
      &:before {
        background: var(--poll-main-color);
        border-color: var(--poll-main-color);
      }
      &:after {
        .icon-checkbox(white);
      }
    }
  }

  .form-label {
    color: inherit;
    font-size: inherit !important;
  }

  .table {
    color: inherit;
    font-size: inherit;
  }

  .form-error {
    font-size: var(--poll-error-font-size);
  }
}

.survey-questions {
  &__preview {
    background-color: var(--poll-bg-color);
    color: var(--poll-text-on-place);
  }

  &__modern-paginator-anchor {
    font-size: var(--poll-service-font-size) !important;
    &.active-anchor {
      .survey-questions__modern-paginator-anchor-num-wrapper {
        border-color: var(--poll-main-color);
      }

      .survey-questions__modern-paginator-anchor-num {
        background-color: var(--poll-main-color);
        color: #fff;
      }
    }
  }

  &__modern-paginator-anchor-text {
    color: var(--poll-text-on-bg);
  }

  &__question-card {
    background-color: var(--poll-main-place-color);

    color: var(--poll-text-on-place);
    input,
    textarea {
      color: #000000;
      font-size: var(--poll-service-font-size);
      &:not(.is-invalid):focus {
        border-color: var(--poll-main-color) !important;
      }
      &::placeholder {
        font-size: var(--poll-service-font-size);
        font-family: var(--poll-font-family);
      }
    }

    .survey-questions__question--type_8 & {
      background: transparent !important;
    }

    .swiper {
      &-slide {
        &__description {
          font-size: var(--poll-font-size);
        }
      }
    }
  }

  &__priority-item {
    background-color: #fff;
  }

  &__search-menu {
    font-family: var(--poll-font-family);
  }

  &__question-message {
    font-size: var(--poll-header-font-size);
    line-height: 1.15;
    color: var(--poll-text-on-bg);
  }

  &__question-subdescription {
    font-size: var(--poll-font-size);
    line-height: 1.15;
    color: var(--poll-text-on-bg);
  }

  &__question-required {
    color: var(--poll-text-on-bg);
  }

  &__question-like-all-button {
    background-color: var(--poll-main-color);
    color: var(--poll-button-text);
    font-size: var(--poll-service-font-size) !important;
  }

  &__choice {
    color: var(--poll-text-on-place);
  }

  &__form-text {
    font-size: 15px;
  }

  &__question-actions-btn {
    &,
    &:hover,
    &:focus,
    &:active {
      color: var(--poll-text-on-bg);
    }

    &.survey-questions__question-actions-btn--next {
      color: var(--poll-next-button-text-color) !important;
      border-color: var(--poll-next-button-stroke-color) !important;
      background: var(--poll-next-button-background-color) !important;
      border-radius: var(--poll-next-button-radius) !important;
    }
    &.survey-questions__question-actions-btn--prev {
      color: var(--poll-back-button-text-color) !important;
      border-color: var(--poll-back-button-stroke-color) !important;
      background: var(--poll-back-button-background-color) !important;
      border-radius: var(--poll-back-button-radius) !important;
    }
  }

  &__slider {
    .swiper-pagination-bullet-active {
      background: var(--poll-main-color);
    }
  }

  &__option {
    input:checked + &-label {
      background-color: var(--poll-main-color);
      color: var(--poll-text-on-bg);
    }
  }

  &__question-object--type_text {
    .survey-questions__question-object-content {
      background-color: var(--poll-bg-color);
      color: var(--poll-text-on-place);
    }
  }

  &__copyright {
    &,
    &:hover {
      color: var(--poll-link-color);
    }
  }

  &__serv {
    .serving {
      color: var(--poll-text-on-place);
      .count {
        color: var(--poll-text-on-place);
      }
    }
  }

  &__question-dish-rating-position-name {
    color: var(--poll-text-on-place);
  }

  &__question--type_16 {
    color: var(--poll-text-on-bg);
  }
}

.comment-card {
  background-color: var(--poll-main-place-color);
  color: var(--poll-text-on-place);
}

.survey-start {
  &__title {
    &,
    & > * {
      font-size: var(--poll-header-font-size);
      line-height: 1.25;
      color: var(--poll-text-on-bg);
    }

    a {
      color: var(--poll-link-color);
    }
  }

  &__text {
    color: var(--poll-text-on-bg);

    a {
      color: var(--poll-link-color);
    }
  }

  &__start-button {
    color: var(--poll-start-button-text-color) !important;
    border: 2px solid var(--poll-start-button-stroke-color) !important;
    background: var(--poll-start-button-background-color) !important;
    border-radius: var(--poll-start-button-radius) !important;

    &:hover,
    &:focus,
    &:active {
      color: white;
    }
  }

  &__complaint-message {
    color: var(--poll-text-on-bg);
  }

  &__complain-button {
    &,
    &:hover {
      color: var(--poll-link-color);
    }
  }

  &__unsubscribe-button {
    &,
    &:hover {
      color: var(--poll-link-color);
    }
  }
}
.survey-report__back button {
  color: var(--poll-back-button-text-color) !important;
  border: 2px solid var(--poll-back-button-stroke-color) !important;
  background: var(--poll-back-button-background-color) !important;
  border-radius: var(--poll-back-button-radius) !important;
  & svg {
    color: var(--poll-back-button-text-color) !important;
  }
}
.survey-end {
  &__title {
    &,
    & > * {
      font-size: var(--poll-header-font-size);
      line-height: 1.25;
      color: var(--poll-text-on-bg);
    }

    a {
      color: var(--poll-link-color);
    }
  }

  &__text {
    color: var(--poll-text-on-bg);

    a {
      color: var(--poll-link-color);
    }
  }

  &__points-button {
    color: var(--poll-back-button-text-color) !important;
    border: 2px solid var(--poll-back-button-stroke-color) !important;
    background: var(--poll-back-button-background-color) !important;
    border-radius: var(--poll-back-button-radius) !important;
    &,
    &:hover,
    &:focus,
    &:active {
      color: var(--poll-back-button-text-color) !important;
      border: 2px solid var(--poll-back-button-stroke-color) !important;
      background: var(--poll-back-button-background-color) !important;
    }
  }

  &__promo-code-copy-button {
    background-color: var(--poll-main-color);
    color: var(--poll-button-text);
  }

  &__promo-code-value {
    color: var(--poll-text-on-bg);
  }

  &__complaint-message {
    color: #fff;
  }

  &__complain-button {
    &,
    &:hover {
      color: var(--poll-link-color);
    }
  }

  &__unsubscribe-button {
    &,
    &:hover {
      color: var(--poll-link-color);
    }
  }
}

.period-picker {
  .drp-calendar {
    font-family: var(--poll-font-family);
    tbody {
      td:not(.off) {
        &.start-date,
        &.end-date {
          background-color: var(--poll-main-color) !important;
        }
      }
    }
  }
}

.foquz-combo-dropdown {
  font-family: var(--poll-font-family);

  input[type="checkbox"],
  input[type="radio"] {
    & + label {
      color: #73808d;
    }
    &:checked {
      & + .form-check-label {
        color: var(--poll-text-on-place) !important;
        &:before {
          background: var(--poll-main-color);
          border-color: var(--poll-main-color);
        }

        &:after {
          .icon-checkbox(white);
        }
      }
    }
  }

  .f-radio {
    &-label {
      font-size: inherit !important;
      //color: var(--poll-main-color)!important;
    }
    &-input:checked + .f-radio-label {
      color: var(--poll-text-on-place) !important;
      &:before {
        background: var(--poll-main-color);
        border-color: var(--poll-main-color);
      }
      &:after {
        background-color: white;
        color: white;
      }
    }
  }

  .f-check {
    &-label {
      font-size: inherit !important;
      //color: var(--poll-main-color)!important;
    }
    &-input:checked + .f-check-label {
      color: var(--poll-text-on-place) !important;
      &:before {
        background: var(--poll-main-color);
        border-color: var(--poll-main-color);
      }
      &:after {
        .icon-checkbox(white);
      }
    }
  }

  .foquz-combo__select {
    color: var(--poll-text-on-place) !important;
  }

  .foquz-combo__empty {
    color: var(--poll-text-on-place) !important;
    opacity: 0.6;
  }

  .foquz-combo__search {
    .form-control {
      font-family: var(--poll-font-family) !important;
      &:focus {
        border-color: var(--poll-main-color) !important;
      }
    }
  }
}

.survey {
  .form-control.is-invalid,
  .is-invalid .form-control {
    background: white !important;
  }
}

.matrix-controller {
  .matrix-lg {
    .os-padding {
      background: white;
    }
  }

  // table {
  //   th.sticky, td.sticky {
  //     background: var(--poll-main-place-color);
  //   }
  // }

  .matrix-sm {
    .native-scrollbar.native-scrollbar--indicator.native-scrollbar--has-x:before {
      background: linear-gradient(
        to right,
        var(--poll-main-place-color),
        transparent
      );
    }

    .native-scrollbar.native-scrollbar--indicator.native-scrollbar--has-x:after {
      background: linear-gradient(
        to left,
        var(--poll-main-place-color),
        transparent
      );
    }
  }
}

@media screen and (max-width: 767px) {
  :root {
    --poll-font-size: 14px !important;
    --poll-header-font-size: 19px !important;
    --poll-finish-bg-image: var(--poll-mobile-bg-image);
  }

  .survey-questions {
    &__question-subdescription {
      font-size: 14px;
    }
  }
}

.survey--tablet {
  .survey-questions {
    &__question-card {
      width: 100% !important;
      padding-left: 85px;
      padding-right: 85px;
    }

    &__question-required {
      font-size: 16px !important;
      margin-top: 0;
    }

    &__question-message {
      font-size: 32px !important;
    }

    &__question-subdescription {
      font-size: 19px;
    }

    &__question-card input,
    &__question-card textarea {
      font-size: 22px !important;
    }

    &__wrapper {
      padding-left: 0;
      padding-right: 0;
      .form-error {
        font-size: 19px !important;
        margin-top: 35px;
      }
      .poll-question-comment {
        .form-label {
          font-size: 22px !important;
        }
      }

      .poll-question-gallery {
        .swiper-slide__description {
          font-size: 19px !important;
        }
      }
    }
  }
}

.no-back-button {
  .survey-questions__question-actions-btn--prev {
    display: none;
  }
  .survey-questions__question-actions-btn:not(:first-child) {
    margin-left: 0;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .survey-questions__question-card {
    textarea,
    input,
    select:focus,
    textarea:focus,
    input:focus {
      font-size: 16px !important;
    }
  }
}
