@import './add-video-by-link-modal-dialog/add-video-by-link-modal-dialog';
@import './delete-modal-dialog/delete-modal-dialog';
@import './unsaved-changes-modal-dialog/unsaved-changes-modal-dialog';
@import './variants-control/variants-control';
@import './quizzes-control/quizzes-control';
@import './number-menu/number-menu';

.survey-question {
  .select2 .select2-selection--multiple.select2-selection .select2-search {
    min-width: 130px;
    input {
      min-width: 100%;
    }
  }
  &__content {
    .logic-badge {
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      color: @f-color-service;

      &__icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        pointer-events: all;

        &:not(.disabled):hover {
          color: @f-color-service--hover;
        }
      }

      .svg-icon {
        z-index: 1;
      }
      &.disabled {
        .svg-icon {
          opacity: 0.5;
        }
      }

      &__cover {
        display: block;
        // position: absolute;
        // top: 0px;
        // left: 0px;
        // right: 0px;
        // bottom: 0px;
        cursor: pointer;
        z-index: 10;
        // pointer-events: none;
      }

      &__content {
        opacity: 0;
        visibility: hidden;
        transition: opacity 350ms ease-in-out;
        position: absolute;
        left: -10px;
        right: 0;
        top: 100%;
        margin-top: 8px;
        background: white;
        z-index: 5;
        box-shadow: 0px 5px 15px rgba(46, 47, 49, 0.3);
        border-radius: 9px;
        padding: 24px;
        font-size: 12px;

        td,
        th {
          font-size: inherit;
        }

        td {
          padding-top: 12px;
          padding-bottom: 12px;
        }

        &:before {
          content: '';
          width: 22px;
          height: 6px;
          background-image: url(data:image/svg+xml,%0A%09%09%3Csvg%20width%3D%2222%22%20height%3D%226%22%20viewBox%3D%220%200%2022%206%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%09%09%09%3Cpath%20d%3D%22M20.7535%205.49998C21.7644%205.49999%2021.9366%205.49999%2020.7753%205.5C20.768%205.5%2020.7607%205.49999%2020.7535%205.49998C17.3188%205.49995%204.20265%205.49992%200.806732%205.49995C0.796259%205.49998%200.785763%205.5%200.775246%205.5C-0.345165%205.49998%20-0.177311%205.49996%200.806732%205.49995C6.89597%205.48125%205.65919%201.90735e-06%2010.7753%201.90735e-06C15.8941%201.90735e-06%2014.5283%205.48723%2020.7535%205.49998Z%22%20fill%3D%22%23FFF%22/%3E%0A%09%09%3C/svg%3E%0A%09);
          position: absolute;
          top: -5px;
          right: 10px;
        }
      }

      &:hover  .logic-badge__content {
        visibility: visible;
        opacity: 1;
      }
    }

    .view-logic-badge {
      .logic-badge__content {
        &:before {
          left: 50px;
        }
      }
    }

    &--initializing {
      visibility: hidden;
    }

    .survey-question__menu {
      margin-top: 21px;
      margin-bottom: 0;
    }

    .survey-question__number-menu {
      margin-top: 20px;
    }

    .survey-question__body {
      margin-top: 15px;
    }

    .menu-quests {
      margin-bottom: 20px;
    }
  }

  &__header-edit-button {
    margin-left: 13px;
    flex-shrink: 0;

    &:before {
      .icon-pencil(#73808d);

      content: '';
    }
  }

  &__header-copy-button {
    margin-left: 10px;
    flex-shrink: 0;

    &:before {
      .icon-link3(#73808d);

      content: '';
    }
  }

  &__body {
    .survey-question__card {
      min-height: 100px;
    }

    // .f-card__footer {
    //   position: sticky;
    //   bottom: 0;
    //   z-index: 8;

    //   &:before {
    //     content: '';
    //     position: absolute;
    //     top: -20px;
    //     left: 0;
    //     right: 0;
    //     height: 20px;
    //     background: linear-gradient(
    //       180deg,
    //       rgba(255, 255, 255, 0) 0%,
    //       #ffffff 100%
    //     );
    //   }
    // }
  }

  &__card {
    padding: 28px 20px 20px 20px;

    background: #fff;
    box-shadow: 0 2px 4px #cfd8dc;
    border-radius: 9px;

    .survey-question__media-type-selector {
      margin-bottom: 25px;
      width: 100%;
    }

    .survey-question__clarifying-type-selector {
      margin-bottom: 26px;
    }

    .survey-question__variants-type-selector {
      margin-bottom: 25px;
      width: 100%;
    }

    .survey-question__custom-variant-checkbox {
      margin-top: 27.6px;
    }

    .survey-question__custom-clarifying-question-answer-checkbox {
      margin-top: 27.6px;
    }

    .survey-question__comment-length-form-group {
      margin-top: 24px;
    }

    .survey-question__custom-variant-length-form-group {
      margin-top: 24px;
    }

    .survey-question__custom-clarifying-question-answer-length-form-group {
      margin-top: 24px;
    }

    .survey-question__card-footer {
      margin: 63px -20px -20px;
    }

    .survey-question__question-loader {
      margin-top: 12px;
    }
  }

  &__preview {
    background: url(../../assets/img/themes/background5.png) center top;
    border-radius: 9px;
    box-shadow: 0 2px 4px 0 #cfd8dc;
    overflow: hidden;

    &-tabs {
      height: 50px;
      .flex();
      position: relative;
      border-bottom: none;
      overflow: hidden;
      flex-wrap: nowrap;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 108px;
        -webkit-filter: blur(10px);
        filter: blur(10px);
        z-index: 1;
        background: url(../../assets/img/themes/background5.png) center top;
      }

      &-tab {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: @f-color-primary;
        background: white !important;
        border: none !important;
        border-radius: 0 !important;
        border-right: 1px solid rgba(207, 216, 220, 0.1) !important;
        flex-grow: 1;
        z-index: 2;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        height: 50px;

        &--expand {
          flex-grow: 0;
          width: 50px;
          height: 50px;
          &:before {
            content: '';
            .icon-expand(@f-color-service);
          }
        }

        &.active,
        &:hover {
          color: @f-color-text !important;

          .btn-question {
            opacity: 1;
          }
        }
      }
    }

    &-tab-pane {
      height: 100%;
      overflow: auto;

      &--mobile {
        height: 806px;

        .survey-question__preview-content-wrapper {
          background: url(../../assets/img/iphone.png) 0 0 no-repeat;
          width: 375.2px;
          height: 760px;
          position: absolute;
          background-size: contain;
          margin: 0 auto;
        }

        .survey-question__preview-content-page {
          position: absolute;
          width: 327px;
          height: 558px;
          top: 105px;
          left: 25px;
          background: #fff;
          overflow-y: auto;
        }
      }

      &--tablet {
        .survey-question__preview-content {
          width: 768px;
          height: 1024px;
        }

        .survey-question__preview-content-page {
          width: 768px;
          height: 1024px;
        }
      }

      &--desktop {
        .survey-question__preview-content {
          width: 1366px;
        }

        .survey-question__preview-content-page {
          width: 1366px;
          height: 768px;
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: relative;
    }
  }

  &__type-form-control-value {
    display: inline-flex;
    align-items: center;

    &:before {
      content: '';

      display: inline-block;
      margin-right: 6px;
    }

    &--rating {
      &:before {
        margin-left: -1px;
        .icon-rating(#A6B1BC);
        margin-right: 8px;
      }
    }

    &--variants {
      &:before {
        margin-left: -3px;
        .icon-variants(#A6B1BC);
        margin-right: 11px;
      }
    }

    &--text {
      &:before {
        margin-left: -3px;
        .icon-type-text(#A6B1BC);
        margin-right: 13px;
      }
    }

    &--date {
      &:before {
        margin-left: -3px;
        .icon-type-date(#A6B1BC);
        margin-right: 11px;
      }
    }

    &--address {
      &:before {
        margin-left: -1px;
        .icon-type-address(#A6B1BC);
        margin-right: 13px;
      }
    }

    &--upload {
      &:before {
        margin-left: -1px;
        .icon-type-upload(#A6B1BC);
        margin-right: 11px;
      }
    }

    &--quiz {
      &:before {
        margin-left: -1px;
        .icon-type-quiz(#A6B1BC);
        margin-right: 11px;
      }
    }

    &--priority {
      &:before {
        margin-left: -1px;
        .icon-type-priority(#A6B1BC);
        margin-right: 11px;
      }
    }

    &--media-variants {
      &:before {
        margin-left: -1px;
        .icon-photovideo(#A6B1BC);
        margin-right: 11px;
      }
    }
  }

  &__first-row {
    display: flex;

    .survey-question__type-form-group {
      width: 315px;
    }

    .survey-question__required-form-group {
      flex-shrink: 0;
      margin-top: 27px;
      margin-left: 40px;
    }
  }

  &__media-type-selector {
    .hat-radio-group {
      height: 48px;
    }

    .hat-radio-group__radio-label {
      justify-content: center;
    }

    .survey-question__media-type-selector-value-icon {
      margin-right: 10px;
      flex-shrink: 0;
    }
  }

  &__media-type-selector-value-text-icon {
    .icon-text(#A6B1BC);

    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
      .icon-text(#3f65f1);
      flex-shrink: 0;
    }
  }

  &__media-type-selector-value-image-icon {
    .icon-image(#A6B1BC);
    flex-shrink: 0;

    width: 24px !important;
    height: 24px !important;

    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
      .icon-image(#3f65f1);
    }
  }

  &__media-type-selector-value-video-icon {
    .icon-video(#A6B1BC);
    flex-shrink: 0;

    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
      .icon-video(#3f65f1);
    }
  }

  &__media-form-control {
    .survey-question__media-form-control-list {
      margin-top: 13px;
    }
  }

  &__media-form-control-actions {
    .survey-question__media-form-control-actions-item {
      &:not(:first-child) {
        margin-left: 8px;
      }
    }
  }

  &__media-form-control-list {
    .survey-question__media-form-control-list-item,
    .survey-question__media-form-control-list-loading-placeholder {
      margin-top: 10px;
      margin-left: 10px;
    }
  }

  &__media-form-control-list-content {
    display: flex;
    flex-wrap: wrap;

    margin-top: -10px;
    margin-left: -10px;
  }

  &__media-form-control-list-item {
    display: flex;
    width: 94px;
    height: 94px;
    position: relative;

    border-radius: 8px;

    &:after {
      content: '';

      position: absolute;

      top: 0;
      left: 0;
      width: 94px;
      height: 94px;

      border-radius: 8px;
      background-color: #000;
      opacity: 0.3;
      cursor: pointer;
    }

    .survey-question__media-form-control-list-item-preview-image {
      max-width: 100%;
      height: 100%;
    }

    .survey-question__media-form-control-list-item-label {
      position: absolute;
      top: 25.68px;
      left: 2px;
      width: 90px;
    }
  }

  &__media-form-control-list-item-preview-image {
    border-radius: 8px;
    object-fit: cover;
  }

  &__media-form-control-list-item-remove-button {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -6px;
    right: -6px;
    border: none;
    z-index: 3;

    font-size: 11px;
    opacity: 0;
    cursor: pointer;
    background-color: #000;
    border-radius: 50%;

    animation: fadeOutToNone 0.1s ease-out;

    & > i {
      cursor: pointer;
      color: #fff;
    }

    .survey-question__media-form-control-list-item:hover & {
      opacity: 1;
    }
  }

  &__media-form-control-list-item-label {
    border: none;
    width: 94px;
    z-index: 2;

    font-family: Roboto, sans-serif;
    font-size: 34px;
    font-weight: bold;
    line-height: 120%;
    color: #fff;
    background: none;
    text-align: center;
    text-transform: uppercase;
  }

  &__media-form-control-list-loading-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 94px;
    height: 94px;
    position: relative;

    border-radius: 8px;
    background-color: #eceff1;

    &:after {
      content: '\f110';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      background-color: transparent;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      width: auto;
      height: auto;
      color: #3f65f1;
      font-size: 20px;

      -webkit-animation: fa-spin 1s infinite steps(8);
      animation: fa-spin 1s infinite steps(8);
    }
  }

  &__assessment-type-selector {
    display: flex;
    flex-wrap: wrap;

    .survey-question__assessment-type-selector-form-group {
      width: 290px;
      margin-right: 17px;
    }

    .survey-question__assessment-type-selector-rating {
      margin-left: 10px;
    }
  }

  &__clarifying-type-selector {
    margin-top: 3.6px;
    display: flex;

    .survey-question__clarifying-type-selector-checkbox {
      display: block;
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    .survey-question__clarifying-type-selector-col {
      &:not(:first-child) {
        margin-left: 30px;
      }
    }
  }

  &__variants-type-selector {
    .hat-radio-group {
      height: 48px;
    }

    .hat-radio-group__radio-label {
      justify-content: center;
    }

    .survey-question__variants-type-selector-value-icon {
      margin-right: 10px;
      flex-shrink: 0;
    }
  }

  &__variants-type-selector-value-single-icon {
    .icon-radio(#A6B1BC);
    flex-shrink: 0;

    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
      .icon-radio(#3f65f1);
    }
  }

  &__variants-type-selector-value-multiple-icon {
    .icon-checkbox2(#A6B1BC);
    flex-shrink: 0;

    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
      .icon-checkbox2(#3f65f1);
    }
  }

  &__variants-type-selector-value-date-icon {
    .icon-date(#A6B1BC);
    flex-shrink: 0;

    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
      .icon-date(#3f65f1);
    }
  }

  &__variants-type-selector-value-time-icon {
    .icon-time(#A6B1BC);
    flex-shrink: 0;

    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
      .icon-time(#3f65f1);
    }
  }

  &__variants-type-selector-value-datetime-icon {
    .icon-datetime(#A6B1BC);
    flex-shrink: 0;

    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
      .icon-datetime(#3f65f1);
    }
  }

  &__variants-type-selector-value-photovideo-icon {
    .icon-photovideo(#A6B1BC);
    flex-shrink: 0;

    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
      .icon-photovideo(#3f65f1);
    }
  }

  &__variants-type-selector-value-photo-icon {
    .icon-image2(#A6B1BC);
    flex-shrink: 0;

    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
      .icon-image2(#3f65f1);
    }
  }

  &__variants-type-selector-value-video-icon {
    .icon-video(#A6B1BC);
    flex-shrink: 0;

    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
      .icon-video(#3f65f1);
    }
  }

  &__variants-type-selector-value-oneline-icon {
    .icon-oneline(#A6B1BC);
    flex-shrink: 0;

    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
      .icon-oneline(#3f65f1);
    }
  }

  &__variants-type-selector-value-multiline-icon {
    .icon-multiline(#A6B1BC);
    flex-shrink: 0;

    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
      .icon-multiline(#3f65f1);
    }
  }

  &__length-control {
    display: flex;
    align-items: center;
    padding-top: 22px;

    .survey-question__length-control-slider {
      flex-grow: 1;
    }

    .survey-question__length-control-min-boundary {
      margin-right: 22px;
    }

    .survey-question__length-control-max-boundary {
      margin-left: 22px;
    }
  }

  &__length-control-slider {
    .survey-question__length-control-slider-handle {
      top: calc(6px / 2 - 24px / 2);
    }
  }

  &__length-control-slider-handle {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px !important;
    height: 24px !important;

    .survey-question__length-control-slider-handle-indicator {
      margin-top: -50px;
    }
  }

  &__length-control-slider-handle-indicator {
    font-weight: bold;
    font-size: 14px;
    color: #3f65f1;
  }

  &__card-footer {
    padding: 20px;
    position: relative;
    border-radius: 0 0 9px 9px;

    background-color: #fbfbfb;
  }

  &__card-action-list {
    display: flex;
    justify-content: flex-end;

    .survey-question__card-action-list-item {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  &__delete-button {
    &:before {
      .icon-plus5(#F96261);
      top: 11px;
      transform: rotate(45deg);
    }
  }

  &__cancel-button {
    &:before {
      .icon-delete(#73808d);
      width: 17px;
      height: 17px;
      top: 9px;
    }
  }

  &__save-button {
    &:before {
      .icon-save(#37a74a);
      top: 10px;
    }
  }

  &__save-message {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    background: #abefb7;
    color: #37a74a;
  }

  &__client-field-selector {
    margin-top: 36px;

    &-link {
      margin-bottom: 26px;
    }
    &-link-field {
      width: 290px;
      margin-bottom: 26px;
    }
    &-rewrite {
      margin-bottom: 26px;
    }
  }

  &__select2 {
    .select2-dropdown--below {
      padding-top: 4px;
    }
    .select2-results__options {
      max-height: 400px !important;
    }
    .select2-dropdown .select2-results__option {
      font-size: 16px;
      padding-left: 17px;
      height: 40px;
      .survey-question__type-form-control-value:before {
      }

      .survey-question__type-form-control-value--rating {
        &:before {
          .icon-rating(#3f65f1);
          width: 22px !important;
          height: 22px !important;
          margin-right: 19px !important;
          margin-left: 0;
        }
      }

      .survey-question__type-form-control-value--variants {
        &:before {
          .icon-variants(#3f65f1);
          margin-left: 0;
          margin-right: 20px;
        }
      }

      .survey-question__type-form-control-value--text {
        &:before {
          .icon-type-text(#3f65f1);
          margin-left: 0;
          margin-right: 21px;
        }
      }

      .survey-question__type-form-control-value--quiz {
        &:before {
          .icon-type-quiz(#3f65f1);
          margin-left: 0;
          margin-right: 21px;
        }
      }

      .survey-question__type-form-control-value--priority {
        &:before {
          .icon-type-priority(#3f65f1);
          margin-left: 0;
          margin-right: 21px;
        }
      }

      .survey-question__type-form-control-value--media-variants {
        &:before {
          .icon-photovideo(#3f65f1);
          margin-left: 0;
          margin-right: 21px;
        }
      }

      .survey-question__type-form-control-value--date {
        &:before {
          .icon-type-date(#3f65f1);
          margin-left: 1px;
          margin-right: 19px;
        }
      }

      .survey-question__type-form-control-value--address {
        &:before {
          margin-left: 3px;
          margin-right: 21px;
          .icon-type-address(#3f65f1);
        }
      }

      .survey-question__type-form-control-value--upload {
        &:before {
          margin-left: 1px;
          margin-right: 20px;
          .icon-type-upload(#3f65f1);
        }
      }
      &[aria-selected='true'] {
        .survey-question__type-form-control-value--rating {
          &:before {
            .icon-rating(#A6B1BC);
          }
        }
        .survey-question__type-form-control-value--variants {
          &:before {
            .icon-variants(#A6B1BC);
          }
        }
        .survey-question__type-form-control-value--text {
          &:before {
            .icon-type-text(#A6B1BC);
          }
        }
        .survey-question__type-form-control-value--quiz {
          &:before {
            .icon-type-quiz(#A6B1BC);
          }
        }
        .survey-question__type-form-control-value--priority {
          &:before {
            .icon-type-priority(#A6B1BC);
          }
        }
        .survey-question__type-form-control-value--mediavariants {
          &:before {
            .icon-photovideo(#A6B1BC);
          }
        }
        .survey-question__type-form-control-value--date {
          &:before {
            .icon-type-date(#A6B1BC);
          }
        }
        .survey-question__type-form-control-value--address {
          &:before {
            .icon-type-address(#A6B1BC);
          }
        }
        .survey-question__type-form-control-value--upload {
          &:before {
            .icon-type-upload(#A6B1BC);
          }
        }
      }
      &:not(:first-child) {
        margin-top: 1px;
      }
    }
  }

  &__mask {
    display: flex;
    align-items: center;
    color: #73808d;
    margin-bottom: 26px;
    height: 48px;
    align-self: flex-end;

    .mask {
      min-width: 100%;
      white-space: nowrap;
    }
  }

  &__strong {
    font-weight: bold;
    margin-right: 0.2em;
  }
}

.mask-name-field {
  padding: 20px 0;
  border-top: 1px solid #e7ebed;

  &:last-child {
    border-bottom: 1px solid #e7ebed;
  }
}

@media (max-width: 1219px) {
  .survey-question {
    &__media-form-control-list-item-remove-button {
      opacity: 1;
    }
  }
}

.survey-question__preview-content-page {
  transition: opacity 0.25s ease-in-out;
}

.survey-questions__wrapper_pre
  .survey-question__media-form-control-list-item:after {
  display: none;
}

.survey-questions__slider-letter:empty {
  display: none;
}

.survey-question {
  &__open-preview {
    &:before {
      width: 40px;
      height: 40px;
    }
  }
  &__view-modal {
    flex-direction: column;
    padding: 0 !important;

    &.show {
      display: flex !important;
    }

    &-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: #ffffff;
      opacity: 0.9;
      z-index: -1;
    }

    &-header {
      display: flex;
      height: 70px;
      position: relative;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      padding: 0 18px 0 30px;

      z-index: 1;
      background: #fff;
      box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);
    }

    &-title {
      font-weight: 900;
      font-size: 24px;
      color: #2e2f31;
    }

    &-close-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 33px;
      height: 33px;
      margin-left: 37px;
      padding: 0 !important;

      border: 1px solid #73808d !important;
      border-radius: 50%;
      box-shadow: none !important;

      &:after {
        content: '';

        .icon-close(#73808d);
      }
    }

    &-sub-title {
      margin-top: 4px;

      font-size: 16px;
      line-height: 1.1;
      color: #2e2f31;
    }

    &-content {
      flex-grow: 1;
      display: flex;
      justify-content: safe center;
      overflow: auto;
      padding: 37px 0;

      &--desktop {
        .survey-question__view-modal-page {
          min-width: 1280px;
          min-height: 768px;
          width: 1280px;
          height: 768px;
        }

        @media (max-width: 1280px) {
          justify-content: unset;
        }
      }

      &--tablet, &--trade {
        .survey-question__view-modal-page {
          min-width: 768px;
          min-height: 1024px;
          width: 768px;
          height: 1024px;
        }

        @media (max-width: 768px) {
          justify-content: unset;
        }
      }

      &--mobile {
        .survey-question__view-modal-page {
          min-width: 360px;
          min-height: 570px;
          width: 360px;
          height: 570px;
        }

        @media (max-width: 360px) {
          justify-content: unset;
        }
      }
    }

    &-page {
      display: block;
    }

    &-open-in-new-tab-button {
      display: inline-flex;
      align-items: center;
      padding: 0;

      font-size: 14px;
      line-height: 24px;
      color: #000000;

      &:before {
        display: inline-block;
        content: '';
        .icon-open-in-new-tab(#73808d);
        margin-right: 10px;
      }
    }

    &-view-buttons {
      display: flex;
      position: absolute;
      align-items: flex-end;
      left: calc((100% - 92px) / 2);
    }

    &-view-button {
      padding: 0;

      &:not(:first-child) {
        margin-left: 20px;
      }

      &:focus {
        box-shadow: none;
      }

      &--desktop {
        .icon-desktop(#73808d);

        &:hover:not(:disabled) {
          .icon-desktop(#007bff);
        }

        &.survey-question__view-modal-view-button--active {
          .icon-desktop(#3f65f1);
        }
      }

      &--tablet {
        .icon-tablet(#73808d);

        &:hover:not(:disabled) {
          .icon-tablet(#007bff);
        }

        &.survey-question__view-modal-view-button--active {
          .icon-tablet(#3f65f1);
        }
      }

      &--mobile {
        .icon-mobile(#73808d);

        &:hover:not(:disabled) {
          .icon-mobile(#007bff);
        }

        &.survey-question__view-modal-view-button--active {
          .icon-mobile(#3f65f1);
        }
      }
    }
  }
}

.user--filial-employee {
  .survey-question__body {
    .f-card__footer {
      display: none;
    }
  }
}
