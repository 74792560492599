.media-wrapper {
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  flex-shrink: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: inherit;
  }

  &--sm {
    width: 35px;
    height: 35px;
  }

  &--md {
    width: 40px;
    height: 40px;
  }

  &--lg {
    width: 94px;
    height: 94px;
  }

  &__remove {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -6px;
    right: -6px;
    border: none;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    cursor: pointer;
    background-color: #000;
    border-radius: 50%;

    color: white;
    font-weight: bold;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &--loading {
    background: @f-color-primary;
    color: white;
  }
}
