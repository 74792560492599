.question-statistics {
    &__priority-statistics {
        display: flex;

        .question-statistics__priority-statistics-legend {
            margin-top: 8px;
            flex-grow: 1;
            margin-left: 40px;
        }
    }

    &__priority-statistics-chart-wrapper {
        .question-statistics__priority-statistics-chart {
            margin-top: 27px;
        }
    }

    &__priority-statistics-mode-toggle {
        .question-statistics__priority-statistics-mode-button {
            &:not(:first-child) {
                margin-left: 3px;
            }
        }
    }

    &__priority-statistics-mode-button {
        cursor: pointer;
    }

    &__priority-statistics-column-mode-button {
        &:before {
            .icon-column-chart(#73808D);
        }

        &.question-statistics__priority-statistics-mode-button--active {
            &:before {
                .icon-column-chart(#3F65F1);
            }
        }
    }

    &__priority-statistics-pie-mode-button {
        &:before {
            .icon-pie-chart(#73808D);
        }

        &.question-statistics__priority-statistics-mode-button--active {
            &:before {
                .icon-pie-chart(#3F65F1);
            }
        }
    }

    &__priority-statistics-chart-wrapper {
        width: 355px;
        position: relative;
    }

    &__priority-statistics-column-chart {
        height: 180px;
    }

    &__priority-statistics-pie-chart {
        height: 240px;
    }

    &__priority-statistics-legend-table {
        th {
            white-space: nowrap;
        }
    }

    &__priority-statistics-legend-table-row {
        cursor: pointer;

        &--disabled {
            .question-statistics__priority-statistics-legend-table-color-indicator {
                background-color: #CCC !important;
            }
        }
    }

    &__priority-statistics-legend-table-text-cell {
        font-weight: 500;
        font-size: 14px;
        color: #2E2F31;
    }

    &__priority-statistics-legend-table-text-cell-content {
        display: flex;
        align-items: center;

        .question-statistics__priority-statistics-legend-table-color-indicator {
            flex-shrink: 0;
            margin-right: 10px;
        }
    }

    &__priority-statistics-legend-table-vote-count-head-cell,
    &__priority-statistics-legend-table-vote-count-cell {
        text-align: end;
    }

    &__priority-statistics-legend-table-percentage-head-cell,
    &__priority-statistics-legend-table-percentage-cell {
        text-align: end;
    }

    &__priority-statistics-legend-table-color-indicator {
        width: 12px;
        height: 12px;

        border-radius: 50%;
    }
}
