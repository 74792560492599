.contact-points {
    &__add-video-by-link-modal-dialog {
        width: 460px !important;

        .contact-points__add-video-by-link-modal-dialog-link-form-group {
            margin-bottom: 0;
        }

        .contact-points__add-video-by-link-modal-dialog-message {
            margin-top: 16px;
        }

        &--initializing {
            visibility: hidden;
        }
    }

    &__add-video-by-link-modal-dialog-link-form-group {
        .form-label {
            font-weight: normal;
        }
    }

    &__add-video-by-link-modal-dialog-message {
        display: flex;
        align-items: center;

        padding: 23px 27px 18px 27px;
        background: rgba(233, 235, 241, 0.45);
        border-radius: 4px;
    }

    &__add-video-by-link-modal-dialog-message-image {
        align-self: center;
        flex: 0 0 auto;

        opacity: 0.27;
        filter: grayscale(1);
    }

    &__add-video-by-link-modal-dialog-message-text {
        margin-left: 24px;

        font-family: Roboto, sans-serif;
        font-weight: normal;
        font-size: 14px;
        color: #73808D;
    }
}
