.rating {
  display: flex;
  align-items: center;

  .rating__star {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.img-rating {

  .img-rating__icon {
    will-change: transform;
    transition: transform 250ms;
    cursor: pointer;
    font-size: 0;

    &.active {
      transform: scale(1.3);
    }

    img {
        width: 100%;
    }

    &:only-child {
      margin-left: 0!important;
      margin-right: 0!important;
    }
  }

  &--like {
    .img-rating__icon {
      width: 58px;
      margin: 0 45px;
    }

    &.img-rating--sm {
      .img-rating__icon {
        width: 36px;
        margin: 0 20px;
      }
    }
  }

  &--heart {
    .img-rating__icon {
      width: 66px;
      margin: 0 45px;
    }

    &.img-rating--sm {
      .img-rating__icon {
        width: 37px;
        margin: 0 20px;
      }
    }
  }

  &--face {
    .img-rating__icon {
      width: 44px;
      margin: 0 30px;
    }

    &[data-icons='2'] {
      margin: 0 45px;
    }

    &[data-icons='5'],
    &.img-rating--sm {
      .img-rating__icon {
        width: 33px;
        margin: 0 15px;
      }
    }
  }

  &--inited {
    .img-rating__icon:not(.active) {
      opacity: 0.4;
    }
  }

  &.img-rating--xs {
    .img-rating__icon {
      width: 22px;
    }
  }
}

:root {
  --f-rating-stars-count: 5;
  --f-rating-spacing: 16px;
  --f-rating-star-size: 25px;
}


.f-rating {
  display: flex;
  justify-content: space-between!important;

  width: calc(var(--f-rating-stars-count) * var(--f-rating-star-size) + var(--f-rating-spacing) * (var(--f-rating-stars-count) - 1));
  max-width: 100%;

  &__item {
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 2px;
    }
  }
  svg.f-rating__star {
    width: var(--f-rating-star-size);
    height: var(--f-rating-star-size);
  }

  &--sm {
    --f-rating-spacing: 11px;
    --f-rating-star-size: 18px;
  }

  &--md {
    --f-rating-spacing: 18px;
    --f-rating-star-size: 32px;
  }

  &--lg {
    --f-rating-spacing: 22px;
    --f-rating-star-size: 48px;
  }

  @media screen and (max-width: 767px) {
    &--sm,
    &--md {
      --f-rating-spacing: 18px;
      --f-rating-star-size: 27px;
    }
  }
}
