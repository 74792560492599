.report {
  &__header {
    display: flex;
    align-items: center;
    max-width: 100%;

    .mobile-and-tablet({
			padding-left: var(--foquz-side-offset);
			padding-right: var(--foquz-side-offset);
		});
  }

  &__header-title {
    padding-bottom: 0;
    flex-shrink: 1;
    word-break: break-word;

    font-weight: 900;
    color: #2e2f31;
  }

  &__header-edit-button {
    margin-left: 13px;
    flex-shrink: 0;

    &:before {
      .icon-pencil(#73808d);

      content: "";
    }
  }

  &__header-share-button {
    margin-left: 10px;
    flex-shrink: 0;

    &:before {
      .icon-share(#73808d);

      content: "";
    }

    &.editor-mode {
      &:before {
        .icon-link6(#73808d);

        content: "";
      }
    }
  }

  &__header-name-form {
    display: flex;
    align-items: center;

    overflow: hidden;
  }

  &__header-name-form-control {
    padding: 0;

    font-size: 22px;
    font-weight: 900;
    color: #2e2f31;
    border: none;
    background: none;
    border-bottom: solid 1px #a6b1bc;
  }

  &__header-name-form-hidden-control {
    padding-left: 20px;

    font-size: 22px;
    font-weight: 900;
    white-space: pre;
  }

  &__header-name-form-cancel-button {
    margin-left: 13px;
    flex-shrink: 0;

    &:before {
      .icon-cross2(#f96261);

      content: "";
    }
  }

  &__header-name-form-save-button {
    margin-left: 10px;
    flex-shrink: 0;

    &:before {
      .icon-done(#37a74a);

      content: "";
    }
  }

  &__header-version-picker {
    margin-left: 10px;
    flex-shrink: 0;
  }

  &__header-version-picker-toggle {
    display: flex;
    padding: 9px 16px 9px 14px;
    align-items: center;

    cursor: pointer;
    background: #ffffff;
    border-radius: 50px;
  }

  &__header-version-picker-label {
    font-size: 14px;
    color: #2e2f31;
  }

  &__header-version-picker-arrow {
    margin-left: 15px;

    .icon-select-arrow(#73808d);
  }

  &__header-version-picker-dropdown-menu {
    width: 205px;
    padding-top: 16px !important;
    padding-bottom: 16px !important;

    &:before {
      right: 87px !important;
    }

    .dropdown-item {
      display: flex;
      padding: 6px 30px 6px 17px;
      height: unset;

      color: #2e2f31 !important;

      &:hover {
        background: #e9ecee;
      }

      &.active {
        color: #73808d !important;
      }
    }
  }

  &__header-version-picker-dropdown-menu-item-wrapper {
    max-height: 134px;
    overflow-y: auto;

    .scroll-element.scroll-y {
      right: 12px;
    }
  }

  &__header-version-picker-dropdown-menu-item-author-avatar {
    width: 30px;
    height: 30px;

    border-radius: 8px;
    background: #ffffff no-repeat center;
    object-fit: cover;
  }

  &__header-version-picker-dropdown-menu-item-data {
    margin-left: 10px;

    overflow: hidden;
  }

  &__header-version-picker-dropdown-menu-item-date {
    font-weight: bold;
    font-size: 14px;
  }

  &__header-version-picker-dropdown-menu-item-author-name {
    margin-top: 3px;

    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__header-owner-name {
    margin-left: 10px;

    font-size: 13px;
    line-height: 15px;
    flex-shrink: 0;
  }

  &__header-owner-name-label {
    color: #73808d;
  }

  &__header-owner-name-value {
    font-weight: 500;
    color: #2e2f31;
  }

  &__header-delete-button {
    margin-left: 25px;
    flex-shrink: 0;

    &:before {
      .icon-cross2(#f96261);

      content: "";
    }
  }

  &__filters-row {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;

    &:not(:first-child) {
      margin-top: 30px;
    }
  }

  &__filter {
    &:not(:first-child) {
      margin-left: 30px;
    }
  }

  &__filters-apply-button {
    margin-top: 29px;
    margin-left: 30px;
    flex-shrink: 0;
  }

  &__apply-button {
    display: flex;
    width: 61px;
    height: 48px;
    align-items: center;
    justify-content: center;

    background: #abefb7;
    border-radius: 4px;

    &:before {
      .icon-done(#37a74a);

      content: "";

      width: 19.6px;
      height: 14px;
    }
  }

  &__statistics-list {
    margin-top: 48px;

    .row:not(:first-child) {
      margin-top: 32px;
    }
  }

  &__statistics-header {
    display: flex;
    align-items: center;
  }

  &__statistics-title {
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    color: #2e2f31;
  }

  &__statistics-title-highlight {
    font-weight: 400;
  }

  &__statistics-button {
    background: none !important;
    font-size: 14px;
    font-weight: normal;
    padding: 0;
    color: #3f65f1 !important;
    cursor: pointer;
  }

  &__statistics-content {
    margin-top: 19px;
  }

  &__statistics-multiple-data-container {
    .row:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__statistics-data-title {
    margin-bottom: 11px;
    padding-left: 6px;

    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
  }

  &__statistics-data {
    height: 320px;
    width: 100%;

    overflow: visible !important;

    .highcharts-container {
      overflow: visible !important;
    }

    svg {
      overflow: visible;
    }
  }

  &__statistics-placeholder {
    display: flex;
    height: 319px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: 1px solid #cfd8dc;
    border-radius: 9px;
  }

  &__statistics-placeholder-icon {
    .icon-statistics(#CFD8DC);

    display: inline-block;
  }

  &__statistics-placeholder-text {
    margin-top: 18px;

    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: #a6b1bc;
  }

  &__back-button {
    display: flex;
    align-items: center;

    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #2e2f31;

    &:before {
      .icon-arrow-back(#73808d);

      content: "";

      margin-right: 10px;

      transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    &:hover {
      &:before {
        .icon-arrow-back(#2e2f31);
      }
    }
  }

  &__save-modal {
    .modal-dialog {
      width: 460px;
    }
  }

  &__save-button {
    margin-left: 13px;
  }
}
