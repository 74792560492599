.mailings {
    &__select-clients-modal-dialog {
        width: 700px !important;
        max-width: unset;

        &--initializing {
            visibility: hidden;
        }
    }

    &__select-clients-modal-dialog-birthday-row {
        margin-left: -10px !important;
        margin-right: -10px !important;

        & > .col {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }

    &__select-clients-modal-dialog-birthday-row-date-col {
        flex: 0 0 60%;
        max-width: 60%;
    }

    &__select-clients-modal-dialog-birthday-row-age-col {
        flex: 0 0 40%;
        max-width: 40%;
    }

    &__select-clients-modal-dialog-birthday-row-additional-controls-group-wrapper {
        position: relative;
    }

    &__select-clients-modal-dialog-birthday-row-additional-controls-group {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__select-clients-modal-dialog-submit-button-wrapper {
        position: relative;
    }

    &__select-clients-modal-dialog-submit-button {
        &--adding {
            visibility: hidden;

            transition: none !important;
        }
    }

    &__select-clients-modal-dialog-submit-button-progress-bar {
        position: absolute !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__select-clients-modal-dialog-body {
        transition: opacity 200ms ease-in-out;

        &--disabled {
            opacity: 0.3;
        }
    }

    &__select-clients-modal-dialog-body-curtain {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
    }
}
