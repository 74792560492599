.kanban {
  display: flex;
  margin-top: 19px;

  &-column {
    width: 20%;
    background: #f5f6fa;
    border-radius: 9px;

    &--disabled {
      opacity: 0.35;
    }

    &:not(:first-child) {
      margin-left: 5px;
    }

    &__header {
      padding: 9px 9px 8px 9px;

      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
      font-weight: bold;
      font-size: 14px;
      color: #ffffff;
      height: 33px;

      position: sticky;
      top: 10px;
      z-index: 3;

      white-space: nowrap;
      overflow:hidden;
      text-overflow: ellipsis;
    }

    &--new .kanban-column__header {
      background: @answer-new-color;
    }

    &--in-process .kanban-column__header {
      background: @answer-in-process-color;
    }

    &--delayed .kanban-column__header {
      background: @answer-delayed-color;
    }

    &--completed .kanban-column__header {
      background: @answer-completed-color;
    }

    &--handle .kanban-column__header {
      background: @answer-handle-color;
    }

    &__content {
      height: 100%;
      padding: 11px 8px;
    }
  }

  &-card {
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(46, 47, 49, 0.15);
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 5px;

    &.gu-hide {
      display: none !important;
    }

    &.gu-mirror {
      position: fixed !important;
      margin: 0 !important;
      height: unset !important;

      z-index: 9999 !important;
      box-shadow: 0 15px 20px rgba(63, 101, 241, 0.3);
      cursor: grabbing;
    }

    &.gu-transit {
      height: 20px;
      padding: 0;

      background: #9bb0fb;
      border-radius: 9px;

      * {
        display: none;
      }
    }
  }
}

.kanban-wrapper {
  .kanban-sticky {
    position: sticky;
    top: -1px;
    margin-left: -20px;
    margin-right: -20px;
    height: 2px;
    z-index: 2;
    pointer-events: none;

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 43px;
      display: none;
      background: white;
    }

    &__shadow {
      height: 22px;
      background: linear-gradient(180deg, rgba(115, 128, 141, 0.15) 0%, rgba(115, 128, 141, 0) 100%);
      position: absolute;
      top: 43px;
      left: 0;
      right: 0;
      display: none;
    }

    &.isSticky {
      .kanban-sticky__shadow,
      .kanban-sticky__bg {
        display: block;
      }
    }
  }
}

.reviews-poll-group__content {
  .kanban-wrapper {
    .kanban-sticky {
      margin-top: -60px;
      padding-top: 60px;
      z-index: 3;

      &__bg {
        top: 46px;
      }
      &__shadow {
        top: 89px;
      }
    }
    .kanban-column__header {
      top: 55px;
    }
  }

  .sliding {
    .kanban-sticky {
      display: none;
    }
    .kanban-column__header {
      position: static;
    }
  }
}

.reviews-poll-group {
  z-index: 10;
  position: relative;
}
