.reviews-page {
  &--view-intersecting:not(.reviews-page--active-poll-fixed) {
    .reviews-list {
      &__header {
        th {
          border-bottom: none;
        }
        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          height: 22px;
          background: linear-gradient(
            180deg,
            rgba(115, 128, 141, 0.15) 0%,
            rgba(115, 128, 141, 0) 100%
          );
        }
      }
    }
  }

  .reviews-list {
    position: relative;
    color: @f-color-text;
    padding-left: 12px;
    padding-right: 12px;

    .outer-cell {
      padding: 0;
      width: 8px !important;
      min-width: 8px !important;
      border: none !important;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }

    &__empty {
      text-align: center;
      padding: 30px;
      color: @f-color-service;
    }

    &__header {
      position: sticky;
      top: 0;
      background: white;
      margin-left: -12px;
      padding-left: 12px;
      margin-right: -12px;
      padding-right: 12px;
      z-index: 100;
      .scroll-x,
      .ps__rail-x {
        display: none;
      }
    }

    .reviews-table {
      color: inherit;

      // tr:only-child {
      //   td {
      //     .reviews-list__cell-content {
      //       min-height: 45px;
      //     }
      //   }
      // }

      td {
        vertical-align: middle;
      }
    }

    .reviews-table--header {
      margin-bottom: 0;
    }

    &__table {
      font-size: 12px;
      font-weight: 500;
      margin-top: 24px;
      color: inherit;
      margin-left: -8px;
      padding-left: 8px;
    }

    &__row {
      transition: background-color 250ms ease-out;
      position: relative;

      .reviews-list__row-custom-link {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }

      &--error {
        background: #ffecec;
      }

      &--highlighted {
        background: @f-color-highlight;

        .reviews-list__cell--points {
          .points:after {
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              #f1f4ff 100%
            );
          }
        }
      }
      &:last-child td {
        border-bottom: 1px solid #dee2e6;
      }
    }

    &__cell-content {
      height: 100%;
      .review-check {
        position: relative;
      }
    }

    &__order,
    &__filter {
      margin-left: 5px;
    }

    &__order {
      transition: transform 250ms ease-in-out;
      &--asc {
      }
      &--desc {
        transform: rotate(-180deg);
      }
    }

    td.reviews-list__cell--check {
      .reviews-list__cell-content {
        padding-top: 6px;
      }
    }

    &__cell {
      padding: 20px 12px;
      &--check {
        padding-left: 0;
        padding-right: 0;
        .reviews-list__cell-content {
          width: 25px;
          height: 25px;
        }
      }
      &--processing {
        padding-left: 0;
        padding-right: 0;
        .reviews-list__cell-content {
          padding-left: 18px;
          padding-right: 6px;
          width: 40px;
        }
      }
      &--type {
        .reviews-list__cell-content {
          width: 25px;
        }
      }

      &--mode {
        .reviews-list__cell-content {
          width: 40px;
        }
      }

      &--channel {
        .reviews-list__cell-content {
          width: 90px;
        }
      }

      &--id {
        .reviews-list__cell-content {
          width: 75px;
        }
      }

      &--name {
        .reviews-list__cell-content {
          width: 115px;
          word-break: break-word;
        }
      }
      &--feedbackTheme {
        .reviews-list__cell-content {
          width: 250px;
          word-break: break-word;
        }
      }
      &--requestTheme {
        .reviews-list__cell-content {
          width: 250px;
          word-break: break-word;
        }
      }
      &--requestPriority {
        .reviews-list__cell-content {
          width: 130px;
          word-break: break-word;
        }
      }
      &--passedAt {
        .reviews-list__cell-content {
          width: 65px;
        }
      }
      &--answerTags {
        .reviews-list__cell-content {
          width: 200px;
          word-break: break-word;
        }
      }
      &--clientField {
        .reviews-list__cell-content {
          width: 170px;
          word-break: break-word;
        }
      }
      &--points {
        .reviews-list__cell-content {
          width: 135px;
          .only-tablet({
            width: 100px;
          });
        }
        .points {
          margin-top: -18px;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 30px;
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              #ffffff 100%
            );
          }

          .rating-point {
            flex-shrink: 0;

            .only-tablet({
              &:nth-child(n + 5) {
                display: none;
              }
            });
          }
        }
      }
      &--comments {
        .reviews-list__cell-content {
          width: 200px;
        }
      }
      &--pointsCollect {
        .reviews-list__cell-content {
          width: 120px;
        }
      }
      &--problems {
        padding: 0;
        vertical-align: middle;
        text-align: center;
        font-size: 0;
        max-width: 20px;
        .reviews-list__cell-content {
          width: 20px;
          padding-left: 4px;
        }
      }
      &--processingStatus {
        .reviews-list__cell-content {
          width: 110px;
        }
      }
      &--processingTime {
        .reviews-list__cell-content {
          width: 110px;
        }
      }
      &--clientName {
        .reviews-list__cell-content {
          width: 85px;
        }
      }
      &--clientPhone {
        .reviews-list__cell-content {
          width: 105px;
        }
      }
      &--device {
        .reviews-list__cell-content {
          width: 80px;
        }
      }
      &--lang {
        .reviews-list__cell-content {
          width: 150px;
        }
      }
      &--mailing {
        .reviews-list__cell-content {
          width: 120px;
        }
      }
      &--filial {
        .reviews-list__cell-content {
          width: 85px;
          word-break: break-word;
        }
      }
      &--executor {
        .reviews-list__cell-content {
          width: 110px;
          word-break: break-word;
        }
      }
      &--moderator {
        .reviews-list__cell-content {
          width: 110px;
          word-break: break-word;
        }
      }
      &--sourceType {
        .reviews-list__cell-content {
          width: 130px;
        }
      }
      &--orderSum {
        .reviews-list__cell-content {
          width: 120px;
        }
      }
      &--orderTime {
        .reviews-list__cell-content {
          width: 95px;
        }
      }
      &--orderType {
        .reviews-list__cell-content {
          width: 100px;
        }
      }
      &--orderAddress {
        .reviews-list__cell-content {
          width: 160px;
          word-break: break-word;
        }
      }
      &--orderNumber {
        .reviews-list__cell-content {
          width: 105px;
          word-break: break-word;
        }
      }
      &--complaint {
        .reviews-list__cell-content {
          width: 110px;
        }
      }
      &--clientEmail {
        .reviews-list__cell-content {
          width: 130px;
          word-break: break-word;
        }
      }
    }

    &__row--error {
      .reviews-list__cell--points {
        .points:after {
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 236, 236, 1) 100%
          );
        }
      }
    }

    &__head-cell {
      vertical-align: top;
      border-top: none;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      &-name {
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-weight: bold;

        .icon {
          flex-shrink: 0;
        }
      }
    }

    .review {
      &__comments-count {
        margin-left: 2px;
      }

      &-complaint {
        max-width: 110px;
        &__text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__search {
      width: 100%;
      margin-top: 4px;
      border: none;
      font-weight: 500;
      color: inherit;
      padding-left: 0;
      padding-right: 0;      
    }

    .only-mobile({
      padding-left: 0;
      padding-right: 0;
    });

    .foquz-success-message {
      position: fixed;
      top: 50%;
      left: 50%;
      bottom: unset;
      right: unset;
      transform: translate(-50%, -50%);
      border-radius: 9px;
      padding: 35px 30px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      min-width: 260px;
      color: #2E2F31;
      z-index: 1000;
      box-shadow: 0px 5px 20px 0px rgba(115, 128, 141, 0.4);
    }

    .foquz-success-message--error {
      .foquz-success-message {
        background: #FFDDDD;
        max-width: 460px;
        .only-mobile({
          max-width: 100%;
        });
      }
    }
  }
}

.reviews-mobile-table {
  position: relative;
  margin-bottom: 27px;

  .reviews-view-right {
    position: absolute;
    right: 1px;
    top: 0;
    bottom: 0;
    width: 2px;
  }

  .ps__rail-x {
    z-index: 21;
  }

  td {
    min-width: 200px;
  }

  .only-mobile({
    .ps__rail-x {
      opacity: 0;
    }
  });
}

.fixed-table {
  th,
  td {
    vertical-align: middle;
  }
}

@media screen and (max-width: 767px) {
  .group-actions {
    width: 100%;
  }

  .group-actions__container {
    flex-wrap: wrap;
    margin-top: -100%;

    .group-actions__counter {
      width: 100%;
      margin-bottom: 24px;
    }
    .group-actions__select-all {
      margin-left: 0;
      width: 100%;
      margin-bottom: 24px;
    }
    select {
      width: 100%;
    }
    .group-actions__dismiss {
      margin-left: 0;
      width: calc(50% - 5px);
    }
    .group-actions__apply {
      width: calc(50% - 5px);
      margin-left: 10px;
    }
    .select2 {
      margin-left: 0;
      width: 100% !important;
      margin-bottom: 24px;
    }
  }
}

.mobile-and-tablet({
  .reviews-page {
    .reviews-list__table {
      .ps__rail-x {
        opacity: 0;
      }
    }

  .scroll-container__scroll-gutter--left {
    left: -15px;
  }
  .scroll-container__scroll-gutter--right {
    right: -15px;
  }
  }
});
