.question-statistics__diff-statistics {
  .chart-title {
    position: absolute;
    top: 13px;
    left: 0;
    font-size: 12px;
    font-weight: 500;
    z-index: 1;
  }

  .question-statistics__diff-statistics-heatmap-chart {
    .highcharts-point, .highcharts-label {
      cursor: pointer;
    }

    .highcharts-grid-line {
      opacity: 0;
    }
    .highcharts-tick {
      transform: translateY(-18px);

      &:first-child {
        transform: translateY(-19px);
      }
    }
    .highcharts-root {
      border-bottom: 1px solid #f2f2f2;
    }
      .highcharts-plot-border {
        border-left: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
      }
  }

  .question-statistics__diff-statistics-scatter-chart {
    .highcharts-grid-line {
      opacity: 0;
    }
    .highcharts-minor-grid-line {
      &:nth-child(even) {
        opacity: 0;
      }
    }
    .highcharts-tick {
      transform: translateY(-18px);

      &:first-child {
        transform: translateY(-19px);
      }
    }
    .highcharts-root {
      border-bottom: 1px solid #f2f2f2;
    }
  }
}
