@import "./delete-modal-dialog/delete-modal-dialog";
@import "./details-modal-dialog/details-modal-dialog";

.users {

    &__section {
        padding-top: 10px;
    }

    &__content {
        &--initializing {
            visibility: hidden;
        }
    }

    &__user-avatar {
        width: 30px;
        min-width: 30px;
        max-width: 30px;
        height: 30px;
        max-height: 30px;
        min-height: 30px;
        margin-right: 11px;
        display: inline-flex;

        &_blank {
            .icon-user-blank-avatar(#CFD8DC);
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            display: inline-flex;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__user-name {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.2;
        color: #2e2f31;
    }

    &__active-icon {
        display: inline-flex;
        .icon-done(#37A74A);
    }

    &__card {
        padding: 32px 20px;
        background: #FFFFFF;
        box-shadow: 0 2px 4px #CFD8DC;
        border-radius: 9px;

        .users__card-header {
            margin-top: -32px;
            margin-left: -20px;
            margin-right: -20px;
        }

        .users__card-filters {
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    &__card-header {
        display: flex;
        padding: 18px 20px;
        align-items: center;
        justify-content: flex-end;
        border-bottom: 1px solid #ECEFF1;

        .button-add {
            padding: 0 18px;
        }
    }

    &__card-description {
        font-size: 12px;
        line-height: 120%;
        color: #73808D;
    }

    &__card-filters {
        padding: 0 20px 18px;

        border-bottom: 1px solid #ECEFF1;
    }

    &__card-filter {
        padding-top: 18px;
    }

    &__card-filters-content {
        display: flex;
        flex-wrap: wrap;
    }

    &__card-filters-actions {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
        padding-top: 18px;
    }

    &__card-filter-checkbox {
        margin-top: 14px;

        .form-check-input:checked + .form-check-label {
            font-weight: 500;
        }
    }

    &__card-filters-actions {
        .users__card-filters-apply-button {
            margin-left: 20px;
        }
    }

    &__card-filter {
        margin-right: 31px;

        &_name {
            margin-right: 27px;
        }
    }

    &__table {
        font-weight: 500;
        font-size: 12px;
        color: #2E2F31;

        td {
            padding-top: 7px;
            padding-bottom: 7px;
            font-weight: normal;
            font-size: 14px;
        }
    }

    &__table-actions-head-cell {
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 24px;

        text-align: center;
    }

    &__table-actions-cell {
        padding-left: 0 !important;
        white-space: nowrap;
        padding-right: 5px !important;
        .btn {
            margin-left: 9px;
            &:first-child {
                margin-left: 0;
            }
        }
    }

    &__table-row {
        position: relative;
    }

    &__table-phone-cell {
        white-space: nowrap;
    }

    &__table-group-actions {
        .users__table-group-actions-tags {
            margin-left: 15px;
            margin-right: 10px;
        }
    }

    &__table-active-cell {
        text-align: center;

        .foq-table__head-cell-title {
            text-align: center;
            justify-content: center;
        }
    }

    &__table-addedAt-cell {
        max-width: 98px;
        width: 98px;
    }

    &__table-complaint-fancybox-description-text {
        margin-top: 7px;
    }

    &__company-name,td&__company-name {
        font-size: 12px;
        font-weight: 500;

        a {
            text-decoration: none;
            &:hover {
                span {
                    text-decoration: underline;
                }
            }
            &:after {
                content: ', ';
                color: #2E2F31;
                text-decoration: none !important;
            }
            &:last-child:after {
                display: none;
            }
        }
    }

    &__table-edit-button {
        &:before {
            .icon-pencil(#73808D);
        }
    }

    &__table-delete-button {
        &:before {
            .icon-delete(#73808D);
        }
    }

    &__details-modal {
        padding-right: 0 !important;

        background: #FFFFFF99;
    }

    &__details-modal-dialog-wrapper {
        position: absolute;
        height: 100%;
        right: 0;
        width: calc(100% - 135px);
        margin-left: 45px;
        max-width: 1174px;
    }

    &__details-modal-dialog-wrapper {
        .users__details-modal-dialog {
            height: 100%;
            width: 100%;
        }

        .users__details-modal-dialog-wrapper-actions {
            position: absolute;
            transform: translate(calc(-100% + -12px), 11px);
            z-index: 2;
        }
    }



    &__details-modal-dialog-wrapper-actions {
        display: flex;
    }

    &__details-modal-dialog-wrapper-close-button {
        display: flex;
        width: 33px;
        height: 33px;
        align-items: center;
        justify-content: center;
        padding: 0;

        border-radius: 50%;
        box-shadow: none !important;
        border: 2px solid #73808D !important;

        &:before {
            .icon-cross3(#73808D);

            content: '';
        }
    }

}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}
.justify-content-between {
    justify-content: space-between;
}
