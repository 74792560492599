.group-actions {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  pointer-events: none;
  // overflow: hidden;

  .foq-table__group-actions {
    pointer-events: all;
  }

  &__container {
    display: inline-flex;
    align-items: center;
    padding: 18px 20px;
    position: sticky;
    top: 0;
    pointer-events: all;

    z-index: 1000;
    background: #FFFFFF;
    box-shadow: 0 5px 15px rgba(46, 47, 49, 0.3);
    border-radius: 9px;
  }

  &__counter {
    font-size: 16px;

    &-value {
      font-weight: bold;
    }
  }


  &__select-all {
    margin-left: 28px;
    padding: 15px 20px;

    background: #DADFE3;
    border-radius: 4px;
    color: #2E2F31;

    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    height: auto;
  }

  &__select {
    &.select2 {
      margin-left: 28px;
      min-width: 255px;
    }
    &.select2-container--disabled {
      .form-control {
        background-color: #F2F5F6 !important;
        border: 1px solid #CFD8DC !important;
      }
    }
  }

  &__dismiss {
    display: flex;
    width: 61px;
    height: 48px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    padding: 0;

    background: #FFDDDD;
    border-radius: 4px;

    &:before {
      .icon-plus3(#F96261);

      content: '';

      transform: rotate(45deg);
      width: 21px;
      height: 21px;
    }
  }

  &__apply {
    display: flex;
    width: 61px;
    height: 48px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    padding: 0;

    background: #ABEFB7;
    border-radius: 4px;

    &:before {
      .icon-done(#37A74A);

      content: '';

      width: 19.6px;
      height: 14px;
    }
  }
}
