.dense-tab-group {
	border-radius: 4px 4px 7px 7px;

	.nav {
		display: flex;
		flex-wrap: nowrap;
		height: 48px;

		border: 1px solid #CFD8DC;
		border-radius: 4px;
		overflow: hidden;
	}

	.nav-item {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 12px 16px;
		flex-grow: 1;

		font-size: 16px;

		cursor: pointer;
		background: #F1F5F6;
		color: #73808D;
		font-weight: 500;
		border: none;
		border-radius: 0;

		transition: all .2s cubic-bezier(.35, .9, .4, .9);

		&:hover {
			background: #DADFE3;
		}

		&:not(:first-child) {
			border-left: 1px solid #CFD8DC;
		}
	}

	.tab-content {
		margin-top: 28px;
	}
}
