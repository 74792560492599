@import "./delete-modal-dialog/delete-modal-dialog";
@import "./add-video-by-link-modal-dialog/add-video-by-link-modal-dialog";
@import "./details-modal-dialog/details-modal-dialog";


.contact-points {
    &__content {
        &--initializing {
            visibility: hidden;
        }
    }

    &__card {
        padding: 32px 20px;

        background: #FFFFFF;
        box-shadow: 0 2px 4px #CFD8DC;
        border-radius: 9px;

        .contact-points__card-header {
            margin-top: -32px;
            margin-left: -20px;
            margin-right: -20px;
        }

        .contact-points__card-filters {
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    &__card-header {
        display: flex;
        padding: 18px 20px;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid #ECEFF1;
    }

    &__card-description {
        font-size: 12px;
        line-height: 120%;
        color: #73808D;
        padding-right: 20px;
    }

    &__card-filters {
        padding: 18px 20px;

        border-bottom: 1px solid #ECEFF1;
    }

    &__card-filters-content {
        display: flex;
        margin-top: -19px;
        margin-left: -47px;
        flex-wrap: wrap;

        .contact-points__card-filter,
        .contact-points__card-filters-actions {
            margin-top: 19px;
            margin-left: 47px;
        }

        .contact-points__card-filters-actions {
            margin-left: auto;
        }

        .contact-points__card-filters-actions-spacer {
            min-width: 47px;
        }
    }

    &__card-filter-checkbox {
        margin-top: 14px;

        .form-check-input:checked + .form-check-label {
            font-weight: 500;
        }
    }

    &__card-filters-actions {
        .contact-points__card-filters-apply-button {
            margin-left: 20px;
        }
    }

    &__table {
        width: 100%;

        color: #2E2F31;

        & > tbody > tr > td {
            height: 47px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    &__table-actions-head-cell {
        padding-top: 5px !important;
        width: 98px;

        text-align: end;
    }

    &__table-actions-cell {
        text-align: end;

        .contact-points__table-action-button {
            transition: none !important;

            &:not(:first-child) {
                margin-left: 13px;
            }
        }
    }

    &__table-row {
        position: relative;

        &--has-details {
            cursor: pointer;
        }
    }

    &__table-survey-cell {
        line-height: 14px;
    }

    &__table-alias-cell {
        min-width: 185px;
    }

    &__table-alias {
        display: inline-flex;
        align-items: center;
        padding-left: 12px;

        font-weight: bold;
        color: #2E2F31;

        &--system {
            padding-left: 0;

            &:before {
                .icon-star(#9BB0FB);

                content: '';

                display: inline-block;
                margin-right: 4px;
            }
        }
    }

    &__table-survey {
        font-weight: bold;
        font-size: 12px;
    }

    &__table-condition-cell {
        max-width: 400px;

        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
    }

    &__table-edit-button {
        &:before {
            .icon-pencil(#73808D);
        }
    }

    &__table-delete-button {
        &:before {
            .icon-delete(#73808D);
        }
    }

    &__details-modal {
        padding-right: 0 !important;

        background: #FFFFFF99;

        .contact-points__details-modal-dialog-wrapper {
            position: absolute;
            height: 100%;
            right: 0;
            width: calc(100% - 135px);
            margin-left: 45px;
            max-width: 1305px;
        }
    }

    &__details-modal-dialog-wrapper {
        .contact-points__details-modal-dialog {
            height: 100%;
            width: 100%;
        }

        .contact-points__details-modal-dialog-wrapper-actions {
            position: absolute;
            transform: translate(calc(-100% + -12px), 11px);
            z-index: 2;
        }
    }

    &__details-modal-dialog-wrapper-actions {
        display: flex;
    }

    &__details-modal-dialog-wrapper-close-button {
        display: flex;
        width: 33px;
        height: 33px;
        align-items: center;
        justify-content: center;
        padding: 0;

        border-radius: 50%;
        box-shadow: none !important;
        border: 2px solid #73808D !important;

        &:before {
            .icon-cross3(#73808D);

            content: '';
        }
    }
}
