.survey-start {
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        justify-content: center;
        padding: 0 52px;
    }

    &__title {
        text-align: center;
        line-height: 1.2;

		strong {
			font-weight: 700;
		}

		em {
			font-style: italic;
		}
    }

    &__text {
        margin-top: 13px;

        font-weight: 400;
        text-align: center;
        line-height: 1.4;

		strong {
			font-weight: 900;
		}

		em {
			font-style: italic;
		}
    }

    &__start-button {
        margin-top: 25px;
        padding-left: 25px;
        padding-right: 25px;
        white-space: nowrap;
        height: 40px;
        color: #FFF !important;
    }

    &__complaint-message {
        margin-top: 28px;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
    }

    &__additional-actions {
        display: flex;
        margin-top: 28px;
        flex-direction: column;
        align-items: center;
    }

    &__content {
        .share {
            margin-top: 50px;
        }
    }

    &__complain-button {
        font-size: 13px;
        text-decoration-line: underline;
    }

    &__unsubscribe-button {
        font-size: 13px;
        text-decoration-line: underline;

        &:not(:first-child) {
            margin-top: 16px;
        }
    }
}

@media (min-width: 661px) {
    .survey-start {
        &__text {
            margin-top: 20px;

            font-size: 16px;
            line-height: 19px;
        }

        &__start-button {
            margin-top: 40px;
        }

        &__complaint-message {
            margin-top: 34px;
            // margin-bottom: -42px;

            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
        }

        &__additional-actions {
            margin-top: 54px;

            flex-direction: row;

            a {
                text-decoration: underline;
            }
        }




        &__unsubscribe-button {
            margin-top: 0px !important;

            &:not(:first-child) {
                margin-left: 20px;
            }
        }
    }
}

@media (min-width: 768px) {
    .survey-start {
        &__text {
            font-size: 18px;
            line-height: 22px;
            max-width: 700px;
        }

        &__start-button {
            margin-top: 50px;
        }

        &__additional-actions {
            margin-top: 64px;
        }




    }
}

@media (max-width: 769px) {
    .survey-start {


        &__end-button {
            margin-top: 30px;
        }

    }
}
