.user__details-modal {
    background: #FFFFFF99;
}

.users {

    &__details-modal-img-preview {
        position: relative;
        display: inline-flex;
        width: 105px;
        height: 105px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__details-modal-dialog-media-form-control {
        width: 105px;
        max-width: 105px;
        min-width: 105px;
        margin-right: 17px;

    }

    &__details-modal-dialog-media-form-control-action {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 25px 15px;
        font-size: 10px;
        width: 105px;
        height: 105px;
        background-color: #cfd8dc;
        color: #73808d;

        &:hover {
            background-color: #cfd8dc;
        }

        svg {
            flex-shrink: 0;
            margin-bottom: 8px;
        }

        &:before {
            display: block;
            position: static;
            width: 16px;
            height: 14px;
            margin-bottom: 6px;
        }
    }

    &__details-modal-dialog {
        &--initializing {
            visibility: hidden;
        }

        .users__details-modal-dialog-header {
            margin-bottom: 24px;
        }

        .users__details-modal-dialog-dish-rating-settings-selector {
            margin-top: 28px;
        }

        .users__details-modal-dialog-conditions {
            margin-top: 27px;
        }
    }

    &__details-modal-dialog-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;

        background: #FFFFFF;
        box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);

        .users__details-modal-dialog-body {
            flex-grow: 1;
        }
    }

    &__details-modal-dialog-header {
        border-bottom: none;
        margin-bottom: 34px;
        line-height: 1;
        font-weight: 900;
        font-size: 22px;
        color: #2E2F31;
    }

    &__details-modal-dialog-body {
        padding-bottom: 58px;

    }

    &__details-modal-dialog-media-type-selector {
        display: flex;

        .users__details-modal-dialog-media-type-selector-option {
            &:not(:first-child) {
                margin-left: 54px;
            }
        }
    }

    &__details-modal-dialog-media-type-selector-option {
        display: flex;
        align-items: center;

        font-weight: bold;
        font-size: 16px;
        color: #A6B1BC;
        cursor: pointer;

        transition: color 600ms cubic-bezier(0.23, 1, 0.32, 1);

        &:before {
            content: '';

            display: block;
            width: 24px;
            height: 24px;
            margin-right: 13px;

            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        &:hover {
            color: #2E2F31;
        }

        &--selected {
            color: #2E2F31;
        }
    }

    &__details-modal-dialog-media-type-selector-text-option {
        &:before {
            .icon-menu-qw-txt(#A6B1BC);
        }

        &:hover {
            &:before {
                .icon-menu-qw-txt(#2E2F31);
            }
        }

        &.users__details-modal-dialog-media-type-selector-option--selected {
            &:before {
                .icon-menu-qw-txt(#3F65F1);
            }
        }
    }

    &__details-modal-dialog-media-type-selector-image-option {
        &:before {
            .icon-menu-qw-img(#A6B1BC);
        }

        &:hover {
            &:before {
                .icon-menu-qw-img(#2E2F31);
            }
        }

        &.users__details-modal-dialog-media-type-selector-option--selected {
            &:before {
                .icon-menu-qw-img(#3F65F1);
            }
        }
    }

    &__details-modal-dialog-media-type-selector-video-option {
        &:before {
            .icon-menu-qw-video(#A6B1BC);
        }

        &:hover {
            &:before {
                .icon-menu-qw-video(#2E2F31);
            }
        }

        &.users__details-modal-dialog-media-type-selector-option--selected {
            &:before {
                .icon-menu-qw-video(#3F65F1);
            }
        }
    }

    &__details-modal-dialog-footer {
        display: flex;
        align-items: center;
    }

    &__details-modal-dialog-disclaimer {
        font-size: 12px;
        color: #F96261;
    }

    &__details-modal-dialog-actions {
        .users__details-modal-dialog-action {
            &:not(:first-child) {
                margin-left: 13px;
            }
        }
    }

    &__details-modal-dialog-type-selector-form-group {
        margin-bottom: 0;
    }

    &__details-modal-dialog-type-selector {
        .users__details-modal-dialog-type-selector-variants {
            margin-top: 20px;
        }

        .users__details-modal-dialog-type-selector-clarifying-question-selector {
            margin-top: 20px;
        }
    }

    &__details-modal-dialog-type-selector-header {
        display: flex;

        .users__details-modal-dialog-type-selector-form-group {
            width: 290px;
        }

        .users__details-modal-dialog-type-selector-rating {
            margin-top: 26px;
            margin-left: 27px;
        }
    }

    &__details-modal-dialog-type-selector-variants {
        .users__details-modal-dialog-type-selector-variants-item {
            width: 236px;
        }

        .users__details-modal-dialog-type-selector-variants-add-button,
        .users__details-modal-dialog-type-selector-variants-item {
            margin-top: 20px;
            margin-left: 20px;
        }
    }

    &__details-modal-dialog-type-selector-variants-content {
        display: flex;
        flex-wrap: wrap;
        margin-top: -20px;
        margin-left: -20px;
    }

    &__details-modal-dialog-type-selector-variants-item {
        .input-group-text {
            min-width: 48px;
            justify-content: center;

            background-color: #f1f5f6;
            border-color: #cfd8dc;
            font-size: 18px;
            font-weight: bold;
            color: #a6b1bc;
        }
    }

    &__details-modal-dialog-type-selector-variants-add-button {
        display: flex;
        align-items: center;
        height: 48px;
        border: none;
        padding: 0 64px 0 0;

        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        color: #3f65f1;
        background-color: #e9f0ff;

        .users__details-modal-dialog-type-selector-variants-add-button-icon {
            margin-right: 16px;
        }

        &[disabled] {
            opacity: 0.7;
        }
    }

    &__details-modal-dialog-type-selector-variants-add-button-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 48px;
        height: 48px;
        background-color: #3f65f1;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        &:before {
            .icon-plus4(#FFF);

            content: '';

            display: block;
        }
    }

    &__details-modal-dialog-type-selector-clarifying-question-selector {
        .users__details-modal-dialog-type-selector-clarifying-question-selector-form-group {
            margin-bottom: 0;
        }

        .users__details-modal-dialog-type-selector-clarifying-question-selector-answers {
            margin-top: 29px;
        }

        .users__details-modal-dialog-type-selector-clarifying-question-selector-custom-answer-availability-checkbox {
            margin-top: 27.6px;
        }
    }

    &__details-modal-dialog-type-selector-clarifying-question-selector-answers {
        .users__details-modal-dialog-type-selector-clarifying-question-selector-answers-item {
            width: 236px;
        }

        .users__details-modal-dialog-type-selector-clarifying-question-selector-answers-add-button,
        .users__details-modal-dialog-type-selector-clarifying-question-selector-answers-item {
            margin-top: 20px;
            margin-left: 20px;
        }
    }

    &__details-modal-dialog-type-selector-clarifying-question-selector-answers-content {
        display: flex;
        flex-wrap: wrap;
        margin-top: -20px;
        margin-left: -20px;
    }

    &__details-modal-dialog-type-selector-clarifying-question-selector-answers-item {
        .input-group-text {
            min-width: 48px;
            justify-content: center;

            background-color: #f1f5f6;
            border-color: #cfd8dc;
            font-size: 18px;
            font-weight: bold;
            color: #a6b1bc;
        }
    }

    &__details-modal-dialog-type-selector-clarifying-question-selector-answers-add-button {
        display: flex;
        align-items: center;
        height: 48px;
        border: none;
        padding: 0 64px 0 0;

        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        color: #3f65f1;
        background-color: #e9f0ff;

        .users__details-modal-dialog-type-selector-clarifying-question-selector-answers-add-button-icon {
            margin-right: 16px;
        }

        &[disabled] {
            opacity: 0.7;
        }
    }

    &__details-modal-dialog-type-selector-clarifying-question-selector-answers-add-button-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 48px;
        height: 48px;
        background-color: #3f65f1;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        &:before {
            .icon-plus4(#FFF);

            content: '';

            display: block;
        }
    }

    &__details-modal-dialog-conditions {
        border-top: 1px solid #CFD8DC;
        padding-top: 26px;
    }

    &__details-modal-dialog-conditions-header {
        display: flex;
        align-items: center;
    }

    &__details-modal-dialog-conditions-title {
        font-weight: bold;
        font-size: 19px;
        line-height: 13px;
        color: #2E2F31;
    }

    &__details-modal-dialog-conditions-count {
        font-weight: 400;
        color: #73808d;
    }

    &__details-modal-dialog-conditions-sub-title {
        margin-top: 12px;

        font-size: 12px;
        line-height: 13px;
        color: #73808D;
    }

    &__details-modal-dialog-conditions-item {
        display: flex;
        padding-top: 23px;
        position: relative;

        border-top: 1px solid #e7ebed;

        .users__details-modal-dialog-conditions-item-form-group {
            width: 255px;

            &:not(:first-child) {
                margin-left: 13px;
            }
        }

        &:nth-child(2) {
            margin-top: 19px;
        }
    }

    &__details-modal-dialog-conditions-item-remove-button {
        display: flex;
        width: 36px;
        height: 36px;
        align-items: center;
        justify-content: center;
        padding: 0;
        position: absolute;
        top: 20px;
        right: 0;

        z-index: 2;

        &:before {
            content: '';
            .icon-add(#F96261);
            transform: rotate(45deg);
        }
    }

    &__details-modal-dialog-media-form-control {
        .users__details-modal-dialog-media-form-control-list {
            margin-top: 0;
        }
    }

    &__details-modal-dialog-media-form-control-actions {
        .users__details-modal-dialog-media-form-control-actions-item {
            &:not(:first-child) {
                margin-left: 8px;
            }
        }
    }

    &__details-modal-dialog-media-form-control-list {
        .users__details-modal-dialog-media-form-control-list-item {
            margin-top: 10px;
            margin-left: 10px;
        }
    }

    &__details-modal-dialog-media-form-control-list-content {
        display: block;
    }

    &__details-modal-dialog-media-form-control-list-item {
        display: flex;
        width: 94px;
        height: 94px;
        position: relative;

        border-radius: 8px;

        &:after {
            content: '';

            position: absolute;

            top: 0;
            left: 0;
            width: 94px;
            height: 94px;

            border-radius: 8px;
            background-color: #000;
            opacity: 0.3;
            cursor: pointer;
        }

        .users__details-modal-dialog-media-form-control-list-item-preview-image {
            width: 100%;
            height: 100%;
        }

        .users__details-modal-dialog-media-form-control-list-item-label {
            position: absolute;
            top: 25.68px;
            left: 2px;
            width: 90px;
        }
    }

    &__details-modal-dialog-media-form-control-list-item-preview-image {
        border-radius: 8px;
        object-fit: cover;
    }

    &__details-modal-dialog-media-form-control-list-item-remove-button {
        position: absolute;
        width: 20px;
        height: 20px;
        top: -6px;
        right: -6px;
        border: none;
        z-index: 3;

        font-size: 11px;
        opacity: 0;
        cursor: pointer;
        background-color: #000;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        animation: fadeOutToNone 0.1s ease-out;

        & > i {
            cursor: pointer;
            color: #fff;
        }

        .users__details-modal-img-preview:hover & {
            opacity: 1;
        }
    }

    &__details-modal-dialog-media-form-control-list-item-label {
        border: none;
        width: 94px;
        z-index: 2;

        font-family: Roboto, sans-serif;
        font-size: 34px;
        font-weight: bold;
        line-height: 120%;
        color: #fff;
        background: none;
        text-align: center;
        text-transform: uppercase;
    }

    &__details-modal-dialog-dish-information-form-group {
        .users__details-modal-dialog-dish-information-form-control {
            margin-top: 10px;
        }
    }

    &__details-modal-dialog {
        .form-group__show-password + label {
            top: 14px;
        }
    }

    &__details-modal-dialog-dish-information-form-control {
        display: flex;

        .users__details-modal-dialog-dish-information-form-control-checkbox {
            &:not(:first-child) {
                margin-left: 23px;
            }
        }
    }

    &__details-modal-dialog-dish-rating-settings-selector {
        display: flex;

        .users__details-modal-dialog-dish-rating-settings-selector-min-dish-price-form-group {
            width: 290px;
        }

        .users__details-modal-dialog-dish-rating-settings-selector-checkbox {
            margin-top: 45px;
            margin-left: 31px;
        }
    }
}

@media (max-width: 1219px) {
    .contact-points {
        &__details-modal-dialog-media-form-control-list-item-remove-button {
            opacity: 1;
        }
    }
}
