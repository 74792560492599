.survey {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;

	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	font-family: 'Roboto', sans-serif;

	.survey__header {
		min-height: 45px;
		position: relative;
	}

	&__content {
		flex-grow: 1;
		position: relative;
		display: flex;
		flex-direction: column;
		z-index: 2;
		//overflow-x: hidden;
	}

	/** <Background> **/

	&__background-covering {
		position: absolute;
		top: 0px;
		right: 0;
		bottom: 0;
		left: 0;

		background: #000000;
		opacity: 0.6;
	}


	/** </Background> **/

	&__chars-counter {
		position: relative;

		.survey__chars-counter-value {
			position: absolute;
			font-size: 11px!important;
		}

		&--type_textarea {
			.survey__chars-counter-value {
				bottom: 10px;
				right: 10px;
			}
		}

		&--type_input {
			.survey__chars-counter-value {
				bottom: 18px;
				right: 10px;
			}
		}

		.form-control:focus + .survey__chars-counter-value {
			color: #73808d;
		}
	}

	&__chars-counter-value {
		font-size: 11px;
		color: #cfd8dc;
	}

	&__images-form-control {
		padding: 0;
		height: auto;

		border: none !important;

		&.form-control {
			box-shadow: none !important;
		}
	}

	&__images-form-control-content {
		display: flex;
		flex-wrap: wrap;
		margin-top: -20px;
		margin-left: -20px;

		.survey__images-form-control-item,
		.survey__images-form-control-add-button {
			width: 80px;
			height: 80px;
			margin-top: 20px;
			margin-left: 20px;
		}
	}

	&__images-form-control-item {
		display: flex;
		position: relative;

		border-radius: 8px;
		background-size: cover;
		background-position: center center;

		.survey__images-form-control-item-delete-button {
			position: absolute;
			top: -12px;
			right: -12px;
			z-index: 3;
		}
	}

	&__images-form-control-item-delete-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		padding: 0;
		z-index: 3;

		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.66);
		border: none;
		border-radius: 50%;
		background-color: #000;

		&:before {
			@background-svg: '
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.43934 11.753C-0.146446 11.1673 -0.146446 10.2175 0.43934 9.63173L9.63173 0.43934C10.2175 -0.146447 11.1673 -0.146447 11.753 0.43934C12.3388 1.02513 12.3388 1.97487 11.753 2.56066L2.56066 11.753C1.97487 12.3388 1.02513 12.3388 0.43934 11.753Z" fill="white"/>
                    <path d="M11.753 11.753C12.3388 11.1673 12.3388 10.2175 11.753 9.63173L2.56066 0.43934C1.97487 -0.146447 1.02513 -0.146447 0.43934 0.43934C-0.146447 1.02513 -0.146447 1.97487 0.43934 2.56066L9.63173 11.753C10.2175 12.3388 11.1673 12.3388 11.753 11.753Z" fill="white"/>
                </svg>
            ';

			content: '';

			width: 13px;
			height: 13px;

			@escaped-background-svg: escape(@background-svg);
			background-image: url('data:image/svg+xml,@{escaped-background-svg}');
			background-size: 100% 100%;
			background-repeat: no-repeat;
		}
	}

	&__images-form-control-add-button {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;

		background: #eceff1;
		border-radius: 8px;
		color: #73808d;
		cursor: pointer;

		font-family: Roboto, sans-serif!important;
		font-size: 10px!important;

		transition: background-color 0.2s ease;

		&:hover {
			background: #c6cfd3;
		}

		.survey__images-form-control-add-button-label {
			margin-top: 6px;
		}

		.survey__images-form-control.is-invalid & {
			border: 1px solid #ea1d27;
		}
	}

	&__images-form-control-add-button-label {
		font-weight: 500;
		font-size: 10px;
		line-height: 12px;
		text-align: center;
		color: #73808d;
	}

	&__container {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
	}



	&__unsubscribe-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-right: 21px;
		padding-left: 21px;

		.survey__unsubscribe-unsubscribe-button {
			margin-top: 28px;
		}

		.survey__unsubscribe-stay-button {
			margin-top: 24px;
		}
	}

	&__unsubscribe-text {
		font-size: 15px;
		line-height: 120%;
		text-align: center;
	}

	&__unsubscribe-text-email {
		font-weight: bold;
	}

	&__unsubscribe-unsubscribe-button {
		font-family: Roboto, sans-serif !important;
		width: 150px;

		color: #fff !important;
	}

	&__unsubscribe-stay-button {
		font-size: 13px;
		text-decoration-line: underline;
	}
}

@media (min-width: 661px) {
	.survey {


		&__images-form-control-item {
			.survey__images-form-control-item-delete-button {
				top: -9px;
				right: -9px;
			}
		}

		&__images-form-control-item-delete-button {
			width: 20px;
			height: 20px;

			&:before {
				@background-svg: '
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.78621 5.9072H1.46371C0.945466 5.9072 0.523193 5.48493 0.523193 4.96669C0.523193 4.44845 0.945466 4.02617 1.46371 4.02617H3.78621V1.58851C3.78621 0.955104 4.28525 0.456055 4.91866 0.456055C5.55207 0.456055 6.05112 0.955104 6.05112 1.58851V4.02617H8.37362C8.89186 4.02617 9.31413 4.44845 9.31413 4.96669C9.31413 5.48493 8.89186 5.9072 8.37362 5.9072H6.05112V8.34486C6.05112 8.97827 5.55207 9.47732 4.91866 9.47732C4.28525 9.47732 3.78621 8.97827 3.78621 8.34486V5.9072Z" fill="white"/>
                    </svg>
                ';

				content: '';

				width: 10px;
				height: 10px;

				@escaped-background-svg: escape(@background-svg);
				background-image: url('data:image/svg+xml,@{escaped-background-svg}');
				background-size: 100% 100%;
				background-repeat: no-repeat;
				transform: rotate(45deg);
			}
		}



		&__unsubscribe-text {
			font-size: 19px;
			line-height: 22px;
		}
	}
}
