.variants-controller {

  &__variant {
    &:first-child:last-child {
      .variants-controller__sortable-handle {
        display: none;
      }
    }

  }

  &__add-button {
    margin-bottom: 28px;

    &.variants-controller__add-button--blocked {
      opacity: 0.7;
    }
  }
}
