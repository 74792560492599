.number-control {
  width: 150px;
  position: relative;

  &--small {
    width: 120px;
  }

  .control-button {
    position: absolute;
    top: 50%;
    cursor: pointer;
    transition: all 0.2s linear 0s;
    display: flex;
    width: 20px;
    height: 20px;
    margin-top: -10px;

    &:before {
      content: '';
      display: block;
      margin: auto;
    }

    &--minus {
      left: 10px;
      &:before {
        .icon-minus(@f-color-service);
      }
    }

    &--plus {
      right: 10px;
      &:before {
        .icon-add(@f-color-service);
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  .form-control {
    text-align: center;
    padding-left: 35px;
    padding-right: 35px;
  }
}
