.matrix {
  width: 100%;
  position: relative;
  overflow: hidden;

  .matrix__drag-indicator {
    cursor: grab;
    color: @f-color-light;
  }

  &__rows {
    position: absolute;
    top: 0;
    left: 0;
    width: 225px;

    .matrix-row {
      display: flex;
      align-items: center;
      padding: 5px;

      border-radius: 4px 0 0 4px;

      .matrix__drag-indicator {
        margin-right: 12px;
        cursor: grab;
        flex-shrink: 0;
      }

      .matrix__name-field {
        flex-grow: 1;

        input {
          padding: 0px 12px;
        }
      }
    }
  }

  &__delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;

    .matrix-row {
      height: 60px;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 4px 4px 0;
    }
  }

  &-header {
    height: 90px;
  }

  &.matrix--has-column-error {
    .matrix-header {
      height: 105px;
    }
  }

  &-row {
    height: 60px;
  }

  &.matrix--has-row-error {
    .matrix-row {
      height: 80px;
    }
  }

  &__name-field {
    position: relative;
    
    input {
      width: 100%;
      height: 48px;
      padding: 0 12px;
      min-width: 56px;
      max-width: 200px;
    }
  }

  &__table {
    width: calc(100% - 255px);
    margin-left: 225px;
    margin-right: 30px;
  }

  &-table {
    width: 100%;
    height: auto!important;

    table {
      min-width: 100%;
      margin-bottom: 20px;
    }

    .matrix__name-field {
      input {
        min-width: 100%;
        max-width: 200px;
        padding: 0 12px!important;
      }
    }

    th {
      min-width: 100px;
      position: relative;
      border-radius: 4px 4px 0 0;
    }

    tr:last-child {
      td {
        border-radius: 0 0 4px 4px;
      }
    }

    .form-error {
      white-space: nowrap;
    }

    td {
      vertical-align: middle;
      text-align: center;
    }

    .matrix__drag-indicator {
      text-align: center;
      margin-bottom: 5px;
      cursor: grab;
      color: @f-color-light;
      i {
        transform: rotate(90deg);
      }
    }

    .matrix-header {
      min-width: 56px;
      padding: 0 5px;
    }
  }

  .matrix-column {
    &-cell--dragging {
      opacity: 0.5;
      background: #d9e0fc;
    }
    &-header--dragging {
      opacity: 0.5;
      background: #d9e0fc;
    }
  }

  .matrix-row {
    &--dragging {
      opacity: 0.5;
      background: #d9e0fc;
    }
    &-header--dragging {
      opacity: 0.5;
      background: #d9e0fc;
    }
    &-delete--dragging {
      opacity: 0.5;
      background: #d9e0fc;
    }
  }

  .selected {
    background: #f1f5f6;

    .f-icon {
      color: #73808d;
    }
  }

  .matrix-header__sort {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-left: 12px;
    padding-top: 42px;
    
    > .fc-btn {
      margin-right: 10px;

      .fc-btn-b__label {
        font-size: 13px;
        font-weight: 500;
        line-height: 14px;
        color: #3F65F1;
      }
    }

    svg {
      color: #73808d;
    }

    &:hover {
      svg {
        color: #2E2F31;
      }
    }
  }
}

.matrix-ghost {
  background: white;
  box-shadow: 0px 6px 15px rgba(63, 101, 241, 0.3);

  table {
    td {
      vertical-align: middle;
      text-align: center;
    }
  }
}

.matrix-ghost-row {
  display: flex;

  &__header {
    width: 220px;
    padding: 5px;
    display: flex;
    align-items: center;

    .matrix__drag-indicator {
      margin-right: 12px;
      flex-shrink: 0;
    }
  }
}
