.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  padding: 3px 4.63px;
  background: #3F65F1;
  border-radius: 10px;
  font-weight: 900;
  font-size: 11px;
  color: #FFFFFF;
  cursor: help;

  &-active {
    background: #3F65F1;
  }

  &-inactive {
    background: #A6B1BC;
  }
}
