.reviews-page {
  &-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .only-mobile({
      flex-direction: column;

      .fc-dropdown-btn {
        width: 100%;
        margin-left: 0!important;

        &__button {
          flex-grow: 1;

          .fc-btn-b {
            width: 100%;
          }
        }
      }
    });
  }
}
