.clients {
    &__clients-loading-complete-modal-dialog {
        width: 460px !important;

        &--initializing {
            visibility: hidden;
        }

        p {
            margin-bottom: 10px;
        }

        b {
            font-weight: bold;
        }
    }
}
