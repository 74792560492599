.strong {
    font-weight: bold;
}


.mailing-conditions {
    &--initializing {
        visibility: hidden;
    }

    &__card {
        padding: 0px 22px 0px 22px;

        .tab-group__tab-pane-footer {
            padding: 21px 22px;
            margin: 0 -22px -20px;
            border-radius: 0 0 9px 9px;
        }

    }

    &__body {
        min-height: 280px;
    }

    &__note {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 13px;
        color: #73808D;
        margin-bottom: 29px;
    }

    &__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        &_bottom {
            margin-bottom: 54px;
            margin-top: 16px;
            justify-content: flex-end;
        }

        .btn-with-icon {
            transform: translateY(-2px);
        }

        .text {
            font-style: normal;
            font-weight: bold;
            font-size: 19px;
            line-height: 1.1;
            color: #2E2F31;

            span {
                font-weight: normal;
            }
        }
    }
}
