@import "comments-modal-dialog/comments-modal-dialog";
@import "clients-modal-dialog/clients-modal-dialog";
@import "text-answers-modal-dialog/text-answers-modal-dialog";
@import "priority-modal-dialog/priority-modal-dialog";
@import "gallery-modal-dialog/index";
@import "addresses-modal-dialog/addresses-modal-dialog";
@import "files-modal-dialog/files-modal-dialog";
@import "profiles-modal-dialog/profiles-modal-dialog";
@import "./rating/rating";
@import "rating-statistics-table/rating-statistics-table";
@import "./variant-statistics/variant-statistics";
@import "./priority-statistics/priority-statistics";
@import "./diff.less";

.question-statistics__service-text {
  font-weight: normal;
  font-size: 12px;
  color: #73808d;
}

.table.gallery-rating.nps-variants {
  max-width: 945px;
}

.question-statistics {
  &__gallery-statistics {
    table {
      margin-top: 20px;
      font-size: 14px;
      max-width: 722px;
      &.star-variants {
        max-width: 945px;
        .star-variants-average {
          color:#2E2F31;
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 110%; /* 15.4px */
          background: #F2F5F6;
          td {
            border-top: none;
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }

      th {
        font-size: 12px;
        font-weight: bold;
        border-top: none;
      }

      td {
        vertical-align: middle;
        padding-top: 5px;
        padding-bottom: 5px;
      }

      tr:last-child {
        td {
          border-bottom: 1px solid #dee2e6;
        }
      }
    }

    .question-statistics__rating {
      .question-statistics__rating-star {
        width: 17.94px;
        height: 17.77px;

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

    }


    .question-statistics__question-average-rating-value {
      font-size: 14px;
    }
  }


  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 427px;

    .text {
      font-size: 16px;
      line-height: 1.5;
      padding-right: 0;
      text-align: center;
      transform: translate(0, -20px);
      display: flex;
      align-items: center;
      justify-content: center;

      ul {

        color: #2e2f31;
        margin-bottom: 25px;

        li {
          font-size: 15px;
          line-height: 1.3;
          margin-bottom: 7px;
          padding-left: 24px;
          position: relative;

          &:before {
            content: '';
            width: 6px;
            height: 6px;
            position: absolute;
            left: 8px;
            top: 7px;
            background-color: #cfd8dc;
            border-radius: 50%;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

      }

      p, .item {
        margin-bottom: 14px;

        &.first-item {
          margin-bottom: 28px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .title {
        font-weight: 900;
        font-size: 22px;
        line-height: 1;
        text-align: center;
        color: #2E2F31;
        margin-bottom: 13px;
      }

    }

    &_large {
      .text {
        font-size: 16px;
        transform: translate(2px, -32px);
      }

      .title {
        transform: translateX(2px);
      }
    }
  }

  &__summary {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-top: -32px;
    margin-left: -20px;
    border-bottom: 1px solid #ECEFF1;
    margin-bottom: 32px;
  }

  &__summary-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    padding: 13px 22px 10px;
    border-right: 1px solid #ECEFF1;
  }

  &__time {
    display: flex;

    &-block {
      display: flex;
      align-items: center;
      margin-left: 24px;
      white-space: nowrap;

    }

    &-icon {
      margin-right: 15px;
    }
  }

  &__filters {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .i-clear {
      .icon-plus3(#F96261) !important;
      cursor: pointer;

      width: 12px !important;
      height: 12px !important;
      order: 1;
      position: absolute;
      top: 50%;
      margin-top: -6px;
      right: 53px;

      transform: rotate(45deg);
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    .position-relative {
      margin-right: 27px;
    }

    .form-error {
      position: absolute;
      margin: 0;
      top: calc(100% + 5px);
      left: 0;
      padding-left: 0 !important;
      background-image: none !important;
      min-width: 290px;
    }

    .form-control {

      width: 275px;
      .icon-calendar(#A6B1BC);
      background-size: 24px 24px;
      background-position: calc(100% - 16px) 50%;
      background-repeat: no-repeat;
      z-index: 3;
      padding-right: 71px;

      &.is-invalid {
        background: initial !important;
        .icon-calendar-important(#A6B1BC);
        background-repeat: no-repeat !important;
        background-size: 24px 24px !important;
        background-position: calc(100% - 16px) 50% !important;
      }

    }
  }

  &__summary-send-item {
    .question-statistics__summary-item-value {
      color: #3A5CDC;
    }
  }

  &__summary-open-item {
    .question-statistics__summary-item-value {
      color: #8400ff;
    }
  }

  &__summary-process-item {
    .question-statistics__summary-item-value {
      color: #2d99ff;
    }
  }

  &__summary-filled-item {
    .question-statistics__summary-item-value {
      color: #16ceb9;
    }
  }

  &__summary-target-item {
    .question-statistics__summary-item-value {
      .icon-target(#CAAD46);
      transform: translateY(-4px);
    }
  }

  &__summary-item-value {
    font-weight: bold;
    font-size: 19px;
  }

  &__summary-item-label {
    margin-top: 1px;

    font-weight: 500;
    font-size: 12px;
    color: #73808D;
  }


  &__content {

    min-width: 870px;

    &--initializing {
      visibility: hidden;
    }

    .question-statistics__menu {
      margin-top: 20px;
    }

  }

  &__header-edit-button {
    margin-left: 13px;
    flex-shrink: 0;

    &:before {
      .icon-pencil(#73808d);

      content: '';
    }
  }

  &__header-copy-button {
    margin-left: 10px;
    flex-shrink: 0;

    &:before {
      .icon-link3(#73808d);

      content: '';
    }
  }


  &__loader {
    min-height: calc(50vh - (48px + 32px + 61px + 32px));
    display: flex;
    align-items: center;
    justify-content: center;

    .pages-loader {
      margin-top: 0;
    }
  }

  &__questions {
    .question-statistics__question {
      &:not(:last-child) {
        border-bottom: 1px solid #E7EBED;
      }
    }
  }

  &__question {
    padding: 32px 20px 32px 50px;
    position: relative;

    .question-statistics__question-header {
      margin-left: -28px;
    }

    .question-statistics__question-number {
      display: inline-block;
      width: 28px;
    }

    .question-statistics__question-short-name {
      display: inline-block;
    }

    .question-statistics__question-name {
      margin-top: 13px;
    }

    .question-statistics__question-text {
      margin-top: 6px;
    }

    .question-statistics__question-average-rating-wrapper {
      margin-top: 20px;
    }


    .question-statistics__question-clarifying-question {
      margin-top: 28px;

      &-for-all-rates {
        font-size: 12px;
        font-weight: normal;
        color: #73808D;
      }
    }

    .question-statistics__question-variant-statistics {
      margin-top: 18px;

      .only-tablet({
        .points-block + & {
          margin-top: 8px;
        }
      });
    }

    .question-statistics__question-assessment-variants-variant-statistics {
      margin-top: 15px;
    }

    .question-statistics__question-media-wrapper {
      margin-top: 20px;
    }

    .question-statistics__question-dish-ratings-statistics-header {
      margin-top: 24px;
    }

    .question-statistics__question-additional-button {
      margin-top: 20px;
      
      &.question-statistics__question-additional-button--matrix-3d {
        border-top: 1px solid #E7EBED;
        margin-top: 0;
        padding-top: 25px;
      }
    }

    .question-statistics__question-chart {
      margin-top: 30px;
      height: 300px;
    }
  }

  &__question-header {
    display: flex;
    align-items: center;
  }

  &__question-body {
    .question-statistics__question--disabled & {
      opacity: 0.6;
    }
  }

  &__question-number {
    font-weight: 900;
    color: #73808D;

    .question-statistics__question--disabled & {
      opacity: 0.6;
    }
  }

  &__question-short-name {
    font-weight: bold;
    color: #73808D;

    .question-statistics__question--disabled & {
      opacity: 0.6;
    }
  }

  &__question-deletion-indicator {
    color: #F96261;
    font-weight: bold;
    font-size: 14px;
    line-height: 13px;
  }

  &__question-name {
    font-weight: 500;
    font-size: 19px;
    color: #2E2F31;
  }

  &__question-text {
    font-size: 16px;
    color: #73808D;
  }

  &__question-average-rating-wrapper {
    display: inline-flex;
    align-items: center;

    cursor: pointer;

    .question-statistics__question-average-rating-value {
      margin-left: 18px;
    }
  }

  &__question-average-rating {
    .question-statistics__question-average-rating-star {
      width: 27.26px;
      height: 27px;

      &:not(:first-child) {
        margin-left: 16px;
      }
    }
  }

  &__question-average-rating-value {
    font-weight: 900;
    font-size: 19px;
    color: #3F65F1;
  }

  &__question-average-rating-statistics-dropdown-menu {
    transform: translate3d(calc(-50% + 15px + 246px / 2), 28px, 0px) !important;
    padding: 30px 30px 28px !important;
  }

  &__question-clarifying-question {
    font-weight: bold;
    font-size: 16px;
    color: #2E2F31;
  }

  &__question-statistics-legend-table-custom-row {
    .question-statistics__variant-statistics-legend-table-text-cell {
      color: #3F65F1;
    }
  }

  &__question-media-wrapper {
    width: 293.33px;
    height: 220px;

    border-radius: 8px;
    overflow: hidden;
  }

  &__question-image {
    width: 100%;
    height: 100%;
    object-fit: contain;

    cursor: pointer;
  }

  &__question-video {
    width: 100%;
    height: 100%;
    position: relative;

    cursor: pointer;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &__question-video-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__question-video-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: black;
    opacity: 0.5;
  }

  &__question-video-play-button {
    .icon-video-play-button(#FFF);

    position: absolute;
    top: calc((100% - 50px) / 2);
    left: calc((100% - 72px) / 2);

    opacity: 0.7;
  }

  &__question-slider {
    width: 100%;
    height: 100%;
  }

  &__question-dish-ratings-statistics {
    .question-statistics__question-dish-ratings-statistics-table-container {
      width: 100%;
      max-width: 738px;
      margin-top: 8px;
    }
  }

  &__question-dish-ratings-statistics-header {
    display: flex;

    .question-statistics__question-dish-ratings-statistics-search-form {
      width: 100%;
      max-width: 325px;
      margin-left: 40px;
    }
  }

  &__question-dish-ratings-statistics-filter {
    .hat-radio-group {
      height: 48px;
    }

    .question-statistics__question-dish-ratings-statistics-filter-icon {
      margin-right: 10px;
    }
  }

  &__question-dish-ratings-statistics-filter-best-icon {
    .icon-best(#73808D);
    flex-shrink: 0;

    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
      .icon-best(#37A74A);
    }
  }

  &__question-dish-ratings-statistics-filter-worst-icon {
    .icon-worst(#73808D);
    flex-shrink: 0;

    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label & {
      .icon-worst(#F96261);
    }
  }

  &__question-dish-ratings-statistics-search-form {
    position: relative;
  }

  &__question-dish-ratings-statistics-search-form-icon {
    .icon-search(#73808D);

    display: inline-block;
    position: absolute;
    left: 15px;
    top: 16px;
    margin-right: 11px;
  }

  &__question-dish-ratings-statistics-search-form-control {
    padding-left: 46px;
  }

  &__question-dish-ratings-statistics-search-form-clear-button {
    .icon-plus3(#2E2F31) !important;

    position: absolute;
    right: 13px;
    top: calc(50% - (17px / 2));
    padding: 0;
    transform: rotate(45deg);

    border-radius: 0;
    box-shadow: none !important;

    transition: none;
  }

  &__question-dish-ratings-statistics-table-container {
    position: relative;

    &:after {
      content: '';

      position: absolute;
      width: 100%;
      height: 30px;
      bottom: 0;

      background: linear-gradient(0, #FFFFFF 18.23%, rgba(255, 255, 255, 0) 100%);
    }

    .question-statistics__question-dish-ratings-statistics-table-wrapper {
      max-height: 340px;
      width: 100% !important;
    }
  }

  &__question-dish-ratings-statistics-table-wrapper {
    display: flex;

    .scroll-element.scroll-y {
      right: 0;
    }
  }

  &__question-dish-ratings-statistics-table {
    width: 100%;
    margin-right: 19px;
    margin-bottom: 30px;

    td {
      height: 33px;

      padding: 5px 17px;

      vertical-align: middle;
      border-bottom: 1px solid #E7EBED;
    }
  }

  &__question-dish-ratings-statistics-table-row {
    cursor: pointer;
  }

  &__question-dish-ratings-statistics-table-name-cell {
    font-weight: bold;
    font-size: 14px;
    color: #2E2F31;
  }

  &__question-dish-ratings-statistics-table-quantity-cell {
    text-align: right;
  }

  &__question-dish-ratings-statistics-table-rating-cell {
    width: 134px;
    padding-left: 13px;
  }

  &__question-dish-ratings-statistics-table-average-rating-wrapper {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    .question-statistics__question-dish-ratings-statistics-table-average-rating-value {
      margin-left: 18px;
    }
  }

  &__question-dish-ratings-statistics-table-average-rating {
    .question-statistics__question-dish-ratings-statistics-table-average-rating-star {
      width: 17.94px;
      height: 17.77px;

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  &__question-dish-ratings-statistics-table-average-rating-value {
    font-weight: 900;
    font-size: 14px;
    line-height: 13px;
    color: #3F65F1;
  }

  &__question-dish-ratings-statistics-table-average-rating-statistics-dropdown-menu {
    transform: translate3d(calc(-50% + 15px + 246px / 2), 28px, 0px) !important;
    padding: 30px 30px 28px !important;
  }

  &__question-dish-ratings-statistics-table-vote-count-cell {
    padding-right: 8px !important;

    color: #73808D;
    text-align: end;
  }

  &__question-dish-ratings-statistics-table-average-rating-cell {
    width: 168.2px;
    padding-left: 8px !important;
  }

  &__question-dish-ratings-statistics-table-average-rating-cell-content {
    display: flex;
    justify-content: flex-end;
  }

  &__question-dish-ratings-statistics-average {
    margin-top: 20px;
  }

  &__question-additional-button {
    display: flex;
    align-items: center;

    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    color: #3F65F1 !important;

    &:before {
      content: '';

      margin-right: 16px;
      width: 16px;
      height: 16px;
    }
  }

  &__question-dish-ratings-statistics-comments-button {
    &:before {
      display: inline-block;
      .icon-comment2(#73808D);
    }
  }

  &__question-all-answers-button {
    &:before {
      display: inline-block;
      .icon-comment2(#73808D);

    }
  }

  &__question-all-addresses-button {
    &:before {
      display: inline-block;
      .icon-pin(#73808D);
    }
  }

  &__question-all-files-button {
    &:before {
      display: inline-block;
      .icon-attachment(#73808D);
    }
  }

  &__question-all-profiles-button {
    &:before {
      display: inline-block;
      .icon-comment2(#73808D);
    }
  }


  &__clients-modal {
    padding-right: 0 !important;

    background: #FFFFFF99;

    .question-statistics__clients-modal-dialog-wrapper {
      position: absolute;
      height: 100%;
      right: 0;
      width: calc(100% - 45px);
      margin-left: 45px;
      max-width: 1192px;
    }
  }

  &__clients-modal-dialog-wrapper {
    .question-statistics__clients-modal-dialog {
      height: 100%;
      width: 100%;
    }

    .question-statistics__clients-modal-dialog-wrapper-actions {
      position: absolute;
      transform: translate(calc(-100% + -12px), 11px);
      z-index: 2;
    }
  }

  &__clients-modal-dialog-wrapper-actions {
    display: flex;
  }

  &__clients-modal-dialog-wrapper-close-button {
    display: flex;
    width: 33px;
    height: 33px;
    align-items: center;
    justify-content: center;
    padding: 0;

    border-radius: 50%;
    box-shadow: none !important;
    border: 2px solid #73808D !important;

    &:before {
      .icon-cross3(#73808D);

      content: '';
    }
  }

  &__comments-modal {
    padding-right: 0 !important;

    background: #FFFFFF99;

    .question-statistics__comments-modal-dialog-wrapper {
      position: absolute;
      height: 100%;
      right: 0;
      width: calc(100% - 45px);
      margin-left: 45px;
      max-width: 1192px;
    }
  }

  &__comments-modal-dialog-wrapper {
    .question-statistics__comments-modal-dialog {
      height: 100%;
      width: 100%;
    }

    .question-statistics__comments-modal-dialog-wrapper-actions {
      position: absolute;
      transform: translate(calc(-100% + -12px), 11px);
      z-index: 2;
    }
  }

  &__comments-modal-dialog-wrapper-actions {
    display: flex;
  }

  &__comments-modal-dialog-wrapper-close-button {
    display: flex;
    width: 33px;
    height: 33px;
    align-items: center;
    justify-content: center;
    padding: 0;

    border-radius: 50%;
    box-shadow: none !important;
    border: 2px solid #73808D !important;

    &:before {
      .icon-cross3(#73808D);

      content: '';
    }
  }

  &__text-answers-modal {
    padding-right: 0 !important;

    background: #FFFFFF99;

    .question-statistics__text-answers-modal-dialog-wrapper {
      position: absolute;
      height: 100%;
      right: 0;
      width: calc(100% - 45px);
      margin-left: 45px;
      max-width: 1192px;
    }
  }

  &__text-answers-modal-dialog-wrapper {
    .question-statistics__text-answers-modal-dialog {
      height: 100%;
      width: 100%;
    }

    .question-statistics__text-answers-modal-dialog-wrapper-actions {
      position: absolute;
      transform: translate(calc(-100% + -12px), 11px);
      z-index: 2;
    }
  }

  &__text-answers-modal-dialog-wrapper-actions {
    display: flex;
  }

  &__text-answers-modal-dialog-wrapper-close-button {
    display: flex;
    width: 33px;
    height: 33px;
    align-items: center;
    justify-content: center;
    padding: 0;

    border-radius: 50%;
    box-shadow: none !important;
    border: 2px solid #73808D !important;

    &:before {
      .icon-cross3(#73808D);

      content: '';
    }
  }

  &__priority-modal {
    padding-right: 0 !important;

    background: #FFFFFF99;

    .question-statistics__priority-modal-dialog-wrapper {
      position: absolute;
      height: 100%;
      right: 0;
      width: calc(100% - 45px);
      margin-left: 45px;
      max-width: 1192px;
    }
  }

  &__priority-modal-dialog-wrapper {
    .question-statistics__priority-modal-dialog {
      height: 100%;
      width: 100%;
    }

    .question-statistics__priority-modal-dialog-wrapper-actions {
      position: absolute;
      transform: translate(calc(-100% + -12px), 11px);
      z-index: 2;
    }
  }

  &__priority-modal-dialog-wrapper-actions {
    display: flex;
  }

  &__priority-modal-dialog-wrapper-close-button {
    display: flex;
    width: 33px;
    height: 33px;
    align-items: center;
    justify-content: center;
    padding: 0;

    border-radius: 50%;
    box-shadow: none !important;
    border: 2px solid #73808D !important;

    &:before {
      .icon-cross3(#73808D);

      content: '';
    }
  }

  &__addresses-modal {
    padding-right: 0 !important;

    background: #FFFFFF99;

    .question-statistics__addresses-modal-dialog-wrapper {
      position: absolute;
      height: 100%;
      right: 0;
      width: calc(100% - 45px);
      margin-left: 45px;
      max-width: 1192px;
    }
  }

  &__addresses-modal-dialog-wrapper {
    .question-statistics__addresses-modal-dialog {
      height: 100%;
      width: 100%;
    }

    .question-statistics__addresses-modal-dialog-wrapper-actions {
      position: absolute;
      transform: translate(calc(-100% + -12px), 11px);
      z-index: 2;
    }
  }

  &__addresses-modal-dialog-wrapper-actions {
    display: flex;
  }

  &__addresses-modal-dialog-wrapper-close-button {
    display: flex;
    width: 33px;
    height: 33px;
    align-items: center;
    justify-content: center;
    padding: 0;

    border-radius: 50%;
    box-shadow: none !important;
    border: 2px solid #73808D !important;

    &:before {
      .icon-cross3(#73808D);

      content: '';
    }
  }

  &__files-modal {
    padding-right: 0 !important;

    background: #FFFFFF99;

    .question-statistics__files-modal-dialog-wrapper {
      position: absolute;
      height: 100%;
      right: 0;
      width: calc(100% - 45px);
      margin-left: 45px;
      max-width: 1192px;
    }
  }

  &__files-modal-dialog-wrapper {
    .question-statistics__files-modal-dialog {
      height: 100%;
      width: 100%;
    }

    .question-statistics__files-modal-dialog-wrapper-actions {
      position: absolute;
      transform: translate(calc(-100% + -12px), 11px);
      z-index: 2;
    }
  }

  &__files-modal-dialog-wrapper-actions {
    display: flex;
  }

  &__files-modal-dialog-wrapper-close-button {
    display: flex;
    width: 33px;
    height: 33px;
    align-items: center;
    justify-content: center;
    padding: 0;

    border-radius: 50%;
    box-shadow: none !important;
    border: 2px solid #73808D !important;

    &:before {
      .icon-cross3(#73808D);

      content: '';
    }
  }

  &__profiles-modal {
    padding-right: 0 !important;

    background: #FFFFFF99;

    .question-statistics__profiles-modal-dialog-wrapper {
      position: absolute;
      height: 100%;
      right: 0;
      width: calc(100% - 45px);
      margin-left: 45px;
      max-width: 1192px;
    }
  }

  &__profiles-modal-dialog-wrapper {
    .question-statistics__profiles-modal-dialog {
      height: 100%;
      width: 100%;
    }

    .question-statistics__profiles-modal-dialog-wrapper-actions {
      position: absolute;
      transform: translate(calc(-100% + -12px), 11px);
      z-index: 2;
    }
  }

  &__profiles-modal-dialog-wrapper-actions {
    display: flex;
  }

  &__profiles-modal-dialog-wrapper-close-button {
    display: flex;
    width: 33px;
    height: 33px;
    align-items: center;
    justify-content: center;
    padding: 0;

    border-radius: 50%;
    box-shadow: none !important;
    border: 2px solid #73808D !important;

    &:before {
      .icon-cross3(#73808D);

      content: '';
    }
  }

  &__rating-statisitcs-popover {
    padding: 30px;
    max-width: unset;

    border-radius: 9px;

    .popover-body {
      padding: 0;
    }

    &:before {
      .popover-arrow(#FFF);

      content: '';

      position: absolute;
      top: -5.5px;
      left: calc(50% - 22px / 2);
    }

    &.bs-popover-top {
      &:before {
        top: unset;
        bottom: -5.5px;
        transform: rotate(180deg);
      }
    }
  }


  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  &__no-data-container {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    :first-child {
      margin-right: 50px;
      @media screen and (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }
}


@media screen and (max-width: 1450px) {
  .question-statistics__filters {
    flex-wrap: wrap;
  }
}
