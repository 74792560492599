.dropdown {
  .dropdown-menu {
    position: relative;
    top: 11px !important;
    padding: 12px 0;

    border: none;
    background: #ffffff;
    border-radius: 9px;
    box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);

    &:before {
      content: "";
      .popover-arrow(#FFF);

      position: absolute;
      top: -5px;
      right: 18px;
    }
  }

  .dropdown-hr {
    margin: 10px 0 10px 23px;
    width: calc(100% - 46px);
    background: rgba(207, 216, 220, 0.488168);
  }

  .dropdown-auto-pool-not-connected-text {
    font-family: "Roboto", sans-serif;
    margin-left: 23px;
    font-weight: 400;
    font-size: 12px;
    line-height: 250%;
    color: #73808d;
  }

  .dropdown-item {
    align-items: center;
    padding: 7px 23px;

    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #3f65f1 !important;
    font-size: 14px;
    cursor: pointer;

    //white-space: normal;

    &:not(:first-child) {
      margin-top: 4px;
    }

    &.active {
      background-color: transparent;
      color: #2e2f31 !important;
    }

    &:hover {
      background: #f1f5f6;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

.dropdown-default {
  .dropdown-menu {
    border: none;
    //box-shadow: 0 3px 10px 0 #cfd8dc;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    min-width: 84px;
    margin-top: 33px;
    background-color: #fff;
    padding-top: 14px;
    padding-bottom: 14px;

    &:before {
      content: "";
      .icon-angle(#fff);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 0 0;
      position: absolute;
      display: block;
      left: calc(~"50% - 32px");
      top: -12px;
      width: 61px;
      height: 15px;
    }

    .dropdown-item {
      display: block;
      padding: 0 30px 0 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.79;
      color: #7d8995;

      &:active,
      &:hover {
        color: #3f65f1;
        background-color: #fff;
      }
    }
  }
  @media (max-width: 1024px) {
    .dropdown-menu {
      padding-bottom: 9px;
      .dropdown-item {
        font-size: 10px;
        line-height: 1.6;
        padding: 0 30px 0 15px;
      }
    }
  }
}

.dropdown.show {
  > .dropdown-menu-center {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

.dropdown-item-description {
  display: block;
  margin-top: 5px;

  white-space: normal;
  font-size: 12px;
  color: #73808d;
}

.popper-dropdown {
  border: none;
  background: #ffffff;
  border-radius: 9px;
  box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);

  &:before {
    content: "";
    .popover-arrow(#FFF);

    position: absolute;
    top: -5px;
    right: 18px;
  }
}

.fc-dropdown__hr {
  margin: 15px 0 !important;
}
