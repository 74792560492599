.survey {
  &__complaint-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-grow: 1;
		padding: 15px 10px;
		width: 100%;

		.survey__complaint-card {
			width: 100%;
			max-width: 648px;
		}

		.survey__complaint-actions {
			margin-top: 20px;
		}
	}

	&__complaint-card {
		padding: 30px 20px;

		border-radius: 9px;
		overflow: hidden;

		.survey__complaint-card-content {
			max-height: calc(100vh - 44px - 30px - 20px - 46px - 60px);
		}
	}

	&__complaint-card-content {
		&.scroll-wrapper {
			margin-right: -12px;
		}

		&.scroll-content {
			margin-right: 12px !important;
		}

		.scroll-element.scroll-y {
			right: 0;
		}

		.survey__complaint-error {
			display: block;
			margin-top: 12px;
			font-size: var(--poll-error-font-size);
		}

		.survey__complaint-text-form-group {
			margin-top: 18px;
			margin-bottom: 22px;

			.form-label {
				font-size: 14px;
				font-weight: 700;
			}
		}

		.survey__complaint-images-form-group {
			margin-bottom: 0;
		}
	}

	&__complaint-title {
		font-weight: 700!important;
		font-size: 19px!important;
	}

	&__complaint-error {
		font-size: 13px;
		line-height: 13px;
		color: #ea1d27;
	}

	&__complaint-actions {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 12px;
		padding-right: 12px;

		.survey__complaint-actions-button {
			width: 140px;

			&:not(:first-child) {
				margin-left: 12px;
			}
		}
	}

	&__complaint-actions-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 140px;
		height: 46px;
		padding: 0;

		font-family: Roboto, sans-serif !important;
		background: none;
		border-width: 2px;
		border-style: solid !important;
		border-color: currentColor;
		border-radius: 100px;
		font-weight: bold;
		font-size: 16px;

		&.focus,
		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}

	&__complaint-end-content {
		.survey__complaint-end-text {
			margin-top: 11px;
		}
	}

	&__complaint-end-title {
		font-weight: 900;
		text-align: center;
	}

	&__complaint-end-text {
		font-size: 13px;
		line-height: 17px;
		text-align: center;
	}
}

@media (min-width: 661px) {
  .survey {
    &__complaint-content {
			flex-grow: unset;

			.survey__complaint-actions {
				margin-top: 40px;
			}
		}

		&__complaint-images-form-control {
			.survey__images-form-control-content {
				display: flex;
				flex-wrap: wrap;
				margin-top: -15px;
				margin-left: -15px;

				.survey__images-form-control-item,
				.survey__images-form-control-add-button {
					width: 105px;
					height: 105px;
					margin-top: 15px;
					margin-left: 15px;
				}
			}
    }

    &__complaint-card {
			padding: 38px 32px;

			.survey__complaint-card-content {
				max-height: calc(407px - 60px);
			}
		}

		&__complaint-card-content {
			.survey__complaint-error {
				display: block;
				margin-top: 20px;
			}

			.survey__complaint-text-form-group {
				margin-top: 24px;
				margin-bottom: 26px;
			}

			.survey__complaint-images-form-group {
				margin-bottom: 0;
			}
		}

		&__complaint-end-content {
			.survey__complaint-end-text {
				margin-top: 20px;
			}
		}

		&__complaint-end-text {
			font-size: 16px;
			line-height: 19px;
		}
  }
}

@media (min-width: 768px) {
	.survey {
		&__complaint-end-content {
			.survey__complaint-end-text {
				margin-top: 25px;
			}
		}

		&__complaint-end-text {
			font-size: 18px;
			line-height: 22px;
		}
	}
}
