@import "./_poll-tab-info.less";
@import "./_poll-tab-question-view.less";
@import "./_poll-tab-complaint.less";
@import "./_poll-tab-processing.less";
@import "./_poll-tab-footer.less";
@import "../_notification-script-templates-control/_notification-script-templates-control.less";
@import "../_slider/_slider.less";

.review-details-modal {
  .review-questions {
    margin-top: 30px;
    margin-bottom: 24px;

    &__header {
      display: flex;
      align-items: center;
    }

    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 19px;
      color: #2e2f31;
    }

    &__answers-count {
      font-weight: normal;
    }
  }
}
