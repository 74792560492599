.smile-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;

  &__type, &__sets, &__labels {
    padding-left: 8px;
    padding-right: 8px;
    width: 50%;
  }

  &__type {

  }
  &__sets {

  }
  &__labels {

  }

  &__sets ~ .smile-form__labels {
    width: 100%;
  }

  &__labels-row {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
  }

  &__label {
    padding-left: 8px;
    padding-right: 8px;
  }
}
