.matrix-controller {
  table {
    th, td {
      font-size: var(--poll-font-size);
      &.sticky {
        background: white;
        position: sticky;
        left: 0;
        z-index: 1;
        min-width: 50px;
      }
    }

    .f-table__outer-cell, .f-table__outer-head-cell {
      background: white;
    }

    .f-table__row--error {
      .sticky {
        background: inherit;
      }
      .f-table__outer-cell, .f-table__outer-head-cell {
        background: inherit;
      }
    }
  }

  &__row {
    margin-bottom: 20px;

    &-wrapper {

        .ps__rail-x {
          display: none;
          visibility: hidden;
        }


    }

    &--error {
      .matrix-controller__item {
        background: #FFDDDD;
      }
    }
  }

  &__skip {
    color:#73808D;
    margin-bottom: 20px;
  }

  &__item {
    background: #E1E8EB;

    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    font-size: 12px;
    cursor: pointer;
    min-height: 36px;
    border-radius: 4px;
    padding: 5px 10px;
    min-width: 50px;
    max-width: 150px;
    margin-right: 2px;
    color: @f-color-text;
    transition: background 300ms ease-in-out;

    input {
      display: none;
    }

    &.active {
      color: white;
      background: var(--poll-main-color);
    }
  }
}

.matrix-controller--differencial {


  table {
    td {
      border: none;
    }

    tr:last-child {
      border: none;
    }

    .matrix-controller__value {
      max-width: 52px;
      padding: 1px;
    }

    .matrix-controller__item {
      min-width: 0;
      max-width: auto;
      width: 48px;
    }

    .matrix-controller__label {
      width: 50%;
      padding: 1px 15px;
      line-height: 1;
    }

    .matrix-controller__start-label {
      padding-right: 20px;
    }
    .matrix-controller__start-label {
      padding-left: 20px;
    }
  }

  .matrix-controller__labels {
    & > div {
      max-width: 50%;

      &:first-child {
        padding-right: 10px;
      }

      &:last-child {
        padding-left: 10px;
        text-align: end;
      }
    }
  }

  .matrix-controller__label {
    font-weight: normal;
  }

  max-width: 350px;
    margin-left: auto;
    margin-right: auto;

  @media screen and (min-width: 992px) {
    max-width: none;
  }
}
