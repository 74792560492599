.b-error {

    padding-top: 11px;

    &_full {
        padding-left: 56px;
        padding-top: 0;
        margin-top: -25px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 29px 0;
        .logo {
            display: inline-flex;
            .icon-foquz-logo(#000000);
            background-image: url("/img/logo-ru.svg");
        }
    }

    &__title {
        font-style: normal;
        font-weight: 900;
        font-size: 22px;
        line-height: 1;
        color: #2E2F31;
        margin-bottom: 25px;

        &_inner {
            margin-bottom: 7px;
        }
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }

    &__body {
        background-color: #ffffff;
        box-shadow: 0 2px 4px #CFD8DC;
        border-radius: 9px;
        display: flex;
        align-items: flex-start;
        text-align: center;
        justify-content: center;
        padding: 95px 50px 50px;
        min-height: 532px;

        &:hover {
            .b-error__icon {
                box-shadow: inset 0 0 16px rgba(#000, .05);
            }
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 220px;
        height: 220px;
        background-color: #EFF2FA;
        margin-bottom: 50px;

        transition: all .45s ease-in-out;

        &_403 {
            padding-top: 18px;
        }

        &_404 {
            svg {
                transform: translate(13px, -7px);
            }
        }

        &_500 {
            svg {
                width: 251px;
                min-width: 251px;
                max-width: 251px;
                height: 249px;
                transform: translate(7px, 7px);
            }
        }
    }

    &__text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        color: #2E2F31;
    }

}
