.service-access-details-modal-dialog {
    &--initializing {
        visibility: hidden;
    }

    .service-access-details-modal-dialog__title {
        margin-bottom: 30px;
    }

    .service-access-details-modal-dialog__body {
        flex-grow: 1;
    }

    .service-access-details-modal-dialog__active-checkbox {
        margin-top: 44px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        border-radius: 0;
        background: #FFFFFF;
        box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);
    }

    &__title {
        padding-bottom: 0;
    }

    &__body {
        padding-bottom: 58px;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__actions {
        .service-access-details-modal-dialog__action {
            &:not(:first-child) {
                margin-left: 13px;
            }
        }
    }
}
