.contact-points-report {
	&__period-filter {
		width: 261px;
		flex-shrink: 0;

		.form-group {
			margin-bottom: 0;
		}
	}

	&__period-dropdown {
		&:after {
            left: 218px !important;
        }
	}

	&__survey-filter {
		width: 0;
		flex-grow: 1;
		flex-shrink: 1;

		.form-group {
			margin-bottom: 0;
		}
	}

	&__branch-filter {
		width: 0;
		flex-grow: 1;
		flex-shrink: 1;

		.form-group {
			margin-bottom: 0;
		}
	}

	&__accuracy-filter {
		width: 261px;
		flex-shrink: 0;

		.form-group {
			margin-bottom: 0;
		}
	}

	&__main-statistics-form {
		display: flex;
		align-items: flex-end;
		flex-wrap: nowrap;
		width: 100%;
		margin-bottom: 40px;
	}

	&__main-statistics-form-form-group {
		margin-bottom: 0;

		&:not(:first-child) {
			margin-left: 30px;
		}
	}

	&__main-statistics-form-compare-form-group {
		width: 0;
		flex-grow: 1;
		flex-shrink: 1;
	}

	&__main-statistics-form-apply-button {
		margin-left: 30px;
		flex-shrink: 0;
	}

	&__compare-input-group {
		display: block;
		padding: 6px 13px;

		border: 1px solid #CFD8DC;
		border-radius: 4px;
	}

	&__compare-period-input {
		display: flex;
		align-items: center;
	}

	&__compare-period-input-label {
		flex-shrink: 0;
	}

	&__compare-period-input-condition-list {
		display: flex;
		flex-wrap: wrap;
		margin-top: -5px;
		margin-left: calc(7px + (-5px));
	}

	&__compare-period-input-condition {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 32px;
		padding-right: 6px;
		padding-left: 17px;
		margin-top: 5px;
		margin-left: 5px;

		border-radius: 60px;
		font-size: 14px;
		color: #2E2F31;
	}

	&__compare-period-input-condition-button {
		width: 20px !important;
		height: 20px !important;
		margin-left: 10px;

		background: #FFF !important;

		&:before {
			.icon-cross(#F96261);

			content: '';
		}
	}

	&__compare-period-input-add-button {
		margin-top: 5px;
		margin-left: 5px;

		background-color: #3f65f1;
		color: #fff;

		&:hover {
			color: #fff;
		}

		&:before {
			.icon-plus2(#FFF);

			content: '';
			display: inline-block;
		}
	}

	&__compare-branch-input {
		display: flex;
		align-items: center;

		margin-top: 6px;
	}

	&__compare-branch-input-label {
		flex-shrink: 0;
	}

	&__compare-branch-input-condition-list {
		display: flex;
		flex-wrap: wrap;
		margin-top: -5px;
		margin-left: calc(7px + (-5px));
	}

	&__compare-branch-input-condition {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 32px;
		padding-right: 6px;
		padding-left: 17px;
		margin-top: 5px;
		margin-left: 5px;

		border-radius: 60px;
		font-size: 14px;
		color: #2E2F31;
	}

	&__compare-branch-input-condition-button {
		width: 20px !important;
		height: 20px !important;
		margin-left: 10px;

		background: #FFF !important;

		&:before {
			.icon-cross(#F96261);

			content: '';
		}
	}

	&__compare-branch-input-add-button {
		margin-top: 5px;
		margin-left: 5px;

		background-color: #3f65f1;
		color: #fff;

		&:hover {
			color: #fff;
		}

		&:before {
			.icon-plus2(#FFF);

			content: '';
			display: inline-block;
		}
	}

	&__compare-branch-input-add-dropdown-menu {
		left: 50% !important;
		transform: translate(-50%, 37px) !important;

		&:before {
			left: calc(50% - (22px / 2));
			right: unset;
		}

		.dropdown-item {
			cursor: pointer;
		}
	}

	&__statistics-data-tooltip-header {
		margin-bottom: 6px;

		font-family: Roboto, sans-serif;
		font-size: 13px;
		font-weight: bold;
		color: #000000;
	}

	&__statistics-data-tooltip-row {
		font-family: Roboto, sans-serif;
		font-weight: 400;
		font-size: 12px;
		color: #2E2F31;

		&:not(:first-child) {
			margin-top: 3px;
		}
	}

	&__statistics-data-tooltip-value {
		font-weight: bold;
	}

	&__statistics-a-data {
		height: 224px;
	}

	&__statistics-b-data {
		height: 188px;
	}
}
