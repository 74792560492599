.review-details-modal {
  .processing-form {
    margin-top: 30px;

    &__title {
      font-weight: bold;
      font-size: 19px;
      color: #2e2f31;
    }

    &__controls {
      margin-top: 20px;
    }

    &__view {
      margin-top: 20px;
    }


  }

}

.status-option {
  &.status--0 {
    color: @answer-new-color;
  }

  &.status--1 {
    color: @answer-in-process-color;
  }

  &.status--2 {
    color: @answer-delayed-color;
  }

  &.status--3 {
    color: @answer-completed-color;
  }

  &.status--4 {
    color: @answer-handle-color;
  }

  &.disabled {
    color: @f-color-service;
    opacity: 0.5;
  }
}
