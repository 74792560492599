.settings {
    &__contact-points-points-form-control {
        &--with-unselected-search {
            .settings__contact-points-points-form-control-container {
                min-height: 145px;
            }

            .settings__contact-points-points-form-control-container-wrapper-container {
                position: relative;
                max-height: calc(100% - 79.2px);
                height: 100%;
            }

            .settings__contact-points-points-form-control-unselected-container {
                min-height: unset;
            }

            .settings__contact-points-points-form-control-unselected-container-control {
                height: calc(100% + 29px);
                margin-top: -29px;
            }

            .settings__contact-points-points-form-control-container-wrapper {
                &.scroll-wrapper {
                    position: absolute !important;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }

    &__contact-points-points-form-control-container {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        padding: 0 5px 5px 5px;
        min-height: 80px;
    }

    &__contact-points-points-form-control-container-wrapper {
        &.scroll-content {
            padding-right: 16px;
        }
    }

    &__contact-points-points-form-control-unselected-container-control-header {
        display: flex;

        .settings__contact-points-points-form-control-unselected-container-control-search-form-group {
            width: 100%;
            margin-bottom: 14px;
        }

        .settings__contact-points-points-form-control-add-button {
            margin-top: 31px;
            margin-left: 18px;
            flex-shrink: 0;
        }
    }

    &__contact-points-points-form-control-unselected-container-control-search-form-group {
        .form-control {
            height: 36px;
        }
    }

    &__contact-points-points-form-control-unselected-container {
        margin-top: -5px;
        margin-left: -5px;
        padding: 0;

        .settings__contact-points-points-form-control-point {
            transition: background-color 0.2s ease;
        }

        &:not(.settings__contact-points-points-form-control-container--over) {
            .settings__contact-points-points-form-control-point:hover {
                background: #C6CFD3;
            }
        }
    }


    &__contact-points-points-form-control-selected-container {
        border: 1px solid #CFD8DC;
        box-sizing: border-box;
        border-radius: 4px;

        .settings__contact-points-points-form-control-point {
            background: #3F65F1;
            color: #FFF;

            &--system {
                &:before {
                    .icon-star(#9BB0FB);

                    content: '';
                }
            }
        }

        .settings__contact-points-points-form-control-point-button {
            &:before {
                content: '';

                .icon-cross(#F96261);
            }
        }
    }

    &__contact-points-points-form-control-point {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        padding-right: 6px;
        padding-left: 17px;
        margin-top: 5px;
        margin-left: 5px;

        user-select: none;
        touch-action: none;
        background: #ECEFF1;
        border-radius: 60px;
        font-size: 14px;
        color: #2E2F31;
        cursor: grab;

        &--system {
            position: relative;
            padding-left: 20px;

            &:before {
                .icon-star(#A6B1BC);

                content: '';
                position: absolute;
                left: 8px;
            }
        }

        &.gu-hide {
            display: none !important;
        }

        &.gu-mirror {
            position: fixed !important;
            z-index: 9999 !important;
            margin: 0 !important;
            background: #3F65F1;
            color: #FFF;
            box-shadow: 0 15px 20px rgba(63, 101, 241, 0.3);
            cursor: grabbing;
        }

        &.gu-transit {
            border: 2px dotted #9BB0FB;
            background: #FFF !important;
            color: #FFF;

            &:before {
                visibility: hidden;
            }

            .settings__contact-points-points-form-control-point-button {
                visibility: hidden;
            }
        }
    }

    &__contact-points-points-form-control-point-button {
        width: 20px !important;
        height: 20px !important;
        margin-left: 10px;

        background: #FFF !important;

        transition: none;

        &:before {
            content: '';

            .icon-plus2(#2E2F31);
        }
    }

    &__contact-points-points-form-control-add-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        margin-top: 5px;
        margin-left: 5px;

        background-color: #3f65f1;
        color: #fff;

        &:hover {
            color: #fff;
        }

        &:before {
            .icon-plus2(#FFF);

            content: '';
            display: inline-block;
        }
    }

    &__contact-points-points-form-control-modal {
        .modal-dialog {
            width: 460px;
            min-height: 421px;
        }
    }

    &__contact-points-row {
        display: flex;

        & > * {
            width: 100%;

            &:not(:first-child) {
                margin-left: 13px;
            }
        }
    }

    &__contact-points-conditions {
        display: flex;
        flex-direction: column;

        .settings__additional-add-button {
            margin-top: 21px;
            align-self: flex-end;
        }
    }

    &__contact-points-conditions-header {
        display: flex;
        align-items: center;
        padding-bottom: 12px;

        border-bottom: 1px solid #e7ebed;
    }

    &__contact-points-conditions-title {
        font-weight: bold;
        font-size: 19px;
        line-height: 13px;
        color: #2E2F31;
    }

    &__contact-points-conditions-count {
        font-weight: 400;
        color: #73808d;
    }

    &__contact-points-conditions-sub-title {
        margin-top: 12px;

        font-size: 12px;
        line-height: 13px;
        color: #73808D;
    }

    &__contact-points-condition {
        padding-top: 23px;
        position: relative;

        &:not(:last-child) {
            border-bottom: 1px solid #e7ebed;
        }
    }

    &__contact-points-condition-remove-button {
        display: flex;
        width: 36px;
        height: 36px;
        align-items: center;
        justify-content: center;
        padding: 0;
        position: absolute;
        top: 20px;
        right: 0;

        z-index: 2;

        &:before {
            content: '';
            .icon-add(#F96261);
            transform: rotate(45deg);
        }
    }
}
