.share {
  &-overlay {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px;

    &.share-square_rounded {
      border-radius: 10px;
      &.share-48 {
        border-radius: 15px;
      }
      &.share-32 {
        border-radius: 10px;
      }
      &.share-24 {
        border-radius: 8px;
      }
    }

    &.share-round {
      border-radius: 20px;
      &.share-48 {
        border-radius: 30px;
      }
      &.share-32 {
        border-radius: 20px;
      }
      &.share-24 {
        border-radius: 16px;
      }
    }
  }


}
