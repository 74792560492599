.survey-question {

    &__number-menu {
        display: flex;
        align-items: center;
    }

    &__number-menu-item-container {
        display: flex;
        align-items: center;

        &:not(.survey-question__number-menu-item-container--over) {
            .survey-question__number-menu-item:not(.survey-question__number-menu-item--active):hover {
                background: #3F65F1;
                color: #FFF !important;
            }
        }
    }

    &__number-menu-item {
        display: flex;
        width: 28px;
        height: 28px;
        align-items: center;
        justify-content: center;
        margin-bottom: 2px;
        margin-top: 2px;

        border-radius: 50%;
        background: #D9E0FC;
        text-align: center;
        //font-family: Roboto, sans-serif;
        font-weight: bold;
        font-size: 15px;
        color: #3F65F1 !important;
        text-decoration: none !important;
        cursor: pointer;

        transition: background-color 0.3s ease,
        color 0.3s ease;

        &:not(:last-child) {
            margin-right: 17px;
        }

        // &.survey-question__number-menu-item--original:first-child + .survey-question__number-menu-item {
        //     margin-left: 0 !important;
        // }

        &--active {
            background: #3F65F1;
            color: #FFF !important;
        }

        &--mirror {
            background: #3F65F1;
            color: #FFF !important;
            box-shadow: 0 8px 10px rgba(63, 101, 241, 0.3);
            opacity: 1 !important;
            cursor: grabbing !important;
            z-index: 100;
        }

        &--dragging, &.quest__contact-point-menu-item--dragging {
            text-indent: -9999px;
            background: transparent;
            border: 2px dotted #9BB0FB;
        }
    }

    &__number-menu-add-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;

        background-color: #3F65F1;
        color: #FFF;

        &:hover {
            color: #FFF;
        }
    }

    &__number-menu-add-button-icon {
        .icon-plus2(#FFF);

        display: inline-block;
    }

    &__number-menu-body--dragging {
        cursor: grabbing !important;

        * {
            cursor: grabbing !important;
        }
    }
}
