.flex-table {
	border-bottom: 1px solid #E7EBED;

	&__head {
		display: flex;

		border-bottom: 2px solid #E7EBED;
	}

	&__row {
		display: flex;
		width: 100%;

		&:not(:first-child) {
			border-top: 1px solid #E7EBED;
		}
	}

	&__head-cell {
		display: flex;
		width: 100%;
		padding: .75rem;

		font-weight: bold;
		font-size: 12px;
		color: #2E2F31;
	}

	&__cell {
		display: flex;
		width: 100%;
		padding: .75rem;
	}
}
