@import "./_mixins.less";

.icon {
  display: inline-block;
  vertical-align: baseline;
  flex-shrink: 0;
  flex-grow: 0;

  &-disabled {
    opacity: 0.5;
  }

  &.icon-lg {
    width: 24px;
    height: 24px;
  }
}

// complaint
.icon-complaint {
  .icon-complaint(@f-color-danger);
}

// poll types
.icon-manual {
  .icon-manual(@f-color-service);
  &--light {
    .icon-manual(@f-color-light);
  }
}
.icon-automatic {
  .icon-automatic(@f-color-service);
  &--light {
    .icon-automatic(@f-color-light);
  }
}

// views
.icon-desktop {
  .icon-desktop(@f-color-service);
}
.icon-tablet {
  .icon-tablet(@f-color-service);
}
.icon-mobile {
  .icon-mobile(@f-color-service);
}
.icon-open-in-new-tab {
  .icon-open-in-new-tab(@f-color-service);
}

// channels
.icon-email {
  .icon-email(@f-color-service);
  &--inactive,
  &.icon--inactive {
    .icon-email(@f-color-text-inactive);
  }
}
.icon-sms {
  .icon-sms(@f-color-service);
  &--inactive,
  &.icon--inactive {
    .icon-sms(@f-color-text-inactive);
  }
}
.icon-telegram {
  .icon-telegram(#1588c9);
  &--inactive,
  &.icon--inactive {
    .icon-telegram(@f-color-text-inactive);
  }
}
.icon-viber {
  .icon-viber(#9151c1);
  &--inactive,
  &.icon--inactive {
    .icon-viber(@f-color-text-inactive);
  }
}
.icon-push {
  .icon-push(@f-color-service);
  &--inactive,
  &.icon--inactive {
    .icon-push(@f-color-text-inactive);
  }
}

.icon-repeat {
  .icon-repeat(@f-color-service);
  &--inactive,
  &.icon--inactive {
    .icon-repeat(@f-color-text-inactive);
  }
}

// logo
.icon-foquz-logo-lg {
  .icon-foquz-logo(@f-color-service);
}
.icon-foquz-logo {
  .icon-foquz-logo2(@f-color-service);
}

// sidebar
.icon-surveys {
  .icon-surveys(@f-color-service);
}
.icon-reports {
  .icon-reports(@f-color-service);
}
.icon-clients {
  .icon-clients(@f-color-service);
}
.icon-contact-points {
  .icon-contact-points(@f-color-service);
}
.icon-settings {
  .icon-settings(@f-color-service);
}
.icon-projects {
  .icon-projects(@f-color-service);
}
.icon-projects-na {
  .icon-projects-na(#CFD8DC);
}
.icon-payment {
  .icon-payment(@f-color-service);
}
.icon-payment-na {
  .icon-payment-na(#CFD8DC);
}
.icon-help {
  .icon-help(@f-color-service);
}
.icon-help-na {
  .icon-help-na(#CFD8DC);
}

// Rating
.icon-rating-1 {
  .icon-rating1(@rating-1-color);
}
.icon-rating-2 {
  .icon-rating2(@rating-2-color);
}
.icon-rating-3 {
  .icon-rating3(@rating-3-color);
}
.icon-rating-4 {
  .icon-rating4(@rating-4-color);
}
.icon-rating-5 {
  .icon-rating5(@rating-5-color);
}

// Tables
.icon-settings-md {
  .icon-settings2(@f-color-service);
}

.icon-columns {
  .icon-columns(@f-color-service);
  &--active {
    .icon-columns(@f-color-blue-2);
  }
}

.icon-list {
  .icon-list(@f-color-service);
  &--active {
    .icon-list(@f-color-blue-2);
  }
}

.icon-sorting {
  .icon-sorting(@f-color-service) !important;
  transition: transform 200ms;

  &--active {
    .icon-sorting(@f-color-blue-2) !important;
  }

  &--desc {
    transform: rotate(-180deg);
  }

  &--default {
    .icon-sorting(#A6B1BC);
  }
}

.icon-filtering {
  .icon-filtering(@f-color-service);
  &--active {
    .icon-filtering(@f-color-blue-2);
  }
}

// Social
.icon-link-insta {
  .icon-link-insta(@f-color-service);
}
.icon-link-fb {
  .icon-link-fb(@f-color-service);
}
.icon-link-vk {
  .icon-link-vk(@f-color-service);
}

// Quick help
.icon-quick-open {
  .icon-quick-open(@f-color-service);
}

// Question types
.icon-rating {
  .icon-rating(@f-color-service);
  &--active {
    .icon-rating(@f-color-blue-2);
  }
}

.icon-type-vars {
  .icon-type-vars(@f-color-service);
  &--active {
    .icon-type-vars(@f-color-blue-2);
  }
}
.icon-type-text {
  .icon-type-text(@f-color-service);
  &--active {
    .icon-type-text(@f-color-blue-2);
  }
}
.icon-type-date {
  .icon-type-date(@f-color-service);
  &--active {
    .icon-type-date(@f-color-blue-2);
  }
}
.icon-type-address {
  .icon-type-address(@f-color-service);
  &--active {
    .icon-type-address(@f-color-blue-2);
  }
}
.icon-type-upload {
  .icon-type-upload(@f-color-service);
  &--active {
    .icon-type-upload(@f-color-blue-2);
  }
}
.icon-type-quiz {
  .icon-type-quiz(@f-color-service);
  &--active {
    .icon-type-quiz(@f-color-blue-2);
  }
}
.icon-type-priority {
  .icon-type-priority(@f-color-service);
  &--active {
    .icon-type-priority(@f-color-blue-2);
  }
}
.icon-mediatype-text {
  .icon-text(@f-color-service);
  &--active {
    .icon-text(@f-color-blue-2);
  }
}
.icon-mediatype-image {
  .icon-image2(@f-color-service);
  &--active {
    .icon-image2(@f-color-blue-2);
  }
}
.icon-mediatype-video {
  .icon-video(@f-color-service);
  &--active {
    .icon-video(@f-color-blue-2);
  }
}
.icon-mediatype-photovideo {
  .icon-photovideo(@f-color-service);
  &--active {
    .icon-photovideo(@f-color-blue-2);
  }
}

// cross
.icon-remove,
.icon-close {
  .icon-cross3(@f-color-service);

  &--error {
    .icon-cross3(@f-color-danger);
  }
  &--light {
    .icon-cross3(@f-color-light);
  }

  &.icon-sm {
    width: 10px;
    height: 10px;
  }

  &.icon-lg {
    width: 20px;
    height: 20px;
  }
}

.icon-reset {
  .icon-plus3(@f-color-text);
  transform: rotate(45deg);
  width: 14px;
  height: 14px;

  &--error {
    .icon-plus3(@f-color-danger);
  }

  &--light {
    .icon-plus3(@f-color-light);
  }

  &.icon-sm {
    width: 10px;
    height: 10px;
  }

  &.icon-lg {
    width: 20px;
    height: 20px;
  }
}

// Plus
.icon-add {
  display: inline-block;
  .icon-add(@f-color-service);

  &--light {
    .icon-add(@f-color-light);
  }

  &.icon-sm {
    width: 10px;
    height: 10px;
  }

  &.icon-lg {
    width: 20px;
    height: 20px;
  }
}

// Arrows
.icon-arrow-bottom {
  .icon-arrow-bottom2(@f-color-service);
}
.icon-video-arrow {
  .icon-video-arrow(@f-color-service);
}
.icon-arrow-to-bottom {
  .icon-arrow-to-bottom(@f-color-service);
}
.icon-expand {
  .icon-expand(@f-color-service);
}
.icon-drag-arrow {
  .icon-drag-arrow(@f-color-service);
}
.icon-drag-arrow--light {
  .icon-drag-arrow(@f-color-light);
}
.icon-arrow-to-bottom_curved {
  .icon-arrow-to-bottom_curved(@f-color-service);
}

.icon-arrow-to-right_curved {
  .icon-arrow-to-right_curved(@f-color-service);
}

// Comments
.icon-comment {
  .icon-comment(@f-color-service);
}
.icon-comment-outline {
  .icon-comment2(@f-color-service);
}

// Media
.icon-image {
  .icon-image(@f-color-service);
}
.icon-image-bold {
  .icon-image-bold(@f-color-service);
}
.icon-upload {
  .icon-upload(@f-color-service);
}
.icon-upload-bold {
  .icon-upload2(@f-color-service);
}
.icon-photovideo {
  .icon-photovideo(@f-color-service);
}
.icon-attachment {
  .icon-attachment(@f-color-service);
}
.icon-launch {
  .icon-launch(@f-color-service);
}
.icon-stop {
  .icon-stop(@f-color-service);
}
.icon-resume {
  .icon-resume(@f-color-service);
}

// Resume
.icon-created-at {
  .icon-created-at(@f-color-service);
}
.icon-passed-at {
  .icon-passed-at(@f-color-service);
}
.icon-last-status-changed-at {
  .icon-last-status-changed-at(@f-color-service);
}
.icon-sending {
  .icon-sending(@f-color-service);
}
.icon-feedback {
  .icon-feedback(@f-color-service);
}
.icon-survey {
  .icon-survey(@f-color-service);
}
.icon-compensation {
  .icon-compensation(@f-color-service);
}
.icon-compensation-usage {
  .icon-compensation-usage(@f-color-service);
}

// Controls
.icon-visible {
  .icon-visible(@f-color-service);
}
.icon-hidden {
  .icon-hidden(@f-color-service);
}
.icon-visible-sm {
  .icon-show-password(@f-color-service);
}
.icon-hidden-sm {
  .icon-hide-password(@f-color-service);
}
.icon-pencil {
  .icon-pencil(@f-color-service);
}
.icon-eye {
  .icon-eye(@f-color-service);
}
.icon-pencil2 {
  .icon-pencil2(@f-color-service);
}

// Links
.icon-link-h {
  .icon-link3(@f-color-service);
}
.icon-link-h-bold {
  .icon-link5(@f-color-service);
}
.icon-clip {
  .icon-link4(@f-color-service);
}
.icon-link {
  .icon-link6(@f-color-service);
}

// Checks
.icon-done {
  display: inline-block;
  .icon-done(@f-color-service);
  &--success {
    .icon-done(@f-color-success);
  }
}
.icon-checkbox {
  .icon-checkbox(@f-color-service);
}

// Charts
.icon-columns-chart {
  .icon-column-chart(@f-color-service);
  &.icon-active {
    .icon-column-chart(@f-color-blue-2);
  }
}
.icon-pie-chart {
  .icon-pie-chart(@f-color-service);
  &.icon-active {
    .icon-pie-chart(@f-color-blue-2);
  }
}
.icon-funnel-chart {
  .icon-funnel-chart(@f-color-service);
  &.icon-active {
    .icon-funnel-chart(@f-color-blue-2);
  }
}

.icon-copy-text {
  .icon-copy-text(#73808d);
}

.icon-user-check {
  .icon-user-check(@f-color-service);
  &.icon-success {
    .icon-user-check(@f-color-success);
  }
}

/**
.icon-menu-close { .icon-menu-close(@f-color-service); }
.icon-menu { .icon-menu(@f-color-service); }
.icon-squares { .icon-squares(@f-color-service); }
    .icon-companies { .icon-companies(@f-color-service); } .icon-users { .icon-users(@f-color-service); } .icon-archive { .icon-archive(@f-color-service); } .icon-copy { .icon-copy(@f-color-service); } .icon-restore { .icon-restore(@f-color-service); } .icon-delete { .icon-delete(@f-color-service); } .icon-rate-star { .icon-rate-star(@f-color-service); } .icon-rate-star-fill { .icon-rate-star-fill(@f-color-service); } .icon-angle { .icon-angle(@f-color-service); } .icon-close-circle { .icon-close-circle(@f-color-service); } .icon-ok-colors { .icon-ok-colors(@f-color-service, @f-color-blue-2); }  .icon-menu-qw-txt { .icon-menu-qw-txt(@f-color-service); } .icon-menu-qw-img { .icon-menu-qw-img(@f-color-service); } .icon-menu-qw-video { .icon-menu-qw-video(@f-color-service); } .icon-save-question { .icon-save-question(@f-color-service); } .icon-question { .icon-question(@f-color-service); } .icon-play { .icon-play(@f-color-service); } .icon-prev { .icon-prev(@f-color-service); } .icon-next { .icon-next(@f-color-service); }  .icon-mistake { .icon-mistake(@f-color-service); } .icon-calendar { .icon-calendar(@f-color-service); } .icon-calendar-important { .icon-calendar-important(@f-color-service); } .popover-arrow { .popover-arrow(@f-color-service); }
.icon-arrow-back { .icon-arrow-back(@f-color-service); }   .icon-star { .icon-star(@f-color-service); }   .icon-dismiss { .icon-dismiss(@f-color-service); } .icon-share { .icon-share(@f-color-service); }  .icon-statistics { .icon-statistics(@f-color-service); }   .icon-folder { .icon-folder(@f-color-service); }  .icon-search { .icon-search(@f-color-service); } .icon-menu2 { .icon-menu2(@f-color-service); }     .icon-pin { .icon-pin(@f-color-service); }    .icon-phone { .icon-phone(@f-color-service); }  .frame { .frame(@f-color-service); } .icon-video-play-button { .icon-video-play-button(@f-color-service); }     .icon-best { .icon-best(@f-color-service); } .icon-worst { .icon-worst(@f-color-service); }  .icon-user-blank-avatar { .icon-user-blank-avatar(@f-color-service); }   .icon-copy-text { .icon-copy-text(@f-color-service); }  .icon-variants { .icon-variants(@f-color-service); } .icon-radio { .icon-radio(@f-color-service); } .icon-checkbox2 { .icon-checkbox2(@f-color-service); } .icon-date { .icon-date(@f-color-service); } .icon-time { .icon-time(@f-color-service); } .icon-datetime { .icon-datetime(@f-color-service); }  .icon-oneline { .icon-oneline(@f-color-service); } .icon-multiline { .icon-multiline(@f-color-service); } .icon-save { .icon-save(@f-color-service); }  .icon-video-play { .icon-video-play(@f-color-service, @f-color-blue-2); } .icon-logout { .icon-logout(@f-color-service); } .icon-sidebar-plus { .icon-sidebar-plus(@f-color-service); }  .icon-input-error { .icon-input-error(@f-color-service); } .icon-burger { .icon-burger(@f-color-service); } .icon-burger-close { .icon-burger-close(@f-color-service); }

 .icon-number-minus { .icon-number-minus(@f-color-service); } .icon-check-arrow { .icon-check-arrow(@f-color-service); } .icon-check-loading { .icon-check-loading(@f-color-service); } .icon-target { .icon-target(@f-color-service); }**/
