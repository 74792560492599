.scrollbar-inner {
    .scroll-element.scroll-y {
        width: 4px;
    }

    .scroll-element.scroll-x {
        height: 4px;
    }

    .scroll-element .scroll-bar {
        background-color: #8e99a3;
        opacity: 1;
    }

    .scroll-element .scroll-element_track {
        background-color: #e8edee;
        opacity: 1;
    }
}
