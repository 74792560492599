.branch-contact-points-report {
	&__period-filter {
		width: 261px;
		flex-shrink: 0;

		.form-group {
			margin-bottom: 0;
		}
	}

	&__period-dropdown {
		&:after {
            left: 218px !important;
        }
	}

	&__contact-point-filter {
		width: 0;
		flex-grow: 1;
		flex-shrink: 1;

		.form-group {
			margin-bottom: 0;
		}
	}

	&__accuracy-filter {
		width: 261px;
		flex-shrink: 0;

		.form-group {
			margin-bottom: 0;
		}
	}
}
