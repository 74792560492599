.scrollable-wrapper {
    position: relative;

    .scrollable-wrapper__curtain {
        position: absolute;
    }

    .scrollable-wrapper__top-curtain {
        top: 0;
        right: 1px;
        left: 1px;
    }

    .scrollable-wrapper__right-curtain {
        right: 0;
        top: 1px;
        bottom: 1px;
    }

    .scrollable-wrapper__bottom-curtain {
        bottom: 0;
        right: 1px;
        left: 1px;
    }

    .scrollable-wrapper__left-curtain {
        left: 0;
        top: 1px;
        bottom: 1px;
    }

    &__content {
        overflow: auto;
    }

    &__curtain {
        content: '';

        display: block;
    }

    &__top-curtain {
        height: 28px;

        background: linear-gradient(0, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }

    &__right-curtain {
        width: 28px;

        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }

    &__bottom-curtain {
        height: 28px;

        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }

    &__left-curtain {
        width: 28px;

        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }
}
