@-webkit-keyframes fade-in {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes fade-in {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes slide-in {
    0% {
        -webkit-transform: translateY(5%);
        transform: translateY(5%)
    }
    100% {
        -webkit-transform: translateY(0%);
        transform: translateY(0%)
    }
}

@keyframes slide-in {
    0% {
        -webkit-transform: translateY(5%);
        transform: translateY(5%)
    }
    100% {
        -webkit-transform: translateY(0%);
        transform: translateY(0%)
    }
}

.colorpicker.fade-in {
    -webkit-animation-name: fade-in;
    animation-name: fade-in;
    -webkit-animation-duration: .2s;
    animation-duration: .2s
}

.colorpicker.slide-in {
    -webkit-animation-name: slide-in;
    animation-name: slide-in;
    -webkit-animation-duration: .15s;
    animation-duration: .15s
}

.colorpicker {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: inherit;
    position: fixed;
    display: none;
    border: 1px solid #e9ebee;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 3px
}

.colorpicker.is-opened {
    display: inline-block;
    z-index: 9
}

.colorpicker:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 6px solid black;
    box-shadow: -1px 1px 1px 0 rgba(147, 157, 170, 0.23);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%
}

.colorpicker-input + .colorpicker--inline, .colorpicker-anchor + .colorpicker--inline {
    margin-top: 10px
}

.colorpicker-circle-anchor {
    box-sizing: border-box;
    display: inline-block;
    line-height: 0
}

.colorpicker-circle-anchor__color {
    line-height: 0;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    width: 30px;
    height: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer
}

.colorpicker-anchor--inline {
    display: block
}

.colorpicker--position-top::after, .colorpicker--position-top::before, .colorpicker--position-top-center::after, .colorpicker--position-top-center::before {
    margin-left: -3px;
    bottom: -6px;
    left: 50%;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.colorpicker--position-bottom::after, .colorpicker--position-bottom-center::after {
    margin-left: -3px;
    top: -6px;
    bottom: auto;
    left: 50%;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg)
}

.colorpicker--position-top.is-arrow-left::after, .colorpicker--position-bottom.is-arrow-left::after {
    left: 18px;
    right: auto
}

.colorpicker--position-top.is-arrow-right::after, .colorpicker--position-bottom.is-arrow-right::after {
    left: auto;
    right: 18px
}

.colorpicker--position-left::after, .colorpicker--position-left-center::after {
    margin-top: -3px;
    left: auto;
    bottom: auto;
    right: -6px;
    top: 50%;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg)
}

.colorpicker--position-right::after, .colorpicker--position-right-center::after {
    margin-top: -3px;
    left: -6px;
    bottom: auto;
    right: auto;
    top: 50%;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(-315deg);
    transform: rotate(-315deg)
}

.colorpicker--position-left.is-arrow-top::after, .colorpicker--position-right.is-arrow-top::after {
    top: 18px;
    bottom: auto
}

.colorpicker--position-left.is-arrow-bottom::after, .colorpicker--position-right.is-arrow-bottom::after {
    top: auto;
    bottom: 18px
}

.colorpicker--inline {
    position: static;
    box-shadow: none;
    display: inline-block
}

.colorpicker--inline:after {
    display: none
}

.colorpicker--no-arrow:after {
    opacity: 0
}

.colorpicker-input {
    position: relative;
    display: block
}

.colorpicker-input--position-right .colorpicker-custom-anchor {
    right: 10px
}

.colorpicker-input--position-right input, .colorpicker-input--position-right .form-control {
    padding-right: 42px
}

.colorpicker-input--position-left .colorpicker-custom-anchor {
    left: 10px
}

.colorpicker-input--position-left input, .colorpicker-input--position-left .form-control {
    padding-left: 42px
}

.colorpicker-input .colorpicker-custom-anchor {
    position: absolute;
    top: 50%;
    margin-top: -13px;
    display: block
}

.colorpicker-input .colorpicker-circle-anchor__color {
    width: 26px;
    height: 26px
}

input + .colorpicker--inline, select + .colorpicker--inline, textarea + .colorpicker--inline {
    margin-top: 10px
}

.colorpicker-default {
    height: auto;
    width: 308px;
    padding: 9px 9px 7px 9px
}

.colorpicker-default__body {
    display: flex
}

.colorpicker-default__spectrum-container {
    width: 230px;
    height: 230px;
    position: relative;
    box-sizing: border-box;
    border-radius: 3px
}

.colorpicker-default__spectrum-canvas {
    width: 230px;
    height: 230px;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    box-sizing: border-box
}

.colorpicker-default__spectrum-cursor, .colorpicker-default__hue-cursor, .colorpicker-default__opacity-cursor {
    border: 2px solid #fff;
    width: 13px;
    height: 13px;
    background: transparent;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    cursor: default;
    box-sizing: border-box
}

.colorpicker-default__hue-cursor, .colorpicker-default__opacity-cursor {
    left: calc(50% - 7px);
    top: 50%
}

.colorpicker-default__hue-container, .colorpicker-default__opacity-container {
    width: 19px;
    height: 230px;
    border-radius: 3px;
    margin-left: 10px;
    position: relative;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1)
}

.colorpicker-default__hue-canvas, .colorpicker-default__opacity-canvas {
    width: 19px;
    height: 230px;
    border-radius: 3px
}

.colorpicker-default--hex-only .colorpicker-default__opacity-container {
    display: none
}

.colorpicker-default--hex-only {
    width: 279px
}

.colorpicker-default--hex-only .colorpicker-default__spectrum-container, .colorpicker-default--hex-only .colorpicker-default__spectrum-canvas {
    width: 230px
}

.colorpicker-default--hex-only .colorpicker-default__r-input-container, .colorpicker-default--hex-only .colorpicker-default__g-input-container, .colorpicker-default--hex-only .colorpicker-default__b-input-container, .colorpicker-default--hex-only .colorpicker-default__a-input-container, .colorpicker-default--hex-only .colorpicker-default__hex-text {
    display: none
}

.colorpicker-default--hex-only .colorpicker-default__hex-input-container, .colorpicker-default--hex-only .colorpicker-default__hex-input {
    width: 100%;
    margin-right: 0
}

.colorpicker-default--hex-only .colorpicker-default__history {
    padding-right: 1px
}

.colorpicker-default__info {
    display: flex;
    padding: 14px 0 0 0
}

.colorpicker-default__info--hidden {
    display: none
}

.colorpicker-default__hex-input-container, .colorpicker-default__r-input-container, .colorpicker-default__g-input-container, .colorpicker-default__b-input-container, .colorpicker-default__a-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 9px
}

.colorpicker-default__hex-input, .colorpicker-default__r-input, .colorpicker-default__g-input, .colorpicker-default__b-input, .colorpicker-default__a-input {
    box-sizing: border-box;
    width: 44px;
    height: 36px;
    border: 1px solid #d3d9dd;
    border-radius: 3px;
    color: #000;
    padding: 6px 8px;
    font-size: 14px;
    transition: border 0.2s ease-in-out;
    background: #fff
}

.colorpicker-default__hex-input::-webkit-input-placeholder, .colorpicker-default__r-input::-webkit-input-placeholder, .colorpicker-default__g-input::-webkit-input-placeholder, .colorpicker-default__b-input::-webkit-input-placeholder, .colorpicker-default__a-input::-webkit-input-placeholder {
    color: #939daa
}

.colorpicker-default__hex-input:-ms-input-placeholder, .colorpicker-default__r-input:-ms-input-placeholder, .colorpicker-default__g-input:-ms-input-placeholder, .colorpicker-default__b-input:-ms-input-placeholder, .colorpicker-default__a-input:-ms-input-placeholder {
    color: #939daa
}

.colorpicker-default__hex-input::-ms-input-placeholder, .colorpicker-default__r-input::-ms-input-placeholder, .colorpicker-default__g-input::-ms-input-placeholder, .colorpicker-default__b-input::-ms-input-placeholder, .colorpicker-default__a-input::-ms-input-placeholder {
    color: #939daa
}

.colorpicker-default__hex-input::placeholder, .colorpicker-default__r-input::placeholder, .colorpicker-default__g-input::placeholder, .colorpicker-default__b-input::placeholder, .colorpicker-default__a-input::placeholder {
    color: #939daa
}

.colorpicker-default__hex-input:focus, .colorpicker-default__r-input:focus, .colorpicker-default__g-input:focus, .colorpicker-default__b-input:focus, .colorpicker-default__a-input:focus {
    border-color: #0094f2;
    box-shadow: none;
    outline: none
}

.colorpicker-default__hex-input {
    width: 76px
}

.colorpicker-default__hex-text, .colorpicker-default__r-text, .colorpicker-default__g-text, .colorpicker-default__b-text, .colorpicker-default__a-text {
    text-transform: uppercase;
    font-size: 14px;
    color: #939daa;
    margin-top: 8px
}

.colorpicker-default__history {
    border-top: 1px solid #e9ebee;
    padding: 15px;
    margin: -9px;
    margin-top: 9px;
    display: flex;
    flex-wrap: wrap;
    padding-right: 5px;
    padding-bottom: 5px
}

.colorpicker-default__history.is-hidden {
    display: none
}

.colorpicker-default__history-item {
    cursor: pointer;
    width: 16px;
    height: 16px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    background: #EEF1F5;
    margin-right: 10px;
    margin-bottom: 10px
}

.colorpicker-default__history-item.is-empty {
    border-color: transparent
}

.colorpicker-default__history-item.is-add-new {
    text-align: center;
    cursor: pointer;
    position: relative;
    background: #dfe5ec url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAA7SURBVHjaYvz//z9Dy6RlDFDwH0oz1uRFQRjNE5cyIAG4ApgAEwMBwIKkC5tJxJnAiM8NjIR8QdAKwABr3RPEnLgHwwAAAABJRU5ErkJggg==") center center no-repeat;
    width: 16px;
    height: 16px;
    border: none
}

.colorpicker-default__history-item.is-add-new:hover {
    background-color: #d5dde6
}
