.survey-list {
	&__duplicate-survey-modal-dialog {
		width: 460px !important;

		.modal-content {
			min-height: unset !important;
		}

		.modal-header {
			padding-bottom: 50px !important;
		}
	}
}
