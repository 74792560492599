.sending-list {
  width: 465px;

  padding: 12px 20px 22px !important;

  font-size: 14px;

  &.dropdown-menu {
    top: 0 !important;
  }

  &:before {
    right: calc(50% - 22px / 2) !important;
  }

  &.dropdown-menu-left {

    &:before {
      left: 22px !important;
    }
  }
}
