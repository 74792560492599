.delete-report-modal-dialog {
	width: 460px !important;

	&__message {
        font-size: 14px;
        line-height: 13px;
        color: #2E2F31;
	}

	&__message-share-list {
		margin-top: 10px;

		li {
			font-weight: bold;
			font-size: 14px;
			line-height: 130%;
			color: #2E2F31;
		}
	}
}
