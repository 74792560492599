.settings {
    &__inter-screens-disable-curtain {
        position: absolute;
        top: 45px;
        right: 0;
        left: 0;
        bottom: 0;

        z-index: 5;
    }

    &__inter-screens-header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__inter-screens-content {
        transition: opacity 200ms ease-in-out;

        &--disabled {
            opacity: 0.3;
        }
    }

    &__inter-screens-preview {
        margin-top: 20px;
        margin-bottom: 26px;

        background: #2E2F31;
        border-radius: 6px;
        overflow: hidden;
    }

    &__inter-screens-preview-enable-toggle {
        margin-right: 10px;
    }

    &__inter-screens-preview-name {
        font-size: 16px;
        font-weight: bold;
        color: #2e2f31;
    }

    &__inter-screens-preview-checkbox {
        margin-top: 5px;
        margin-bottom: 0;
    }

    &__inter-screens-page {
        position: relative;
        min-height: 291px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &__inter-screens-page-background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-size: cover;
        background-position: center center;
        z-index: 1;
    }

    &__inter-screens-page-covering {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background: rgba(0, 0, 0, 0.7);
        border-radius: 6px;
        z-index: 2;
    }

    &__inter-screens-page-content {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 52px;

        z-index: 3;
    }

    &__inter-screens-page-title {
        font-weight: 400;
        font-size: 21px;
        text-align: center;
        color: #FFFFFF;
        line-height: 24px;
    }

    &__inter-screens-page-text {
        margin-top: 20px;
        max-width: 360px;

        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #FFFFFF;
        line-height: 19px;
    }

    &__inter-screens-page-title,
    &__inter-screens-page-text {
        strong {
            font-weight: 900;
        }

        em {
            font-style: italic;
        }

        a {
            text-decoration: underline;
            cursor: pointer;
            color: #FFF;
        }
    }

    &__inter-screens-page-start-button {
        margin-top: 23px;
    }

    &__inter-screens-page-promo-code {
        display: flex;
        padding: 7px;
        margin-top: 20px;

        border: 1px solid #CFD8DC;
        border-radius: 4px;
    }

    &__inter-screens-page-promo-code-value {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        padding-left: 13px;
        padding-right: 13px;

        font-weight: 900;
        font-size: 16px;
        color: #FFFFFF;
        text-transform: uppercase;
    }

    &__inter-screens-page-promo-code-copy-button {
        margin-left: 13px;
    }

    &__inter-screens-page-additional-actions {
        display: flex;
        margin-top: 20px;
        flex-direction: row;
    }

    &__inter-screens-page-complain-button {
        font-size: 14px;
        text-decoration-line: underline;
        color: #EA1D27 !important;
    }

    &__inter-screens-page-unsubscribe-button {
        font-size: 14px;
        text-decoration-line: underline;
        color: #FFFFFF !important;

        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    &__inter-screens-row {
        display: flex;
        .form-error_inner {
            position: absolute;
            top: 100%;
        }
    }

    &__inter-screens-title-form-group {
        flex-grow: 1;
    }

    &__inter-screens-background-picker {
        margin-top: 29px;
        margin-left: 18px;
    }

    &__inter-screens-unsubscribe-checkbox {
        .form-check-label {
            color: #FF3B30;
        }
    }

    &__inter-screens-preview-header-view-buttons {
        display: flex;
        margin-left: 28px;
        align-items: flex-end;

        .settings__inter-screens-view-button {
            &:not(:first-child) {
                margin-left: 9px;
            }
        }
    }

    &__inter-screens-view-button {
        border-radius: 0;
        padding: 0;

        &:focus {
            box-shadow: none;
        }

        &--desktop {
            .icon-desktop(#73808D);

            &:hover:not(:disabled) {
                .icon-desktop(#007bff);
            }
        }

        &--tablet {
            .icon-tablet(#73808D);

            &:hover:not(:disabled) {
                .icon-tablet(#007bff);
            }
        }

        &--mobile {
            .icon-mobile(#73808D);

            &:hover:not(:disabled) {
                .icon-mobile(#007bff);
            }
        }

        &--active {
            &.settings__inter-screens-view-button--desktop {
                .icon-desktop(#3F65F1);
            }

            &.settings__inter-screens-view-button--tablet {
                .icon-tablet(#3F65F1);
            }

            &.settings__inter-screens-view-button--mobile {
                .icon-mobile(#3F65F1);
            }
        }
    }

    &__inter-screens-view-modal {
        flex-direction: column;
        padding: 0 !important;

        &.show {
            display: flex !important;
        }
    }

    &__inter-screens-view-modal-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: #FFFFFF;
        opacity: 0.9;
        z-index: -1;
    }

    &__inter-screens-view-modal-header {
        display: flex;
        height: 70px;
        position: relative;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        padding: 0 18px 0 30px;

        z-index: 1;
        background: #FFF;
        box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);
    }

    &__inter-screens-view-modal-title {
        font-weight: 900;
        font-size: 24px;
        color: #2E2F31;
    }

    &__inter-screens-view-modal-close-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 33px;
        margin-left: 37px;
        padding: 0 !important;

        border: 1px solid #73808D !important;
        border-radius: 50%;
        box-shadow: none !important;

        &:after {
            content: '';

            .icon-close(#73808D);
        }
    }

    &__inter-screens-view-modal-sub-title {
        margin-top: 5px;

        font-size: 16px;
        line-height: 13px;
        color: #2E2F31;
    }

    &__inter-screens-view-modal-content {
        flex-grow: 1;
        display: flex;
        justify-content: safe center;
        overflow: auto;
        padding: 37px 0;

        &--desktop {
            .settings__inter-screens-view-modal-page {
                min-width: 1280px;
                min-height: 768px;
                width: 1280px;
                height: 768px;
            }

            @media (max-width: 1280px) {
                justify-content: unset;
            }
        }

        &--tablet {
            .settings__inter-screens-view-modal-page {
                min-width: 768px;
                min-height: 1024px;
                width: 768px;
                height: 1024px;
            }

            @media (max-width: 768px) {
                justify-content: unset;
            }
        }

        &--mobile {
            .settings__inter-screens-view-modal-page {
                min-width: 360px;
                min-height: 570px;
                width: 360px;
                height: 570px;
            }

            @media (max-width: 360px) {
                justify-content: unset;
            }
        }
    }

    &__inter-screens-view-modal-page {
        display: block;
    }

    &__inter-screens-view-modal-open-in-new-tab-button {
        display: inline-flex;
        align-items: center;
        padding: 0;

        font-size: 14px;
        line-height: 24px;
        color: #000000;

        &:before {
            display: inline-block;
            content: '';
            .icon-open-in-new-tab(#73808D);
            margin-right: 10px;
        }
    }

    &__inter-screens-view-modal-view-buttons {
        display: flex;
        position: absolute;
        align-items: flex-end;
        left: calc((100% - 92px) / 2);

        .settings__inter-screens-view-button {
            &:not(:first-child) {
                margin-left: 20px;
            }
        }
    }

    &__inter-screens-end-promo-code-form-group {
        .form-control {
            text-transform: uppercase;
        }
    }
}
