.clients {
    &__create-modal-dialog {
        width: 700px !important;
        max-width: 700px;

        &--initializing {
            visibility: hidden;
        }

        .modal-body {
            .row {
                margin-left: -20px !important;
                margin-right: -20px !important;
            }

            .col {
                flex-grow: 1;
                flex-basis: 0;
                padding-left: 20px !important;
                padding-right: 20px !important;
            }
        }
    }

    &__create-modal-dialog-contacts-row {
        .col {
            &:first-child {
                padding-right: 0 !important;
            }

            &:last-child {
                padding-left: 0 !important;
            }
        }
    }

    &__create-modal-dialog-contacts-row-divider {
        width: 40px;
        margin-top: 45px;
        text-align: center;
        margin-left: -20px;
        margin-right: -20px;
    }

    &__create-modal-dialog-additional-section {
        margin-top: 30px;
    }

    &__create-modal-dialog-additional-section-header {
        display: flex;
        align-items: center;

        font-weight: bold;
        font-size: 19px;
        line-height: 13px;
        color: #2E2F31;
        cursor: pointer;

        .clients__create-modal-dialog-additional-section-header-icon {
            margin-left: 13px;
        }
    }

    &__create-modal-dialog-additional-section-header-icon {
        .icon-select-arrow(#73808D);

        display: inline-block;
    }

    &__create-modal-dialog-additional-list {
        margin-top: 20px;

        .clients__create-modal-dialog-additional-list-item {
            &:not(:first-child) {
                margin-top: 25px;
            }
        }
    }

    &__create-modal-dialog-additional-list-item {
        .clients__create-modal-dialog-additional-list-item-form-group {
            margin-bottom: 0;
        }
    }
}
