@import "./notifications-control/notifications-control";
@import "./slider/slider";

.statistics {
    &__details-modal {
        overflow: hidden!important;
    }
    &__details-modal-dialog {
        &--initializing {
            visibility: hidden;
        }
    }

    &__details-modal-dialog-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        background: #FFFFFF;
        box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);
        border-top: 4px solid;

        .statistics__details-modal-dialog--status_new & {
            border-top-color: #16CEB9;
        }

        .statistics__details-modal-dialog--status_process & {
            border-top-color: #2D99FF;
        }

        .statistics__details-modal-dialog--status_delayed & {
            border-top-color: #F96261;
        }

        .statistics__details-modal-dialog--status_completed & {
            border-top-color: #8400FF;
        }
    }

    &__details-modal-dialog-nav {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        position: relative;

        border: none;

        .nav-item {
            display: flex;
            flex-grow: 1;
            width: 50%;
            align-items: center;
            padding: 13px 16px 13px 28px;

            font-weight: bold;
            font-size: 15px;
            color: #2E2F31;
            border: none;
            border-radius: 0;

            &:not(.active) {
                background: #DFE3E5;
                color: #73808D;
            }
        }

        &:after {
            content: '';

            position: absolute;
            width: 100%;
            height: 30px;
            bottom: -30px;
            z-index: 1;

            background: linear-gradient(180deg, #FFFFFF 18.23%, rgba(255, 255, 255, 0) 100%);
        }
    }

    &__details-modal-dialog-id {
        font-weight: normal;
        font-size: 15px;
        color: #2E2F31;
    }

    &__details-modal-dialog-complaint-indicator {
        .icon-complaint(#F96261);

        margin-left: 13px;
    }

    &__details-modal-dialog-rating-indicator {
        margin-left: 9px;

        &--value_1 {
            .icon-rating1(#F96261);
        }

        &--value_2 {
            .icon-rating2(#F97F46);
        }

        &--value_3 {
            .icon-rating3(#F4A224);
        }

        &--value_4 {
            .icon-rating4(#AAB540);
        }

        &--value_5 {
            .icon-rating5(#3ECE67);
        }
    }

    &__details-modal-dialog-tab-content {
        flex-grow: 1;
    }

    &__details-modal-dialog-tab-pane {
        padding: 20px 30px;
    }

    &__details-modal-dialog-survey-tab-pane {
        display: flex !important;
        flex-direction: column;
        min-height: 100%;

        &:not(.active) {
            display: none !important;
        }
    }

    &__details-modal-dialog-info {
        display: flex;
        height: 95px;

        .statistics__details-modal-dialog-info-block {
            height: 100%;
            flex-grow: 1;

            &:not(:first-child) {
                margin-left: 10px;
            }
        }
    }

    &__details-modal-dialog-info-block {
        background: #ECEFF1;
        border-radius: 8px;
    }

    &__details-modal-dialog-client-info-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 16px;
        padding-right: 16px;

        .statistics__details-modal-dialog-client-info-block-order-count {
            margin-left: 14px;
        }

        .statistics__details-modal-dialog-client-info-block-phone {
            margin-top: 8px;
        }
    }

    &__details-modal-dialog-client-info-block-title {
        display: flex;
        align-items: center;
    }

    &__details-modal-dialog-client-info-block-name {
        font-weight: bold;
        font-size: 16px;
        color: #2E2F31;
    }

    &__details-modal-dialog-client-info-block-order-count {
        display: inline-block;
        padding: 3px 4.63px;

        background: #3F65F1;
        border-radius: 10px;
        font-weight: 900;
        font-size: 11px;
        color: #FFFFFF;
        cursor: help;
    }

    &__details-modal-dialog-client-info-block-phone {
        font-weight: 400;
        font-size: 16px;
        color: #2E2F31;
    }

    &__details-modal-dialog-call-block {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__details-modal-dialog-call-block-button {
        display: flex;
        width: 44px;
        height: 44px;
        align-items: center;
        justify-content: center;
        padding: 0;

        box-shadow: none !important;
        background: #37A74A;
        border-radius: 50%;

        &:before {
            .icon-phone(#FFF);

            content: '';
        }
    }

    &__details-modal-dialog-notifications-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 16px;
        padding-right: 29px;
    }

    &__details-modal-dialog-notifications-block-header {
        font-weight: bold;
        font-size: 12px;
        color: #73808D;
    }

    &__details-modal-dialog-notifications-block-list {
        display: flex;
        margin-top: 21px;

        .statistics__details-modal-dialog-notifications-block-item {
            &:not(:first-child) {
                margin-left: 29px;
            }
        }
    }

    &__details-modal-dialog-notifications-block-item {
        display: flex;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        position: relative;

        background: #FFFFFF;
        border-radius: 50%;

        &:before {
            content: '';
        }

        &--disabled {
            background: #f6f7f8;

            &:before {
                opacity: 0.4;
            }
        }

        .statistics__details-modal-dialog-notifications-block-item-repeat-count {
            position: absolute;
            top: -3px;
            right: -13px;
        }
    }

    &__details-modal-dialog-notifications-block-email-item {
        &:before {
            .icon-email(#73808D);
        }
    }

    &__details-modal-dialog-notifications-block-sms-item {
        &:before {
            .icon-sms(#73808D);
        }
    }

    &__details-modal-dialog-notifications-block-viber-item {
        &:before {
            .icon-viber(#9151C1);
        }
    }

    &__details-modal-dialog-notifications-block-telegram-item {
        &:before {
            .icon-telegram(#1588C9);
        }
    }

    &__details-modal-dialog-notifications-block-item-repeat-count {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 6.32px;

        background: #3F65F1;
        border-radius: 10px;
        font-weight: 900;
        font-size: 11px;
        color: #FFFFFF;

        .statistics__details-modal-dialog-notifications-block-item--disabled & {
            background: #A6B1BC;
        }
    }

    &__details-modal-dialog-dates-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 16px;
        padding-right: 16px;

        .statistics__details-modal-dialog-dates-block-date {
            &:not(:first-child) {
                margin-top: 14px;
            }
        }
    }

    &__details-modal-dialog-dates-block-date {
        display: flex;
        align-items: center;

        font-weight: bold;
        font-size: 12px;
        color: #73808D;
        cursor: help;
    }

    &__details-modal-dialog-dates-block-created-at-date {
        .statistics__details-modal-dialog-dates-block-date-icon {
            .icon-created-at(#73808D);

            margin-right: 10px;
        }
    }

    &__details-modal-dialog-dates-block-passed-at-date {
        .statistics__details-modal-dialog-dates-block-date-icon {
            .icon-passed-at(#73808D);

            margin-right: 11px;
        }
    }

    &__details-modal-dialog-dates-block-last-status-changed-at-date {
        .statistics__details-modal-dialog-dates-block-date-icon {
            .icon-last-status-changed-at(#73808D);

            margin-right: 11px;
        }
    }

    &__details-modal-dialog-questions {
        margin-top: 30px;

        .statistics__details-modal-dialog-questions-nav {
            margin-top: 19px;
        }
    }

    &__details-modal-dialog-questions-header {
        display: flex;
        align-items: center;
    }

    &__details-modal-dialog-questions-title {
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        color: #2E2F31;
    }

    &__details-modal-dialog-questions-answers-count {
        font-weight: normal;
    }

    &__details-modal-dialog-questions-paginator {
        display: flex;

        .statistics__details-modal-dialog-questions-paginator-page {
            &:not(:first-child) {
                margin-left: 12px;
            }
        }
    }

    &__details-modal-dialog-questions-paginator-page {
        width: 12px;
        height: 12px;

        background: #CFD8DC;
        cursor: pointer;
        border-radius: 50%;

        &--active {
            background: #3F65F1;
        }
    }

    &__details-modal-dialog-questions-nav {
        height: 110px;
    }

    &__details-modal-dialog-questions-nav-item-container {
        display: flex;
        position: absolute;

        .statistics__details-modal-dialog-questions-nav-item {
            flex-shrink: 0;

            &:not(:first-child) {
                margin-left: 8px;
            }
        }
    }

    &__details-modal-dialog-questions-nav-item {
        width: 110px;
        height: 110px;
        position: relative;

        border: 1px solid #CFD8DC;
        border-radius: 8px;
        cursor: pointer;
        user-select: none;

        &--disabled {
            opacity: 0.4;
            cursor: default;
        }

        &--active {
            //border: none;
            z-index: 2;

            &:after {
                .frame(#FFF);

                content: '' !important;

                position: absolute;
                left: -6px;
                top: -6px;

                z-index: -1;
            }
        }

        .statistics__details-modal-dialog-questions-nav-item-index {
            position: absolute;
            top: 10px;
            left: 10px;
        }

        .statistics__details-modal-dialog-questions-nav-item-name {
            width: 90px;
            position: absolute;
            top: 71px;
            left: 10px
        }

        .statistics__details-modal-dialog-questions-nav-item-employee-name {
            width: 90px;
            position: absolute;
            top: 86px;
            left: 10px
        }

        .statistics__details-modal-dialog-questions-nav-item-comment-indicator {
            position: absolute;
            top: 11px;
            right: 10px;
        }

        .statistics__details-modal-dialog-questions-nav-item-type {
            width: 100%;
            height: 32px;
            position: absolute;
            top: 31px;
        }
    }

    &__details-modal-dialog-questions-nav-item-index {
        font-weight: 500;
        font-size: 12px;
        color: #2E2F31;
        cursor: help;
    }

    &__details-modal-dialog-questions-nav-item-name {
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        color: #2E2F31;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: help;
    }

    &__details-modal-dialog-questions-nav-item-employee-name {
        font-weight: 500;
        font-size: 11px;
        text-align: center;
        color: #73808D;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: help;
    }

    &__details-modal-dialog-questions-nav-item-comment-indicator {
        .icon-comment(#73808D);

        display: inline-block;
    }

    &__details-modal-dialog-questions-nav-item-type {
        display: flex;
        align-items: center;
        justify-content: center;

        *:not(:first-child) {
            margin-left: 5px;
        }
    }

    &__details-modal-dialog-questions-nav-item-media-type-indicator {
        &--value_text {
            .icon-text(#A6B1BC);
        }

        &--value_vars {
            .icon-type-vars(#A6B1BC);
        }

        &--value_quiz {
            .icon-type-quiz(#A6B1BC);
        }

        &--value_priority {
            .icon-type-priority(#A6B1BC);
        }

        &--value_text-input {
            .icon-type-text(#A6B1BC);
        }

        &--value_address {
            .icon-type-address(#A6B1BC);
        }

        &--value_upload {
            .icon-type-upload(#A6B1BC);
        }

        &--value_date {
            .icon-type-date(#A6B1BC);
        }

        &--value_image {
            .icon-image2(#A6B1BC);
        }

        &--value_video {
            .icon-video(#A6B1BC);
        }
    }

    &__details-modal-dialog-questions-nav-item-rating {
        display: flex;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        position: relative;

        border-radius: 50%;
        font-weight: 900;
        font-size: 13px;
        color: #FFFFFF;

        &--value_1 {
            background: #F96261 !important;
        }

        &--value_2 {
            background: #F97F46 !important;
        }

        &--value_3 {
            background: #F4A224 !important;
        }

        &--value_4 {
            background: #AAB540 !important;
        }

        &--value_5 {
            background: #3ECE67 !important;
        }
    }

    &__details-modal-dialog-questions-view {
        margin-top: 29px;
    }

    &__details-modal-dialog-dish-rating-question {
        display: flex;

        .statistics__details-modal-dialog-dish-rating-question-table-container {
            flex-grow: 1;
        }

        .statistics__details-modal-dialog-dish-rating-question-comment {
            margin-left: 17px;
            max-width: 310px;
        }
    }

    &__details-modal-dialog-dish-rating-question-table-container {
        position: relative;

        &:after {
            content: '';

            position: absolute;
            width: 100%;
            height: 30px;
            bottom: 0;

            background: linear-gradient(0, #FFFFFF 18.23%, rgba(255, 255, 255, 0) 100%);
        }

        .statistics__details-modal-dialog-dish-rating-question-table-wrapper {
            max-height: 200px;
            width: 100% !important;
        }
    }

    &__details-modal-dialog-dish-rating-question-table-wrapper {
        display: flex;

        .scroll-element.scroll-y {
            right: 0;
        }
    }

    &__details-modal-dialog-dish-rating-question-table {
        width: 100%;
        margin-right: 18px;
        margin-bottom: 30px;

        border-top: 1px solid #E7EBED;

        td {
            height: 33px;

            padding: 5px 17px;

            vertical-align: middle;
            border-bottom: 1px solid #E7EBED;
        }
    }

    &__details-modal-dialog-dish-rating-question-table-name-cell {
        font-weight: bold;
        font-size: 14px;
        color: #2E2F31;
    }

    &__details-modal-dialog-rating {
        display: inline-flex;
        align-items: center;

        .statistics__details-modal-dialog-rating-element {
            &:not(:first-child) {
                margin-left: 11px;
            }
        }
    }

    &__details-modal-dialog-rating-element {
        .icon-rate-star(#F8CD1C);

        width: 17.94px;
        height: 17.77px;

        border: none;
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: contain;

        &--active {
            .icon-rate-star-fill(#F8CD1C);
        }
    }

    &__details-modal-dialog-dish-rating-question-table-quantity-cell {
        text-align: right;
    }

    &__details-modal-dialog-dish-rating-question-table-rating-cell {
        width: 134px;
        padding-left: 13px;
    }

    &__details-modal-dialog-dish-rating-question-comment {
        padding: 23px 16px;

        background: #ECEFF1;
        border-radius: 8px;
    }

    &__details-modal-dialog-dish-rating-question-comment-title {
        display: block;

        font-weight: bold;
        font-size: 16px;
        color: #2E2F31;
    }

    &__details-modal-dialog-dish-rating-question-comment-text {
        display: block;
        margin-top: 10px;

        font-size: 14px;
        color: #2E2F31;
    }

    &__details-modal-dialog-basic-question {
        &--media-type_text {
            .statistics__details-modal-dialog-basic-question-clarifying-question-variants {
                margin-left: 15px;
                margin-right: 15px;
            }

            .statistics__details-modal-dialog-basic-question-variants {
                margin-left: 15px;
                margin-right: 15px;
            }
        }

        &--media-type_image,
        &--media-type_video {
            .statistics__details-modal-dialog-basic-question-clarifying-question-variants {
                margin-right: 15px;
            }

            .statistics__details-modal-dialog-basic-question-variants {
                margin-right: 15px;
            }
        }

        .statistics__details-modal-dialog-basic-question-text {
            margin-top: 10px;
        }

        .statistics__details-modal-dialog-basic-question-body {
            margin-top: 18px;
        }

        .statistics__details-modal-dialog-basic-question-clarifying-question {
            margin-top: 27px;
        }
    }

    &__details-modal-dialog-basic-question-name {
        display: block;

        font-weight: 500;
        font-size: 19px;
        color: #2E2F31;
    }

    &__details-modal-dialog-basic-question-text {
        display: block;

        font-weight: normal;
        font-size: 16px;
        color: #73808D;
    }

    &__details-modal-dialog-basic-question-body {
        display: flex;

        .statistics__details-modal-dialog-basic-question-body-media-section {
            margin-right: 32px;
        }

        .statistics__details-modal-dialog-basic-question-body-answer-section {
            flex-grow: 1;
        }
    }

    &__details-modal-dialog-basic-question-rating {
        display: flex;
        align-items: center;

        .statistics__details-modal-dialog-basic-question-rating-label {
            margin-left: 18px;
        }
    }

    &__details-modal-dialog-basic-question-rating-label {
        font-weight: 900;
        font-size: 16px;
        color: #2E2F31;
    }

    &__details-modal-dialog-basic-question-clarifying-question {
        .statistics__details-modal-dialog-basic-question-clarifying-question-variants {
            margin-top: 16px;
        }
    }

    &__details-modal-dialog-service-text {
        font-size: 12px;
        color: #73808D;
        font-weight: normal;
    }

    &__details-modal-dialog-basic-question-clarifying-question-text {
        font-weight: bold;
        font-size: 16px;
        color: #2E2F31;
    }

    &__details-modal-dialog-basic-question-clarifying-question-variants {
        .statistics__details-modal-dialog-basic-question-clarifying-question-variant {
            &:not(.statistics__details-modal-dialog-basic-question-clarifying-question-variant--selected) {
                border-top: 1px solid #E7EBED;

                &:last-child {
                    border-bottom: 1px solid #E7EBED;
                }
            }
        }

        .statistics__details-modal-dialog-basic-question-clarifying-question-variant--selected + .statistics__details-modal-dialog-basic-question-clarifying-question-variant {
            border-top: none;
        }
    }

    &__details-modal-dialog-basic-question-clarifying-question-variant {
        padding: 10px 1px;

        font-size: 16px;
        color: #2E2F31;

        &--selected {
            margin-left: -15px;
            margin-right: -15px;
            padding: 11px calc(1px + 15px);

            background: #D9E0FC;
            border-radius: 8px;

            & + .statistics__details-modal-dialog-basic-question-variant--selected {
                margin-top: 1px;
            }
        }

        .statistics__details-modal-dialog-basic-question-clarifying-question-custom-answer {
            margin-top: 12px;
        }
    }

    &__details-modal-dialog-basic-question-clarifying-question-custom-answer {
        display: block;

        font-size: 14px;
    }

    &__details-modal-dialog-basic-question-variants {
        .statistics__details-modal-dialog-basic-question-variant {
            &:not(.statistics__details-modal-dialog-basic-question-variant--selected) {
                border-top: 1px solid #E7EBED;

                &:last-child {
                    border-bottom: 1px solid #E7EBED;
                }
            }
        }

        .statistics__details-modal-dialog-basic-question-variant--selected + .statistics__details-modal-dialog-basic-question-variant {
            border-top: none;
        }
    }

    &__details-modal-dialog-basic-question-variant {
        padding: 10px 1px;

        font-size: 16px;
        color: #2E2F31;

        &--selected {
            margin-left: -15px;
            margin-right: -15px;
            padding-left: calc(1px + 15px);
            padding-right: calc(1px + 15px);

            background: #D9E0FC;
            border-radius: 8px;
            &+.statistics__details-modal-dialog-basic-question-variant--selected {
                margin-top: 1px;
            }
        }
    }

    &__details-modal-dialog-basic-question-media-wrapper {
        width: 293.33px;
        height: 220px;

        border-radius: 8px;
        overflow: hidden;
    }

    &__details-modal-dialog-basic-question-image {
        width: 100%;
        height: 100%;
        object-fit: cover;

        cursor: pointer;
    }

    &__details-modal-dialog-basic-question-video {
        width: 100%;
        height: 100%;
        position: relative;

        cursor: pointer;
    }

    &__details-modal-dialog-basic-question-video-content {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__details-modal-dialog-basic-question-video-mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background: black;
        opacity: 0.5;
    }

    &__details-modal-dialog-basic-question-video-play-button {
        .icon-video-play-button(#FFF);

        position: absolute;
        top: calc((100% - 50px) / 2);
        left: calc((100% - 72px) / 2);

        opacity: 0.7;
    }

    &__details-modal-dialog-basic-question-slider {
        width: 100%;
        height: 100%;
    }

    &__details-modal-dialog-complaint {
        margin-top: 30px;
        margin-left: -30px;
        margin-right: -30px;
        padding: 22px 30px 24px;

        background: #FFDDDD;

        .statistics__details-modal-dialog-complaint-text {
            margin-top: 10px;
        }

        .statistics__details-modal-dialog-complaint-photos {
            margin-top: calc(10px + (-5px));
        }
    }

    &__details-modal-dialog-complaint-title {
        font-weight: bold;
        font-size: 19px;
        color: #2E2F31;
    }

    &__details-modal-dialog-complaint-text {
        display: block;

        font-size: 14px;
        color: #2E2F31;
    }

    &__details-modal-dialog-complaint-photos {
        display: flex;
        flex-wrap: wrap;
        margin-top: -5px;
        margin-left: -5px;

        .statistics__details-modal-dialog-complaint-photo {
            margin-top: 5px;
            margin-left: 5px;
        }
    }

    &__details-modal-dialog-complaint-photo {
        width: 35px;
        height: 35px;

        border-radius: 8px;
        object-fit: cover;
        cursor: pointer;
    }

    &__details-modal-dialog-processing {
        margin-top: 30px;
    }

    &__details-modal-dialog-processing-title {
        font-weight: bold;
        font-size: 19px;
        color: #2E2F31;
    }

    &__details-modal-dialog-processing-controls {
        margin-top: 20px;
    }

    &__details-modal-dialog-processing-view {
        margin-top: 20px;
    }

    &__details-modal-dialog-status-form-control-value {
        &--new {
            color: #16CEB9 !important;
        }

        &--process {
            color: #2D99FF !important;
        }

        &--delayed {
            color: #F96261 !important;
        }

        &--completed {
            color: #8400FF !important;
        }
    }

    &__details-modal-dialog-footer {
        display: flex;
        margin: 0 -30px -20px -30px;

        border-top: none;
        background: #FBFBFB;
        padding: 21.5px 30px;

        *:not(:first-child) {
            margin-left: 13px;
        }
    }

    &__details-modal-dialog-view-form-group {
        margin-bottom: 26px;
    }

    &__details-modal-dialog-view-form-label {
        font-size: 16px;
        color: #73808D;
    }

    &__details-modal-dialog-view-form-control {
        display: block;
        margin-top: 8px;

        font-weight: bold;
        font-size: 16px;
        color: #2E2F31;
    }

    &__details-modal-dialog-status-view-form-control {
        color: #9151C1;
    }

    &__details-modal-dialog-history-nav {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        position: relative;

        border: 1px solid #CFD8DC;
        border-radius: 4px;
        overflow: hidden;

        .nav-item {
            display: flex;
            flex-grow: 1;
            width: 100%;
            align-items: center;
            padding: 14.4px 15px;

            font-weight: bold;
            font-size: 16px;
            color: #2E2F31;
            border: none;
            border-radius: 0;

            &:not(:first-child) {
                border-left: 1px solid #CFD8DC;
            }

            &:not(.active) {
                background: #F1F5F6;
                color: #73808D;
            }
        }
    }

    &__details-modal-dialog-history-nav-item {
        .statistics__details-modal-dialog-history-nav-item-count {
            margin-left: 11px;
        }
    }

    &__details-modal-dialog-history-nav-item-count {
        font-size: 16px;
        font-weight: normal;
        color: #73808D;
    }

    &__details-modal-dialog-history-orders-table {
        margin-top: 24px;
        margin-left: -7px;
        margin-right: -7px;
        width: calc(100% + 14px);
    }

    &__details-modal-dialog-history-orders-table-row {
        &--open {
            background: #ECEFF1;

            &:not(:first-child) {
                & > td {
                    padding-top: calc(0.75rem + 1px);

                    border-top: none;
                }
            }

            & + .statistics__details-modal-dialog-history-orders-table-content-row + .statistics__details-modal-dialog-history-orders-table-row {
                & > td {
                    padding-top: calc(0.75rem + 1px);

                    border-top: none;
                }
            }

            & + .statistics__details-modal-dialog-history-orders-table-content-row {
                border-bottom: 2px solid #E7EBED;
            }
        }
    }

    &__details-modal-dialog-history-orders-table-id-cell {
        cursor: pointer;
    }

    &__details-modal-dialog-history-orders-table-id {
        color: #3F65F1 !important;
        font-weight: 500;
        text-decoration: none !important;
    }

    &__details-modal-dialog-history-orders-table-price-cell {
        white-space: nowrap;
    }

    &__details-modal-dialog-history-orders-table-price-ruble-sign {
        font-size: 12px;
    }

    &__details-modal-dialog-history-orders-table-arrow-cell {
        width: 34px;
        padding-left: 14px !important;
        padding-right: 6px !important;
    }

    &__details-modal-dialog-history-orders-table-arrow {
        .icon-arrow-bottom2(#73808D);

        display: inline-block;

        cursor: pointer;

        transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);

        .statistics__details-modal-dialog-history-orders-table-row--open & {
            transform: rotate(180deg);
        }
    }

    &__details-modal-dialog-history-orders-table-sending-indicator-cell {
        width: 41px;

        padding-right: 9px !important;
    }

    &__details-modal-dialog-history-orders-table-feedback-indicator-cell {
        width: 39px;

        padding-left: 9px !important;
        padding-right: 16px !important;
    }

    &__details-modal-dialog-history-orders-table-sending-indicator {
        .icon-sending(#73808D);

        display: inline-block;
    }

    &__details-modal-dialog-history-orders-table-feedback-indicator {
        .icon-feedback(#37A74A);

        display: inline-block;
    }

    &__details-modal-dialog-history-orders-table-content-row {
        & > td {
            padding: 0;
            border-top: none;
        }
    }

    &__details-modal-dialog-history-orders-table-content {
        padding-top: 13px;
        padding-bottom: 16.5px;
    }

    &__details-modal-dialog-history-orders-table-content-table {
        & > thead {
            & > tr {
                & > th {
                    padding: 8px 16px;

                    font-size: 16px;
                    color: #2E2F31
                }
            }
        }

        & > tbody {
            & > tr {
                & > td {
                    padding: 8px 16px;
                }

                &:first-child {
                    & > td {
                        border-top-width: 2px;
                    }
                }
            }
        }
    }

    &__details-modal-dialog-history-orders-table-order-list-table-quantity-cell {
        text-align: right;
    }

    &__details-modal-dialog-history-orders-table-order-list-table-price-head-cell {
        white-space: nowrap;
        text-align: right;

        .statistics__details-modal-dialog-history-orders-table-order-list-table-price-ruble-sign {
            font-size: 14px;
        }
    }

    &__details-modal-dialog-history-orders-table-order-list-table-price-cell {
        white-space: nowrap;
        text-align: right;

        .statistics__details-modal-dialog-history-orders-table-order-list-table-price-ruble-sign {
            font-size: 12px;
        }
    }

    &__details-modal-dialog-history-orders-table-history-table-time-cell {
        text-align: right;
    }

    &__details-modal-dialog-history-orders-table-info-block {
        display: flex;
        padding-left: 12px;

        .statistics__details-modal-dialog-history-orders-table-info-block-item {
            &:not(:first-child) {
                margin-left: 64px;
            }
        }
    }

    &__details-modal-dialog-history-orders-table-info-block-item {
        .statistics__details-modal-dialog-history-orders-table-info-block-item-value-row {
            margin-top: 4px;
        }
    }

    &__details-modal-dialog-history-orders-table-info-block-item-label {
        font-weight: bold;
        font-size: 14px;
        color: #2E2F31;
    }

    &__details-modal-dialog-history-orders-table-info-block-item-value-row {
        display: block;

        font-size: 14px;
        color: #2E2F31;
    }

    &__details-modal-dialog-history-orders-table-date {
        & > *:not(:first-child) {
            margin-left: 7px;
        }
    }

    &__details-modal-dialog-history-reviews-table {
        margin-top: 24px;
    }

    &__details-modal-dialog-history-reviews-table-name {
        color: #3F65F1 !important;
        font-weight: 500;
        text-decoration: none !important;
        cursor: pointer;
    }

    &__details-modal-dialog-history-reviews-table-id {
        color: #3F65F1 !important;
        font-weight: 500;
        text-decoration: none !important;
        cursor: pointer;
    }

    &__details-modal-dialog-history-reviews-table-price-cell {
        white-space: nowrap;
    }

    &__details-modal-dialog-history-reviews-table-price-ruble-sign {
        font-size: 12px;
    }

    &__details-modal-dialog-history-reviews-table-complaint-indicator-cell {
        width: 43px;

        padding-right: 9px !important;
    }

    &__details-modal-dialog-history-reviews-table-compensation-indicator-cell {
        width: 44px;

        padding-left: 9px !important;
        padding-right: 16px !important;
    }

    &__details-modal-dialog-history-reviews-table-complaint-indicator {
        .icon-complaint(#F96261);

        display: inline-block;
    }

    &__details-modal-dialog-history-reviews-table-compensation-indicator {
        .icon-compensation(#73808D);

        display: inline-block;
    }

    &__details-modal-dialog-history-reviews-table-rating-cell {
        .statistics__answer-list {
            width: 200px;
        }
    }

    &__details-modal-dialog-history-reviews-table-row {
        cursor: pointer;
    }

    &__details-modal-dialog-history-compensations-table {
        margin-top: 24px;
    }

    &__details-modal-dialog-history-compensations-table-name {
        color: #3F65F1 !important;
        font-weight: 500;
        text-decoration: none !important;
        cursor: pointer;
    }

    &__details-modal-dialog-history-compensations-table-compensation-usage-indicator-cell {
        width: 48px;

        padding-left: 9px !important;
        padding-right: 16px !important;
    }

    &__details-modal-dialog-history-compensations-table-compensation-usage-indicator {
        .icon-compensation-usage(#37A74A);

        display: inline-block;
    }

    &__details-modal-dialog-history-compensations-table-compensation-name-cell,
    &__details-modal-dialog-history-compensations-table-compensation-reason-cell,
    &__details-modal-dialog-history-compensations-table-notification-script-cell {
        font-size: 12px;
    }

    &__details-modal-dialog-history-compensations-table-date {
        & > *:not(:first-child) {
            margin-left: 7px;
        }
    }
}

.statistics__details-modal-dialog-basic-question--input-text {
    border-radius: 8px;
    background-color: #eceff1;
    font-size: 16px;
    line-height: 1.2;
    color: #2e2f31;
    padding: 11px 19px;
    &_body {
        padding: 20px 15px;
        font-size: 14px;
        line-height: 1.3;
        .title {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 9px;
            line-height: 1;
        }
    }
    &_quiz {
        margin-bottom: 1px;
        padding: 15px 20px 12px;
        .label {
            font-size: 12px;
            color: #73808d;
            line-height: 1.08;
            margin-bottom: 4px;
        }
    }
}

.statistics__details-modal-dialog-basic-question-files-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: calc(100% + 10px);
    padding-bottom: 9px;
    padding-top: 3px;
}

.statistics__details-modal-dialog-basic-question-file {
    width: 105px;
    height: 105px;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    img,video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    }
    .pseudo {
        position: absolute;
        z-index: 999;
        opacity: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

.statistics__details-modal-dialog-basic-question-video {
    &:before {
        .icon-video-play-button(#FFF);
        position: absolute;
        top: calc((100% - 50px) / 2);
        left: calc((100% - 72px) / 2);
        opacity: 0.7;
    }
}

.statistics__details-modal-dialog-priority-variant {
  display: flex;
  align-items: center;
  min-height: 40px;
  margin: 1px 0;
  font-size: 16px;
  color: #2E2F31;
  background: #ECEFF1;
  border-radius: 8px;
  padding: 15px;

  &-index {
      font-weight: bold;
      margin-right: 10px;
  }
}
