.question-type-select.select2-container {
  .question-type-select__option {
    display: flex;
    align-items: center;
  }

  .f-icon {
    &-question-type {
      &--stars, &--rate {
        & + span {
          margin-top: 3px!important;
        }
      }
    }
  }



  .select2-selection {
    .question-type-select__option {
      .f-icon {
        color: @f-color-light;
        margin-right: 8px;
        margin-top: 2px;


      }
    }
  }

  .select2-dropdown {
    &:before {
      left: 242px;
    }
  }

  .select2-results {
    &__options {
      width: 590px;
      max-height: 346px!important;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
    &__option {
      width: 295px;
      padding: 0 8px!important;
      align-items: stretch!important;
      .question-type-select__option {
        flex-grow: 1;
        border-radius: 4px;
        padding-left: 8px;
        padding-right: 8px;
        .f-icon {
          color: @f-color-primary;
          margin-right: 20px;
        }
      }

      &--highlighted, &[aria-selected="true"] {
        background-color: transparent;
        .question-type-select__option {
          background-color: #F1F5F6;
          color: #73808D;
        }

      }

      &[aria-selected="true"] {
        .question-type-select__option {
          .f-icon {
            color: @f-color-light;
          }
        }
      }
    }
  }
}
