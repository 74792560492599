.mailing-card {
  width: 100%;
  position: relative;

  &__checked {
    position: absolute;
    top: 9px;
    right: 9px;
    z-index: 6;


    .f-check-input:not(:checked) + .f-check-label {
      &:before {
        background: transparent;
      }
    }

  }

  &__content {
    width: 100%;
    height: 140px;
    border-radius: 9px;
    padding: 5px 10px 10px;
    background-color: #2e2f31;
    color: #fff;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &-link {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      cursor: pointer;
    }

    button,
    [data-tooltip] {
      position: relative;
      z-index: 4;
    }
  }

  &__curtain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 250ms;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 140px;
    z-index: 5;

    .f-btn {
      width: 120px;
      margin: 5px 0;
    }
  }

  &:not(.mailing-card--archived) {
    &:hover {
      .mailing-card__content {
        background-color: #496ebe;
      }
      .dropdown button {
        color: #ffffff;
      }
    }

    .archived-card-checked & {
      .mailing-card__checked {
        visibility: hidden;
      }
    }
  }

  &--archived {
    .mailing-card__checked {
      .f-check {
        &-label {
          &:before {
            border-color: white;
          }
        }
      }
    }
    .not-archived-card-checked & {
      .mailing-card__checked {
        visibility: hidden;
      }
    }
    .mailing-card__content {
      opacity: 0.2;
    }

    &:hover {
      .mailing-card__curtain {
        opacity: 1;
      }
    }
  }

  &.mailing-card--checked {
    .mailing-card__content {
      background-color: #496ebe;
    }
    .dropdown button {
      color: #ffffff;
    }
  }

  &__header {
    font-size: 11px;
  }

  &__stats {
    font-weight: bold;

    .f-icon--target {
      margin-top: -6px;
    }
  }

  &__mailing-icon {
    color: @f-color-blue;
  }

  &__footer {
    margin-top: 15px;
  }

  &__link {
    color: @f-color-text;
    text-decoration: none;

    &:hover {
      color: @f-color-primary;
      text-decoration: none;
    }
  }

  &__name {
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__folder {
    font-size: 12px;
    color: @f-color-service;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
