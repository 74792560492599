//*********************************************
//		Assets
//*********************************************
@import "parts/reset";

@import "parts/base";
@import "parts/media";
@import "parts/fonts";
@import "parts/_mixins";
@import "parts/sur";
@import "parts/filter";
@import "parts/_badge";
@import "parts/_buttons";
@import "parts/_form";
@import "parts/_menu-quests";
@import "parts/_modal";
@import "parts/_typography";
@import "parts/_icons";
@import "parts/_tooltip";
@import "parts/_popovers";
@import "parts/_pages-loader";
@import "parts/_dropdown";
@import "parts/_alert";
@import "parts/_tab-group";
@import "parts/_hat-radio-group";
@import "parts/_points";
@import "parts/_spacer";
@import "parts/_spinner";
@import "parts/_sortable";
@import "parts/_time-picker";
@import "parts/_ckeditor";
@import "parts/_image-picker";
@import "parts/_flex-table";
@import "parts/_dense-tab-group";
@import "parts/_foq-table";
@import "parts/_select-group";
@import "parts/_scrollbar";
@import "parts/_breadcrumbs";
@import "parts/_header-name-form";
@import "parts/_page-header";
@import "parts/_dense-form-group";
@import "parts/fancybox";
@import "parts/_color-picker";
@import "parts/_scrollable-wrapper";

//*********************************************
//		Blocks
//*********************************************
@import "components/quick-help";
@import "components/sidebar";
@import "components/footer";
@import "components/upward-button";
@import "components/rating-slider";
@import "components/rating";
@import "components/review-details-modal/_review-details-modal";
@import "components/progress-bar";
@import "components/number-control.less";
@import "components/conditions/conditions.less";
@import "components/link-field-block.less";
@import "components/question-form/question-form.less";
@import "components/media-preview.less";
@import "components/media-wrapper.less";
@import "components/mailing-card.less";
@import "components/folder-card.less";
@import "components/search-field.less";
@import "components/cards-group.less";
@import "components/folders-list.less";
@import "components/search-block.less";
@import "components/share.less";
@import "components/kanban.less";
@import "components/sending-list.less";

@import "components/tag-input";
@import "components/slider";
@import "components/select-list.less";
@import "components/_change-status-modal.less";
@import "components/_edit-columns-modal.less";
@import "components/_group-actions-modal.less";
@import "components/_scroll-container.less";

@import "components/delete-report-modal-dialog";
@import "components/share-report-modal-dialog";
@import "components/edit-columns-modal-dialog";
@import "components/delete-service-access-modal-dialog";
@import "components/service-access-details-modal-dialog";


@import "pages/survey-list/survey-list";
@import "pages/questions/quest";
@import "pages/survey/index";
@import "pages/design/design";
@import "pages/answers/answers";
@import "pages/settings/settings";
@import "pages/statistics/statistics";
@import "pages/reports/report";
@import "pages/reports/consolidated-report/consolidated-report";
@import "pages/reports/branch-contact-points-report/branch-contact-points-report";
@import "pages/reports/contact-points-report/contact-points-report";
@import "pages/report-list/report-list";
@import "pages/clients/clients";
@import "pages/mailings/mailings";
@import "pages/question-statistics/question-statistics";
@import "pages/contact-points/contact-points";
@import "pages/companies/companies";
@import "pages/company/company";
@import "pages/company-settings/company-settings";
@import "pages/users/users";
@import "pages/survey-question/survey-question";
@import "pages/poll-design/poll-design";
@import "pages/poll-design/auto-poll-info";
@import "pages/error/error";
@import "pages/mailing-conditions/mailing-conditions";
@import "pages/login/login";
@import "pages/mailing-settings/index";
@import "pages/mailings-list/index";
@import "pages/mailing-sender/style";
@import "pages/mailing-answers/index";


@import "parts/_media.less";


.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 10%;
  width: 80%;
  height: 80%;
}
.survey-questions__slider-letter {
  text-transform: uppercase;
}
.survey__images-form-control-add-button-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #ECEFF1;
  border-radius: 8px;
  color: #73808D;
  cursor: pointer;
  width: 105px;
  height: 105px;
  margin-top: 15px;
  margin-left: 15px;
  transition: background-color 0.2s ease;
}
.survey__images-form-control-add-button-loader:hover {
  background: #C6CFD3;
}
.survey__images-form-control-add-button-loader:after {
  content: "\f110";
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  background-color: transparent;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  width: auto;
  height: auto;
  color: #3F65F1;
  font-size: 20px;
  top: 36px;
  left: 36px;
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}


// new year 2021-2022

.sidebar__logo-link {
  background-image: url("/img/logo-ru.svg");

  &:hover {
    background-image: url("/img/logo-ru.svg");
  }
}
