.review-details-modal-wrapper--adaptive,
.review-sidesheet {
  .nav-item--close {
    width: 48px;

    font-size: 24px;
  }

  .tab-inner {
    padding: 0 30px;
  }

  .review-questions__view {
    margin-top: 20px;
  }

  @media screen and (max-width: 991px) {
    width: 100% !important;
    margin-left: 0 !important;

    .modal-page__body {
      overflow: auto;
    }

    .nav-item {
      width: auto;

      &--order {
        flex-grow: 2;
      }
    }

    .review-details-modal-nav__poll-name {
      font-size: 14px;
      font-weight: 700;
    }

    .tab-inner {
      padding: 0 20px;
    }

    .review-details-modal-wrapper__actions {
      display: none;
    }

    .review-details-modal__info-block {
      border-radius: 0;
    }

    .review-questions__view {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    .nav-item {
      padding-left: 15px;
      padding-right: 15px;
      overflow: hidden;

      .review-details-modal-nav__poll-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      span {
      }
    }

    .tab-inner {
      padding: 0;
    }

    .events-filters {
      padding: 0 15px;
      flex-direction: column;
      align-items: flex-start !important;

      &__actions {
        margin-left: 0 !important;
        margin-top: 24px;
      }
    }

    .review-question-preview-wrapper {
      width: 110px!important;
    }


    .review-details-modal__info {
      margin-left: 0;
      margin-right: 0;
    }

    .review-details-modal__info-block {
      margin-left: 0;
      margin-right: 0;
      padding-left: 15px;
      padding-right: 15px;
    }

    .review-question__self-answer {
      margin-left: 0!important;
      margin-right: 0!important;
      border-radius: 0!important;
      padding-left: 15px;
      padding-right: 15px;
    }

    .review-dates {
      .review-date {
        margin: 0!important;
      }
    }

    .review-details-modal__info-block--full {
      margin-bottom: 0;
      max-width: 100%;
    }

    .call-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 48px;
      color: white;
      text-decoration: none;
      font-size: 16px;
      font-weight: 700;

      .call-block {
        margin: 0;
        margin-right: 0px;
      }
    }

    .review-dish-rating-question {
      display: block;

      .review-dish-rating-question__comment {
        width: 100%;
        max-width: none;
        margin-left: 0;
      }
    }

    .review-question-view__name {
      font-size: 16px;
    }

    .review-question-view__meta {
      margin-top: 8px;
      padding-left: 2px;
    }

    .review-question-view__body {
      display: block;
      margin-top: 12px;
    }

    .review-question-view__media-section {
      padding-left: 15px;
      padding-right: 15px;
      margin-right: 0;
      margin-bottom: 20px;
    }

    .review-question-view__name,
    .review-question-view__gallery,
    .review-question-view__text,
    .review-question-view-rating,
    .review-question-view-clarifying-question__text,
    .review-complaint,
    .nps-result,
    .smile-result,
    .review-questions-view-priority,
    .review-question-view-file,
    .review-question-view__meta,
    .review-questions-view-media-variants {
      padding-left: 15px;
      padding-right: 15px;
    }

    .review-details__points {
      padding-left: 0;
      padding-right: 0;
    }

    .review-complaint {
      margin-left: 0;
      margin-right: 0;
    }

    .diff-table {
      width: 100% !important;
    }

    .review-question-view-block {
      margin-left: 15px;
      margin-right: 15px;
    }

    .review-question-view-variant--selected {
      border-radius: 0;
    }

    .processing-form {
      padding: 0 15px;
      margin-top: 24px;
    }

    .edit-columns-modal-dialog .edit-columns-modal__column {
      width: 100%;
    }

    .review-details-modal__footer {
      justify-content: center;
      padding-left: 15px;
      padding-right: 15px;

      .spacer {
        display: none;
      }

      button.btn,
      button.f-btn {
        width: calc(50% - 5px);
        max-width: 150px;
        margin: 0 !important;

        &:last-child {
          margin-left: 10px !important;
        }

        &.btn-close {
          margin-left: 0 !important;
          width: 100% !important;
          max-width: none !important;
          text-align: left;
        }
      }
    }

    .review-question-view-comment {
      margin-left: -15px;
      margin-right: -15px;
      border-radius: 0;
    }

    .review-questions-view--type_3 {

      .review-question-view-block {
        margin-left: 0;
        margin-right: 0;
        border-radius: 0;
      }
    }

    .review-question-view--type_matrix {
      table {
        .matrix-row-name-cell {
          & + td,
          & + th {
            padding-left: 15px;
          }
        }

        td:last-child,
        th:last-child {
          padding-right: 15px;
        }
      }
    }

    .matrix-row-name {
      td {
        padding-bottom: 0;
        padding-left: 15px;
        padding-right: 15px;
      }
      & + tr {
        td {
          border-top: 0;
        }
      }
    }
  }
}
