.semantic-differencial {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;

  .diff-table {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 34px;

    &__point {
      text-align: center;
      padding-top: 5px;
    }

    &__point-filler {
      width: 26px;
      &:before {
        display: block;
        content: '';
        background: #E1E8EB;
        width: 26px;
        height: 48px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }

  .diff-row {
    td {
      height: 58px;
      padding: 5px 2px;
      vertical-align: top;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    &__drag-indicator {
      cursor: grab;
      color: @f-color-light;
    }

    &__name-field {
      input {
        width: 100%;
        height: 48px;
        padding: 0 12px;
        min-width: 56px;
        max-width: 100%;
      }
    }

    .diff-row__delete, .diff-row__drag {
      vertical-align: middle;
    }

    &--dragging {
      opacity: 0.5;
      background: #d9e0fc;
    }

    &.selected {
      background: #f1f5f6;

      .f-icon {
        color: #73808d;
      }
    }
  }

  &__add-row {
    min-width: 50%;
  }
}

.diff-ghost {
  background: white;
  box-shadow: 0px 6px 15px rgba(63, 101, 241, 0.3);

  td {
    vertical-align: middle;
    text-align: center;
  }
}
