.survey__images-form-control-add-button_big,.survey__images-form-control-content .survey__images-form-control-add-button.survey__images-form-control-add-button_big {
    width: 105px;
    height: 105px;
    padding: 0 10px;
}

.form-group_to_check.form-group {
    margin-bottom: 19px;
}

.survey-question__media-form-control-list-content_flex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    .i-icon-photovideo {
        .icon-photovideo(#A6B1BC);
    }
    .survey-question__media-form-control-list-loading-placeholder {
        width: 105px;
        height: 105px;
        margin: 0 10px 0 0 !important;
    }
    .survey-questions__image.survey-question__media-form-control-list-item {
        width: 105px;
        height: 105px;
        margin: 0 10px 0 0 !important;
    }
    .survey-question__media-form-control-list-item:after {
        display: none !important;
    }
}

.survey-questions {

    &__search-menu {
        padding-bottom: 15px;

        border: 1px solid #3F65F1 !important;
        border-top: none !important;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        box-shadow: 2px 6px 6px rgba(46, 47, 49, 0.15);

        .survey-list__search-menu-item:not(:first-child) {
            margin-top: 2px;
        }

        .ui-menu-item-wrapper {
            border: none;
            background: none;
            margin: 0;
            padding: 0;
        }
    }

    &__search-menu-item {
        display: flex;
        height: 34px;
        align-items: center;
        padding-left: 8px;

        &--focus {
            background: #F1F5F6;
        }
    }

    &__search-menu-item-icon {
        display: inline-block;
        margin-right: 11px !important;
    }

    &__search-menu-item-folder-icon {
        .icon-folder(#A6B1BC) !important;

        margin-left: 2px !important;
    }

    &__search-menu-item-survey-icon {
        .icon-survey(#A6B1BC) !important;
    }

    &__search-menu-item-name {
        font-size: 14px;
        color: #3F65F1;
    }

    &__search-menu-item-sub-name {
        font-size: 12px;
        color: #73808D;
    }

    &__search-menu-no-data-placeholder {
        padding: 5px 54.3px 20px 54.3px;

        font-size: 12px;
        color: #2E2F31;
        cursor: text !important;
    }

    &__search-menu-loading-placeholder {
        display: flex;
        align-self: center;
        justify-content: center;
        margin: 0 !important;
        height: unset !important;
        padding: 15px 0 10px;

        font-size: 14px !important;
        color: #3f65f1;
        border: none !important;
    }

    &__content {
        display: flex;
        max-width: 100%;
        // overflow: hidden;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }

    &__image {
        position: relative;
        width: 105px;
        height: 105px;
        margin-right: 10px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &:hover {
            .survey-question__media-form-control-list-item-remove-button {
                opacity: 1;
            }
        }
    }

    &__wrapper {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0 35px;
        .form-group {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__body {
        max-width: 100%;
        flex-grow: 1;
    }

    &__footer {
        margin-bottom: 18px;
    }

    /** <Paginator> **/

    &__paginator {
        box-sizing: content-box;
        max-width: 100%;
        margin-top: 17px;

        &--compact {
            .survey-questions__paginator-anchor {
                display: flex;
                width: 32px;
                margin-right: 0;

                &.active-anchor {
                    margin-right: 5px;
                }
            }

            .survey-questions__paginator-anchor-text {
                display: none;
            }

            .swiper-slide {
                width: 32px;

                &.active-tab {
                    width: auto;
                }

                &:not(:first-child) {
                    margin-left: 3px;
                }
            }
        }

        .nav-tabs {
            border: none;
        }

        .swiper-wrapper {
            flex-wrap: nowrap;
            padding-left: 6px;
            padding-right: 6px;
        }

        .swiper-slide {
            width: auto;
        }
    }

    &__paginator-anchor {
        display: flex;
        width: auto;
        border-radius: 50%;
        align-items: center;
        transition: none;
        margin-right: 14px;

        &.active-anchor {
            width: auto;
            background: none;

            .survey-questions__paginator-anchor-num-wrapper {
                padding: 5px;
                border-width: 3px;
                border-style: solid;
            }

            .survey-questions__paginator-anchor-text {
                display: inline;
                opacity: 1;
            }
        }

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }

    &__paginator-anchor-num-wrapper {
        padding: 8px;
        border-radius: 50%;
        margin-left: -3px;
        margin-right: -3px;
    }

    &__paginator-anchor-num {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;

        background-color: #cfd8dc;
        color: #000;
        border-radius: 50%;
        font-size: 14px;
        line-height: 15px;
        font-weight: bold;
    }

    &__paginator-anchor-text {
        mix-blend-mode: normal;
        opacity: 0.6;
        font-weight: 500;
        margin-left: 3px;
        font-size: 15px;

        .active-anchor & {
            margin-left: 11px;
        }
    }

    /** </Paginator> **/


    &__tab-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }

    &__tab-pane {
        flex-grow: 1;
        display: flex !important;
        flex-direction: column;

        &:not(.active) {
            display: none !important;
        }
    }

    /** <Question> **/

    &__question {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        padding-bottom: 26px;

        &--type_dishes {
            .survey-questions__question-object {
                &--type_single-multimedia {
                    .survey-questions__question-object-content {
                        background: none;
                    }
                }
            }

            .survey-questions__question-card {
                padding: 20px 20px 40px;

                & > .survey-questions__question-card-column {
                    &:last-child {
                        position: relative;
                        padding-top: 0;

                        &:before {
                            content: '';

                            position: absolute;
                            top: -20px;
                            left: 0;
                            width: 100%;
                            height: 37px;
                            z-index: 1;

                            background: linear-gradient(180deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%);

                            display: none;
                        }

                        .survey-questions__question-card-column-content {
                            width: 100%;
                            height: unset !important;
                            overflow: visible !important;

                            & > .scroll-content {
                                box-sizing: border-box !important;
                                width: 100% !important;
                                align-items: stretch;
                            }

                            .scroll-element {
                                display: none;
                            }
                        }
                    }
                }
            }

            &.survey-questions__question--multimedia-type_less {
                .survey-questions__question-card > .survey-questions__question-card-column {
                    &:last-child {
                        &:before {
                            display: none;
                        }
                    }
                }
                .survey-questions__question-like-all-button {
                    margin-top: 0;
                }
            }
        }

        &:not(.survey-questions__question--type_dishes).survey-questions__question--multimedia-type_less {
            .survey-questions__question-card {
                padding-right: 0;
                padding-left: 0;
                padding-bottom: 40px;

                & > .survey-questions__question-card-column {
                    width: 100%;
                }
            }

            .survey-questions__form-text {
                margin-bottom: 24px;
            }

            .survey-questions__form {
                width: calc(100% - 70px);
                margin-left: 35px;
                margin-right: 35px;
            }

            .survey-questions__choice {
                margin-left: 35px;
                margin-right: 35px;

                text-align: start;

                &--option {
                    align-self: flex-start;
                    font-size: 15px;
                }
            }

            .survey-questions__option {
                justify-content: flex-start;
                margin-left: 25px;
                margin-right: 35px;
            }
        }
    }

    &__question-object {
        display: block;
        height: 100%;

        width: 100%;
        max-width: 100%;

        overflow: hidden;

        &--type_text {
            .survey-questions__question-object-content {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-radius: 11px;
                padding: 36px 44px;
                font-size: 15px;
                line-height: 1.3;
                text-align: center;
                color: #000;
            }
        }

        &--type_single-multimedia {
            .survey-questions__question-object-content {
                border-radius: 12px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                background: black;
                position: relative;
            }
        }

        &--type_multiple-multimedia {
            border-radius: 0;

            .survey-questions__question-object-content {
                max-height: 238px;
            }
        }
    }

    &__question-object-content {
        width: 100%;
        height: 100%;
    }

    &__question-object-content-img {
        width: 100%;
        height: 100%;

        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__question-message {
        max-width: 660px;
        text-align: center;
        margin: 12px 30px 0 30px;
    }

    &__question-card {
        display: flex;
        flex-wrap: wrap;
        margin-top: 22px;
        width: 100%;

        border-radius: 8px;
        padding: 14px 14px 36px;
    }

    &__question-card-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    &__question-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        width: 100%;
        max-width: 660px;

        padding-left: 12px;
        padding-right: 12px;
    }

    &__question-actions-btn {

        font-size: 16px !important;
        width: 100%;
        max-width: 155px;
        min-height: 48px;
        background: none;
        border-width: 2px!important;
        border-style: solid!important;
        border-color: currentColor;
        border-radius: 100px;

        line-height: 1;
        padding-top: 2px;
        padding-bottom: 2px;

        &:not(:first-child) {
            margin-left: 24px;
        }

        &.focus, &:focus {
            outline: 0;
            box-shadow: none;
        }

        &--auth {
            background-color: var(--poll-main-color);
            border-color: var(--poll-main-color);
            max-width: 97px;
            font-size: 15px !important;
            color: white !important;
            border: none !important;
            white-space: nowrap;
            @media screen and (max-width: 767px) {
                max-width: 100%;
            }
        }

        @media screen and (max-width: 768px) {
            min-height: 36px;
            &:not(:first-child) {
                margin-left: 10px;
            }
        }
    }

    &__question-like-all-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 165px;
        height: 40px;
        margin-top: 18px;
        font-weight: bold;
        font-size: 15px;
        color: #FFF !important;
        border-radius: 100px;

        &.focus, &:focus {
            outline: 0;
            box-shadow: none;
        }


    }

    &__question-dish-rating {
        width: calc(100% + 28px);
        margin: 21px -20px 16px;

        .survey-questions__question-dish-rating-position {
            border-top: 1px solid #CFD8DC;

            &:last-child {
                border-bottom: 1px solid #CFD8DC;
            }
        }
    }

    &__question-dish-rating-position {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 16px 20px 18px;
    }

    &__question-dish-rating-position-value {
        justify-content: start;
        width: 100%;
        margin-top: 21px !important;

        .survey-questions__rating-star {
            &:not(:first-child) {
                margin-left: 18px;
            }
        }
    }

    &__question-dish-rating-position-name {
        display: block;
        margin-bottom: 4px;

        font-weight: bold;
        font-size: 16px;
        color: #2E2F31;
    }

    &__question-dish-rating-position-quantity {
        font-size: 14px;
        color: #2E2F31;
    }

    &__question-dish-rating-position-weight {
        font-size: 14px;
        color: #73808D;
    }

    &__question-dish-rating-position-category {
        font-size: 14px;
        color: #73808D;
    }

    &__question-dish-comment-form-group {
        margin-bottom: 0;

        .form-control {
            min-height: 75px;
        }
    }

    /** </Question> **/


    /** <Rating> **/

    &__rating {
        display: flex;
        margin-top: 14px;

        .survey-questions__rating-star.active,
        &:hover .survey-questions__rating-star,
        &:hover .survey-questions__rating-star:hover {
            .icon-rate-star-fill(#F8CD1C);
        }

        .survey-questions__rating-star:hover ~ .survey-questions__rating-star {
            .icon-rate-star(#F8CD1C);
        }
    }

    &__rating-star {
        width: 30px;
        height: 28.8px;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .icon-rate-star(#F8CD1C);
        cursor: pointer;
        padding: 0;
        box-sizing: content-box;
        border: none;

        &:not(:first-child) {
            margin-left: 18px;
        }
    }

    /** </Rating> **/


    &__choice {
        font-size: 19px;
        line-height: 1.3;
        text-align: center;
        margin-top: 15px;

        &--widescreen {
            display: none;
        }
    }

    &__choice-score {
        font-weight: bold;
    }


    /** <Form> **/

    &__form {
        margin-top: 18px;
        margin-left: 25px;
        margin-right: 25px;
        align-self: start;

        .form-check {
            margin-top: 10px;

            input:checked + .form-check-label {
                color: #000;
                font-weight: 500;
            }
        }

        .form-check-label {
            font-size: 15px;
            line-height: 22px;
            color: #9ea4aa;
            font-weight: 300;

            &:before {
                width: 22px;
                height: 22px;
                top: 0;
            }

            &:after {
                color: #231f20;
                top: 7px;
                left: 7px;
            }
        }
    }

    &__form-text {
        color: #000;
        font-weight: normal;
        line-height: 1.38;
        margin-bottom: 24px;
    }

    &__form-check-list {
        padding-right: 28px;

        .form-check-label {
            font-weight: normal !important;

            &:before {
                border-radius: 3px;
            }

            &:after {
                .icon-checkbox(#000);
            }
        }
    }

    &__form-textarea {
        min-height: 77.2px;
        margin: 18px 0 0;
    }

    /** </Form> **/


    /** <Slider> **/

    &__slider {
        width: 100%;
        height: 100%;
        padding: 0 0 31px;
        font-size: 0;

        .swiper-slide {
            overflow: hidden;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: black;
        }
    }

    &__slider-img {
        width: 100%;
        height: 100%;
        .border-radius(18px);
		object-fit: cover;
    }

    &__slider-video {
        height: 100%;
        width: 100%;
        .border-radius(18px);
        object-fit: cover;
    }

    &__slider-mask {
        background-color: rgba(0, 0, 0, 0.32);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        border-radius: 12px;
        z-index: 2;
    }

    &__slider-controls {
        position: absolute;
        left: ~'calc(50% - 38px)';
        top: ~'calc(50% - 50px)';
        z-index: 3;
    }

    &__slider-play-button {
        background-color: rgba(0, 0, 0, 0.34);
        background-position: 56% center;
        background-repeat: no-repeat;
        background-size: 26.5px 27.1px;
        .icon-play(#fff);
        width: 76px;
        height: 76px;
        .border-radius(50%);
        border: none;
        padding: 0;
    }

    &__slider-time {
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        margin: 5px 0 0;
    }

    &__slider-letter {
        background-color: rgba(0, 0, 0, 0.34);
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        min-width: 30px;
        width: auto;
        padding: 0 10px;
        height: 30px;
        position: absolute;
        top: 13px;
        left: 14px;
        .border-radius(15px);
        text-align: center;
        line-height: 30px;
        z-index: 5;
    }

    &__slider-paginator {
        position: absolute;
        top: 13px;
        right: 12px;
        display: flex;
        z-index: 5;

        *:not(:first-child) {
            margin-left: 7px;
        }
    }

    &__slider-paginator-button {
        background-color: rgba(0, 0, 0, 0.34);
        width: 30px;
        height: 30px;
        .border-radius(50%);
        border: none;
        padding: 0;
        display: none;

        &--prev {
            .icon-prev(#fff);
        }

        &--next {
            .icon-next(#fff);
        }
    }


    &__slider-counter {
        background-color: rgba(0, 0, 0, 0.34);
        color: #fff;
        font-size: 13px;
        padding-left: 12.28px;
        padding-right: 12.28px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        z-index: 5;
    }

    /** </Slider> **/

    &__option {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 6px;
        margin-left: -10px;

        &-check {
            margin-top: 10px;
            margin-left: 10px;
        }

        input[type="checkbox"]+&-label {
            display: inline-flex;
            align-items: center;
            justify-content: center;

            border: 2px solid #e0e0e0;
            .border-radius(24px);
            height: 48px;
            min-width: 48px;
            font-size: 16px;
            font-weight: bold;
            padding-right: 23px;
            padding-left: 23px;
            color: #2e2f31;
            background-color: #fff;

            &:before,
            &:after {
                content: none;
            }
        }

        input:checked+&-label {
            padding-right: 25px;
            padding-left: 25px;
            border: none;
            color: #FFF !important;
        }
    }

    &__copyright {
        font-size: 12px;
        text-decoration-line: underline;
    }
}

@media (min-width: 768px) {
    .survey-questions {
        &__body {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__wrapper {
            padding: 0;
        }

        &__paginator {
            margin-top: 32px;
        }

        &__paginator-anchor-text {
            font-size: 16px;
        }

        &__question-card {
            margin-top: 36px;
            padding: 28px 23px 25px;
			width: 680px;
        }

        /** <Question> **/

        &__question {
            .survey-questions__question-card > .survey-questions__question-card-column {
                &:first-child {
                    width: 338px;
                }

                &:last-child {
                    width: 246px;
                }
            }

            &--type_dishes {
                .survey-questions__question-card {
                    width: 680px;
                    padding-bottom: 20px;
                }

                .survey-questions__question-object {
                    max-height: 292px;
                }

                .survey-questions__question-card > .survey-questions__question-card-column {
                    &:last-child {
                        .survey-questions__question-card-column-content {
                            overflow: visible !important;

                            .scroll-element {
                                display: none;
                            }
                        }
                    }
                }

                .survey-questions__question-card > .survey-questions__question-card-column {
                    &:first-child {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: calc(42.3% - 10px);
                    }

                    &:last-child {
                        width: calc(57.7% - 10px);
                        margin-left: 20px;
                        max-height: 371px;
                        margin-top: 0;

						&:before {
							display: block;
						}

                        .survey-questions__question-card-column-content {
                            overflow: hidden !important;

                            & > .scroll-content {
                                padding-right: 20px;

                                overflow: auto !important;
                            }

                            .scroll-element.scroll-y {
                                display: block;
                            }
                        }
                    }
                }

                &.survey-questions__question--multimedia-type_less {
                    .survey-questions__question-card-column {
                        width: 100% !important;
                    }

                    .survey-questions__question-like-all-button {
                        margin-top: 0;
                        margin-bottom: 21px;
                    }
                }
            }

            &:not(.survey-questions__question--type_dishes).survey-questions__question--multimedia-type_less {
                .survey-questions__question-card {
                    padding-right: 50px;
                    padding-left: 50px;
                }

                .survey-questions__question-card-column {
                    width: 100% !important;
                }

                .survey-questions__form-text {
                    margin-bottom: 16px;
                }

                .survey-questions__form-check-list {
                    margin-top: 16px;
                }

                .survey-questions__form {
                    width: calc(100%);
                    margin-left: 0;
                    margin-right: 0;
                }

                .survey-questions__choice {
                    margin-left: 0;
                    margin-right: 0;
                }

                .survey-questions__option {
                    margin-left: -10px;
                    margin-right: 0;
                }
            }
        }

        &__question-rating-label {
            display: block;
        }

        &__question-card-column {
            width: calc(50% - 25px);
            align-items: flex-start;

            &:not(:first-child) {
                margin-left: 50px;
            }

            &:nth-child(2) {
                padding-top: 10px;
            }
        }

        &__question-actions {
            margin-top: 30px;
        }

        &__question-object {
            position: relative;
            width: 100%;
            height: 338px;
            overflow: hidden;

            &--type_multiple-multimedia {
                .survey-questions__question-object-content {
                    max-height: unset;
                }
            }
        }

        &__question-object-content-img {
            width: unset;
        }

        &__question-dish-rating {
            width: 100%;
            margin-top: 0;
            margin-right: 0;
            margin-left: 0;
        }

        &__question-dish-rating-position {
            padding-right: 0;
            padding-left: 0;
            justify-content: space-between;
            flex-wrap: nowrap;
        }

        &__question-dish-rating-position-value {
            width: 134px;
            margin-left: 15px;
            margin-top: 0 !important;

            .survey-questions__rating-star {
                &:not(:first-child) {
                    margin-left: 11px;
                }
            }
        }

        &__question-like-all-button {
            margin-bottom: 35px;
        }

        /** </Question> **/


        /** <Rating> **/

        &__rating {
            margin-top: 9px;
        }

        &__rating-star {
            width: 24px;
            height: 24px;

            &:not(:first-child) {
                margin-left: 14px;
            }
        }

        /** </Rating> **/


        &__choice {
            display: none;
            font-size: 15px;
            margin-top: 0;
            text-align: start;

            &--widescreen {
                display: block;
            }

            &--option {
                display: block;
            }
        }

        /** <Form> **/

        &__form {
            margin-top: 24px;
            margin-left: 0;
            margin-right: 0;
        }

        &__form-text {
            font-size: 15px;
        }

        &__form-check-list {
            margin-top: 28px;
            padding-left: 0;
            padding-right: 0;
        }

        /** </Form> **/


        /** <Slider> **/

        &__slider {
            padding: 0;

            .swiper-pagination {
                display: none;
            }
        }

        &__slider-paginator-button {
            display: block;
        }


        &__slider-img {
            min-height: unset;
        }

        &__slider-video {
            min-height: unset;
        }

        /** </Slider> **/

        &__option {
            justify-content: flex-start;
        }
    }

    .form-control_full {
        width: 100%;
        min-height: 100px;
        &.sm {
            min-height: 75px;
        }
    }
}

@media (max-width: 767px) {
    .survey-questions__question:not(.survey-questions__question--type_dishes).survey-questions__question--multimedia-type_less .survey-questions__choice {
        text-align: center;
    }
    .survey-questions__rating {
        justify-content: center;
    }
}
