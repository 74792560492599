.clients {
    &__edit-columns-modal-dialog {
        width: 460px !important;

        .clients__edit-columns-modal-dialog-edit-form {
            margin: 30px -30px -30px;
        }

        &--initializing {
            visibility: hidden;
        }
    }

    &__edit-columns-modal-dialog-search-form {
        position: relative;
    }

    &__edit-columns-modal-dialog-search-form-icon {
        .icon-search(#73808D);

        display: inline-block;
        position: absolute;
        left: 15px;
        top: 16px;
        margin-right: 11px;
    }

    &__edit-columns-modal-dialog-search-form-control {
        padding-left: 46px;
    }

    &__edit-columns-modal-dialog-search-form-clear-button {
        .icon-plus3(#2E2F31) !important;

        position: absolute;
        right: 13px;
        top: calc(50% - (17px / 2));
        padding: 0;
        transform: rotate(45deg);

        border-radius: 0;
        box-shadow: none !important;

        transition: none;
    }

    &__edit-columns-modal-dialog-column-picker {
        margin-top: 24px;
    }

    &__edit-columns-modal-dialog-column-picker-default-list-wrapper {
        .scroll-content {
            padding-top: 4px;
            padding-bottom: 4px;
        }

        .scroll-element.scroll-y {
            right: 0;
        }
    }

    &__edit-columns-modal-dialog-column-picker-default-list {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: -18px;
    }

    &__edit-columns-modal-dialog-column-picker-default-list-item {
        width: 50%;
        min-height: 24px;
        padding-top: 5px;

        margin-top: 18px;
    }

    &__edit-columns-modal-dialog-column-picker-additional-section {
        margin-top: 30px;

        .clients__edit-columns-modal-dialog-column-picker-additional-section-add-button {
            margin-top: 16px;
        }
    }

    &__edit-columns-modal-dialog-column-picker-additional-section-header {
        font-weight: 900;
        font-size: 15px;
        line-height: 13px;
        color: #2E2F31;
    }

    &__edit-columns-modal-dialog-column-picker-additional-list {
        margin-top: 20px;

        .clients__edit-columns-modal-dialog-column-picker-additional-list-item {
            &:not(:first-child) {
                margin-top: 14px;
            }
        }
    }

    &__edit-columns-modal-dialog-column-picker-additional-list-item {
        display: flex;
        align-items: center;

        .clients__edit-columns-modal-dialog-column-picker-additional-list-item-edit-button {
            margin-left: 14px;
        }

        .clients__edit-columns-modal-dialog-column-picker-additional-list-item-delete-button {
            margin-left: 4px;
        }
    }

    &__edit-columns-modal-dialog-column-picker-additional-list-item-edit-button {
        &:before {
            .icon-pencil(#73808D);
        }
    }

    &__edit-columns-modal-dialog-column-picker-additional-list-item-delete-button {
        &:before {
            .icon-delete(#73808D);
        }
    }

    &__edit-columns-modal-dialog-column-picker-additional-section-add-button {
        color: #37A74A;
    }

    &__edit-columns-modal-dialog-edit-form {
        display: flex;
        position: relative;
        border-top: 1px;
        padding-top: 35px;
        padding-right: 30px;
        padding-left: 30px;

        border-top-style: solid;
        border-top-color: #CFD8DC;

        .clients__edit-columns-modal-dialog-edit-form-name-form-group {
            flex-grow: 1;
        }

        .clients__edit-columns-modal-dialog-edit-form-submit-button {
            margin-top: 30px;
            margin-left: 30px;
        }

        .clients__edit-columns-modal-dialog-edit-form-cancel-button {
            position: absolute;
            top: 18px;
            right: 28px;
        }
    }

    &__edit-columns-modal-dialog-edit-form-submit-button {
        display: flex;
        width: 61px;
        height: 48px;
        align-items: center;
        justify-content: center;
        padding: 0;

        background: #ABEFB7;
        border-radius: 4px;

        &:before {
            .icon-done(#37A74A);

            content: '';

            width: 19.6px;
            height: 14px;
        }
    }
}
