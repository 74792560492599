.tag-input {
    &__content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: -8px;
        margin-left: -15px;

        .tag-input__item,
        .tag-input__add-tag-button-wrapper {
            margin-top: 8px;
            margin-left: 15px;
        }
    }

    &__item {
        font-weight: 500;

        .tag-input__item-remove-button {
            margin-left: 7px;
        }
    }

    &__item-remove-button {
        display: inline-flex;
        width: 20px !important;
        height: 20px !important;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        background: #ECEFF1 !important;

        &:before {
            .icon-cross(#F96261) !important;

            content: '';
            display: inline-block;
        }
    }

    &__add-tag-button-wrapper {
        display: flex;
        align-items: center;

        .tag-input__add-tag-button-label {
            margin-left: 12px;
        }
    }

    &__add-tag-button {
        display: flex;
        width: 20px !important;
        height: 20px !important;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        background: #ECEFF1 !important;

        &:before {
            .icon-cross(#2E2F31) !important;

            content: '';

            display: inline-block;
            transform: rotate(45deg);
        }
    }

    &__add-tag-button-label {
        font-weight: 500;
        font-size: 12px;
        color: #2E2F31;
    }

    &__dropdown-menu {
        width: 270px;
        transform: translate3d(calc(-50% + 15px + 20px / 2), 32px, 0px) !important;
        padding-top: 12px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;

        &.dropdown-menu {
            top: 0 !important;
        }

        &[x-placement="top-start"] {
            transform: translate3d(calc(-50% + 15px + 20px / 2), -100%, 0px) !important;

            &:before {
                top: unset !important;
                bottom: -5.5px;
                transform: rotate(180deg);
            }
        }

        &:before {
            right: calc(50% - 22px / 2) !important;
        }

        .tag-input__dropdown-menu-list {
            &.scroll-wrapper {
                margin-top: 14px;
            }
        }

        .tag-input__dropdown-menu-message {
            margin-top: 14px;
        }
    }

    &__dropdown-menu-list {
        margin-left: -20px;
        margin-right: -20px;
        max-height: 165px;

        .dropdown-item {
            color: #2E2F31 !important;
            cursor: pointer;
            white-space: normal;
        }

        .scroll-element.scroll-y {
            right: 20px;
        }
    }

    &__dropdown-menu-control-wrapper {
        position: relative;
    }

    &__dropdown-menu-control {
        height: 36px;
        padding: 9px 41px 9px 9px;

        font-size: 14px;
    }

    &__dropdown-menu-control-create-tag-button {
        .icon-plus3(#2E2F31) !important;

        position: absolute;
        right: 13px;
        top: calc(50% - (17px / 2));
        padding: 0;

        border-radius: 0;
        box-shadow: none !important;

        transition: none;
    }

    &__dropdown-menu-message {
        display: block;

        font-weight: normal;
        font-size: 14px;
        color: #2E2F31;
    }
}
