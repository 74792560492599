.company {
    &__content {
        &--initializing {
            visibility: hidden;
        }

        .company__card {
            margin-top: 30px;

            .tab-group__tab-pane-body {
                min-height: 500px;
            }
        }

        .company__section {
            &:not(:first-child) {
                border-top-width: 1px;
                margin-top: 30px;
                padding-top: 30px;

                border-top-style: solid;
                border-top-color: #CFD8DC;
            }
        }
    }

    &__section {
        .form-group {
            margin-bottom: 0;
        }

        .company__section-name {
            display: block;
            margin-bottom: 18px;
        }
    }

    &__section-name {
        font-weight: bold;
        font-size: 19px;
        color: #2E2F31;
    }

    &__service-accesses-tab-pane {
        padding-top: 20px;

        .company__service-accesses-tab-pane-header {
            margin-top: -32px;
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    &__service-accesses-tab-pane-header {
        display: flex;
        padding: 18px 20px;
        align-items: center;
        justify-content: flex-end;

        border-bottom: 1px solid #ECEFF1;
    }

    &__service-accesses-tab-pane-table {
        width: 100%;

        color: #2E2F31;

        & > tbody > tr > td {
            height: 47px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .company__service-accesses-tab-pane-table-connected-at-head-cell {
            width: 121px;
        }

        .company__service-accesses-tab-pane-table-active-cell {
            width: 91px;
        }

        .company__service-accesses-tab-pane-table-actions-head-cell {
            width: 98px;
        }
    }

    &__service-accesses-tab-pane-table-row {
        &--inactive {
            color: #CFD8DC;
        }
    }

    &__service-accesses-tab-pane-table-service-cell {
        font-weight: bold;
    }

    &__service-accesses-tab-pane-table-active-head-cell {
        text-align: center;
    }

    &__service-accesses-tab-pane-table-active-cell {
        text-align: center;
    }

    &__service-accesses-tab-pane-table-active-checkbox {
        display: inline;
        padding-left: 10px;
    }

    &__service-accesses-tab-pane-table-actions-head-cell {
        padding-top: 5px !important;

        text-align: end;
    }

    &__service-accesses-tab-pane-table-actions-cell {
        text-align: end;

        .company__service-accesses-tab-pane-table-action-button {
            transition: none !important;

            &:not(:first-child) {
                margin-left: 13px;
            }
        }
    }

    &__service-accesses-tab-pane-table-row {
        position: relative;

        &--has-details {
            cursor: pointer;
        }
    }

    &__service-accesses-tab-pane-table-edit-button {
        &:before {
            .icon-pencil(#73808D);
        }
    }

    &__service-accesses-tab-pane-table-delete-button {
        &:before {
            .icon-delete(#73808D);
        }
    }

    &__actions {
        display: flex;

        .company__save-button {
            margin-left: 13px;
        }
    }

    &__back-button {
        display: flex;
        align-items: center;

        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #2E2F31;

        &:before {
            .icon-arrow-back(#73808D);

            content: '';
            margin-right: 10px;
        }
    }


    &__service-access-details-modal {
        padding-right: 0 !important;

        background: #FFFFFF99;

        .company__service-access-details-modal-dialog-wrapper {
            position: absolute;
            height: 100%;
            right: 0;
            width: calc(100% - 284px);
            margin-left: 45px;
            max-width: 1156px;
        }
    }

    &__service-access-details-modal-dialog-wrapper {
        .company__service-access-details-modal-dialog {
            height: 100%;
            width: 100%;
        }

        .company__service-access-details-modal-dialog-wrapper-actions {
            position: absolute;
            transform: translate(calc(-100% + -12px), 11px);
            z-index: 2;
        }
    }

    &__service-access-details-modal-dialog-wrapper-actions {
        display: flex;
    }

    &__service-access-details-modal-dialog-wrapper-close-button {
        display: flex;
        width: 33px;
        height: 33px;
        align-items: center;
        justify-content: center;
        padding: 0;

        border-radius: 50%;
        box-shadow: none !important;
        border: 2px solid #73808D !important;

        &:before {
            .icon-cross3(#73808D);

            content: '';
        }
    }
}
