.question-statistics {
    &__variant-statistics {
        display: flex;

        .question-statistics__variant-statistics-legend {
            margin-top: 8px;
            flex-grow: 1;
            margin-left: 40px;
            max-height: 506px;
            height: max-content;
            .variants-statistics-legend {
                height: auto;
            }
        }
    }

    &__variant-statistics-chart-wrapper {
        .question-statistics__variant-statistics-chart {
            margin-top: 27px;
        }
    }

    &__variant-statistics-mode-toggle {
        .question-statistics__variant-statistics-mode-button {
            &:not(:first-child) {
                margin-left: 3px;
            }
        }
    }

    &__variant-statistics-mode-button {
        cursor: pointer;
    }

    &__variant-statistics-column-mode-button {
        &:before {
            .icon-column-chart(#73808D);
        }

        &.question-statistics__variant-statistics-mode-button--active {
            &:before {
                .icon-column-chart(#3F65F1);
            }
        }
    }

    &__variant-statistics-pie-mode-button {
        &:before {
            .icon-pie-chart(#73808D);
        }

        &.question-statistics__variant-statistics-mode-button--active {
            &:before {
                .icon-pie-chart(#3F65F1);
            }
        }
    }

    &__variant-statistics-bar-mode-button {
        &:before {
            .icon-bar-chart(#73808D);
        }

        &.question-statistics__variant-statistics-mode-button--active {
            &:before {
                .icon-bar-chart(#3F65F1);
            }
        }
    }

    &__variant-statistics-table-mode-button {
        &:before {
            .icon-table-chart(#73808D);
        }

        &.question-statistics__variant-statistics-mode-button--active {
            &:before {
                .icon-table-chart(#3F65F1);
            }
        }
    }

    &__variant-statistics-chart-wrapper {
        width: 355px;
        position: relative;
        &.filials-statistics-chart-wrapper {
            width: 50%;
        }
        &.priority-chart-wrapper {
            width: 100%;
            .highcharts-container {
                max-width: initial !important;
              }
        }
        &.priority-chart-wrapper-column-chart {
            max-width: 100%;
            overflow: hidden !important;
        }
    }

    &__filials-chart-wrapper {
        max-height: 506px;
        margin-top: 10px;
        height: 100%;
    }

    &__variant-statistics-column-chart {
        height: 180px;
        &.filials-bar-chart {
            margin-top: 0px;
            height: auto;
            .highcharts-contextbutton {
                display: none;
            }
        }
    }

    &__variant-statistics-pie-chart {
        height: 240px;
    }

    &__variant-statistics-legend-table {
        th {
            white-space: nowrap;
        }
    }

    &__variant-statistics-legend-table-row {
        cursor: pointer;

        &--disabled {
            .question-statistics__variant-statistics-legend-table-color-indicator {
                background-color: #CCC !important;
            }
        }
    }

    &__variant-statistics-legend-table-text-cell {
        font-weight: 500;
        font-size: 14px;
        color: #2E2F31;
        
        span {
            
            
            
            @media screen and (max-width: 1360px) {
                max-width: 400px;
              }
              @media screen and (max-width: 1200px) {
                max-width: 300px;
              }
              @media screen and (max-width: 1080px) {
                max-width: 220px;
              }
              @media screen and (max-width: 768px) {
                max-width: 120px;
              }
              max-width: 480px;
            
        }
    }

    &__variant-statistics-legend-table-text-cell-content {
        display: flex;
        align-items: center;

        .question-statistics__variant-statistics-legend-table-color-indicator {
            flex-shrink: 0;
            margin-right: 10px;
        }
    }

    &__variant-statistics-legend-table-vote-count-head-cell,
    &__variant-statistics-legend-table-vote-count-cell {
        text-align: end;
    }

    &__variant-statistics-legend-table-percentage-head-cell,
    &__variant-statistics-legend-table-percentage-cell {
        text-align: end;
    }

    &__variant-statistics-legend-table-color-indicator {
        width: 12px;
        height: 12px;

        border-radius: 50%;
    }
}
