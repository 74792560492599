.share-report-modal-dialog {
	width: 600px !important;
	max-width: 600px;

	.dense-tab-group {
		.nav-item {
			width: 100%;
		}
	}

	&__invite-form {
		display: flex;
	}

	&__share-available-users-form-group {
		margin-bottom: 0;
		flex-grow: 1;
	}

	&__share-link-form-group {
		margin-bottom: 0;
		flex-grow: 1;
	}

	&__share-link-input-group {
		position: relative;

		.form-control {
			padding-right: 52px;

			border-radius: 4px !important;
		}
	}

	&__share-link-input-group-button {
		.icon-link5(#73808D) !important;

		position: absolute;
		top: 16.5px;
		right: 16px;

		z-index: 3;
		box-shadow: none !important;
		background-color: transparent !important;
		border-radius: 0 !important;
	}

	&__rights-form-group {
		margin-top: 29px;
		margin-left: 14px;
		margin-bottom: 0;
		flex-shrink: 0;
	}

	&__rights-form-control {
		width: 95px;
	}

	&__rights-form-control-icon {
		display: inline-block;
		top: 0.5px;
		position: relative;
	}

	&__rights-form-control-view-icon {
		.icon-visible(#73808D);

		width: 24px;
		height: 17.3px;
	}

	&__rights-form-control-view-disabled-icon {
		.icon-hidden(#73808D);

		width: 24px;
		height: 25.2px;
	}

	&__rights-form-control-edit-icon {
		.icon-pencil2(#73808D);
	}

	&__rights-form-control-dropdown {
		left: -100px !important;

		&:before {
			left: unset !important;
			right: 16px !important;
		}
	}

	&__share-users {
		margin-top: 42px;

		&--open {
			.share-report-modal-dialog__share-users-header-icon {
				transform: rotate(180deg);
			}
		}
	}

	&__share-users-header {
		display: flex;
		align-items: center;

		cursor: pointer;
	}

	&__share-users-title {
		font-weight: bold;
		font-size: 16px;
		color: #2E2F31;
	}

	&__share-users-header-icon {
		.icon-select-arrow(#2E2F31);

		display: inline-block;
		margin-left: 14px;

		transition: transform 0.3s ease;
	}

	&__share-users-table-wrapper {
		margin-top: 11px;
	}

	&__share-users-table {
		border-bottom: 1px solid #E7EBED;

		td {
			padding-top: 7px;
			padding-bottom: 7px;

			border-color: #E7EBED;
			vertical-align: middle !important;
		}
	}

	&__share-users-table-avatar-cell {
		width: 54px;
		padding-right: 7px !important;
	}

	&__share-users-table-avatar {
		width: 30px;
		height: 30px;

		object-fit: cover;
		border-radius: 8px;
	}

	&__share-users-table-name-cell {
		padding-left: 7px !important;

		font-weight: 500;
		color: #2E2F31;
	}

	&__share-users-table-rights-cell {
		width: 113px;
		padding-right: 18px !important;
		text-align: right;
	}

	&__share-users-table-owner {
		color: #73808D;
	}

	&__share-users-table-rights-form-control {
		display: inline-flex !important;
		border: none !important;
		box-shadow: none !important;
		height: unset !important;

		padding: 0;

		.select2-selection__arrow {
			margin-right: 0 !important;
		}
	}

	&__share-users-table-rights-form-control-dropdown {
		left: -50px !important;

		&:before {
			left: unset !important;
			right: 46px !important;
		}
	}

	&__share-users-table-actions-cell {
		width: 40px;
		padding-left: 18px !important;
	}

	&__share-users-table-remove-button {
		width: 20px !important;
		height: 20px !important;

		background: none !important;
		box-shadow: none !important;

		&:before {
			.icon-cross2(#F96261);

			content: '';
		}
	}

	&__save-button {
		margin-left: 13px;
	}

	.modal-footer {
		position: relative;

		.save-message {
			margin: 0;
		}
	}
}
