.mailing-page.mailing-settings {
  .mailing-settings-form {
    &__description {
      textarea {
        min-height: 150px;
      }
    }
  }
  .mailing-channel {
    cursor: pointer;

    td {
      vertical-align: middle;
    }
    &__name {
      font-weight: bold;
      white-space: nowrap;
    }
    &__delay {
      font-weight: bold;
    }
    &__message {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--inactive {
      .mailing-channel {
        &__name, &__delay, &__message, &__repeats {
          color: @f-color-text-inactive;
        }
        &__icon {
          opacity: 0.5;
        }
      }
    }

    &--no-template {
      background: #F1F5F6;
    }
  }
}
