.service-text {
  font-size: 12px;
  font-weight: normal;
  color: @f-color-service;
  line-height: 16px;
}

.bold {
  font-weight: bold;
}

.text-nowrap {
  .text-nowrap();
}

.fs-1 {
  font-size: 12px;
  line-height: 16px;
}

.lh-16p {
  line-height: 16px;
}

.lh-18p {
  line-height: 18px;
}

.hyphenate {
  word-wrap: break-word;
  overflow-wrap: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
