.auto-poll-info {
  padding-bottom: 58px;

  @media (max-width: 800px) {
    padding: 0 20px 58px 20px;
  }

  &-ellipse {
    margin: 40px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    background: #EFF2FA;

    & svg {
      margin-top: 8px;
    }
  }

  &-definition {
    margin: 30px auto 0 auto;
    max-width: 700px;

    @media (max-width: 800px) {
      max-width: 100%;
    }

    &-title {
      font-weight: 700;
      font-family: Roboto;
      font-size: 19px;
      line-height: 130%;
      text-align: center;
    }

    &-description {
      font-family: Roboto;
      font-weight: 400;
      font-size: 19px;
      line-height: 130%;
      text-align: center;
    }

    &-text {
      font-family: Roboto;
      font-weight: 400;
      font-size: 15px;
      line-height: 130%;

      &-bold {
        font-weight: 700;
      }
    }

    &-scheme {
      display: block;
      margin: 26px auto 0 auto;
    }
  }
  &-contacts {
    max-width: 700px;
    margin: 26px auto 0 auto;
    display: flex;
    align-items: center;

    @media (max-width: 800px) {
      max-width: 100%;
    }

    &-item {
      margin-right: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }

      &-desc {
        margin-top: 12px;
        font-family: Roboto;
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
        text-align: center;
      }

      &-link {
        font-family: Roboto;
        font-weight: 700;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        color: #3F65F1;
      }
    }
  }
  &-submit {
    width: 162px;
    margin: 37px auto 0 auto;
  }

  &-pe-none {
    pointer-events: none !important;
  };
}