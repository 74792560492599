

.upward-button-container {
    position: sticky;
    bottom: 8px;
    height: 60px;
    padding-top: 12px;
    margin-top: -60px;
    max-width: 1600px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    pointer-events: none;

    .mobile-and-tablet({
        padding-right: var(--foquz-side-offset);
        margin-top: -30px;
        margin-bottom: -30px;
    });
}

.upward-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    margin-left: auto;

    background: #FFFFFF;
    box-shadow: 0 2px 4px #CFD8DC;
    border: none;
    border-radius: 50%;

    pointer-events: all;

    transition: transform 200ms ease-in;

    z-index: 50;

    &:before {
        .icon-arrow-back(#2E2F31);

        content: '';
        display: inline-block;
        transform: rotate(90deg);
    }

    &--hidden {
        transform: scale(0);
    }
}
