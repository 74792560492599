.search-field {
  display: flex;
  align-items: center;
  position: relative;

  &__icon {
    margin-right: 15px;
  }
  &__input {
    color: @f-color-service;
    font-size: 16px;
    border: none;
    background: transparent;
    outline: none;
    box-shadow: none;
    flex-grow: 1;
    padding-right: 20px;
  }
  &__apply {
    position: absolute;
    right: 0;
  }
}
