.survey-list {
	&__new-folder-modal-dialog {
		width: 460px !important;
	}

	&__new-folder-modal-dialog-location-form-control-folder-icon {
		.icon-folder(#A6B1BC);

		display: inline-block;
		margin-right: 11px;
	}
}
