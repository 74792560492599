.sidebar {
  padding: 20.5px 35px 0 25px;
  transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);

  .jivo-btn {
    width: 50px;
    height: 50px;
    margin-top: 16px;
    padding: 0;
    display: none;
    padding-top: 3px;
  }

  &_empty {
    padding-top: 30px;
    padding-left: 6px;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__button {
    .btn-menu {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
      padding: 10px;
      width: 40px;
      height: 40px;

      box-shadow: none;

      &--close {
        .icon-menu-close(#73808d);
      }

      &--open {
        .icon-menu(#73808d);
        background-size: 26px;
        margin: 0 -2px;
      }
    }
  }

  &__logo {
    margin-left: 20px;
    overflow: hidden;
  }

  &__logo-link {
    .icon-foquz-logo(#000000);

    text-decoration: none;
    display: block;
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    transition: all 600ms 100ms cubic-bezier(0.23, 1, 0.32, 1);
    cursor: pointer;
    background-image: url("/img/logo-ru.svg");

    &:hover {
      .icon-foquz-logo(#3f65f1);
    }
  }

  &__account {
    display: flex;
    margin-bottom: 23px;

    .sidebar__info {
      overflow: hidden;
    }

    .dropdown-menu {
      min-width: 210px;
      left: 0 !important;
      transform: translate3d(0, 40px, 0px) !important;
      &:before {
        right: auto;
        left: 10px;
      }
    }

    .dropdown-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      i {
        .icon-logout(#73808d);
        width: 10px !important;
        height: 10px !important;
        margin-left: 9px;
      }
    }
  }

  &__picture-link {
    position: relative;
    overflow: hidden;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 6px;
    background-color: rgba(207, 216, 220, 0.49);
    transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
    margin-right: 16px;
  }

  &__account-picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  &__info {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__account-title {
    padding-bottom: 0;

    font-weight: 500;
    font-size: 14px;
    color: #000000;

    transition: all 600ms 100ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  &__username-link {
    text-decoration: none;
    font-size: 14px;
    line-height: 1.1;
    font-weight: bold;
    color: #3d63ec;
    cursor: pointer;

    transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);

    &:hover {
      color: #395cdd;
      text-decoration: none;
    }
  }

  &__username-role {
    color: #73808d;
    font-size: 12px;
    font-weight: 500;
    line-height: 110%;
  }

  &__create-survays {
    margin-bottom: 25px;

    .button-add {
      max-width: 180px;
      width: 100%;
    }

    .dropdown {
      .dropdown-menu:before {
        left: 149px;
      }
    }
  }

  &__menu-bar {
    display: flex;
    flex-direction: column;
  }

  &__menu-item {
    margin-bottom: 18px;
    position: relative;

    &:last-child {
      margin-bottom: 32px;
    }

    .f-exclamation-mark {
      position: absolute;
      left: 16px;
      top: -3px;
      cursor: pointer;
    }
  }

  &__item-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 0.81;
    color: #73808d;
    //transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      flex-shrink: 0;
      position: relative;
      top: 0;
      left: 0;
      width: 40px !important;
      margin-right: 5px;
      background-position: left 5px center;
      background-size: auto !important;
      transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    &--disabled {
      cursor: default;
      color: #73808d;
    }

    &--projects {
      &:before {
        .icon-projects(#73808d);
      }

      &:active,
      &.active {
        &:before {
          .icon-projects(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-projects(#2e2f31);
        }
      }

      &.sidebar__item-link--disabled {
        &:before {
          .icon-projects-na(#CFD8DC);
          transform: translateX(-4px);
        }
      }
    }

    &--surveys {
      &:before {
        .icon-surveys(#73808d);
      }

      &:active,
      &.active {
        &:before {
          .icon-surveys(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-surveys(#2e2f31);
        }
      }

      &.sidebar__item-link--disabled {
        &:before {
          .icon-surveys(#CFD8DC);
        }
      }
    }

    &--answers {
      &:before {
        .icon-answers(#73808d);
        top: 2px;
        position: relative;
      }

      &:active,
      &.active {
        &:before {
          .icon-answers(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-answers(#2e2f31);
        }
      }

      &.sidebar__item-link--disabled {
        &:before {
          .icon-answers(#CFD8DC);
        }
      }
    }

    &--settings {
      &:before {
        .icon-settings(#73808d);
      }

      &:active,
      &.active {
        &:before {
          .icon-settings(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-settings(#2e2f31);
        }
      }

      &.sidebar__item-link--disabled {
        &:before {
          .icon-settings(#CFD8DC);
        }
      }
    }

    &--payment {
      &:before {
        .icon-payment(#73808d);
      }

      &:active,
      &.active {
        &:before {
          .icon-payment(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-payment(#2e2f31);
        }
      }

      &.sidebar__item-link--disabled {
        &:before {
          .icon-payment-na(#CFD8DC);
          transform: translateX(-5px);
        }
      }
    }

    &--widget {
      &:before {
        .icon-widget(#73808d);
      }

      &:active,
      &.active {
        &:before {
          .icon-widget(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-widget(#2e2f31);
        }
      }
    }

    &--requests {
      &:before {
        .icon-requests(#73808d);
      }

      &:active,
      &.active {
        &:before {
          .icon-requests(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-requests(#2e2f31);
        }
      }
    }

    &--help {
      &:before {
        .icon-help(#73808d);
      }

      &:active,
      &.active {
        &:before {
          .icon-help(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-help(#2e2f31);
        }
      }

      &.sidebar__item-link--disabled {
        &:before {
          .icon-help-na(#CFD8DC);
          transform: translateX(-5px);
        }
      }
    }

    &--mailings {
      &:before {
        .icon-mailings(#73808d);
      }

      &:active,
      &.active {
        &:before {
          .icon-mailings(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-mailings(#2e2f31);
        }
      }
    }

    &--reports {
      &:before {
        .icon-reports(#73808d);
      }

      &:active,
      &.active {
        &:before {
          .icon-reports(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-reports(#2e2f31);
        }
      }

      &.sidebar__item-link--disabled {
        &:before {
          .icon-reports(#CFD8DC);
        }
      }
    }

    &--clients {
      &:before {
        .icon-clients(#73808d);
      }

      &:active,
      &.active {
        &:before {
          .icon-clients(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-clients(#2e2f31);
        }
      }

      &.sidebar__item-link--disabled {
        &:before {
          .icon-clients(#CFD8DC);
        }
      }
    }

    &--contact-points {
      &:before {
        .icon-contact-points(#73808d);
      }

      &:active,
      &.active {
        &:before {
          .icon-contact-points(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-contact-points(#2e2f31);
        }
      }

      &.sidebar__item-link--disabled {
        &:before {
          .icon-contact-points(#CFD8DC);
        }
      }
    }

    &--companies {
      &:before {
        .icon-companies(#73808d);
      }

      &:active,
      &.active {
        &:before {
          .icon-companies(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-companies(#2e2f31);
        }
      }

      &.sidebar__item-link--disabled {
        &:before {
          .icon-companies(#CFD8DC);
        }
      }
    }

    &--users {
      &:before {
        .icon-users(#73808d);
      }

      &:active,
      &.active {
        &:before {
          .icon-users(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-users(#2e2f31);
        }
      }

      &.sidebar__item-link--disabled {
        &:before {
          .icon-users(#CFD8DC);
        }
      }
    }

    &--coupons {
      &:before {
        .icon-coupons(#73808d);
      }

      &:active,
      &.active {
        &:before {
          .icon-coupons(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-coupons(#2e2f31);
        }
      }

      &.sidebar__item-link--disabled {
        &:before {
          .icon-coupons(#CFD8DC);
        }
      }
    }

    &--admin-report {
      &:before {
        .icon-admin-report(#73808d);
      }

      &:active,
      &.active {
        &:before {
          .icon-admin-report(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-admin-report(#2e2f31);
        }
      }

      &.sidebar__item-link--disabled {
        &:before {
          .icon-admin-report(#CFD8DC);
        }
      }
    }

    &--legals {
      &:before {
        .icon-legals(#73808d);
        top: -2px;
      }

      &:active,
      &.active {
        &:before {
          .icon-legals(#3f65f1);
        }
      }

      &:hover {
        &:before {
          .icon-legals(#2e2f31);
        }
      }

      &.sidebar__item-link--disabled {
        &:before {
          .icon-legals(#CFD8DC);
        }
      }
    }

    &:hover,
    &:active,
    &.active {
      color: #2e2f31;
      text-decoration: none;
    }
  }
}

.sidebar-backdrop {
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s ease;
}

.app__sidebar {
  &--small {
    margin-left: -11px;

    &.app__sidebar_login {
      width: 260px;
    }

    .button-add--with-dropdown:before {
      .icon-sidebar-plus(#fff);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .sidebar__item-link--payment.sidebar__item-link--disabled:before,
    .sidebar__item-link--projects.sidebar__item-link--disabled:before,
    .sidebar__item-link--help.sidebar__item-link--disabled:before {
      transform: none;
    }

    .sidebar__row {
      margin-bottom: 10px;
    }

    .sidebar__info {
      display: none;
    }

    .sidebar__menu-item {
      margin-bottom: 12px;
    }

    .sidebar {
      padding: 21px 36px 0 36px;

      .jivo-btn {
        width: 40px;
        height: 40px;
        padding-top: 2px;
      }

      &.sidebar_empty {
        padding: 30px 26px 0 6px;
        width: 260px;
        .sidebar__logo-link {
          width: 94px;
        }
      }

      &__logo-link {
        width: 0;
        overflow: hidden;
      }

      &__picture-link {
        width: 36px;
        height: 36px;
        margin-right: 0;
      }

      &__account {
        flex-direction: column;
        padding-bottom: 0;
      }

      &__account-title {
        font-size: 0;
      }

      &__username-link {
        line-height: 2;
        text-align: center;
      }

      &__item-link {
        // justify-content: center;

        font-size: 0;

        &:before {
          margin: 0;
          // background-position: center;
        }
      }

      &__menu {
        padding-left: 0;
      }

      &__menu-item {
        //margin-left: 7px;
        position: relative;
        .f-exclamation-mark {
          position: absolute;
          left: 22px;
          top: -3px;
          cursor: pointer;
        }
      }

      &__create-survays {
        .button-add {
          font-size: 0;
          height: 36px;
          padding: 0;

          & + .dropdown-menu {
            &:before {
              right: unset;
              left: 8px;
            }
          }
        }
      }
    }
  }

  &--clone {
    display: none;

    .sidebar {
      position: relative;
    }
  }
}

.only-desktop({
    .sidebar-backdrop {
        display: none !important;
    }
});

.mobile-and-tablet({
    .app__sidebar {
        transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;

        z-index: 20;

        &--small {
            width: 108px;
            position: static;
        }

        &--clone {
            z-index: 1000;
            left: -259px;
            display: block;
            background-color: #f5f6fa;
        }
    }

    .sidebar-open {
        .app__sidebar {
            &--clone {
                z-index: 1000;
                left: 0;
            }
        }

        .sidebar-backdrop {
            opacity: 1;
        }
    }
});

.only-mobile({
    .app__sidebar {
        .sidebar {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
});

body.sidebar-open {
  .tooltip--sidebar {
    display: none;
  }
}

.body.jivo-loaded {
  .sidebar {
    .jivo-btn {
      display: block;
    }
  }
}
