.progress-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    background: #F1F4FF;
    border-radius: 4px;
    overflow: hidden;

    width: 190px;
    height: 40px;

    .progress-bar__indicator {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
    }

    .progress-bar__value {
        z-index: 3;
    }

    &__indicator {
        background: linear-gradient(270deg, #002DD4 0%, #3F65F1 100%);

        transition: 200ms linear;
    }

    &__value {
        font-weight: bold;
        color: #FFFFFF;
    }

    &--theme_success {
        .progress-bar__indicator {
            background: linear-gradient(270deg, #37A74A 0%, #ABEFB7 100%);
        }
    }
}
