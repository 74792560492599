.reviews-page {
  &.reviews-page--empty {

    .content {
      padding-top: 0;
    }
    .reviews-controls {
      border-top: 0;
    }

    .reviews-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 190px 40px;

      &__icon {
        position: relative;
        display: flex;
        width: 220px;
        height: 220px;
        flex-shrink: 0;
        margin-right: 50px;

        &:before {
          content: '';
          position: absolute;
          border-radius: 50%;
          background: #EFF2FA;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        svg {
          position: relative;
          margin: auto;
        }
      }

      &__text {
        max-width: 500px;
        line-height: 1.4;
      }

      .only-mobile({
        flex-direction: column;
        padding: 20px 0px;

        &__icon {
          margin-right: 0;
          margin-bottom: 30px;
        }
      })
    }

    .only-mobile({
      .reviews-page-meta {
        border-bottom: 0;
      }
      .reviews-page-info {
        border-bottom: 0;
      }
    })
  }
}
